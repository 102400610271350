import React from 'react';
import PropTypes from 'prop-types';
import BaseButtonIcon from './BaseButtonIcon';
import FullscreenExit from '../svg/fullscreen_exit-24px.svg';
import FullscreenEnter from '../svg/fullscreen-24px.svg';
import useFullscreen from '../hooks/useFullscreen';

export default function ButtonToggleFullscreen ( props ) {
  const { isFullscreen, isFullscreenToggle } = useFullscreen();
  const { onClick = isFullscreenToggle } = props;
  const IconFullscreen = isFullscreen ? FullscreenExit : FullscreenEnter;
  const title = isFullscreen ? 'exit fullscreen' : 'enter fullscreen';

  return (
    <BaseButtonIcon
      {...props}
      onClick={() => onClick({ value: !isFullscreen })}
      title={title}>
      <IconFullscreen width="25px" height="25px" />
    </BaseButtonIcon>
  );
}

ButtonToggleFullscreen.propTypes = {
  onClick: PropTypes.func
};
