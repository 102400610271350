import ismobilejs from 'ismobilejs';
import { ICONIC_ENGINE_SIGNIN_URL } from './env';

const ismobile = ismobilejs();

const ERROR = 'error';
const INFO = 'info';

const STOREURI_ANDROID = 'market://details?id=com.immersivemedia.android';
const STOREURI_APPLE = 'https://itunes.apple.com/us/app/im360/id437314677?ls=1&mt=8';
const STOREURI = ismobile.apple.device
  ? STOREURI_ANDROID
  : STOREURI_APPLE;

const BLOCKTYPECOMPAT = 'compatblock';
const BlockCompat = () => ({
  blocktype: BLOCKTYPECOMPAT,
  canFallBackToMp4: false,
  canRedirectToMobileApp: ismobile.android.device || ismobile.apple.device,
  canChrome: false
});

const BlockToast = type => ({
  autoClose: type === INFO
});

const getMeta = ( type, code, name, message, opts ) =>
  opts && opts[BLOCKTYPECOMPAT]
    ? BlockCompat() : ( opts || {});

const errors = [ [
  INFO, 10,
  'LoadingStory',
  'Loading interactive video...', {
    closeOnClick: false,
    pauseOnHover: false
  }
], [
  ERROR, 11,
  'UploadFailed',
  'Upload failed!'
], [
  ERROR, 12,
  'LoadingStory',
  'Story not found!'
], [
  ERROR, 13,
  'HotspotDestNotFound',
  'Destination not found!'
], [
  ERROR, 14,
  'UnauthorizedStory',
  'Only authorized accounts may edit this story'
], [
  ERROR, 15,
  'VideoHLSUnsupported',
  'HLS video is unsupported'
], [
  ERROR, 16,
  'VideoHTTPUnsupported',
  'HTTP video is unsupported'
], [
  ERROR, 17,
  'ForecastDeviceQualityDifficulty',
  'Your device may have trouble playing this video.'
], [
  ERROR, 18,
  'StoryDataUnsupported',
  'Story data is unsupported'
], [
  ERROR, 19,
  'LoadingSession',
  'Invalid session, you must be <a href=":uri" target="_blank">signed in</a>', {
    uri: ICONIC_ENGINE_SIGNIN_URL
  }
], [
  INFO, 20,
  'LoadingVideo',
  'Loading video...'
], [
  INFO, 21,
  'LoadingScene',
  'Loading scene...', {
    pauseOnHover: false
  }
], [
  INFO, 22,
  'UploadFinished',
  'Upload finished!', {
    pauseOnHover: false
  }
], [
  ERROR, 23,
  'MediaNotFound',
  'Media Not Found'
], [
  ERROR, 24,
  'DestSceneNotFound',
  'Destination Scene Not Found', {
    reason: [
      'a hotspot or scene playback directs to a scene that is',
      'since-deleted or otherwise not found.'
    ].join( ' ' )
  }
], [
  ERROR, 25,
  'QualityLevelsNotFound',
  'A supported media type is not found', {
    reason: [
      'the browser does not recognize an un-common video codec',
      'such as "video/x-matroska"'
    ].join( ' ' )
  }
], [
  INFO, 26,
  'ActionNotFound',
  'No action was found', {
    reason: 'A hotspot with no action was clicked'
  }
], [
  INFO, 27,
  'DestSceneNotFound',
  'No destination was found', {
    reason: 'A gotoscene hotspot with no destination was clicked'
  }
], [
  INFO, 28,
  'CreatingScene',
  'Creating Scene', {
    reason: 'A new scene is being created'
  }
], [
  INFO, 29,
  'DeletingScene',
  'Deleting Scene', {
    reason: 'A new scene is being deleted'
  }
], [
  // //////////////////////////////////////
  // //////////////////////////////////////
  //
  // compat blocks from lasher,
  //
  //   https://code.venom360.com/platform/lasher \
  //     /blob/develop/src/index.js#L830-841
  ERROR, 30,
  'MediaStopped',
  'Media Stopped', {
    reason: 'service metadata includes stopped status'
  }
], [
  ERROR, 31,
  'MediaNotReady',
  'Media Is Not Ready', {
    reason: 'service metadata does not have a ready status'
  }
], [
  ERROR, 32,
  'MediaStartInFuture',
  'Media starts in the future', {
    reason: 'service metadata shows status not yet started'
  }
], [
  ERROR, 33,
  'MediaEndInPast',
  'Media has ended', {
    reason: 'service metadata shows status past'
  }
], [
  // //////////////////////////////////////
  // //////////////////////////////////////
  //
  // compat blocks from lasher getBlocking
  //
  // * SAFARI_IOS_4K_VIDEO: 52,
  // * SAFARI_IOS_CROSS_ORIGIN: 51,
  // * SAFARI_ON_INTEL_GPU: 50,
  // * CANNOT_PLAY_VIDEO_TYPE: 49,
  // * NON_INLINE_APPLE_DEVICE: 40,
  // * STOCK_ANDROID_BROWSER: 41,
  // * NON_MOBILE_SUPPORTED_ANDROID: 42,
  // * HLS_JS_NOT_SUPPORTED: 47,
  // * OLD_IE: 53,
  // * FIREFOX_MOBILE: 54,
  // * IOS_HLS: 55,
  // * PLATFORM_NOT_SUPPORTED: 56,
  // * AD_HOC: 57,
  // * IOS_4K: 58
  //
  ERROR, 52,
  'SAFARI_IOS_4K_VIDEO',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT
  }
], [
  ERROR, 51,
  'SAFARI_IOS_CROSS_ORIGIN',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT,
    canRedirectToMobileApp: true,
    reason: 'Cross-origin video is broken on iOS Safari. CanvasRenderer can be used to work around it but lags a lot so we block it. https://bugs.webkit.org/show_bug.cgi?id=135379'
  }
], [
  ERROR, 50,
  'SAFARI_ON_INTEL_GPU',
  'Your browser is unsupported for this video. Please try Google Chrome. (:code)', {
    BLOCKTYPECOMPAT,
    canFallBackToMp4: true,
    canChrome: true,
    reason: 'Desktop + HLS + Safari + Intel GPU = lag, so we disable this.'
  }
], [
  ERROR, 49,
  'CANNOT_PLAY_VIDEO_TYPE',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT
  }
], [
  ERROR, 40,
  'NON_INLINE_APPLE_DEVICE',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT,
    canRedirectToMobileApp: true,
    reason: "Older iOS versions don't support inline video, required for us."
  }
], [
  ERROR, 41,
  'STOCK_ANDROID_BROWSER',
  'Your browser is unsupported for this video. Please try Google Chrome. (:code)', {
    BLOCKTYPECOMPAT,
    canRedirectToMobileApp: true,
    canChrome: true,
    reason: 'Super old'
  }
], [
  ERROR, 42,
  'NON_MOBILE_SUPPORTED_ANDROID',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT
  }
], [
  ERROR, 47,
  'HLS_JS_NOT_SUPPORTED',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT
  }
], [
  ERROR, 53,
  'OLD_IE',
  'Your browser is unsupported for this video. Please try Google Chrome. (:code)', {
    BLOCKTYPECOMPAT,
    canChrome: true,
    reason: 'No WebGL'
  }
], [
  ERROR, 54,
  'FIREFOX_MOBILE',
  'Your browser is unsupported for this video. Please try Google Chrome. (:code)', {
    BLOCKTYPECOMPAT,
    canChrome: true,
    reason: "Doesn't render, unsure why."
  }
], [
  ERROR, 55,
  'IOS_HLS',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT,
    canFallBackToMp4: true,
    canRedirectToMobileApp: true,
    reason: 'Many problems, missing video, error messages, crashing safari page when switching streams.'
  }
], [
  ERROR, 56,
  'PLATFORM_NOT_SUPPORTED',
  'Your browser is unsupported for this video. Please try Google Chrome. (:code)', {
    BLOCKTYPECOMPAT,
    canRedirectToMobileApp: ismobile.any,
    canChrome: true,
    reason: 'HLS or media format not supported'
  }
], [
  ERROR, 57,
  'AD_HOC',
  'Your machine or browser is unsupported for this video. (:code)', {
    BLOCKTYPECOMPAT,
    reason: 'See cfg.adhocBlocked'
  }
], [
  INFO, 58,
  'IOS_4K',
  'Your device may have trouble playing this video. (:code)', {
    BLOCKTYPECOMPAT
  }
], [
  ERROR, 60,
  'TRY_MOBILE_APP',
  [ '<p>Your device or browser is unsupported for HTML5 video. Try Our app! (:code)</p>',
    '<a href=":uri">Get The App</a>'
  ].join( '\n' ), {
    BLOCKTYPECOMPAT,
    uri: STOREURI
  }
], [
  ERROR, 61,
  'InvalidVod',
  'Invalid VOD data', {
    reason: 'incomplete data'
  }
], [
  ERROR, 62,
  'InvalidStory',
  'Invalid Story data', {
    reason: 'incomplete data'
  }
], [
  ERROR, 63,
  'MediaNotPlayable',
  'Media Not Playable', {
    reason: 'player data are missing some details or the type of data is un-supported'
  }
], [
  ERROR, 64,
  'InvalidCodecH265',
  'The uploaded master file is H265, which is not supported by web. Please transcode before attempting playback or upload a version using H264.', {
    reason: 'invalid codec h265'
  }
], [
  ERROR, 14,
  'UnauthorizedApplicationToken',
  'Authorization is required'
], [
  INFO, 10,
  'LoadingProperties',
  'Loading properties...', {
    closeOnClick: false,
    pauseOnHover: false
  }
] ].map( ([ type, code, name, message, opts ]) => ({
  name: `${type}${name}`,
  type,
  message: message
    .replace( /:code/, code )
    .replace( /:uri/, opts && opts.uri ),
  code, // number
  ...getMeta( type, code, name, message, opts ),
  toastOpts: Object.assign( BlockToast( type ), opts || {})
}) );

const customError = message => ({
  type: 'error',
  message,
  name: `error${message}`,
  toastOpts: {
    autoClose: true
  }
});

const getError = errorType => typeof errorType === 'string'
  ? errors.find( error => error.name === errorType )
  : errors.find( error => error.code === errorType );

//
// generate a mapping of message types
//
// ex,
//
//   {
//     infoLoadingScene: 'infoLoadingScene',
//     infoLoadingVideo: 'infoLoadingVideo',
//     ...
//   }
//
const errorType = errors.reduce(
  ( tmt, { name }) => Object.assign( tmt, { [name]: name }), {}
);

export {
  errors, errorType, getError, customError
};
