import React from 'react';
import PropTypes from 'prop-types';
import PauseSVG from '../svg/baseline-pause-24px.svg';
import PlaySVG from '../svg/baseline-play_arrow-24px.svg';
import ReplaySVG from '../svg/baseline-replay-24px.svg';
import BaseButtonIcon from './BaseButtonIcon';

export default function ButtonTogglePlayPauseReplay ( props ) {
  const { hasPlayed, isPlaying } = props;
  const title = isPlaying ? 'pause' : hasPlayed ? 'replay' : 'play';
  const BttnSVG = isPlaying ? PauseSVG : hasPlayed ? ReplaySVG : PlaySVG;

  return (
    <BaseButtonIcon {...props} title={title} >
      <BttnSVG width="25px" height="25px" />
    </BaseButtonIcon>
  );
}

ButtonTogglePlayPauseReplay.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  hasPlayed: PropTypes.bool.isRequired
};
