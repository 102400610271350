import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { queryToken, queryTokenPasscode } from '../env';
import { useLocalToastAction } from './useLocalToast';
import { getApplicationId, getApplicationAmplitudeProjectId } from '../utils/contentUtils';

export const AUTHENTICATE_APPLICATION = gql`
mutation( $input: AuthenticateApplicationInput! ) {
    authenticateApplication( input:$input ) {
        token
        application { id name amplitude { projectId } }
    }
}`;

export default function useAuthenticateApplication () {
  const applicationToken = queryToken;
  const { showToast } = useLocalToastAction();
  const [ authState, authStateSet ] = useState({ authToken: null, appId: null });
  const [ authenticateApp, authenticateAppState ] = useMutation( AUTHENTICATE_APPLICATION, {
    fetchPolicy: 'no-cache'
  });

  useEffect( () => { // occurs when token is changed
    if ( applicationToken ) {
      authenticateApp({
        variables: {
          input: {
            token: applicationToken,
            testingPasscode: queryTokenPasscode
          }
        }
      });
    }
  }, [ applicationToken ]);

  useEffect( () => {
    const { authenticateApplication } = authenticateAppState.data || {};
    const { application, token } = authenticateApplication || {};

    if ( authenticateApplication ) {
      if ( token ) {
        authStateSet({
          authToken: token,
          appId: getApplicationId( application ),
          appAmplitudeKey: getApplicationAmplitudeProjectId( application )
        });
      } else {
        showToast( 'errorUnauthorizedApplicationToken' );
      }
    }
  }, [ authenticateAppState.data ]);

  return authState;
}
