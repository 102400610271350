const formatAbbreviated = 'm:ss';
const formatNarrow = 'mm:ss';
const formatWide = 'h:mm:ss';
const formatFullWide = 'hh:mm:ss';
const formatDefault = 'ss';

const getBestFormat = ssnum => {
  let format = formatDefault;

  if ( ssnum >= 36000 ) { // 10 hours
    format = formatFullWide;
  } else if ( ssnum >= 3600 ) { // lt 10 hours
    format = formatWide;
  } else if ( ssnum >= 600 ) { // 10 min
    format = formatNarrow;
  } else if ( ssnum >= 60 ) { // lt 10 min
    format = formatAbbreviated;
  }

  return format;
};

const getAsNum = ( val, defval ) => {
  if ( typeof val === 'number' ) {
    defval = val;
  } else if ( !Number.isNaN( parseFloat( val ) ) && Number.isFinite( val ) ) {
    defval = +val;
  }

  return defval;
};

const getFormattedTimeShortened = formatted =>
  formatted
    .replace( '00:', '' )
    .replace( '00:', '' );

const getTimecodeFormatted = ( ssnum, format, isDropZeros ) => {
  let timeformat = format || getBestFormat( ssnum );
  const numpad = num => ( String( num ).length > 1 ? '' : '0' ) + num;
  let { hh, mm, ss } = {};

  hh = Math.floor( ssnum / 3600 );
  ssnum -= hh * 3600;
  mm = Math.floor( ssnum / 60 );
  ssnum -= mm * 60;
  ss = Math.floor( ssnum );

  timeformat = timeformat
    .replace( /hh/, numpad( hh ) )
    .replace( /h/, hh )
    .replace( /mm/, numpad( mm ) )
    .replace( /m/, mm )
    .replace( /ss/, numpad( ss ) );

  if ( !format || isDropZeros ) {
    timeformat = getFormattedTimeShortened( timeformat );
  }

  return timeformat;
};

const round = num => Math.round( num * 100 ) / 100;

const getParseSeconds = ss => ({
  mm: Math.floor( ss / 60 ),
  ss: Math.round( ss % 60 )
});

const getUnParseSeconds = ({ mm, ss }) => round( ( mm * 60 ) + ss );

export {
  getAsNum,
  getBestFormat,
  getTimecodeFormatted,
  getFormattedTimeShortened,
  getParseSeconds,
  getUnParseSeconds,

  formatAbbreviated,
  formatNarrow,
  formatWide,
  formatFullWide,
  formatDefault
};
