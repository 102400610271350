import parseURL from 'url-parse';
import * as m3u8Parser from 'm3u8-parser';
import { getFriendlyNameForResolution } from './video'; // eslint-disable-line import/no-cycle

import { // eslint-disable-line import/no-cycle
  videolevelCreateVariantId,
  videolevelsFilterVariant
} from './videolevel.js';

import {
  videolevelCodecsParse
} from './videolevelCodecs.js';

const hlsMimeTypes = [
  // Apple santioned
  'application/vnd.apple.mpegurl',
  // Apple sanctioned for backwards compatibility
  'audio/mpegurl',
  // Very common
  'audio/x-mpegurl',
  // Very common
  'application/x-mpegurl',
  // Included for completeness
  'video/x-mpegurl',
  'video/mpegurl',
  'application/mpegurl'
];

const isMimeHls = mime =>
  hlsMimeTypes.includes( String( mime ).toLowerCase() );

function parseHLSPlaylist ( playlist, index, sourceOrigin, sourceBaseURL ) {
  const attributes = ( playlist && playlist.attributes ) || {};
  const { RESOLUTION: resolution, BANDWIDTH: bitrate } = attributes;
  const codecs = videolevelCodecsParse( attributes.CODECS );

  const url = playlist.uri.startsWith( 'http' )
    ? playlist.uri // absolute URI
    : playlist.uri.startsWith( '/' )
      ? `${sourceOrigin}${playlist.uri}` // root URI
      : `${sourceBaseURL}/${playlist.uri}`; // relative URI;

  return resolution && {
    url,
    file: { url },
    resolution,
    bitrate,
    codecs,
    frameRate: attributes['FRAME-RATE'],
    variantId: videolevelCreateVariantId( codecs, resolution, bitrate ),
    type: 'application/x-mpegURL',
    label: getFriendlyNameForResolution( resolution ),
    id: index + 1,
    value: index + 1 // Auto is first
  };
}

const parsePlaylistQualityLevels = ( masterplaylist, playlistURL, defaultResolution ) => {
  const parser = new m3u8Parser.Parser();
  parser.push( masterplaylist );
  parser.end();

  const sourceBaseURL = playlistURL.split( '/' ).slice( 0, -1 ).join( '/' );
  const sourceOrigin = parseURL( playlistURL ).origin;

  if ( !parser.manifest.playlists ) { // no quality levels
    return [];
  }

  let qualityLevels = parser.manifest.playlists
    .map( ( playlist, i ) => parseHLSPlaylist( playlist, i, sourceOrigin, sourceBaseURL ) )
    .filter( x => x );

  qualityLevels = videolevelsFilterVariant( qualityLevels );

  // qualityLevels = Object.values( qualityLevels.reduce( ( obj, level ) => {
  //     if ( !obj[level.resolution.height] || level.bitrate > obj[level.resolution.height].bitrate ) {
  //         obj[level.resolution.height] = level;
  //     }
  //     return obj;
  // }, {}) );

  if ( !qualityLevels.length ) {
    return qualityLevels;
  }

  // Sort by height, descending
  qualityLevels.sort( ( a, b ) => b.resolution.height - a.resolution.height );

  // Prepend auto
  const auto = {
    url: playlistURL,
    type: 'application/x-mpegURL',
    codecs: qualityLevels[0].codecs,
    bitrate: qualityLevels[0].bitrate,
    frameRate: qualityLevels[0].frameRate,
    resolution: defaultResolution || qualityLevels[0].resolution,
    label: 'Auto',
    id: 0,
    value: 0
  };

  qualityLevels = [ auto, ...qualityLevels ];

  return qualityLevels;
};

export { isMimeHls, parsePlaylistQualityLevels };
