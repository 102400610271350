import castas from 'castas';

const isCoordSizeValid = coords => Array.isArray( coords ) && coords.length > 1;

const asValidNumberCoords = ( coords, def = [ 0, 0 ]) => (
  isCoordSizeValid( coords )
    ? coords.map( n => Number( n ) )
    : def );

const asValidIntCoords = ( coords, def = [ 0, 0 ]) => (
  isCoordSizeValid( coords )
    ? coords.map( n => parseInt( n, 10 ) )
    : def );

const hotspotToService = ( data = {}) => ({
  coords: asValidIntCoords( data.pos, [ 0, 0, -200 ]),
  pos: asValidIntCoords( data.pos, [ 0, 0, -200 ]),
  name: castas.str( data.name, 'new hotspot' ),
  isactive: true,
  description: castas.str( data.description, 'description' ),
  id: data.id || null,
  dest_scene: data.dest_scene || 'null',
  dest_start: typeof data.dest_start === 'number' ? data.dest_start : 0,
  // below fields are required by service
  dimensions: asValidNumberCoords( data.dimensions, [ 100, 100, 200 ]),
  postype: 'Cartesian',
  type: castas.str( data.type, 'actionportal' ),
  hidden: data.hidden,
  shape: data.shape || [],
  color: data.color || '#ffffff',
  opacity: data.opacity || 1,
  audio: data.audio || {
    click: null,
    mouseOver: null
  },
  infobox: data.infobox || {
    linkedImage: null,
    constrainProportions: true,
    height: 100,
    width: 100
  }
});

const hotspotInfoboxFromService = servdata => {
  const infobox = servdata || {};

  infobox.height = castas.num( infobox.height, 100 );
  infobox.width = castas.num( infobox.width, 100 );

  // should not be persisted at service
  // if properties are constrained, persist resulting
  // constrained properties only
  infobox.constrainProportions = castas.bool( infobox.constrainProportions, true );

  return infobox;
};

const hotspotDataSanitised = hotspotData => ({
  ...hotspotData,
  name: hotspotData.name || 'hotspot',
  description: castas.str( hotspotData.description, 'no description' ),
  shape: Array.isArray( hotspotData.shape )
    ? hotspotData.shape.filter( e => e ).map( asValidIntCoords )
    : [],
  pos: asValidNumberCoords( hotspotData.pos ),
  dimensions: asValidIntCoords( hotspotData.dimensions, [ 200, 200 ]),
  start: Number( hotspotData.start || 0 ),
  end: Number( hotspotData.end || 30 ),
  modified_ts: hotspotData.modified_ts || Date.now(),
  dest_scene: hotspotData.dest_scene,
  dest_start: hotspotData.dest_start,
  hidden: hotspotData.hidden || false,
  color: hotspotData.color || '#ffffff',
  opacity: castas.num( hotspotData.opacity, 1 ),
  audio: hotspotData.audio || {
    click: null,
    mouseOver: null
  },
  infobox: hotspotInfoboxFromService( hotspotData.infobox )
});

const sequenceToService = ( data = {}) => ({
  ...data,
  uuid: data.uuid || null,
  start: data.start || 0,
  end: data.end || 30,
  isactive: true,
  name: castas.str( data.name, 'new hotspot' ),
  description: castas.str( data.description, 'description' ),
  type: castas.str( data.type, 'actionportal' ),

  // below fields required by service
  dest_scene: data.dest_scene || null,
  dest_start: castas.num( data.dest_start, 0 )
});

const sequenceDataSanitised = ( data = {}) => ({
  ...data,
  uuid: data.uuid || null,
  start: castas.num( data.start, 0 ),
  end: castas.num( data.end, 30 ),
  isactive: true,
  name: castas.str( data.name, 'new hotspot' ),
  description: castas.str( data.description, 'description' ),
  type: castas.str( data.type, 'actionportal' ),
  dest_scene: data.dest_scene || null,
  dest_start: castas.num( data.dest_start, 0 )
});

const sceneToService = ( data = {}) => ({
  name: castas.str( data.name, 'defaultname' ),
  postplayback: data.postplayback || {},
  postplaybackbehavior: data.postplayback || {}
});

const sceneFromService = servdata => ({
  name: castas.str( servdata.name, 'defaultname' ),
  id: servdata.id || Date.now(),
  create_ts: servdata.created_ts || Date.now(),
  modified_ts: servdata.modified_ts || Date.now(),
  media_dict: servdata.media_dict || {},
  hotspots_dict: servdata.hotspots_dict || {},
  isactive: servdata.isactive || true,
  postplayback: servdata.postplayback || {},
  sequences: servdata.sequences || []
});

const getOrgMediaLibrary = servdata => ({
  id: servdata.id || null,
  root_folder_id: servdata.root_folder_id || null
});

const getAllMediaItems = servdata => {
  const { media_items } = servdata.media_folder;
  const audio = media_items.filter( m => m.media_class === 'audio' );
  const image = media_items.filter( m => m.media_class === 'image' );
  const video = media_items.filter( m => m.media_class === 'video' );

  return { audio, image, video };
};

export {
  hotspotToService,
  hotspotInfoboxFromService,
  hotspotDataSanitised,

  sequenceToService,
  sequenceDataSanitised,

  sceneToService,
  sceneFromService,

  getOrgMediaLibrary,
  getAllMediaItems
};
