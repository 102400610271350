import * as adapter from '../adapter';
import { toastReducer } from './toast';
import { getVideoAdvertDefault, getAdvertDefault } from './advert';
import { isVideoHTTP, addVideo, setIsPlaying, isMediaVideo } from './videoplayback';
import { toastMessageType } from '../components/toasts';
import { getMediaProjectionTypeFromLegacyProjection } from '../utils/legacyProjectionUtils';
import { MediaProjectionTypeEnum } from '../enums';

import {
  getSceneMedia,
  getSceneMainVideoInfo
} from '../utils/sceneUtils';

import {
  isSequenceHotspot
} from '../utils/seqUtils';

import {
  START_HOTSPOT,
  END_HOTSPOT,
  ADD_ERROR_TOAST
} from '../actions/ActionTypes';

import { getBrand, getPoster } from '../utils/mediaUtils';
import { createMoment, sortMoments } from '../utils/moments';

const momentsAddSequenceHotspot = ( moments, sequence ) => {
  moments.push( createMoment({
    time: sequence.start,
    meta: {
      action: START_HOTSPOT,
      sequenceId: sequence.uuid,
      hotspotId: sequence.uuid
    }
  }) );

  moments.push( createMoment({
    time: sequence.end,
    meta: {
      action: END_HOTSPOT,
      sequenceId: sequence.uuid,
      hotspotId: sequence.uuid
    }
  }) );

  return moments;
};

const momentsRemoveSequenceHotspot = ( moments, sequence ) => moments
  .filter( moment => moment.meta.sequenceId !== sequence.uuid );


const getSceneDefault = () => ({
  // three types of native format:
  //
  //   1. 'story', story canonical format of data
  //   2. 'video', vod or stream metadata are canonical format of data
  //   3. 'ad', ad metadata, usually defined on a vod or stream metadata
  //
  nativeFormat: 'story', // 'story', 'video' (vod or stream) or 'ad'
  name: 'defaultname',
  created_ts: Date.now(),
  modified_ts: Date.now(),
  media_dict: {},
  hotspots_dict: {},
  hotspots_modified_ts: Date.now(),
  id: Date.now(),
  isactive: true,
  isPlayed: false,
  moments: [],
  isMountComplete: false,
  postplayback: {},
  sequences: [],
  sequences_modified_ts: Date.now(),
  projectionState: MediaProjectionTypeEnum.EQUIRECTANGULAR
});

// applyScene helps to avoid writing this...
//
// state = {
//     ...state,
//     scenes : {
//         ...state.scenes,
//         [scene.id]: { ...scene }
//     }
// };
//
// instead use this...
//
// assignScene( state, scene, {
//     isMountComplete : true
// })
//
const applyScene = ( state, scene, obj = {}) => ({
  ...state,
  scenes: {
    ...state.scenes,
    [scene.id]: {
      ...scene,
      ...obj
    }
  }
});

// by requiring sceneId rather than scene ... this makes
// it more obvious that 'scene' given here is not mutated
//
const applyToSceneId = ( state, sceneId, obj = {}) =>
  applyScene( state, state.scenes[sceneId], obj );

// return a new scene state w/ sequence added
//
const setSceneSequence = ( state, sceneId, sequenceId, seq ) => {
  let { sequences, moments } = state.scenes[sceneId];

  sequences = sequences.filter( sceneseq => (
    sceneseq.id !== sequenceId && sceneseq.uuid !== sequenceId
  ) );

  moments = momentsRemoveSequenceHotspot( moments, seq );
  moments = momentsAddSequenceHotspot( moments, seq );

  sequences.push( adapter.sequenceDataSanitised( seq ) );

  return applyToSceneId( state, sceneId, {
    sequences_modified_ts: Date.now(),
    sequences,
    moments: sortMoments( moments )
  });
};

// return a new scene state w/ hotspot added
const setSceneHotspot = ( state, sceneId, hotspotId, hotspot ) => {
  return applyToSceneId( state, sceneId, {
    hotspots_modified_ts: Date.now(),
    hotspots_dict: {
      ...state.scenes[sceneId].hotspots_dict,
      [hotspotId]: adapter.hotspotDataSanitised( hotspot )
    }
  });
}

// return a new scene w/ redefined media item
const applySceneMedia = ( scene, media ) => ({
  ...scene,
  media_dict: {
    ...scene.media_dict,
    [media.id]: {
      ...media
    }
  }
});

const isSceneDataCompatible = sceneData =>
  !Object.keys( sceneData.media_dict ).length;

// livestream data include array of 'cameras' definitions,
// that use slightly different property names --adapt those
const getVideoAdapted = ( videodata, embedType ) => {
  videodata.file_url = videodata.file_url || videodata.filepath;
  videodata.mime_type = videodata.mime_type || videodata.type;
  videodata.brand = getBrand( videodata );
  videodata.poster = getPoster( videodata );
  videodata.embedType = embedType;

  return videodata;
};

// scene ex,
//
// {
//   "name": "MP4 With Pre-Roll Ads",
//   "created_ts": 1510276250256,
//   "modified_ts": 1510276250256,
//   "media_dict": {
//     "2908ff4b-7281-47a4-b127-d3a37f377b2c": { ... }
//   },
//   "hotspots_dict": {},
//   "id": "2908ff4b-7281-47a4-b127-d3a37f377b2c",
//   "isactive": false,
//   "postplayback": {
//     "type": 4,
//     "dest_scene": false
//   },
//   "sequences": [{
//       "name": "video",
//       "type": "video",
//       "uuid": "2908ff4b-7281-47a4-b127-d3a37f377b2c",
//       "start": 0,
//       "end": 63.488,
//       "isactive": true
//   }],
//   "has_ads": true,
//   "adsid": "hyd0fqhtmjnjhdlucbf2"
// }
//
const getSceneAdapted = ( scenedata, embedType = scenedata.embedType ) => {
  const videoMedia = getSceneMainVideoInfo( scenedata ) || {};

  return Object.assign( getSceneDefault(), {
    ...scenedata,
    embedType,
    // loop through medias and adapt video-style media
    // normalise multi-camera video media
    projectionState: getMediaProjectionTypeFromLegacyProjection(
      videoMedia.projection,
      MediaProjectionTypeEnum.EQUIRECTANGULAR
    ),

    media_dict: Object.keys( scenedata.media_dict ).reduce( ( prev, key ) => {
      const media = scenedata.media_dict[key];

      prev[key] = isMediaVideo( media )
        ? getVideoAdapted( media, 'story' )
        : media;

      return prev;
    }, {}),
    isactive: Boolean( scenedata.isactive ),
    postplayback: {
      type: scenedata.postplayback.type || 0,
      dest_scene: scenedata.postplayback.dest_scene || false
    }
  });
};

const getSceneGQLAdapted = ( scenedata, embedType = scenedata.embetType ) => Object.assign( getSceneDefault(), {
  ...scenedata,
  embedType,
  // loop through medias and adapt video-style media
  // normalise multi-camera video media
  projectionState: MediaProjectionTypeEnum.EQUIRECTANGULAR,
  isactive: Boolean( scenedata.isactive ),
  postplayback: {
    type: scenedata.postPlayback.action || 0,
    dest_scene: (
      scenedata.postPlayback
                && scenedata.postPlayback.destinationScene
                && scenedata.postPlayback.destinationScene.id )
            || false
  }
});

// link scenes using kludgy postplayback
//
// postplayback of endscene will be lost, so if you're linking
// multiple scenes, call this function in a reverse ordered way so
// the first scene is used in the last call
//
const linkScenePostplayback = ( startScene, endScene ) => ([ {
  ...startScene,
  postplayback: { type: 1, dest_scene: endScene.id }
}, {
  ...endScene,
  postplayback: startScene.postplayback
} ]);


// containerType is 'pre_roll' or 'post_roll'
//
// return advert metadata as scene data
//
const getAdvertAsScene = ( advert, embedType = 'ad', containerType ) => {
  const sceneId = advert.id;

  return {
    id: sceneId,
    nativeFormat: 'advert',
    embedType,
    name: advert.name,
    postplayback: { type: 4 }, // disabled

    projectionState: getMediaProjectionTypeFromLegacyProjection(
      advert.projection,
      MediaProjectionTypeEnum.EQUIRECTANGULAR
    ),
    media_dict: {
      [sceneId]: getAdvertDefault( advert, containerType )
    },

    sequences_modified_ts: Date.now(),
    sequences: [ {
      ad_type: advert.ad_type,
      name: advert.name,
      type: /video/gi.test( advert.ad_type )
        ? 'video-advert'
        : 'image-advert',
      uuid: sceneId,
      start: 0,
      end: advert.duration,
      isactive: true
    } ]
  };
};

// return video metadata as scene data
//
// 'camerastreamid' are for video stream metadata that includes 'cameras' array of
// other stream video metadata
//
// example metadata w/ 'cameras' is found at this query
//
//  * ?h=4ku232lewutfja64yozl&islive=true
//
// when 'cameras' is found, a scene is constructed around each camera,
// so that cameras compose the story/scene style data used by rest of this
// application, allowing camera control and transitions using the scene pipeline
//
const getVideoAsScene = ( video, camerastreamid = false ) => {
  const sceneId = video.id;
  const { app_id } = video;

  return {
    app_id,
    id: sceneId,
    camerastreamid,
    nativeFormat: 'video',
    adverts: video.has_ads && getVideoAdvertDefault( video, sceneId ),

    embedType: video.media_type,
    name: video.title,
    postplayback: { type: 4 }, // disabled

    projectionState: getMediaProjectionTypeFromLegacyProjection(
      video.projection,
      MediaProjectionTypeEnum.EQUIRECTANGULAR
    ),
    media_dict: {
      [sceneId]: getVideoAdapted( video, video.media_type )
    },

    sequences_modified_ts: Date.now(),
    sequences: [ {
      name: 'video',
      type: 'video',
      uuid: sceneId,
      start: 0,
      end: video.duration,
      isactive: true
    } ]
  };
};

// use this function when focusing a specific scene
//
// loops sequences on the scene to redefine hotspots namespace
const mountStateScene = ( state, scene /* startss = 0 */ ) => {
  const scenehotspots = {};
  const prevSceneId = String( state.select.sceneId );
  let toasterror = null;

  const media = getSceneMedia( scene );

  if ( media ) {
    if ( isVideoHTTP( media ) ) {
      toasterror = toastMessageType.errorVideoHTTPUnsupported;
    }

    state = {
      ...state,
      videoplayback: {
        ...state.videoplayback,
        ...addVideo( state.videoplayback, media, scene )
      }
    };
  }

  // sanitize sequences and hotspots
  const sceneData = scene.sequences.reduce( ( obj, sequence ) => {
    obj.sanitisedSequences.push( adapter.sequenceDataSanitised( sequence ) );

    if ( isSequenceHotspot( sequence ) ) {
      obj.sanitisedHotspots[sequence.uuid] = adapter
        .hotspotDataSanitised( scene.hotspots_dict[sequence.uuid]);

      obj.moments = momentsAddSequenceHotspot( obj.moments, sequence );
    }

    return obj;
  }, {
    moments: [],
    sanitisedSequences: [],
    sanitisedHotspots: {}
  });

  state = applyScene( state, scene, {
    moments: sortMoments( sceneData.moments ),
    sequences: sceneData.sanitisedSequences,
    sequences_modified_ts: Date.now(),
    hotspots_modified_ts: Date.now(),
    hotspots_dict: {
      ...scene.hotspots_dict,
      ...sceneData.sanitisedHotspots
    }
  });

  return {
    ...state,
    istransition: true,
    hotspots: scenehotspots,
    moments: [],
    toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: toasterror }),
    select: {
      ...state.select,
      prevSceneId,
      hotspotId: null,
      video: getSceneMainVideoInfo( scene ),
      sceneId: scene.id
    }
  };
};

const assignSelection = ( state, delta ) => ({
  ...state,
  select: {
    ...state.select,
    ...delta
  }
});

const removeSceneSequence = ( state, sceneId, sequenceId ) => {
  const scene = state.scenes[sceneId];
  const moments = momentsRemoveSequenceHotspot( scene.moments, { uuid: sequenceId });
  const sequences = scene.sequences.slice();

  sequences.splice( scene.sequences.findIndex( seq => seq.uuid === sequenceId ), 1 );

  // remove all moments
  state = applyScene( state, scene, {
    moments,
    sequences,
    sequences_modified_ts: Date.now()
  });

  if ( state.select.hotspotId === sequenceId ) {
    const sequenceHotspot = sequences.find( isSequenceHotspot );

    state = assignSelection( state, {
      hotspotId: sequenceHotspot ? sequenceHotspot.uuid : null
    });
  }

  return state;
};

const addPreviousScene = ( state, sceneId ) => {
  state.previousScenes = state.previousScenes.slice();
  state.previousScenes.push( sceneId );

  return state;
};

const getPreviousScene = state =>
  state.previousScenes[state.previousScenes.length - 1];

const rmPreviousScene = state => {
  state.previousScenes = state.previousScenes.slice();
  state.previousScenes.pop( );

  return state;
};

const mountNextStateScene = ( state, scene, startss = 0 ) => {
  const { sceneId } = state.select;

  state = mountStateScene( state, scene, startss );
  state = addPreviousScene( state, sceneId );

  return state;
};

const mountPreviousStateScene = state => {
  const prevSceneId = getPreviousScene( state );
  const prevScene = prevSceneId && state.scenes[prevSceneId];

  if ( prevScene ) {
    state = rmPreviousScene( state );
    state = mountStateScene( state, prevScene );
  } else {
    state = setIsPlaying( state, false );
  }

  return state;
};

const setSceneMountComplete = ( state, sceneId, isMountComplete ) => {
  const scene = state.scenes[sceneId];

  return applyScene( state, scene, {
    isMountComplete
  });
};

const setCameraRotation = ( scene, radianxy ) => ({
  ...scene,
  camerarot: radianxy
});

export {
  getSceneDefault,
  applyScene,
  applyToSceneId,
  applySceneMedia,
  setSceneSequence,
  removeSceneSequence,
  setSceneHotspot,
  isSceneDataCompatible,
  getSceneAdapted,
  getSceneGQLAdapted,
  getVideoAdapted,
  getAdvertAsScene,
  getVideoAsScene,
  linkScenePostplayback,
  mountStateScene,
  addPreviousScene,
  getPreviousScene,
  rmPreviousScene,
  mountNextStateScene,
  mountPreviousStateScene,
  setSceneMountComplete,
  setCameraRotation
};
