import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdown from './BaseDropdown';
import { MediaProjectionTypeEnum } from '../enums';
import { labelProjectionMap } from '../utils/labels';
import { isSupportedLegacyProjection } from '../utils/legacyProjectionUtils';

const projectionOptions = Object
  .values( MediaProjectionTypeEnum )
  .filter( isSupportedLegacyProjection )
  .map( type => ({ value: type, label: labelProjectionMap[type] }) );

export default function DropdownProjection ( props ) {
  return (
    <BaseDropdown {...props} options={projectionOptions} />
  );
}

DropdownProjection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  align: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string
};
