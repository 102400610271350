import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import HeatmappingOverlay from 'heatmapping-visualizer';
import styled from 'styled-components';

import {
  isMediaAd
} from '../utils/mediaUtils';

import {
  getSceneEmbedType
} from '../utils/sceneUtils';

const StageRegion = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const MediaRegion = styled.div`
    position:relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

// this component wraps the heatmapping-visualizer component to islote
// some of its operations here and to redux to emit some heatmapping
// events such as the camera-exited event
//
// for more info,
//
//   https://code.venom360.com/platform/heatmapping-visualizer
//
class Heatmap extends Component {
  // valid types,
  //  * vod
  //  * stream
  //  * stream_camera
  //  * scene
  //  * ad
  //  * ad_item
  //
  getHeatmapMediaType ( data, scene, media ) {
    let mediatype = null;
    const sceneEmbedType = getSceneEmbedType( scene );

    if ( isMediaAd( media ) ) {
      mediatype = 'ad';
    } else if ( sceneEmbedType === 'story' ) {
      mediatype = 'scene';
    } else if ( sceneEmbedType === 'live' ) {
      mediatype = scene.camerastreamid
        ? 'stream_camera'
        : 'stream';
    } else if ( sceneEmbedType === 'vod' || sceneEmbedType === 'media' ) {
      mediatype = 'vod';
    }

    return mediatype;
  }

  getHeatmapData ( data, scene, media ) {
    return {
      id: media.id,
      uri: 'https://dev-07.venom360.com/console/analytics/graphql',
      type: this.getHeatmapMediaType( data, scene, media ),
      timestamp: ( data.videoplayback.timess || 0 ) * 1000
    };
  }

  scaleWH ([ w, h ], [ minw ]) {
    const scaledh = Math.floor( h * ( minw / w ) );

    return [ minw, scaledh ];
  }

  render () {
    const {
      data, scene, media, canvastype, dimensions
    } = this.props;
    const {
      // id, timestamp, type, uri
    } = this.getHeatmapData( data, scene, media );
    const scaleWH = canvastype === 'projection'
      ? [ dimensions.width, dimensions.height ]
      : this.scaleWH(
        [ media.resolution.width, media.resolution.height ],
        [ dimensions.width, dimensions.height ]
      );

    return (
      <StageRegion>
        <MediaRegion width={scaleWH[0]} height={scaleWH[1]}>
          {/*<HeatmappingOverlay
               id={id} timestamp={timestamp} type={type} uri={uri} />*/}
        </MediaRegion>
      </StageRegion>
    );
  }
}

Heatmap.propTypes = {
  data: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  canvastype: PropTypes.string.isRequired // 'projection' | 'rectangle'
};

export default Heatmap;
