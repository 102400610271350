import ApolloClient from 'apollo-boost';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import venomApiSchema from '../venom-api-schema.json';
import { VENOM_API_BASE } from './env';
import { KEY_VENOM_USER_TOKEN } from './enums';

import { defaultLocalUser, GET_USER_STATE } from './hooks/useLocalLogin';
import { defaultLocalIVE, GET_IVE_STATE } from './hooks/useLocalIVE';
import { defaultLocalTiles, GET_TILES_STATE } from './hooks/useLocalTiles';
import { defaultLocalModals, GET_MODALS_STATE } from './hooks/useLocalModals';
import { defaultLocalStage, GET_STAGE_STATE } from './hooks/useLocalStage';
import { defaultLocalPlaylist, GET_PLAYLIST_STATE } from './hooks/useLocalPlaylist';
import { defaultLocalMediaPlayback, GET_MEDIA_PLAYBACK_STATE } from './hooks/useLocalMediaPlayback';
import { defaultLocalFeatures } from './hooks/useLocalFeatures';
import { defaultLocalToast, GET_TOAST_STATE } from './hooks/useLocalToast';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: venomApiSchema
});

export const defaults = {
  iveState: defaultLocalIVE.iveState,
  userState: defaultLocalUser.userState,
  featuresState: defaultLocalFeatures.featuresState,
  mediaPlaybackState: defaultLocalMediaPlayback.mediaPlaybackState,
  playlistState: defaultLocalPlaylist.playlistState,
  modalsState: defaultLocalModals.modalsState,
  tilesState: defaultLocalTiles.tilesState,
  stageState: defaultLocalStage.stageState,
  toastState: defaultLocalToast
};

export const resolvers = {
  Mutation: {
    userState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_USER_STATE });
      const userState = {
        ...curQuery.userState,
        ...input,
        lastUpdate: Date.now()
      };

      client.writeQuery({
        query: GET_USER_STATE,
        data: { userState }
      });

      return userState;
    },

    toastState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_TOAST_STATE });
      let { toastState } = curQuery;

      const prevToastIds = curQuery.toastState.toastIds;
      const nextToastIds = prevToastIds.filter( id => id !== input.showToast );

      nextToastIds.push( input.showToast );

      if ( prevToastIds.length !== nextToastIds.length ) {
        toastState = {
          ...curQuery.toastState,
          ...input,
          toastIds: nextToastIds,
          lastUpdate: Date.now()
        };

        client.writeQuery({
          query: GET_TOAST_STATE,
          data: { toastState }
        });
      }

      return toastState;
    },

    iveState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_IVE_STATE });
      const iveState = {
        ...curQuery.iveState,
        ...input
      };

      client.writeQuery({
        query: GET_IVE_STATE,
        data: { iveState }
      });

      return iveState;
    },

    tilesState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_TILES_STATE });
      const tilesState = {
        ...curQuery.tilesState,
        ...input
      };

      client.writeQuery({
        query: GET_TILES_STATE,
        data: { tilesState }
      });

      return tilesState;
    },

    stageState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_STAGE_STATE });
      const stageState = {
        ...curQuery.stageState,
        ...input
      };

      client.writeQuery({
        query: GET_STAGE_STATE,
        data: { stageState }
      });

      return stageState;
    },

    modalsState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_MODALS_STATE });

      const modalsState = {
        ...curQuery.modalsState,
        ...input
      };

      client.writeQuery({
        query: GET_MODALS_STATE,
        data: { modalsState }
      });

      return modalsState;
    },

    playlistState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_PLAYLIST_STATE });
      const playlistState = {
        ...curQuery.playlistState,
        ...input
      };

      client.writeQuery({
        query: GET_PLAYLIST_STATE,
        data: { playlistState }
      });

      return playlistState;
    },

    mediaPlaybackState: ( root, { input }, { client, cache }) => {
      const curQuery = cache.readQuery({ query: GET_MEDIA_PLAYBACK_STATE });
      const mediaPlaybackState = {
        ...curQuery.mediaPlaybackState,
        ...input
      };

      client.writeQuery({
        query: GET_MEDIA_PLAYBACK_STATE,
        data: { mediaPlaybackState }
      });

      return mediaPlaybackState;
    }
  }
};

// TODO migrate
// https://www.apollographql.com/docs/react/data/local-state/#migrating-from-apollo-link-state
//
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher
  }),
  uri: `${VENOM_API_BASE}/graphql`,
  clientState: {
    defaults,
    // update to match structure found here:
    // https://codesandbox.io/s/apollo-client-error-template-lcber
    resolvers
  },
  request: async operation => {
    const token = window.localStorage.getItem( KEY_VENOM_USER_TOKEN );

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });
  }
});

export default apolloClient;
