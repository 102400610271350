import { xrIsSupported } from '../env';

const xrState = {
  polyfill: null,
  currentSession: null,
  glrenderer: null
};

const xrPolyfill = async () => {
  if ( xrState.polyfill )
    return xrState.polyfill;

  const { default: WebXRPolyfill } = await import( 'webxr-polyfill' );

  xrState.polyfill = new WebXRPolyfill({
    allowCardboardOnDesktop: true
  });

  return xrState.polyfill;
};

const xrSessionIsSupported = async () => {
  if ( !xrIsSupported )
    return false;

  await xrPolyfill();

  // inline for flat mode, else immersive-vr
  // return navigator.xr.isSessionSupported( 'inline' );
  return navigator.xr.isSessionSupported( 'immersive-vr' );
};

const xrSessionOnEnded = () => {
  xrState.currentSession.removeEventListener( 'end', xrSessionOnEnded );
  xrState.currentSession = null;

  if ( xrState.exitFn ) {
    xrState.exitFn();
  }
};

const xrSessionOnStarted = session => {
  if ( xrState.glrenderer ) {
    xrState.glrenderer.xr.setSession( session );
    xrState.currentSession = session;
    xrState.currentSession.addEventListener( 'end', xrSessionOnEnded );
  }
};

const xrSessionRequest = ( glrenderer, exitFn ) => {
  xrState.glrenderer = glrenderer;
  xrState.exitFn = exitFn;

  glrenderer.xr.enabled = true;

  navigator.xr.requestSession( 'immersive-vr', {
    optionalFeatures: [ 'local-floor', 'bounded-floor' ]
  }).then( xrSessionOnStarted );

  // inline for phones and flat viewers
  //
  // navigator.xr.requestSession( 'inline', {
  //     optionalFeatures: [ 'local-floor', 'bounded-floor' ]
  // }).then( xrSessionOnStarted );
};

// not usable. the threejs renderer instance is required now
const xrSessionRequestCanvas = canvasElem => {
  const glrenderer = canvasElem instanceof Element
    ? canvasElem.getContext( 'webgl', { xrCompatible: true })
    : null;

  if ( glrenderer )
    xrSessionRequest( glrenderer );
};

// https://github.com/immersive-web/webxr/blob/master/spatial-tracking-explainer.md#bounded-reference-space
const xrSessionDeviceOrientation = ( /* glrenderer, object */ ) => {
  // const referenceSpace = glrenderer.xr.getReferenceSpace();
  // const session = glrenderer.xr.getSession();
  const session = xrState.currentSession;
  if ( !session )
    return;

  session.requestReferenceSpace( 'viewer' ).then( referenceSpace => {
    if ( session && referenceSpace ) {
      session.requestAnimationFrame( ( /* time, xrFrame */ ) => {
        // let viewer = xrFrame.getViewerPose( referenceSpace );
        // device.getFrameData( frameData );
        // object.quaternion.set( ...frameData.pose.orientation );

        return true;
      });
    }
  });
};

export {
  xrSessionIsSupported,
  xrSessionRequest,
  xrSessionRequestCanvas,
  xrSessionDeviceOrientation
};
