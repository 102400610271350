import { useState } from 'react';
import { isInsideTime, nextCursor } from '../utils/moments';
import { isVideoElem } from '../utils/elemUtils';

export default function useThreeMoment () {
  const [ state, setState ] = useState({
    index: -1 // behind first moment at index 0,
  });

  // 'nextCursor' returns new index of moment w/ greatest time, lt 'time'.
  // function is called for the moment at each index between given index
  // and new index
  //
  // startindex is the cursor index which will commonly be the last cursor
  // position. to apply all moments from '0' to 'time', use startindex -1
  //
  const cursorMoments = ( time, moments, fn, startindex ) => {
    const index = nextCursor( startindex, moments, time, moment => {
      fn( moment, isInsideTime( moment.time, time ) );
    });

    if ( index !== state.index ) setState({ index });
  };

  // should be renamed... media..
  const cursorVideoMoments = ( videoElem, moments, fn, index = state.index ) => {
    if ( isVideoElem( videoElem ) ) {
      cursorMoments( videoElem.currentTime, moments, fn, index );
    }
  };

  const cursorVideoMomentsRefresh = ( videoElem, moments, fn ) => {
    if ( isVideoElem( videoElem ) ) {
      cursorMoments( videoElem.currentTime, moments, fn, -1 );
    }
  };

  return {
    cursorState: state,
    cursorVideoMoments,
    cursorVideoMomentsRefresh
  };
}
