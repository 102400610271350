import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonCog from './ButtonCog';
import ButtonToggleVR from './ButtonToggleVR';
import ButtonToggleHeatmap from './ButtonToggleHeatmap';
import ButtonToggleVolume from './ButtonToggleVolume';
import ButtonToggleFullscreen from './ButtonToggleFullscreen';
import ButtonTogglePlayPauseReplay from './ButtonTogglePlayPauseReplay';

import LabelTime from './LabelTime';
import LabelLive from './LabelLive';
import SlideVolume from './SlideVolume';
import ButtonCameras from './ButtonCameras';

const Container = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    margin:12px;
`;

const Left = styled.ul`
    flex:1;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
`;

const Item = styled.li`
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Right = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
`;

export default function PlayerControlsButtons ({ actions, data, uipanels, durationss, timess, scene }) {
  const togglePlaying = playing => {
    actions.togglePlaying( playing );
  };

  const toggleFullscreen = () => {
    actions.fullscreenToggle( document.body );
  };

  const toggleCameras = () => {
    actions.panelToggle( 'cameras' );
  };

  const toggleMenu = () => {
    actions.panelToggle( 'settings' );
  };

  const toggleVRMode = () => {
    const canvasElem = document.getElementsByTagName( 'canvas' )[0];

    actions.vrmodeToggle( canvasElem );
  };

  const toggleHeatMode = () => {
    actions.canvasToggle();
    actions.heatmodeToggle();
  };

  const toggleVolume = () => {
    const { videoplayback } = data;

    actions.toggleMute( !videoplayback.mute );
  };

  const changeVolume = ( e, value ) => {
    actions.toggleVolume( value );
  };

  // anticipates behaviour not specified in spec... object keys returns ordered keys
  const getSceneIndex = ( scenes, scene ) => {
    return Object.keys( scenes ).findIndex( sceneId => sceneId === scene.id ) || 0;
  };

  const { vrmode, scenes, islive, heatmap, videoplayback } = data;
  const { isHeatSupport, isHeatEnabled } = heatmap;
  const { isvrsupport, isvrenabled } = vrmode;
  const { playing, mute, volume } = videoplayback;
  const hasPlayed = scene && videoplayback && scene.isPlayed && scene.isPlayed > videoplayback.lastPlayTS;

  // left: playpause time display, volume
  // right: settings, fullscreen
  return (
    <Container>
      <Left>
        <Item>
          <ButtonTogglePlayPauseReplay
            isPlaying={playing}
            hasPlayed={hasPlayed}
            onClick={togglePlaying} />
        </Item><Item>
          <LabelTime durationss={durationss} timess={timess} />
        </Item><Item>
          <ButtonToggleVolume
            isEnabled={Boolean( mute && volume )}
            onClick={toggleVolume} />
          <SlideVolume mute={mute} volume={volume} states={[ 0, 1, 2, 3, 4, 5 ]} onChange={changeVolume} />
        </Item>
        {islive && (
          <Item>
            <LabelLive />
          </Item>
        )}
      </Left>
      <Right>
        {isHeatSupport && (
          <Item>
            <ButtonToggleHeatmap
              isHeatEnabled={isHeatEnabled}
              onClick={toggleHeatMode} />
          </Item>
        )}
        {scene && Object.keys( data.scenes ).length > 1 && (
          <Item>
            <ButtonCameras
              isActive={uipanels.activePanelName === 'cameras'}
              onClick={toggleCameras}
              cameranum={getSceneIndex( scenes, scene ) + 1} />
          </Item>
        )}
        <Item>
          <ButtonCog
            title={'menu'}
            onClick={toggleMenu} />
        </Item>
        {isvrsupport && (
          <Item>
            <ButtonToggleVR
              isVREnabled={Boolean( isvrenabled )}
              onClick={toggleVRMode} />
          </Item>
        )}
        <Item>
          <ButtonToggleFullscreen onClick={toggleFullscreen} />
        </Item>
      </Right>
    </Container>
  );
}

PlayerControlsButtons.propTypes = {
  data: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  uipanels: PropTypes.object.isRequired,
  durationss: PropTypes.number.isRequired,
  timess: PropTypes.number.isRequired
};
