import { useState, useEffect } from 'react';

const win = typeof window === 'object' ? window : null;

const getWindowWH = () => win
  ? [ win.innerWidth,
    win.innerHeight ]
  : [ 0, 0 ];

export default function useWindowSize () {
  const [ windowSize, setWindowSize ] = useState( getWindowWH() );

  const addEventListeners = fn =>
    win && win.addEventListener( 'resize', fn );

  const removeEventListeners = fn =>
    win && win.removeEventListener( 'resize', fn );

  useEffect( () => {
    const handleResize = () => setWindowSize( getWindowWH() );

    addEventListeners( handleResize );
    return () => removeEventListeners( handleResize );
  }, []);

  return windowSize;
}
