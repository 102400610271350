import React from 'react';
import styled from 'styled-components';

const LiveBox = styled.div`
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 0 8px;
    flex: none;
    margin: 0 8px;
`;

const LiveBoxLabel = styled.div`
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 16px;
    color: #fff;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: 400;
    &:before {
        content: "\\2022";
        color: red;
        display: inline-block;
        margin-right: 8px;
        font-size: 29px;
        vertical-align: middle;
        line-height: 16px;
    }
`;

export default function LabelLive () {
  return (
    <LiveBox>
      <LiveBoxLabel>LIVE</LiveBoxLabel>
    </LiveBox>
  );
}
