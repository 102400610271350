import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import Theme from './styletheme';
import client from './apolloClient';
import reducer from './reducers';
import ConnectPlayer from './components/RootPlayer';
import ConnectPlayerGQL from './components/RootPlayerGQL';
import { FLAG_GQL_ENABLED, APPTYPE, VERSION } from './env.js';
import './index.css';

console.log( `${APPTYPE}-${VERSION}` );

if ( FLAG_GQL_ENABLED ) {
  render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={Theme}>
        <ConnectPlayerGQL />
      </ThemeProvider>
    </ApolloProvider>,
    document.getElementById( 'root' )
  );
} else {
  const store = createStore( reducer, compose( applyMiddleware( thunk ) ) );

  render(
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <ConnectPlayer />
      </ThemeProvider>
    </Provider>,
    document.getElementById( 'root' )
  );
}
