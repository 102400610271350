import {
  LOGIN,
  LOGOUT
} from '../actions/ActionTypes';

export const apiUserDefault = {
  token: localStorage.getItem( 'venom-user-token' ),
  email: localStorage.getItem( 'venom-user-email' )
};

export const apiUserReducer = ( state, action ) => {
  switch ( action.type ) {
  case LOGIN:
    localStorage.setItem( 'venom-user-token', action.bearerToken );
    localStorage.setItem( 'venom-user-email', action.email );
    return { ...state, token: action.bearerToken, email: action.email };
  case LOGOUT:
    localStorage.setItem( 'venom-user-token', '' );
    localStorage.setItem( 'venom-user-email', '' );
    return { ...state, token: null, email: null };
  default:
    return state;
  }
};
