//
// see heatmapping README,
//
//  * https://code.venom360.com/platform/heatmapping-lib-js
//

// import * as heatmapping from 'heatmapping-lib-js';

import {
  getVolatileScene,
  getVolatileStoryId
} from '../utils/volatileUtils';

import {
  getSceneEmbedType,
  getSceneMedia
} from '../utils/sceneUtils';

import {
  isMediaAd
} from '../utils/mediaUtils';

import {
  TYPEACTIONS,
  SET_VIDEO_TIME,
  SCENE_CHANGED,
  WINDOW_BEFORE_UNLOAD,
  HEATMODE_TOGGLE,
  HEATMODE_START,
  HEATMODE_END
} from '../actions/ActionTypes';

import {
  APPTYPE,
  // HEATMAP_ENDPOINT_URL,
  heatmapIsEnabled
} from '../env';

const heatmapDefault = {
  // is heat available as an option to the usere
  isHeatSupport: false,
  isHeatEvents: APPTYPE === 'player',

  // is heat engaged
  isHeatEnabled: false
};

const assignHeatmap = ( state, obj ) => ({
  ...state,
  heatmap: { ...state.heatmap, ...obj }
});

const heatModeEnd = state => assignHeatmap( state, {
  isHeatEnabled: false
});

const heatModeStart = state => assignHeatmap( state, {
  isHeatEnabled: heatmapIsEnabled && true
});

const heatModeToggle = state => state.heatmap.isHeatEnabled
  ? heatModeEnd( state )
  : heatModeStart( state );


// scene,     mediaType, heatmap event
// story,     ad,        adStarted
// story,     not-ad,    interactiveVideoSceneStarted
// story,     not-ad,    interactiveVideoSceneStarted
// vod|media, ad,        adStarted
// vod|media, not-ad,    vodStarted
// live,      ad,        adStarted
// live,      not-ad,    streamStarted
// live,      not-ad,    streamCameraStarted
const heatmapSceneMounted = ( state, nextSceneId ) => {
  const scene = getVolatileScene( state, nextSceneId );
  const media = getSceneMedia( scene );
  const sceneEmbedType = getSceneEmbedType( scene );

  if ( !heatmapIsEnabled )
    return state;

  if ( isMediaAd( media ) ) {
    // heatmapping.adStarted( media.id );
  } else if ( sceneEmbedType === 'story' ) {
    // console.log( 'sceneId', {
    //     sceneId: getVolatileSceneId( state ),
    //     storyId: getVolatileStoryId( state ),
    //    state
    // });
    // heatmapping.interactiveVideoSceneStarted(
    //   getVolatileStoryId( state ), nextSceneId
    // );
  } else if ( sceneEmbedType === 'live' ) {
    if ( scene.camerastreamid ) {
      // heatmapping.streamCameraStarted( media.id, scene.camerastreamid );
    } else {
      // heatmapping.streamStarted( media.id );
    }
  } else if ( sceneEmbedType === 'vod' || sceneEmbedType === 'media' ) {
    // heatmapping.vodStarted( media.id );
  }

  return state;
};

const heatmapInit = ( state, isHeatmappingAccess ) => {
  if ( !heatmapIsEnabled ) {
    return state;
  }
  // heatmapping.config({
  //   endpoint: HEATMAP_ENDPOINT_URL,
  //   appId: '6e5d46de-16a2-431e-b694-7826624d9aff'
  // });

  return assignHeatmap( state, {
    isHeatSupport: Boolean( isHeatmappingAccess )
  });
};

const heatmapDiscontinuity = state => {
  // heatmapping.discontinuity();

  return state;
};

const heatmapDispose = state => {
  // heatmapping.dispose();

  return assignHeatmap( state, {
    isHeatEvents: false
  });
};

const heatmapReducer = ( state, action ) => {
  if ( !state.heatmap.isHeatEvents ) {
    return state;
  }

  switch ( action.type ) {
  case TYPEACTIONS.STORY_GET.REQ_SUCCESS:
    return heatmapInit( state, action.res.heatmapping_access );

  case TYPEACTIONS.VIDEO_GET.REQ_SUCCESS:
    return heatmapInit( state, action.res.heatmapping_access );

  case HEATMODE_START:
    return heatModeStart( state );

  case HEATMODE_END:
    return heatModeEnd( state );

  case HEATMODE_TOGGLE:
    return heatModeToggle( state );

  case SET_VIDEO_TIME:
    return heatmapDiscontinuity( state );

  case WINDOW_BEFORE_UNLOAD:
    return heatmapDispose( state );

  case SCENE_CHANGED:
    return heatmapSceneMounted( state, action.nextSceneId );

  default:
    return state;
  }
};

export {
  heatmapReducer,
  heatmapDefault,
  heatmapInit
};
