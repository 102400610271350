import screenfull from 'screenfull';

import {
  FULLSCREEN_TOGGLE,
  FULLSCREEN_START,
  FULLSCREEN_END
} from '../actions/ActionTypes';

export const fullscreenmodeDefault = {
  isfullscreenenabled: false
};

export function fullscreenStart ( state, domElem ) {
  screenfull.request( domElem );
  return state;
}

export function fullscreenEnd ( state ) {
  screenfull.toggle();
  return state;
}

export function fullscreenToggle ( state, domElem ) {
  return screenfull.enabled && ( ( domElem && !screenfull.isFullscreen ) )
    ? fullscreenStart( state, domElem )
    : fullscreenEnd( state );
}

export const fullscreenmodeReducer = ( state, action ) => {
  switch ( action.type ) {
  case FULLSCREEN_START:
    return fullscreenStart( state, action.domElem );
  case FULLSCREEN_END:
    return fullscreenEnd( state, action.domElem );
  case FULLSCREEN_TOGGLE:
    return fullscreenToggle( state, action.domElem );
  default:
    return state;
  }
};
