//
// vr demo page that works w/ oculus but no video
//
//  * http://lune.xyz/tests/vr2/

import PropTypes from 'prop-types';
import addquery from 'addquery';
import * as THREE from 'three';
import * as touchboom from 'touchboom';
import readyaim from 'readyaim/src/readyaim';
import throttle from 'lodash.throttle';
import CanvasThree from './CanvasThree';
import * as units from '../utils/units';
import { isVideoElem } from '../utils/elemUtils';
import { setDeviceOrientation, isVRMode, fireDisplayChanged } from '../utils/vr';
import {
  createMesh,
  createImagePlane,
  createMeshCollider,
  createTestMaterial,
  getVerticesAvg,
  getEdgeHelper,
  getUniversalXYAsProjectionVector,
  getIcon3DWeak,
  getIcon3DStrong,
  getIconImgMesh,
  getIconMeshCollider,
  getCanvasGLRenderer,
  getImageTexture,
  getVideoTexture,
  getVideoMeshType,
  getVideoGeometryType,
  getVideoShaderType,
  setMeshFitCanvasCamera,
  setCanvasWidthHeight,
  disposeNodeHierarchy,
  createPerspectiveCameraForCanvas
} from '../utils/canvasShapes';

import {
  getProjection
} from '../utils/mediaUtils';

import {
  getSceneMedia,
  getSceneProjectionType
} from '../utils/sceneUtils';

import {
  isSequenceHotspot
} from '../utils/seqUtils';

class CanvasPublish extends CanvasThree {
  constructor ( props ) {
    super( props );

    this.isBoxHelpers = false;
    this.ismousedown = false;
    this.ismouseup = true;

    this.ismouseintersecting = false;
    this.ismousechangeddown = false;
    this.ismousechangedup = false;

    this.coords = null;
  }

  // return a new date object that has time of the given dateObj, defined to
  // the first second of the day.
  getTimeBgnDay ( dateObj ) {
    const d = new Date( dateObj || null );

    d.setMilliseconds( 0 );
    d.setSeconds( 0 );
    d.setMinutes( 0 );
    d.setHours( 0 );
    return d;
  }

  // construct system for handling camera
  // head and body give independent vertical and horizontal control
  //
  // vrcontrols hardware position can be connected directly to camera,
  // allows positioning for both to remain independent
  getCameraObj ( camera ) {
    const bodygroup = new THREE.Object3D();
    const headgroup = new THREE.Object3D();

    bodygroup.name = 'bodygroup';
    headgroup.name = 'headgroup';
    camera.name = 'camera';

    bodygroup.add( headgroup );
    headgroup.add( camera );

    return [ bodygroup, headgroup, camera ];
  }

  // hotspot ex,
  //
  //   { id: 'no-id', x: 0, y: 0 }
  //
  addSequenceHotspot ( hotspotData, isSelected ) {
    const hotspot3D = this.createHotspot3D( hotspotData, isSelected );

    const collider = this.createHotspot3DCollider( hotspot3D );
    hotspot3D.add( collider );
    hotspot3D.collider = collider;

    // if (hotspot.shape && hotspot.shape.length) {
    //     console.log('add mesh as collider', hotspotmesh);
    //     this.colliders.push( hotspotmesh );
    // } else {
    this.colliders.push( hotspot3D.collider );
    // }
    this.scene.add( hotspot3D );
    this.setHotspot3D( hotspotData.id, hotspot3D );

    hotspot3D.lookAt( this.camera.position );

    if ( this.props.data.vrmode.isvrsupport ) {
      this.aimstate = this.addGaze( this.aimstate, hotspot3D.collider );
    }

    return hotspot3D;
  }

  refreshMoments ( sceneData ) {
    const { moments, hotspots_dict, sequences } = sceneData;
    const selectedId = this.props.data.select.hotspotId;

    this.scene = this.clearAllHotspots( this.scene );

    if ( this.props.data.vrmode.isvrsupport ) {
      this.aimstate = this.initReadyAim();
    }

    sequences.forEach( sequence => {
      if ( isSequenceHotspot( sequence ) )
        this.addSequenceHotspot(
          this.getHotspotData( hotspots_dict, sequence.uuid ),
          sequence.uuid === selectedId
        );
    });

    this.cursorVideoMomentsRefresh( this.getTargetElem(), moments );

    if ( this.props.data.vrmode.isvrsupport ) {
      this.aimstate = this.connectGaze();
    }
  }

  threeRender () {
    const targetElem = this.getTargetElem( );
    const { data, scene } = this.props;

    if ( !targetElem ) {
      return null;
    }

    if ( isVideoElem( targetElem ) ) {
      if ( targetElem.readyState === targetElem.HAVE_ENOUGH_DATA ) {
        if ( window.hasLoaded ) {
          //
          // https://webglfundamentals.org/webgl/lessons/webgl-2d-drawimage.html
          //
          // fails when 2d and webgl context are accessed from same canvas element
          //
          // this.getCanvas2dContext( this.getCanvas() ).drawImage( this.targetElem, 0, 0 );
        }
        if ( !targetElem.paused && scene.moments.length ) {
          this.cursorVideoMoments( targetElem, scene.moments );
        }
      }
    }

    this.onRender( );

    if ( data.vrmode.isvrsupport && this.aimstate ) {
      this.aimstate = readyaim.update( this.aimstate );
    }

    return this.renderFrame();
  }

  getVReffect ( canvas, renderer ) {
    const parentelem = canvas.parentElement;
    const [ w, h ] = [ parentelem.offsetWidth, parentelem.offsetHeight ];

    renderer.setSize( w, h );
    renderer.renderer = renderer;

    if ( 'getVRDisplays' in navigator ) {
      navigator.getVRDisplays().then( displays => {
        if ( displays.length ) {
          renderer.vr.setDevice( displays[0]);
          renderer.vr.enabled = true;
          [ this.device ] = displays;
          this.frameData = new VRFrameData();
        }
      });
    }

    return renderer;
  }

  isHotspotCustomShape = hotspotInfo =>
    hotspotInfo.shape && hotspotInfo.shape.length

  isInfoBoxMeta = hotspotInfo => hotspotInfo && hotspotInfo.infobox && hotspotInfo.infobox.linkedImage;

  // return canned values for now
  getInfoBoxMeta = hotspot => ({
    linkedImage: this.props.data.accessMedia.image.filter( i => i.id === hotspot.infobox.linkedImage )[0],
    resolution: { width: hotspot.infobox.width, height: hotspot.infobox.height },
    height: hotspot.infobox.height,
    width: hotspot.infobox.width
  })

  getInfoBoxGroup ( hotspotId, hotspotinfo, infoboxinfo ) {
    const infoboxwh = [ 30, 30 ];
    const imageplane = createImagePlane( infoboxinfo.linkedImage.file_url, infoboxwh );
    const [ infox, infoy ] = this.isHotspotCustomShape( hotspotinfo )
      ? getVerticesAvg( hotspotinfo.shape )
      : [ hotspotinfo.x, hotspotinfo.y ];

    imageplane.actionType = 'infobox';
    imageplane.position.copy(
      getUniversalXYAsProjectionVector([ infox, infoy ], 97 )
    );

    const closeiconwh = [ 8, 8 ];
    const closeiconplane = this.getCloseIconMesh( closeiconwh );
    closeiconplane.position.set(
      ( infoboxwh[0] / 2 ) + ( closeiconwh[0] / 2 ) + 1,
      ( infoboxwh[1] / 2 ) - ( closeiconwh[0] / 2 ),
      -4
    );

    if ( this.isBoxHelpers ) {
      closeiconplane.add( new THREE.BoxHelper( closeiconplane, 0xffff00 ) );
    }
    closeiconplane.visible = true;
    closeiconplane.hotspotId = hotspotId;
    closeiconplane.actionType = 'close';
    this.colliders.push( closeiconplane );

    imageplane.add( closeiconplane );

    this.aimstate = this.addGaze( this.aimstate, closeiconplane, 0xff0000 );

    return imageplane;
  }

  showInfoBox ( hotspotId ) {
    const hotspotinfo = this.getHotspotData( this.props.scene.hotspots_dict, hotspotId );
    const infoboxmeta = this.getInfoBoxMeta( hotspotinfo );
    let infoboxgroup;

    if ( infoboxmeta ) {
      infoboxgroup = this.getInfoBoxGroup( hotspotId, hotspotinfo, infoboxmeta );

      this.scene.add( infoboxgroup );
      infoboxgroup.lookAt( this.camera.position );
    }
  }

  getRendererEffect ( canvas, renderer = getCanvasGLRenderer( canvas ) ) {
    return this.getVReffect( canvas, renderer );
  }

  onRender () {
    if ( this.props.data.vrmode.isvrsupport && this.bodygroup ) {
      setDeviceOrientation( this.device, this.frameData, this.camera );
    }

    if ( this.renderer && this.renderer.isPresenting === false && isVRMode() ) {
      fireDisplayChanged();
    }

    this.detectHit();
  }

  setMouseXY ( coordarr ) {
    const { mouse } = this;
    const canvaselem = this.getCanvas();

    if ( mouse && canvaselem ) {
      const rect = canvaselem.getBoundingClientRect();
      const offsetx = rect.width / 2;
      // let offsety = rect.height / 2;

      const x = ( coordarr[0] - rect.left ) - offsetx;
      // let y = ( coordarr[1] - rect.top ) + offsety;

      mouse.x = x / ( rect.width / 2 );
      mouse.y = ( -( ( coordarr[1] - rect.top ) / ( rect.bottom - rect.top ) ) * 2 ) + 1;
    }

    return this;
  }

  detectHit () {
    let ismouseintersecting = Boolean( this.ismouseintersecting );
    const ismousechangeddown = Boolean( this.ismousechangeddown );
    const mesh = this.scene && this.getintersectingobject();

    if ( mesh ) {
      if ( ismousechangeddown ) {
        ismouseintersecting = true;

        this.onClickMesh( mesh );
      }
      this.onIntersectMesh( mesh );
    } else {
      this.onIntersectNone( );
    }

    this.ismouseintersecting = ismouseintersecting;
    this.ismousechangeddown = false;
    this.ismousechangedup = false;
  }

  getMediaUrl ( media ) {
    return addquery( media.file_url, `date=${this.getTimeBgnDay().getTime()}` );
  }

  initScene ( props = this.props ) {
    const media = getSceneMedia( props.scene );
    const canvas = setCanvasWidthHeight( this.getCanvas(), props.dimensions );
    const glrenderer = this.props.data.vrmode.isvrsupport
      ? this.getRendererEffect( canvas )
      : getCanvasGLRenderer( canvas );
    const camera = createPerspectiveCameraForCanvas( canvas );
    const scene = new THREE.Scene();
    const targetElem = this.getTargetElem( props );
    const targettexture = isVideoElem( targetElem )
      ? getVideoTexture( targetElem )
      : getImageTexture( this.getMediaUrl( media ) );
    const videogeometry = getVideoGeometryType(
      getSceneProjectionType( props.scene )
    );
    const videomaterial = getVideoShaderType( targettexture, media.projection, media.type );
    let videomesh = getVideoMeshType(
      getProjection( media ),
      videogeometry,
      videomaterial,
      media.resolution,
      this.props.dimensions
    );
    const [ bodygroup, headgroup ] = this.getCameraObj( camera );

    if ( /flat/i.test( media && media.projection ) ) {
      videomesh = setMeshFitCanvasCamera( videomesh, canvas, camera );
    }

    scene.add( videomesh );
    scene.add( bodygroup );

    camera.lookAt( scene.position );

    // ensure first mouse position isn't a 'hit'
    this.mouse = new THREE.Vector2( Infinity, Infinity );

    this.scale = 2;
    this.videomesh = videomesh;
    this.headgroup = headgroup;
    this.bodygroup = bodygroup;
    this.renderer = glrenderer;
    this.scene = scene;
    this.camera = camera;

    this.resetCameraRotation( props );

    // different methods for entering vrmode on oculus
    //
    // http://lune.xyz/tests/vr2/
    // navigator.getVRDisplays().then( ([ display ]) => {
    //   glrenderer.renderer.vr.enabled = true;
    //   glrenderer.renderer.vr.setDevice( display );
    // });
    //
    // if ( this.props.data.vrmode.isvrsupport ) {
    //   document.body.appendChild( WEBVR.createButton( glrenderer.renderer ) );
    // }
  }

  getCoords = ( canvaselem, rotationxy ) => {
    const pw = units.elempixelweight( canvaselem );

    return touchboom.coords([ {
      autoweight: 50,
      bgn: units.degreetopixel( rotationxy[0], pw )
    }, {
      autoweight: 50,
      bgn: units.degreetopixel( rotationxy[1], pw ),
      min: units.degreetopixel( -90, pw ),
      max: units.degreetopixel( 90, pw )
    } ]);
  };

  // mutates existing set of coords by constructing new coords
  // and merging into old coords
  //
  updateCoords ( coordsxy, canvaselem, rotationxy ) {
    return this.getCoords( canvaselem, rotationxy ).reduce( ( coords, coord, i ) => (
      Object.assign( coords[i], coord ) && coords
    ), coordsxy );
  }

  applyRadCoords ( coordsxy, canvaselem, radxy ) {
    return this.updateCoords( coordsxy, canvaselem, radxy.map( rad => (
      units.radiantodegree( rad )
    ) ).reverse() );
  }

  onIntersectMesh ( mesh ) {
    const { lastmesh } = this;

    if ( mesh && mesh !== lastmesh ) {
      if ( lastmesh ) {
        this.onRayoutMesh( lastmesh );
      }

      this.onRayoverMesh( mesh );
      this.lastmesh = mesh;
    }
  }

  onRayoverMesh ( mesh ) {
    const { hotspotId } = mesh;
    let hotspot3D = this.getHotspot3D( hotspotId );

    if ( mesh.actionType === 'close' ) {
      this.setCanvasState( 'israyover', true );
    } else if ( hotspot3D && hotspot3D.visible ) {
      // attach sound here
      this.setCanvasState( 'israyover', true );

      // if ( hotspot3D.iconplane && hotspot3D.iconplane.children[0] ) {
      //    hotspot3D.iconplane.children[0].material.opacity = 1;
      // }
      hotspot3D = getIcon3DStrong( hotspot3D );
      this.props.actions.hotspotRayover( hotspotId );
    }
  }

  onRayoutMesh ( mesh ) {
    let hotspot3D = this.getHotspot3D( mesh.hotspotId );

    if ( hotspot3D || mesh.actionType === 'close' ) {
      hotspot3D = getIcon3DWeak( hotspot3D );
      this.setCanvasState( 'israyover', false );
    }
  }

  onIntersectNone ( ) {
    if ( this.lastmesh ) {
      this.onRayoutMesh( this.lastmesh );

      this.lastmesh = null;
    }
  }

  onClickMesh ( mesh ) {
    const { hotspotId } = mesh;
    const hotspot = this.getHotspot3D( hotspotId );
    const { scene, actions } = this.props;

    if ( mesh.actionType === 'close' ) {
      mesh.parent.visible = false;
      disposeNodeHierarchy( mesh.parent );
      this.scene.remove( mesh.parent );
      this.colliders = this.colliders.filter( collidermesh => (
        collidermesh !== mesh
      ) );

      if ( this.aimstate ) {
        this.aimstate = readyaim.rmmesh( this.aimstate, mesh );
      }
    } else if ( hotspot && hotspot.hotspotId && hotspot.visible ) {
      const hotspotinfo = this.getHotspotData( this.props.scene.hotspots_dict, hotspotId );

      if ( this.isInfoBoxMeta( hotspotinfo ) ) {
        this.showInfoBox( hotspotId );
      }

      actions.hotspotEngage( scene.id, hotspotId );
    }
  }

  onMouseOver () {

  }

  onMouseOut () {

  }

  onMouseUp () {
    this.ismousechangedup = Boolean( this.ismousedown );
    this.ismousedown = false;
    this.ismouseup = true;
  }

  onMouseDown () {
    this.ismousechangeddown = Boolean( this.ismouseup );
    this.ismousedown = true;
    this.ismouseup = false;
  }

  onMouseMove ( e ) {
    const evxy = touchboom.getevxy( e );

    this.setMouseXY( evxy );
  }

  applyRotation ([ radx, rady ]) {
    this.camera.rotation.x = radx;
    this.headgroup.rotation.y = rady;
  }

  getSceneRotation () {
    return {
      radx: this.camera.rotation.x,
      rady: this.headgroup.rotation.y
    };
  }

  initRenderer ( ) {
    // override
  }

  resetCameraRotation ( props ) {
    const { pixeltodegree, degreetoradian } = units;
    const pxwindow = units.windowpixelweight();
    // xy-translation coords are peristed on 'this' for mutation elsewhere
    // while still being used here, for example, coords are modified when
    // viewport is rotated or resized. to preserve the cameras rotation
    // xy-translation values here must be updated
    //
    // keep existing coords to preserve previous scene camera rotations
    if ( this.touchcfg && this.touchcfg.coords ) {
      this.coords = this.touchcfg.coords;
      this.applyRotation( touchboom.coordsgettotal({ coords: this.coords }).map( px => (
        pixeltodegree( px, pxwindow )
      ) ).map( degreetoradian ).reverse() );
    } else {
      this.coords = this.getCoords( this.getCanvas(), props.data.camerarot );
    }
  }

  addGaze ( aimstate, mesh, color = 0xffffff ) {
    return this.aimstate && readyaim.addmesh( THREE, this.aimstate, mesh, {
      // Override global reticle and fuse
      reticle: {
        hoverColor: color
      },
      fuse: {
        duration: 1.5,
        color
      }
    });
  }

  initReadyAim () {
    return readyaim( THREE, this.camera, {
      proximity: false,
      clickevents: true,

      near: null,
      far: null,

      reticle: {
        visible: true,

        // Defines the reticle's resting point when no object has been targeted
        restPoint: 1000,
        color: 0xffffff,
        innerRadius: 0.0001,
        outerRadius: 0.003,

        hoverColor: 0xffffff,
        hoverInnerRadius: 0.02,
        hoverOuterRadius: 0.024,
        hoverSpeed: 5,
        hoverVibrate: 50
      },
      fuse: {
        visible: true,
        duration: 2.5,
        color: 0xffffff,
        innerRadius: 0.045,
        outerRadius: 0.06,
        vibrate: 100,

        // does click cancel targeted fuse?
        clickCancel: false
      }
    });
  }

  connectGaze () {
    readyaim.attach( this.aimstate, this.getCanvas(), {
      oneventfn: ( cfg, etype, mesh ) => {
        if ( etype === readyaim.events.GAZELONG ) {
          this.onClickMesh( mesh );

          // mesh.material.emissive.setHex( 0x0000cc );
          console.log( '[...] called: onGazeLong' );
        }

        if ( etype === readyaim.events.GAZEOUT ) {
          // mesh.material.emissive.setHex( 0xcc0000 );
          this.onIntersectNone( );
          console.log( '[...] called: onGazeOut' );
        }

        if ( etype === readyaim.events.GAZEOVER ) {
          // mesh.material.emissive.setHex( 0xffcc00 );
          this.onIntersectMesh( mesh );
          console.log( '[...] called: onGazeOver' );
        }

        if ( etype === readyaim.events.GAZECLICK ) {
          this.onClickMesh( mesh );
          // mesh.material.emissive.setHex( 0x0000cc );
          console.log( '[...] called: onGazeClick' );
        }
      }
    });

    return this.aimstate;
  }

  connectScene ( props ) {
    const { pixeltodegree, degreetoradian } = units;
    const canvas = this.getCanvas();
    const pxwindow = units.windowpixelweight();

    this.resetCameraRotation( props );
    // this.printrads( 'initrender', props.scene.camerarot, this.coords );

    if ( this.touchcfg ) {
      this.touchcfg = touchboom.detach( this.touchcfg, this.getCanvas() );
    }

    const throttledCameraAction = throttle( ( lprops, radxy ) => {
      this.props.actions.cameraSetRotation( lprops.scene.id, radxy );
    }, 400 );

    this.touchcfg = touchboom.attach({
      coords: this.coords
    }, canvas, {
      oneventfn: ( cfg, etype, e ) => {
        if ( etype === 'start' ) {
          canvas.focus();
          this.onMouseDown( e );
        }

        if ( etype === 'end' || etype === 'cancel' ) {
          this.onMouseUp( e );
        }
      },
      oninertiafn: cfg => {
        const radxy = touchboom.coordsgettotal( cfg ).map( px => (
          pixeltodegree( px, pxwindow )
        ) ).map( degreetoradian ).reverse();

        this.applyRotation( radxy );

        // calling action.cameraSetRotation each camera movement causes slowdown in editor, throttle
        throttledCameraAction( props, radxy );
        // this.printrads( 'oninertia', radxy, cfg.coords );

        return cfg;
      },
      onmovefn: ( cfg, etype, e ) => {
        this.onMouseMove( e );
      }
    });

    if ( this.props.data.vrmode.isvrsupport && this.bodygroup ) {
      setDeviceOrientation( this.device, this.frameData, this.camera );
    }
  }

  getCloseIconMesh ( wh ) {
    return getIconImgMesh( '/img/infobox.close-520px.png', wh );
  }

  createHotspot3DCollider ( hotspot3D ) {
    const collider = getIconMeshCollider();

    collider.hotspotId = hotspot3D.hotspotId;
    // position just in front of hotspot...
    collider.position.set( 0, 0, 2 );
    collider.visible = false;

    return collider;
  }

  //
  // custom shape hotspot
  //
  createHotspot3DShape ( hotspot ) {
    const mesh = new THREE.Group();
    const vertices = hotspot.shape.map( xy => (
      getUniversalXYAsProjectionVector( xy ) ) );

    const shapemesh = createMesh(
      vertices,
      createTestMaterial( hotspot.color, hotspot.opacity )
    );

    if ( this.isBoxHelpers ) {
      shapemesh.add( getEdgeHelper( shapemesh ) );
    }

    const collider = createMeshCollider( shapemesh );

    // hiding collider material hides mesh material
    // (custom shape collider is clone of mesh)
    // collider.material.visible = false;
    mesh.hotspotId = hotspot.id;

    mesh.add( collider );
    mesh.collider = collider;
    mesh.collider.hotspotId = hotspot.id;

    shapemesh.visible = true;

    if ( this.isBoxHelpers ) {
      shapemesh.add( new THREE.BoxHelper( shapemesh, 0xffff00 ) );
    }

    mesh.add( shapemesh );
    mesh.iconplane = shapemesh;

    return mesh;
  }

  applyHotspotPosition ( hotspot3D, hotspotData ) {
    if ( !hotspotData.shape || !hotspotData.shape.length ) {
      hotspot3D.position.copy(
        // 95 safely within radius 128
        getUniversalXYAsProjectionVector( hotspotData.pos, 95 )
      );
    } else {
      // hotspot3D.position.copy(
      //    this.latLonToVector3( lat, lon, 128 ) );
      // hotspot3D.lookAt( this.camera.position );
    }

    return hotspot3D;
  }
}

CanvasPublish.propTypes = {
  data: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  getTargetElem: PropTypes.func.isRequired,
  onRenderFn: PropTypes.func.isRequired,
  refresh: PropTypes.number
};

export default CanvasPublish;
