import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CanvasRadar from './CanvasRadar';
import SlideSeekbar from './SlideSeekbar';
import PlayerControlsButtonsGQL from './PlayerControlsButtonsGQL';
import { getMediaDurationSS } from '../utils/contentUtils';
import { isLegacyProjectionFlatRe } from '../utils/legacyProjectionUtils';

import useLocalIVE from '../hooks/useLocalIVE';
import useLocalStage from '../hooks/useLocalStage';
import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';

const Container = styled.div``;

export default function PlayerControlsSeekGQL ( props ) {
  const {
    mediaIsScrubberTime,
    mediaScrubberTime,
    mediaScrubberTimeToggleOn,
    mediaScrubberTimeToggleOff,
    mediaSetCurrentTime,
    mediaPlaybackState
  } = useLocalMediaPlayback();

  const { iveState } = useLocalIVE();
  const { stageState } = useLocalStage();

  const { stageDimensionsWH, content, isRenderHooksOnly } = props;
  const display = ( iveState.isDisabledUI || isRenderHooksOnly ) ? 'none' : 'block';

  return (
    <Container style={{ display }}>
      {!isLegacyProjectionFlatRe.test( iveState.selectedProjection ) && (
        <CanvasRadar
          stageDimensionsWH={[ stageDimensionsWH.width, stageDimensionsWH.height ]}
          dimensions={[ 65, 65 ]}
          camerarot={stageState.cameraRotationRadians} />
      )}
      <SlideSeekbar
        timess={mediaIsScrubberTime
          ? mediaScrubberTime
          : mediaPlaybackState.currentTime}
        durationss={getMediaDurationSS( props.media, 0 )}
        loadedPercent={mediaPlaybackState.loaded}
        onSeek={( seekPer, seekTime ) => mediaSetCurrentTime( seekTime )}
        onMouseDown={mediaScrubberTimeToggleOn}
        onMouseUp={mediaScrubberTimeToggleOff}
      />
      <PlayerControlsButtonsGQL
        media={props.media}
        content={content} />
    </Container>
  );
}

PlayerControlsSeekGQL.propTypes = {
  media: PropTypes.object,
  content: PropTypes.object.isRequired,
  isRenderHooksOnly: PropTypes.bool,
  stageDimensionsWH: PropTypes.object.isRequired
};
