// Video helper functions
import parseUrl from 'url-parse';

const mimes = {
  m3u8: 'application/x-mpegURL',
  flv: 'video/x-flv',
  mp4: 'video/mp4',
  ts: 'video/MP2T',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv',
  ogg: 'video/ogg'
};

// http://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
const pathExtension = path => {
  path = ( /[.]/.exec( path ) ) && /[^.]+$/.exec( path );
  return path && path[0].replace( /\?.*/, '' );
};

const mimeTypeOfPath = path => mimes[pathExtension( path )];

const isCrossOrigin = url => {
  const parsedUrl = parseUrl( url );
  return parsedUrl.origin !== window.location.origin;
};

const stripQueryHash = url => String( url )
  .replace( /#.*$/, '' )
  .replace( /\?.*$/, '' );

const getBasename = url =>
  stripQueryHash( url ).split( '/' ).reverse()[0];

export {
  mimes,
  pathExtension,
  isCrossOrigin,
  mimeTypeOfPath,
  stripQueryHash,
  getBasename
};
