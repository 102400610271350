

const mimearr = [ {
  extn: 'm3u8',
  type: 'application/x-mpegURL'
}, {
  extn: 'flv',
  type: 'video/x-flv'
}, {
  extn: 'mp4',
  type: 'video/mp4'
}, {
  extn: 'ts',
  type: 'video/MP2T'
}, {
  extn: 'mov',
  type: 'video/quicktime'
}, {
  extn: 'avi',
  type: 'video/x-msvideo'
}, {
  extn: 'wmv',
  type: 'video/x-ms-wmv'
}, {
  extn: 'ogg',
  type: 'video/ogg'
} ];

// http://stackoverflow.com/questions/190852/ \
//   how-can-i-get-file-extensions-with-javascript
export const getpathextn = path => {
  path = ( /[.]/.exec( path ) ) && /[^.]+$/.exec( path );

  return path && path[0].replace( /\?.*/, '' );
};

export const getextnmimeobj = extn => (
  mimearr.find( mime => mime.extn === extn ) );

export const getextnmimetype = extn =>
  ( getextnmimeobj( extn ) || {}).type || null;

export const getpathmimetype = path =>
  getextnmimetype( getpathextn( path ) );
