import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseButtonIcon from './BaseButtonIcon';
import CameraFilled from '../svg/videocam-24px.svg';
import CameraOutline from '../svg/videocam-outline-24px.svg';

const Container = styled.div`
    display: inline-flex;
    position:relative;
    &:hover b {
        color: #fff;
    }
`;

const CamNum = styled.b`
    font-size:10px;
    color: #bebebe;
    position:absolute;
    left: 0; right: 4px;
    text-align: center;
`;

// returns number no less than 1, no greater than 10
const normalizeNumber = num => Math.max( Math.min( 10, num ), 1 );

const getCameraSVG = cameraNum => cameraNum > 1
  ? CameraOutline
  : CameraFilled;

export default function ButtonCameras ( props ) {
  const { onClick, isEnabled, cameranum, isActive } = props;
  const CameraSVG = getCameraSVG(
    normalizeNumber( cameranum ), isActive
  );

  return (
    <Container>
      <BaseButtonIcon
        onClick={() => onClick({ value: !isEnabled })}
        title={'camera'}
        isEnabled={isEnabled}>
        <CameraSVG width="25px" height="25px" /><CamNum>{cameranum}</CamNum>
      </BaseButtonIcon>
    </Container>
  );
}

ButtonCameras.propTypes = {
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  cameranum: PropTypes.number.isRequired
};
