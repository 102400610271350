import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import caramelcookie from 'caramelcookie';
import { KEY_VENOM_USER_TOKEN, KEY_VENOM_USER_EMAIL } from '../enums';

// note, local storage is inaccessibe to other (sub)domains
export const storageGet = ( keyName, defaultValue ) => window
  .localStorage.getItem( keyName ) || defaultValue;

export const storageSet = ( keyName, value ) => window
  .localStorage.setItem( keyName, value );

export const defaultLocalUser = {
  userState: {
    __typename: 'UserState',
    id: 'userstate',
    lastUpdate: Date.now(),
    confirmed: false,
    token: storageGet( KEY_VENOM_USER_TOKEN, '' ),
    email: storageGet( KEY_VENOM_USER_EMAIL, '' ) || 'CMSUser',
    appId: '',
    appAmplitudeKey: ''
  }
};

export const GET_USER_STATE = gql`{
  userState @client {
    id
    lastUpdate
    confirmed
    token
    email
    appId
    appAmplitudeKey
  }
}`;

export const SET_USER_STATE = gql`
mutation SetUserState( $input: SetUserInput! ) {
    userState( input: $input ) @client
}
`;

export const GET_CMSUSER_ID = gql`
query getUserInfo {
    viewer {
        ...on CMSUser { id }
    }
}`;

export const AUTHENTICATE_APPLICATION = gql`
mutation( $input: AuthenticateApplicationInput! ) {
    authenticateApplication( input:$input ) {
        token
    }
}`;

export default function useLocalLogin ( appAuth ) {
  const { userState = {} } = useQuery( GET_USER_STATE ).data || {};
  const [ getUserLazyId, userLazyId ] = useLazyQuery( GET_CMSUSER_ID, {
    fetchPolicy: 'no-cache'
  });

  const [ setUserMutation ] = useMutation( SET_USER_STATE );

  const setLogin = ( token, email, confirmed = false ) => {
    storageSet( KEY_VENOM_USER_TOKEN, token );
    storageSet( KEY_VENOM_USER_EMAIL, email );

    setUserMutation({
      variables: {
        input: {
          token,
          email,
          confirmed
        }
      }
    });
  };

  const setLoginApp = ( token, appId, appAmplitudeKey, confirmed = true ) => {
    storageSet( KEY_VENOM_USER_TOKEN, token );

    setUserMutation({
      variables: {
        input: {
          token,
          appId,
          appAmplitudeKey,
          confirmed
        }
      }
    });
  };

  const confirmLogin = () => setUserMutation({
    variables: {
      input: {
        confirmed: true
      }
    }
  });

  useEffect( () => {
    if ( appAuth && appAuth.authToken && appAuth.appId ) {
      const bearerToken = caramelcookie.get( KEY_VENOM_USER_TOKEN )
                  || appAuth.authToken;

      setLoginApp( bearerToken, appAuth.appId, appAuth.appAmplitudeKey );
    }
  }, [ appAuth ]);

  useEffect( () => { // occurs when token is changed
    if ( userState.token && !userState.confirmed && !userLazyId.loading ) {
      getUserLazyId({
        variables: { cacheId: userState.token }
      });
    }

    if ( !userState.token ) {
      console.log( '[...] request token not found' );
    }
  }, [ userState.token ]);

  useEffect( () => { // occurs when userLazyId.data is changed
    const { viewer } = userLazyId.data || {};

    if ( viewer && viewer.id ) {
      confirmLogin();
    } else if ( viewer === null ) {
      setLogin( '', '' );
    }
  }, [ userLazyId.data ]);

  useEffect( () => {
    if ( userLazyId.error ) {
      setLogin( '', '' );
    }
  }, [ userLazyId.error ]);

  return { userState };
}
