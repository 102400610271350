import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ismobilejs from 'ismobilejs';
import { VERSION } from '../env';
import { getBestFormat, getTimecodeFormatted } from '../utils/timecode';
import DropdownProjection from './DropdownProjection';
import DropdownLevels from './DropdownLevels';
import ButtonClose from './ButtonClose';
import LabelArea from './LabelArea';
import LinkUnstyled from './LinkUnstyled';

import {
  getLabelMimeType,
  getLabelRotations,
  getLabelPercent,
  getLabelBitrate,
  getLabelNarrow,
  labelResolution,
  labelProjectionNarrow,
  labelProjectionWide
} from '../utils/labels.js';

import {
  Container,
  Top,
  Columns,
  List,
  ListItem,
  InputColumns,
  InputList
} from './PanelPlayerSettingsGQL.js';

class PanelPlayerSettings extends React.Component {
  onClose () {
    this.props.actions.panelToggle( 'settings' );
  }

  onQualityChange ( qualityId ) {
    this.props.actions.qualitySetId( this.props.scene.id, qualityId );
  }

  onProjectionChange ( projectionId ) {
    this.props.actions.projectionSetId( this.props.scene.id, projectionId );
  }

  getProp ( obj, prop, def ) {
    return ( obj && prop in obj )
      ? obj[prop]
      : def;
  }

  render () {
    const { data, video, scene, width, height } = this.props;
    const { getProp } = this;
    const videotype = getProp( video, 'mime_type', 'unknown' );
    const qualityLevels = getProp( video, 'qualityLevels', []);
    const qualityLevel = getProp( video, 'qualityLevel', null );
    const videoprojection = getProp( video, 'projection', 'unknown' );
    const videourl = getProp( video, 'file_url', 'unknown' );
    const videourlnarrow = getLabelNarrow( videourl, 10 );
    const videoduration = getProp( video, 'duration', 0 );
    const videobitrate = getProp( video, 'bit_rate', {});
    const videobitrateaudio = getProp( videobitrate, 'audio', 0 );
    const videobitratevideo = typeof qualityLevel.bitrate === 'number'
      ? qualityLevel.bitrate
      : getProp( videobitrate, 'video', 0 );
    const { timess, loaded } = data.videoplayback;
    const rotationlabel = getLabelRotations( data.camerarot );
    const resolutionlabel = labelResolution( video && video.resolution, 'auto' );
    const timeformat = getBestFormat( video.duration || 120 );
    const timessFormatted = getTimecodeFormatted( timess, timeformat );
    const durationssFormatted = getTimecodeFormatted( videoduration, timeformat );

    return (
      <Container stageh={height} stagew={width}>
        <Top>
          <ButtonClose onClick={this.onClose.bind( this )} />
        </Top>
        <InputColumns>
          <InputList>
            <ListItem>
              {qualityLevel &&
                                <LabelArea label="Quality">
                                  <DropdownLevels
                                    qualityLevels={qualityLevels}
                                    selected={qualityLevel}
                                    onChange={e => this.onQualityChange( e.value )} />
                                </LabelArea>
              }
            </ListItem>
          </InputList>
          <InputList>
            <ListItem>
              <LabelArea label="Projection">
                <DropdownProjection
                  selectedValue={scene.projectionState}
                  onChange={e => this.onProjectionChange( e.value )} />
              </LabelArea>
            </ListItem>
          </InputList>
        </InputColumns>
        <Columns>
          <List>
            <ListItem>
              <LabelArea label="type">
                {getLabelMimeType( videotype )}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="projection" title={labelProjectionWide( videoprojection )}>
                {labelProjectionNarrow( videoprojection )}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="url">
                <LinkUnstyled href={videourl}>
                  {videourlnarrow}
                </LinkUnstyled>
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="resolution">
                {resolutionlabel}
              </LabelArea>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <LabelArea label="loadpercent">
                {getLabelPercent( loaded )}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="video dimension">
                {resolutionlabel}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="duration">
                {durationssFormatted}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="timeposition">
                {timessFormatted}
              </LabelArea>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <LabelArea label="bitrate audio">
                {videobitrateaudio} bps
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="bitrate media">
                {getLabelBitrate( videobitratevideo )}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="rotations">
                {rotationlabel}
              </LabelArea>
            </ListItem>
            <ListItem>
              <LabelArea label="version">
                {VERSION}
              </LabelArea>
            </ListItem>
          </List>
        </Columns>
      </Container>
    );
  }
}

PanelPlayerSettings.propTypes = {
  data: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  video: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number
};

export default PanelPlayerSettings;
