import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_CONTENT = gql`
fragment PlayerLanguageData on ContentInternationalization {
    id
    language
    title
    description
}

fragment PlayerVideoInformationData on VideoInformation {
    projection
    resolution { width height }
    size { mb }
    mediaType
    blindspotType
    videoBitrate
    audioBitrate
    file { url __typename }
    hlsPlaylist { uri }
    duration { ms seconds }
}

fragment PlayerVideoMediaItemData on Media {
    id
    title
    filename
    projection
    resolution { width height }
    size { mb }
    mediaType
    blindspotType
    videoCodec
    videoBitrate
    audioBitrate
    duration { ms seconds }
    file { url __typename }
    hlsPlaylist { uri }
}

fragment PlayerVideoCameraData on Camera {
    id
    isDefault
    primaryLanguage { ...PlayerLanguageData }
    video { ...PlayerVideoInformationData }
    media { ...PlayerVideoMediaItemData }
}

fragment PlayerVideoVodFormatData on VodFormat {
    id
    formatDescription
    createdAt
    isPrimary
    media { ...PlayerVideoMediaItemData }
    video { ...PlayerVideoInformationData }
}

fragment PlayerVideoAdData on Ad {
    __typename
    id
    title
    description
    media { ...PlayerVideoMediaItemData }
}

fragment PlayerScenePostPlayback on ScenePostPlaybackInfo {
    action
    destinationScene { id name }
}

fragment PlayerSceneSequence on SceneSequence {
    name
    active
    createdAt
    start
    end
    ... on VideoSceneSequence {
        media {
            ...PlayerVideoMediaItemData
        }
    }
    ... on HotspotSceneSequence {
        description
        destination { id name }
        destinationStartTime { ms }
        name
        active
        hotspot {
            id
            scale { x y }
            position { x y }
            createdAt
            hidden
            name
        }
    }
}

fragment PlayerInteractiveScene on InteractiveScene {
    id
    name
    active
    postPlayback { ...PlayerScenePostPlayback }
    sequences { ...PlayerSceneSequence }
}

query getContent ( $contentId: UUID, $hash: String ) {
    content( id: $contentId, hash: $hash ) {
        id
        type
        category
        access
        allowDownloads
        allowSharing
        geoRestrictions {
            allowOnly
            deny
        }
        appRestrictions {
            allowOnly { id name }
            deny { id name }
        }
        primaryLanguage {
            ...PlayerLanguageData
        }
        languages {
            ...PlayerLanguageData
        }

        availability {
            start
            end
        }
        art {
            tile { url }
            key { url }
        }
        preRollAds { ...PlayerVideoAdData }
        postRollAds { ...PlayerVideoAdData }
        ref {
            ... on InteractiveRef {
                interactive {
                    id
                    name
                    startScene { id }
                    scenes { ...PlayerInteractiveScene }
                }
            }
            ... on VodRef {
                assets {
                    id
                    name
                    mimeType
                }
                formats { ...PlayerVideoVodFormatData }
            }
            ... on StreamRef {
                cameras { ...PlayerVideoCameraData }
            }
            ... on AdRef {
                ad { ...PlayerVideoAdData }
            }
        }
    }
}`;

export default function useContent ( opts ) {
  return useLazyQuery( GET_CONTENT, {
    fetchPolicy: 'no-cache',
    variables: {
      contentId: opts.contentId,
      hash: opts.hash
    }
  });
}
