import { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  analyticsContentTimerResume,
  analyticsContentTimerPause
} from '../analytics.js';

export const defaultLocalMediaPlayback = {
  mediaPlaybackState: {
    id: 'mediaplaybackstate',
    __typename: 'MediaPlaybackState',
    lastPulseTime: null,
    playedSeconds: 0,
    currentTime: 0,
    loaded: 0,
    loadedSeconds: 0,

    // TODO GQL was Boolean( scene && videoplayback && scene.isPlayed && scene.isPlayed > videoplayback.lastPlayTS )
    hasPlayed: false,
    isReady: false,

    mute: false,
    buffering: false,
    recovering: false,
    volume: 0.8,
    playing: false,

    isLogEnabled: /isVideoLog=true/gi.test( window.search ),
    isHLSLogEnabled: /isHLSLog=true/gi.test( window.search )
  }
};

export const GET_MEDIA_PLAYBACK_STATE = gql`{
  mediaPlaybackState @client {
    id
    lastPulseTime
    playedSeconds
    currentTime
    loaded
    loadedSeconds

    mute
    buffering
    recovering
    volume
    playing
    hasPlayed

    isReady
    isLogEnabled
    isHLSLogEnabled
  }
}`;

export const SET_MEDIA_PLAYBACK_STATE = gql`
mutation SetMediaPlaybackState( $input: SetMediaPlaybackInput! ) {
  mediaPlaybackState( input: $input ) @client
}`;

export default function useLocalMediaPlayback ( mediaElemId = 'videochild' ) {
  const [ mediaElem, mediaElemSet ] = useState( null );
  const [ mediaScrubberTime, mediaScrubberTimeSet ] = useState( false );
  const { mediaPlaybackState = {} } = useQuery( GET_MEDIA_PLAYBACK_STATE ).data || defaultLocalMediaPlayback;
  const [ mediaPlaybackMutation ] = useMutation( SET_MEDIA_PLAYBACK_STATE );

  const playerRef = useCallback( node => {
    if ( node && node !== mediaElem ) {
      mediaElemSet( node );
    }
  }, []);

  const getMediaElem = () => document.getElementById( mediaElemId );

  // https://www.npmjs.com/package/react-player#callback-props
  // contrary to documentation, played and playedSeconds are undefined --redefine here
  //
  // ex, ({ played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 })
  //
  // legacy analog called 'mediaPulse'
  const onMediaProgress = ({ loaded, loadedSeconds }) => {
    const mediaElem = getMediaElem();

    if ( mediaElem && mediaElem.currentTime !== mediaPlaybackState.currentTime ) {
      mediaPlaybackMutation({
        variables: {
          input: {
            currentTime: mediaElem.currentTime,
            playedSeconds: mediaElem.currentTime,
            loaded,
            loadedSeconds
          }
        }
      });
    }
  };

  const onMediaPlay = content => analyticsContentTimerResume( content );
  const onMediaPause = content => analyticsContentTimerPause( content );
  const onMediaStart = () => {};
  const onMediaReady = () => {
    mediaPlaybackMutation({
      variables: {
        input: {
          isReady: true
        }
      }
    });
  };
  const onMediaEnded = content => analyticsContentTimerPause( content );
  const onMediaSeek = () => {};
  const onMediaBuffer = content => analyticsContentTimerPause( content );
  const onMediaBufferEnd = content => analyticsContentTimerResume( content );

  // TODO GQL convert
  const onMediaError = ( e, data, hlsInstance, hlsGlobal ) => {
    // let { actions } = this.props;
    // this.log( 'onError', { e, data });

    // https://github.com/CookPete/react-player/pull/355
    if ( !hlsGlobal ) {
      return;
    }

    switch ( data.type ) {
    case hlsGlobal.ErrorTypes.NETWORK_ERROR:
      // try to recover network error
      // hls: fatal network error encountered, trying to recover
      // actions.videoIsRecovering();
      hlsInstance.startLoad();
      break;
    case hlsGlobal.ErrorTypes.MEDIA_ERROR:
      // hls: fatal media error encountered, trying to recover
      // actions.videoIsRecovering();
      console.error( 'video error attempt recovery ', e, data );
      hlsInstance.recoverMediaError();
      break;
    default:
      // actions.addErrorToast( 'HLS: fatal error occurred' );
      // cannot recover
      console.error( 'video error cannot recover ' );
      hlsInstance.destroy();
      break;
    }
  };

  const togglePlaying = isPlaying => {
    mediaPlaybackMutation({
      variables: {
        input: {
          playing: typeof isPlaying === 'boolean'
            ? isPlaying
            : !mediaPlaybackState.playing
        }
      }
    });
  };

  const toggleMute = isMute => {
    mediaPlaybackMutation({
      variables: {
        input: {
          mute: typeof isMute === 'boolean'
            ? isMute
            : !mediaPlaybackState.mute
        }
      }
    });
  };

  const mediaSetCurrentTime = currentTime => {
    const mediaElem = getMediaElem();

    if ( typeof mediaScrubberTime === 'number' ) {
      mediaScrubberTimeSet( currentTime );
    }

    if ( mediaElem ) {
      mediaElem.currentTime = currentTime;
    }
  };

  // fewer hls crashes when stopping video first before redefining src
  const mediaSetSrc = ( src, currentTime ) => {
    const mediaElem = getMediaElem();
    const playing = mediaElem && mediaElem.playing;

    if ( mediaElem ) {
      mediaElem.pause();
      mediaElem.src = src;

      if ( typeof currentTime === 'number' ) {
        mediaSetCurrentTime( currentTime );
      }

      if ( playing ) {
        mediaElem.play();
      }
    }
  };

  const mediaSetVolume = volume => {
    mediaPlaybackMutation({
      variables: {
        input: {
          volume: Number( volume )
        }
      }
    });
  };

  // start mediaScrubberTime at current media time
  const mediaScrubberTimeToggleOn = () => {
    const mediaElem = getMediaElem();

    if ( mediaElem ) {
      mediaScrubberTimeSet( mediaElem.currentTime );
    }
  };

  const mediaScrubberTimeToggleOff = () => {
    mediaScrubberTimeSet( false );
  };

  return {
    mediaPlaybackState,
    mediaElem,
    playerRef,
    mediaSetSrc,
    mediaSetCurrentTime,

    mediaIsScrubberTime: typeof mediaScrubberTime === 'number',
    mediaScrubberTime,
    mediaScrubberTimeToggleOn,
    mediaScrubberTimeToggleOff,
    mediaSetVolume,

    togglePlaying,
    toggleMute,

    onMediaError,
    onMediaPlay,
    onMediaPause,
    onMediaStart,
    onMediaReady,
    onMediaEnded,
    onMediaSeek,
    onMediaBuffer,
    onMediaBufferEnd,
    onMediaProgress
  };
}
