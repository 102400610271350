import React from 'react';
import PropTypes from 'prop-types';
import BaseButtonIcon from './BaseButtonIcon';
import CloseSVG from '../svg/baseline-close-24px.svg';

export default function ButtonClose ( props ) {
  const { onClick, isEnabled } = props;

  return (
    <BaseButtonIcon
      {...props}
      onClick={() => onClick && onClick({ value: !isEnabled })}
      title={'close'}>
      <CloseSVG width="25px" height="25px" />
    </BaseButtonIcon>
  );
}

ButtonClose.propTypes = {
  onClick: PropTypes.func,
  isEnabled: PropTypes.bool
};
