import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import scalewh from 'scalewh';
import * as THREE from 'three';

import { hotspotDataSanitised } from '../adapter';
import CanvasThreeGQL from './CanvasThreeGQL';

import useCanvas3D from '../hooks/useCanvas3D';
import useLocalIVE from '../hooks/useLocalIVE';
import useMouse from '../hooks/useMouse';

import { FLAG_GQL_ENABLED } from '../env';

import {
  getIcon3DWeak,
  getIcon3DStrong,
  getVideoTexture,
  getVideoShaderType,
  getVideoMeshTypePlane,
  getCanvasGLRenderer,
  getUniversalPositionMesh,
  setRelativePositionMeshHotspot,
  setCanvasStateClass,
  setCanvasWidthHeight,
  getRayIntersectMeshFirst,
  getCameraIntersectingObject,
  createPerspectiveCameraForCanvas,
  createPositionFactor,
  createScaleFactor,
  setMeshFitCanvasCamera
} from '../utils/canvasShapes';

export default function CanvasEditGQL ( props ) {
  const {
    iveState,
    iveSetSelectedSequenceId
  } = useLocalIVE();

  const {
    sceneState,
    sceneStateSetProps,
    lifecycleSet,
    objects3DRef,
    useSceneEffect,
    getCanvasElem
  } = useCanvas3D({
    ...props,
    selected_hotspotId: iveState.selectedHotspotId,
    canvasId: 'CanvasThree-rectangle',
    getMediaElem: props.getMediaElem,
    dimensions: props.dimensions,
    moments: props.moments || props.scene.moments,
    animationLoop: true
  });

  const {
    setMouseContainerElem,
    mouseMoveXYVector,
    mouseDownXYVector,
    mouseUpXYVector
  } = useMouse();

  const threeSceneRefreshed = ( sceneState, canvasElem ) => {
    setCanvasStateClass( canvasElem, 'israyover', false );

    if ( props.media && props.getMediaElem() ) {
      setMouseContainerElem( canvasElem );
    }

    return sceneState;
  };

  // eslint-disable-next-line no-shadow
  const threeSceneCreate = ( canvasElem, sceneState, props ) => {
    const scene3D = new THREE.Scene();
    // scene3D.add( new THREE.AmbientLight( 0x505050 ) );
    //
    // lighting carried over from legacy sources. It was probably used
    // to cast light on 'drawable' hotspts to make them visible
    //
    // const light = new THREE.SpotLight( 0xffffff, 1.5 );
    // light.position.set( 0, 500, 2000 );
    // light.castShadow = true;
    //
    // light.shadow = new THREE.LightShadow( new THREE.PerspectiveCamera( 50, 1, 200, 1000 ) );
    // light.shadow.bias = -0.00022;
    // light.shadow.mapSize.width = 2048;
    // light.shadow.mapSize.height = 2048;
    //
    // scene3D.add( light );

    if ( !props.getMediaElem() ) {
      throw new Error( '[!!!] targetElem must be defined' );
    }

    const { resolution, projection, type } = props.media;

    const [ w, h ] = scalewh.max(
      [ resolution.width, resolution.height ],
      [ props.dimensions.width, props.dimensions.height ]
    );

    // this could probably just use target plane dimensions... (do later)
    const scale = createScaleFactor( scalewh.max(
      [ resolution.width, resolution.height ],
      [ props.dimensions.width, props.dimensions.height ]
    ) );

    canvasElem = setCanvasWidthHeight( canvasElem, props.dimensions );
    const camera = createPerspectiveCameraForCanvas( canvasElem );

    const positionFactor = createPositionFactor( w, h );

    const renderer = getCanvasGLRenderer( canvasElem );
    renderer.sortObjects = false;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFShadowMap;

    const mediaElem = props.getMediaElem();
    const texture = getVideoTexture( mediaElem );

    const videoMaterial = getVideoShaderType( texture, projection, type );

    let mesh = getVideoMeshTypePlane( videoMaterial, resolution, props.dimensions );
    mesh = setMeshFitCanvasCamera( mesh, canvasElem, camera );

    const overlayzpos = mesh.position.z + 0.1;
    scene3D.add( mesh );

    return {
      ...sceneState,
      threeInitUpdate: Date.now(),
      scale,
      scene3D,
      camera,
      renderer,
      positionFactor,
      overlayzpos
    };
  };

  const getHotspotData = ( hotspots, hotspotId ) => {
    const hotspotData = hotspots[hotspotId];

    return hotspotDataSanitised( hotspotData );
  };

  const onRayoutMesh = hotspot3D => getIcon3DWeak( hotspot3D );

  const onRayoverMesh = hotspot3D => getIcon3DStrong( hotspot3D );

  const onMouseMoveNotDragging = intersectObj => {
    const rayoverHotspotId = intersectObj && intersectObj.hotspotId;
    const hotspotIdHoverPrev = sceneState.hotspotIdHover;
    if ( hotspotIdHoverPrev !== rayoverHotspotId ) {
      if ( hotspotIdHoverPrev ) {
        onRayoutMesh( objects3DRef.current.hotspot3DMap[hotspotIdHoverPrev]);
        setCanvasStateClass( getCanvasElem(), 'israyover', false );
      }

      if ( rayoverHotspotId ) {
        onRayoverMesh( objects3DRef.current.hotspot3DMap[rayoverHotspotId]);
        setCanvasStateClass( getCanvasElem(), 'israyover', true );
      }

      sceneStateSetProps({
        hotspotIdHover: rayoverHotspotId
      });
    }
  };

  const onMouseMoveDragging = ( dragObj, mousePos ) => {
    if ( dragObj.type === 'Mesh' ) {
      setRelativePositionMeshHotspot( dragObj.parent, mousePos );
      if ( dragObj.hotspotId === props.selected_hotspotId ) {
        if ( FLAG_GQL_ENABLED ) {
          iveSetSelectedSequenceId( dragObj.hotspotId );
        } else {
          props.actions.selectSequence( dragObj.hotspotId );
        }
      }
      // } else if ( dragObj.type === 'Points' ) {
      //     let hotspot = dragObj.parent.parent;
      //     // Edit all three children
      //     // type Mesh and Line move the vertices
      //     // type point find the propper child (dragObj?)
      //     for ( let i = 0; i < hotspot.children.length; i += 1 ) {
      //         let child = hotspot.children[i];
      //         switch ( child.type ) {
      //         case 'Object3D':
      //             dragObj.geometry.verticesNeedUpdate = true;
      //             dragObj.geometry.vertices[0].x = mousePos.truex;
      //             dragObj.geometry.vertices[0].y = mousePos.truey;
      //             break;
      //         case 'Mesh':
      //             child.material.opacity = 0.3;
      //             //  //Making the mesh invisible while moving
      //             //  child.geometry.verticesNeedUpdate = true;
      //             //  if ( this.shapeVertice === null ) this.shapeVertice = child.geometry.vertices.filter( v => {
      //             //      let x1 = Math.abs( dragObj.geometry.vertices[0].x );
      //             //      let x2 = Math.abs( v.x );
      //             //      let max = Math.max( x1, x2 );
      //             //      let min = Math.min( x1, x2 );
      //             //      return max - min < DRAGTHRESHOLD;
      //             //  })[0] || null;
      //             //  if ( this.shapeVertice ) {
      //             //      this.shapeVertice.x = mousePos.truex;
      //             //      this.shapeVertice.y = mousePos.truey;
      //             //  }
      //             break;
      //         case 'Line':
      //             // child.geometry.verticesNeedUpdate = true;
      //             // if ( this.lineVertice === null ) {
      //             //     this.lineVertice = child.geometry.vertices.filter( v => {
      //             //         let x1 = Math.abs( dragObj.geometry.vertices[0].x );
      //             //         let x2 = Math.abs( v.x );
      //             //         let max = Math.max( x1, x2 );
      //             //         let min = Math.min( x1, x2 );
      //             //         return max - min < DRAGTHRESHOLD;
      //             //     })[0] || null;
      //             // }
      //             //
      //             // if ( this.lineVertice ) {
      //             //     this.lineVertice.x = mousePos.truex;
      //             //     this.lineVertice.y = mousePos.truey;
      //             // }
      //          break;
      //             default:
      //             break;
      //          }
      //     }
    }
  };

  const onMouseMove = mousePosVector => {
    if ( props.isDrawMode )
      return null;

    if ( sceneState.dragging ) {
      //  || ( intersect.length > 0
      //      && mouseBtn === 1 ) ) {
      // on my trackpad, any mouse movement returns e.which === 1
      // causes hotspot to 'stick' to mouse
      onMouseMoveDragging( sceneState.dragging.object, mousePosVector );
    } else {
      const intersect = getCameraIntersectingObject(
        Object.values( objects3DRef.current.hotspot3DMap ),
        sceneState.camera,
        mousePosVector,
        sceneState.raycaster,
        true
      );

      onMouseMoveNotDragging( intersect );
    }
  };

  const onDragEnd = dragObj => {
    const { type } = dragObj;

    const { scene, actions } = props;
    const hotspot3D = type === 'Mesh'
      ? dragObj : dragObj.parent.parent;

    if ( type === 'Mesh' ) {
      if ( FLAG_GQL_ENABLED ) {
        console.log( 'TODO onDragEnd' );
      } else {
        const hotspotData = getHotspotData( scene.hotspots_dict, hotspot3D.hotspotId );
        const [ x, y ] = getUniversalPositionMesh( dragObj.parent, sceneState.positionFactor );
        const pos = hotspotData.pos.slice();

        pos[0] = x;
        pos[1] = y;

        actions.putHotspotDisp( scene.id, hotspot3D.hotspotId, { pos });
      }
      // } else if ( type === 'Points' ) {
      //   let shape = dragObj.parent.children
      //       .map( c => getUniversalPosition([
      //           c.geometry.vertices[0].x,
      //           c.geometry.vertices[0].y
      //       ], this.positionFactor ) );
      //
      //   actions.putHotspotDisp( scene.id, hotspot3D.hotspotId, { shape });
      //
      //   this.shapeVertice = null;
      //   this.lineVertice = null;
    }
  };

  const onDragStart = dragObj => {
    const { hotspotId } = dragObj;

    if ( hotspotId && hotspotId !== props.selected_hotspotId ) {
      if ( FLAG_GQL_ENABLED ) {
        iveSetSelectedSequenceId( hotspotId );
      } else {
        props.actions.selectSequence( hotspotId );
      }
    }

    // let selectedHotspot = this.hotspot3DMap[this.props.data.select.hotspotId];
    // if ( selectedHotspot ) {
    //     let dots = selectedHotspot.children.filter( c => c.name === 'Dots' );
    //     if ( dots.length ) {
    //         let intersectedDots = dots[0].children.filter( d => {
    //             let x1 = mousePos.truex;
    //             let x2 = d.geometry.vertices[0].x;
    //             let xmax = Math.max( x1, x2 );
    //             let xmin = Math.min( x1, x2 );
    //
    //             let y1 = mousePos.truey;
    //             let y2 = d.geometry.vertices[0].y;
    //             let ymax = Math.max( y1, y2 );
    //             let ymin = Math.min( y1, y2 );
    //
    //             return ( Math.abs( xmax - xmin ) < DRAGTHRESHOLD )
    //                 && ( Math.abs( ymax - ymin ) < DRAGTHRESHOLD );
    //         });
    //         if ( intersectedDots.length ) this.dragging = { object: intersectedDots[0] };
    //     }
    // }
  };

  const onMouseUp = () => {
    if ( sceneState.dragging ) {
      onDragEnd( sceneState.dragging.object );

      sceneStateSetProps({ dragging: false });
    }
  };

  const onMouseDown = mousePosVector => {
    const { isDrawMode } = props;

    if ( isDrawMode ) { // default mode
      // if ( this.objectBeingDrawn === null ) {
      //     this.objectBeingDrawn = new THREE.Object3D();
      //     this.scene.add( this.objectBeingDrawn );
      // }
      // this.draw( mousePosVector );
    } else {
      sceneState.raycaster.setFromCamera( mousePosVector, sceneState.camera );

      const hotspotobj = getRayIntersectMeshFirst(
        sceneState.raycaster, Object.values( objects3DRef.current.hotspot3DMap )
      );

      if ( hotspotobj && hotspotobj.hotspotId ) {
        if ( !sceneState.dragging ) {
          sceneState.dragging = { object: hotspotobj };

          onDragStart( sceneState.dragging.object, mousePosVector );
        }
      }
    }
  };

  useEffect( () => lifecycleSet({
    threeSceneCreate,
    threeSceneRefreshed
  }), [ objects3DRef.current.hotspot3DMap ]);

  useSceneEffect( () => {
    onMouseMove( mouseMoveXYVector );
  }, [ mouseMoveXYVector ]);

  useSceneEffect( () => {
    onMouseDown( mouseDownXYVector );
  }, [ mouseDownXYVector ]);

  useSceneEffect( () => {
    onMouseUp( mouseUpXYVector );
  }, [ mouseUpXYVector ]);

  return <CanvasThreeGQL id={'CanvasThree-rectangle'} />;
}

CanvasEditGQL.propTypes = {
  media: PropTypes.object, // media used at mediaElem (video or image)
  dimensions: PropTypes.object,
  moments: PropTypes.array,
  getMediaElem: PropTypes.func.isRequired,

  isDrawMode: PropTypes.bool,

  actions: PropTypes.object,

  selected_hotspotId: PropTypes.string,
  scene: PropTypes.object.isRequired,
  playback: PropTypes.object, // video audio... target playback

  onRenderFn: PropTypes.func.isRequired
};
