import { useRef, useEffect } from 'react';

const win = typeof window === 'object' ? window : null;

// user must interact with document for this to fire
//
// use these to block unloading when testing this
//
// e.preventDefault();
// e.returnValue = '';
//
const addUnloadListener = fn => (
  win && win.addEventListener( 'beforeunload', fn ) );

const removeUnloadListener = fn => (
  win && win.removeEventListener( 'beforeunload', fn ) );

export default function useWindowUnload ( fn ) {
  const fnRef = useRef();

  fnRef.current = fn;

  useEffect( () => {
    const onUnload = e => fnRef.current( e );

    addUnloadListener( onUnload );

    return () => removeUnloadListener( onUnload );
  }, [ fnRef ]);
}
