//
// handles window resize and passes width/height down reducer
//

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import screenfull from 'screenfull';
import { getSceneMainVideoInfo } from '../utils/sceneUtils';
import { getTitle, isMediaStartFuture } from '../utils/mediaUtils';
import { getVolatileSceneId } from '../utils/volatileUtils';
import CanvasPlayer from './CanvasPlayer';
import PlayerControlsSeek from './PlayerControlsSeek';
import SlatePlayPause from './SlatePlayPause';
import SlateCountdown from './SlateCountdown';
import AdsPanel from './AdsPanel';
import CamerasPanel from './CamerasPanel';
import PanelPlayerSettings from './PanelPlayerSettings';
import StageTitle from './StageTitle';
import ImgWatermark from './ImgWatermark';
import ImgPoster from './ImgPoster';
import { setCanvasStateClass } from '../utils/canvasShapes';

const StageContainer = styled.div`
   width: 100%;
   height: 100%;

   &.isEngage-true.isAd-false .stagetitle {
      transition-delay: 0s, 0s;
      opacity:1;
      top:0;
   }
   &.isEngage-true.isAd-false .stagefoot {
      transition-delay: 0s;
      bottom:0;
   }
   &.isEngage-true.isAd-false .stagefootcontent {
      transition-delay: 0s;
      opacity:1;
   }
`;

const FootContainer = styled.div`
    position:relative;
    height:100%;
`;

const SettingsContainer = styled.div`
    position: ${props => props.stagew > 700 ? 'absolute' : 'fixed'};
    top: ${props => props.stagew > 700 ? '-440px' : '0'};
    right: ${props => props.stagew > 700 ? '20px' : '0'};
    width: ${props => props.stagew > 700 ? '400px' : '100%'};
    z-index:4;
`;

const Title = styled.div`
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s, top .2s;
    transition-delay: 2s, 2s;
    opacity: 0;
    top: -80px;

    position: absolute;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: 3;
`;

const Foot = styled.div`
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s, bottom .2s;
    transition-delay: 2s, 2s;
    opacity: 0;

    transition: bottom .2s;
    transition-delay: 2s;
    opacity: 1;
    bottom: -80px;

    color:#fff;
    position: absolute;
    right: 0;
    left: 0;
    height:70px;
`;

const FootContent = styled.div`
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s;
    transition-delay: 2s;
    opacity: 0;

    color:#fff;
    height:100%;
`;

class PlayerStage extends React.Component {
  constructor ( ) {
    super();

    const [ width, height ] = this.getStageWH( );

    this.state = {
      width,
      height
    };

    this.timeout = false;
    this.isEngage = true;
    this.onEngageStart = this.onEngageStart.bind( this );
    this.onEngageEnd = this.onEngageEnd.bind( this );

    this.updateDimensions = this.updateDimensions.bind( this );
  }

  getStageElem () {
    return document.getElementById( 'Stage' );
  }

  updateDimensions () {
    const [ width, height ] = this.getStageWH( );

    this.setState({
      width,
      height
    });
  }

  togglePlaying ( playing ) {
    this.props.actions.togglePlaying( playing );
  }

  componentDidMount () {
    this.updateDimensions();
    this.attach();
  }

  componentWillUnmount () {
    this.detach();
  }

  setStageState ( statename, statebool ) {
    return setCanvasStateClass( this.getStageElem(), statename, statebool );
  }

  onEngageStart () {
    this.isEngage = true;
    this.setStageState( 'isEngage', true );
  }

  onEngageEnd () {
    // prevent immediate disengage
    //
    // give 'engaged' stage time-space to complete transition|delay css
    if ( this.timeout ) {
      clearTimeout( this.timeout );
    }

    this.timeout = setTimeout( () => {
      this.isEngage = false;
      this.setStageState( 'isEngage', false );
    }, 1000 );
  }

  attach () {
    const stageElem = this.getStageElem();

    stageElem.addEventListener( 'mousemove', this.onEngageStart );
    stageElem.addEventListener( 'touchstart', this.onEngageStart );

    stageElem.addEventListener( 'mouseup', this.onEngageEnd );
    stageElem.addEventListener( 'mouseout', this.onEngageEnd );
    stageElem.addEventListener( 'touchend', this.onEngageEnd );

    if ( screenfull.enabled ) {
      screenfull.on( 'change', () => this.updateDimensions );
    }

    window.addEventListener( 'resize', this.updateDimensions );
  }

  detach () {
    const stageElem = this.getStageElem();

    stageElem.removeEventListener( 'mouseover', this.onEngageStart );
    stageElem.removeEventListener( 'touchstart', this.onEngageStart );

    stageElem.removeEventListener( 'mouseup', this.onEngageEnd );
    stageElem.removeEventListener( 'mouseout', this.onEngageEnd );
    stageElem.removeEventListener( 'touchend', this.onEngageEnd );

    window.removeEventListener( 'resize', this.updateDimensions );
  }

  getPropsScene ( props, sceneId = getVolatileSceneId( props.data ) ) {
    return props.data.scenes[sceneId];
  }

  getPropsVideo ( props, sceneId = getVolatileSceneId( props.data ) ) {
    return getSceneMainVideoInfo( this.getPropsScene( props, sceneId ) );
  }

  // stage width, 100% width of parent
  // stage height, 60% height of parent
  getStageWH () {
    const stage = document.getElementById( 'Stage' );
    const stageparent = stage && stage.parentElement;

    return stageparent
      ? [ stageparent.offsetWidth,
        stageparent.offsetHeight ]
      : [ 0, 0 ];
  }

  render () {
    const [ width, height ] = this.getStageWH( );
    const { data, actions, uipanels, ...containerprops } = this.props;
    const refresh = Number( data.vrmode.isvrenabled );
    const { playing, lastPlayTS, isReady } = data.videoplayback;
    const sceneId = getVolatileSceneId( this.props.data );
    const scene = this.getPropsScene( this.props, sceneId );
    const video = this.getPropsVideo( this.props, sceneId );
    const isPlayable = ( scene && scene.isMountComplete );

    const isAd = Boolean( video && video.ad_type === 'Video Ad' );
    const title = video
      ? video.title
      : 'loading…';

    return (
      <StageContainer id="Stage" className={`isEngage-${this.isEngage} isAd-${isAd}`}>
        <CanvasPlayer
          canvastype={data.canvastype}
          actions={actions}
          data={data}
          scene={scene}
          playing={playing}
          dimensions={{ width, height }}
          refresh={refresh}
        />
        {video && video.poster && !lastPlayTS &&
                    <ImgPoster imgSrc={video.poster && video.poster.poster_img_src} />
        }
        <Title className={'stagetitle'}>
          <StageTitle>{title}</StageTitle>
        </Title>
        {uipanels.activePanelName === 'cameras' &&
                    <CamerasPanel
                      data={data}
                      actions={actions}
                      scene={scene}
                    />
        }
        {isPlayable && isReady &&
                    <SlatePlayPause
                      scene={scene}
                      videoplayback={data.videoplayback}
                      isPlaying={playing}
                      onClick={this.togglePlaying.bind( this )} />
        }
        {!isPlayable && video && isMediaStartFuture( video ) && (
          <SlateCountdown
            title={getTitle( video, 'Untitled' )}
            startTime={video.start_time} />
        )}
        {isAd && (
          <AdsPanel
            onCompleted={() => actions.adCompleted( video )}
            media={video}
          />
        )}
        {video && video.brand && video.brand.brand_img_src && (
          <ImgWatermark imgSrc={video.brand.brand_img_src} />
        )}
        <Foot className={'stagefoot'}>
          <FootContainer>
            {uipanels.activePanelName === 'settings' &&
                            <SettingsContainer stagew={width}>
                              <PanelPlayerSettings
                                width={width}
                                height={height}
                                data={data}
                                scene={scene}
                                video={video}
                                actions={actions}
                              />
                            </SettingsContainer>
            }
            {isPlayable &&
                            <FootContent className={'stagefootcontent'}>
                              <PlayerControlsSeek
                                stageDimensionsWH={[ width, height ]}
                                data={data}
                                actions={actions}
                                uipanels={uipanels}
                                scene={scene}
                                {...containerprops} />
                            </FootContent>
            }
          </FootContainer>
        </Foot>
      </StageContainer>
    );
  }
}

PlayerStage.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  uipanels: PropTypes.object.isRequired
};

export default PlayerStage;
