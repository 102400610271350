import queryString from 'query-string';
import formurlencoded from 'form-urlencoded';
import Evaporate from 'evaporate';
import * as addquery from 'addquery';
import sha256 from 'js-sha256';
import sparkMD5 from 'spark-md5';
import { getBasename } from './utils/url';
import {
  VENOM_API_LEGACY,
  gqlIsLogin
} from './env';
import * as serviceGQL from './serviceGQL';

import {
  overlayFetch,
  typeActions
} from './actions/action-overlays';

import {
  SCENE_SET_VIDEO
} from './actions/ActionTypes';

import {
  uploadIsFinished,
  uploadIsError,
  addErrorToast,
  playlistGetStart,
  playlistGetLocal
} from './actions/index';
import { isMimeHls } from './utils/hls';

import {
  getSequenceFromMediaVideo
} from './utils/seqUtils';

import {
  getSceneSequence,
  getSceneHotspot
} from './utils/sceneUtils';

import {
  sequenceToService,
  hotspotToService,
  sceneToService
} from './adapter';

import {
  KEY_VENOM_USER_TOKEN,
  KEY_VENOM_USER_EMAIL
} from './enums.js';

const IVSTORYKEY = 'iv';
const STORYKEY = 'load_story_id';
const VIDEOKEY = 'h';
const ISLIVEKEY = 'islive';

const HOST = VENOM_API_LEGACY;

const APIV12 = `${HOST}/api/v1.2`;
const APIV20 = `${HOST}/api/v2.0`;

// general route that returns story, iv and video embed style data
const uri_content = `${HOST}/embed?hash=:hash`;

const uri_video = `${HOST}/embed?hash=:hash&type=media`;
const uri_ivideo = `${HOST}/embed?hash=:hash&type=story`;
const uri_video_live = `${HOST}/embed/live/:hash/details`;
const uri_video_ads = `${HOST}/embed/:videohash/ads`;

const uri_video_upload = `${APIV20}/s3/uploadPath`;
const uri_upload_sign = `${APIV20}/s3/uploadAuth`;

const uri_story = `${APIV12}/stories/:storyid`;
const uri_sessionlogin = `${APIV12}/sessionLogin`;
const uri_hotspots = `${APIV12}/hotspots/:hotspotid`;
const uri_orgs = `${APIV12}/organizations`;
const uri_stories = `${APIV12}/organizations/:orgid/stories`;
const uri_scene = `${APIV12}/scenes/:sceneId`;
const uri_scenehotspots = `${APIV12}/scenes/:sceneId/hotspots`;
const uri_storyscenes = `${APIV12}/stories/:storyid/scenes`;
const uri_storyvideos = `${APIV12}/stories/:storyid/videos`;
const uri_sequence = `${APIV12}/scenes/:sceneId/sequences/:sequenceid`;

// 2.0
const uri_orgmedialib = `${APIV20}/organizations/:orgid/mediaLibraries`;
const uri_mediafolder = `${APIV20}/mediaFolders/:folderid`;
const uri_newmedia = `${APIV20}/mediaFolders/:folderid/mediaItems`;
const uri_putmedia = `${APIV20}/mediaFolders/:folderid/mediaItems/:mediaid`;
const uri_scenemedia = `${APIV20}/scenes/:sceneId/mediaItems/:mediaid`;
const uri_usercredentials = `${APIV20}/auth/getUserCredentials`;

// querykey = 'storyid'
// query = '?storyid=1234'
//
// return '1234'
//
// for now (temporarily), returns default story id,
// 58157f12-08d5-41c6-8486-207829f2f5b0
const getqueryval = ( query, querykey ) => queryString.parse( query )[querykey];

export const getStoryIdInQuery = ( query = window.location.search, storykey = STORYKEY ) => getqueryval( query, storykey );

export const getInteractiveStoryIdInQuery = ( query = window.location.search, ivstorykey = IVSTORYKEY ) => getqueryval( query, ivstorykey );

export const getVideoHashInQuery = ( query = window.location.search, videokey = VIDEOKEY ) => getqueryval( query, videokey );

const getIframeElem = ( src, onloadfn ) => {
  const iframe = document.createElement( 'iframe' );
  iframe.width = '1px';
  iframe.height = '1px';
  iframe.frameBorder = '0';
  iframe.allowfullscreen = '';
  iframe.src = src;

  iframe.onerror = e => (
    typeof onloadfn === 'function' && onloadfn( e ) );
  iframe.onload = () => (
    typeof onloadfn === 'function' && onloadfn() );

  return iframe;
};

async function endSession () {
  window.localStorage.setItem( KEY_VENOM_USER_TOKEN, '' );
  window.localStorage.setItem( KEY_VENOM_USER_EMAIL, '' );
  document.body.appendChild( getIframeElem(
    'https://vr.digitaldomain.com/console/logout',
    () => window.location.reload()
  ) );
}

async function getSession ( dispatch ) {
  const venomApiToken = window.localStorage.getItem( KEY_VENOM_USER_TOKEN );
  const uri = uri_sessionlogin;

  if ( gqlIsLogin && venomApiToken ) {
    await overlayFetch( dispatch, typeActions.SESSIONUSER_GET, uri_usercredentials, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        Authorization: `Bearer ${venomApiToken}`
      })
    });
  }

  await overlayFetch( dispatch, typeActions.SESSION_GET, uri, {
    method: 'GET',
    credentials: 'include'
  });
}

async function getOrgs ( dispatch, authtoken ) {
  const uri = uri_orgs;

  await overlayFetch( dispatch, typeActions.ORGS_GET, uri, {
    method: 'GET',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
}

async function getPlaylistText ( dispatch, playlisturl, sceneId, mediaId ) {
  const uri = playlisturl;

  await overlayFetch( dispatch, typeActions.PLAYLIST_GET, uri, {
    method: 'GET',
    responseType: 'text'
  }, { sceneId, mediaId, playlisturl });
}

async function getOrgStories ( dispatch, orgId ) {
  const uri = uri_stories.replace( /:orgid/, orgId );

  await overlayFetch( dispatch, typeActions.ORGS_GET, uri, {
    method: 'GET'
  });
}

async function getInteractiveStory ( dispatch, storyId = getInteractiveStoryIdInQuery() ) {
  const uri = uri_ivideo.replace( /:hash/, storyId );

  await overlayFetch( dispatch, typeActions.STORY_GET, uri, {
    method: 'GET'
  });
}

async function getStory ( dispatch, storyId = getStoryIdInQuery() ) {
  const uri = uri_story.replace( /:storyid/, storyId );

  await overlayFetch( dispatch, typeActions.STORY_GET, uri, {
    method: 'GET'
  });
}

async function getStoryGQL ( dispatch, storyId = getStoryIdInQuery() ) {
  const data = await serviceGQL.getContent( storyId );

  dispatch({
    type: typeActions.STORY_GET.REQ_SUCCESS,
    storyId,
    res: data
  });
}

// general route that returns story, iv and video embed style data
//
async function getContent ( dispatch, hash = getVideoHashInQuery() ) {
  const uri = uri_content.replace( /:hash/, hash );

  await overlayFetch( dispatch, typeActions.VIDEO_GET, uri, {
    method: 'GET',
    credentials: 'include'
  }, { hash });
}

async function getVideo ( dispatch, hash = getVideoHashInQuery(), isLive ) {
  const uri = isLive
    ? uri_video_live.replace( /:hash/, hash )
    : uri_video.replace( /:hash/, hash );

  await overlayFetch( dispatch, typeActions.VIDEO_GET, uri, {
    method: 'GET',
    credentials: 'include'
  }, { hash, videoType: isLive ? 'stream' : 'vod' });
}

// getStoryAny/2
//
// taken from lasher_temp --thank you Andy Brenneke
//
// requests story data associated with a storyid, but will ALSO request
// vanilla lasher-style video data
//
// * 2K Video
//   ?hash=jygvs7p1ptdgjm7szwca
//
// * 4K Video
//   ?hash=uenk29tswak0zx7b40li
//
// * ABR Video
//   ?hash=4kfqwwyqg0yel5xcl313
//
// * 3D L/R (Mighty Earth: Storm) (ABR with fallback support)
//   ?hash=9giwlm2zma0480qzwt9q
//
// * 3D L/R (MicroGiants) (4K)
//   ?hash=chvw8brds0dotw3f4t6r
//
// * HLS Stream Multiple Cameras
//   ?hash=4ku232lewutfja64yozl
//
// * MP4 With Pre-Roll Ads
//   ?hash=hyd0fqhtmjnjhdlucbf2
//
// * ABR With Pre-Roll Ads
//   ?hash=nc12nc9w079cqi99wyhr
//
// * Live Stream
//   ?hash=r26usf9ot4sapeexbq82
//
// * Story
//   ?hash=Ljiyqs0OVEXV6USRE509
//
// note: per ANDY, creating/using an sequential dispatch behaviour would
//       allow us to request story/video data then advert data in the same place
//
// psuedo code
//     ? getLiveVideo( dispatch, [function queuedfunction ( dispatch, livevideodata ) {
//     }] )
//
async function getStoryAny ( dispatch ) {
  const query = window.location.search;
  const hash = getqueryval( query, 'hash' );
  const storyId = getqueryval( query, STORYKEY );
  const ivhash = getqueryval( query, IVSTORYKEY );
  const videohash = getqueryval( query, VIDEOKEY );
  const isvideolive = getqueryval( query, ISLIVEKEY );

  if ( hash ) {
    getContent( dispatch, hash );
    //
    //
    // the different requests for vod/livestream/story are being-phased out
    // in favor of single request for any data using hash value
  } else if ( storyId ) {
    getStory( dispatch, storyId );
  } else if ( ivhash ) {
    getInteractiveStory( dispatch, ivhash );
  } else if ( videohash ) {
    getVideo( dispatch, videohash, isvideolive );
  }
}

async function getOrgMediaLibrary ( dispatch, orgId ) {
  const uri = uri_orgmedialib
    .replace( /:orgid/, orgId );

  return overlayFetch( dispatch, typeActions.MEDIALIBRARY_GET, uri, {
    method: 'GET'
  });
}

async function getFolderMedia ( dispatch, libId, media_class = null ) {
  let uri = uri_mediafolder
    .replace( /:folderid/, libId );

  if ( media_class !== null ) {
    const split = uri.split( '?' );
    uri = `${split[0]}?media_class=${media_class}${split[1]}`;
  }

  return overlayFetch( dispatch, typeActions.MEDIA_GET, uri, {
    method: 'GET'
  }, { media_class });
}

async function getAdvert ( dispatch, advert ) {
  const uri = uri_video_ads
    .replace( /:videohash/, advert.hash );

  await overlayFetch( dispatch, typeActions.ADVERT_GET, uri, {
    method: 'GET'
  }, { advert });
}

async function putSequence ( dispatch, authtoken, sceneId, sequenceId, data ) {
  const uri = uri_sequence
    .replace( /:sceneId/, sceneId )
    .replace( /:sequenceid/, sequenceId );

  await overlayFetch( dispatch, typeActions.SEQUENCE_UPDATE, uri, {
    method: 'PUT',
    body: formurlencoded( data ),
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    delta: data,
    sceneId,
    sequenceId
  });
}

async function putHotspot ( dispatch, authtoken, sceneId, hotspotId, data ) {
  const uri = uri_hotspots.replace( /:hotspotid/, hotspotId );

  await overlayFetch( dispatch, typeActions.HOTSPOT_UPDATE, uri, {
    method: 'PUT',
    body: formurlencoded( data ),
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    delta: data,
    sceneId,
    hotspotId
  });
}

async function deleteSequence ( dispatch, authtoken, sceneId, sequenceId ) {
  const uri = uri_sequence
    .replace( /:sceneId/, sceneId )
    .replace( /:sequenceid/, sequenceId );

  await overlayFetch( dispatch, typeActions.SEQUENCE_DELETE, uri, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    sceneId,
    sequenceId
  });
}

async function deleteVideoSequence ( dispatch, authtoken, sceneId, videoId, sequenceArr ) {
  for ( let i = 0; i < sequenceArr.length; i += 1 ) {
    deleteVideoSequenceCall( dispatch, authtoken, sceneId, videoId, sequenceArr[i].uuid );
  }
}

async function deleteVideoSequenceCall ( dispatch, authtoken, sceneId, videoId, sequenceId ) {
  const uri = uri_sequence
    .replace( /:sceneId/, sceneId )
    .replace( /:sequenceid/, sequenceId );

  await overlayFetch( dispatch, typeActions.VIDEO_DELETE, uri, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    sceneId,
    videoId,
    sequenceId
  });
}

async function deleteScene ( dispatch, authtoken, sceneId ) {
  const uri = uri_scene
    .replace( /:sceneId/, sceneId );

  await overlayFetch( dispatch, typeActions.SCENE_DELETE, uri, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
}

async function getBrandBlob ( dispatch, sceneId, srcurl ) {
  await overlayFetch( dispatch, typeActions.BRANDBLOB_GET, srcurl, {
    method: 'GET',
    responseType: 'blob'
  }, {
    sceneId
  });
}

async function getPosterBlob ( dispatch, sceneId, srcurl ) {
  await overlayFetch( dispatch, typeActions.POSTERBLOB_GET, srcurl, {
    method: 'GET',
    responseType: 'blob'
  }, {
    sceneId
  });
}

async function getStoryVideos ( dispatch, authToken, storyId ) {
  const uri = uri_storyvideos
    .replace( /:storyid/, storyId );

  await overlayFetch( dispatch, typeActions.VIDEOS_GET, uri, {
    method: 'GET',
    headers: new Headers({
      Authorization: authToken
    })
  });
}

async function postScene ( dispatch, authtoken, storyId ) {
  const uri = uri_storyscenes
    .replace( /:storyid/, storyId );

  const res = await overlayFetch( dispatch, typeActions.SCENE_CREATE, uri, {
    method: 'POST',
    body: formurlencoded({
      name: 'new scene',
      postplayback: {
        type: 4, // do nothing
        dest_scene: ''
      }
    }),
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });

  return res;
}

async function putScene ( dispatch, authtoken, sceneId, data ) {
  const uri = uri_scene.replace( /:sceneId/, sceneId );

  await overlayFetch( dispatch, typeActions.SCENE_UPDATE, uri, {
    method: 'PUT',
    body: formurlencoded( data ),
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    delta: data,
    sceneId
  });
}

async function deleteMediaFromScene ( dispatch, authtoken, scene, media ) {
  const uri = uri_scenemedia
    .replace( /:sceneId/, scene.id )
    .replace( /:mediaid/, media.id );

  await overlayFetch( dispatch, typeActions.SCENEMEDIA_DELETE, uri, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
}

function deleteAllMediaFromScene ( dispatch, authtoken, scene ) {
  return Object.values( scene.media_dict ).map( media => (
    deleteMediaFromScene( dispatch, authtoken, scene, media ) ) );
}

async function addMediaToScene ( dispatch, authtoken, scene, media ) {
  const uri = uri_scenemedia
    .replace( /:sceneId/, scene.id )
    .replace( /:mediaid/, media.id );

  await overlayFetch( dispatch, typeActions.SCENEMEDIA_CREATE, uri, {
    method: 'PUT',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
}

// Create a hotspot (also creates correspodning sequence item entry in to the scene)
//
// POST /scenes/:scene_id/hotspots
//     required sequence data: name, start, end, dest_scene, dest_start
//     required hotspot data: coords, pos, dimensions
//     responds with { sequence: sequence, hotspot: hotspot }
async function postHotspot ( dispatch, authtoken, sceneId, data ) {
  const uri = uri_scenehotspots.replace( /:sceneId/, sceneId );

  await overlayFetch( dispatch, typeActions.HOTSPOT_CREATE, uri, {
    method: 'POST',
    body: formurlencoded( Object.assign(
      sequenceToService( data ),
      hotspotToService( data )
    ) ),
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }, {
    sceneId
  });
}

// legacy venom api.mediaLibrary.updateMediaItem
async function putMediaProperties ( dispatch, authtoken, folderId, mediaid, mediaProps ) {
  const uri = uri_putmedia
    .replace( ':folderid', folderId )
    .replace( ':mediaid', mediaid );

  await overlayFetch( dispatch, typeActions.SCENEMEDIA_UPDATE, uri, {
    method: 'PUT',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: formurlencoded( mediaProps )
  }, {
    delta: mediaProps,
    mediaid
  });
}

async function saveMedia ( dispatch, authtoken, folderId, file ) {
  const uri = uri_newmedia
    .replace( ':folderid', folderId );

  const media_class = file.type.split( '/' )[0];

  const response = await fetch( uri, {
    method: 'POST',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: formurlencoded({
      media_class,
      title: file.name,
      file_url: file.uploadPath,
      access: 'Public',
      lang: 'en-US',
      projection: 'Spherical',
      storage_type: 'Internal'
    })
  });


  if ( response.ok ) {
    dispatch( uploadIsFinished() );
  } else {
    dispatch( uploadIsError() );
  }
}

// media_class is 'video', 'image', 'audio'
async function saveExternalMedia ( dispatch, authtoken, folderId, fileURL, media_class ) {
  const uri = uri_newmedia.replace( ':folderid', folderId );

  const response = await fetch( uri, {
    method: 'POST',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: formurlencoded({
      media_class,
      title: getBasename( fileURL ),
      file_url: fileURL,
      access: 'Public',
      lang: 'en-US',
      projection: 'Spherical',
      storage_type: 'External'
    })
  });

  dispatch( response.ok
    ? uploadIsFinished({ media: await response.json(), file: fileURL })
    : uploadIsError() );
}

async function uploadMedia ( dispatch, authtoken, orgId, folderId, file, type, onProgress ) {
  const uri = addquery.addquery( uri_video_upload, formurlencoded({
    filename: file.name,
    filesize: file.size,
    filetype: file.type
  }) );

  const response = await fetch( uri, {
    method: 'GET',
    headers: new Headers({
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });

  const data = await response.json();

  if ( data.error ) {
    return dispatch( addErrorToast( data.error ) );
  }

  file.objectKeyPath = data.objectKeyPath;
  file.uploadPath = data.uploadPath;

  const createConfig = {
    signerUrl: `${uri_upload_sign}`,
    aws_key: data.awsKey,
    bucket: data.bucket,
    awsRegion: data.awsRegion,
    logging: false,
    partSize: 10 * 1024 * 1024,
    maxFileSize: 50 * 1024 * 1024 * 1024,
    maxConcurrentParts: 10,
    awsSignatureVersion: '4',
    cloudfront: true,
    s3Acceleration: true,
    computeContentMd5: true,
    cryptoMd5Method: d => btoa( sparkMD5.ArrayBuffer.hash( d, true ) ),
    cryptoHexEncodedHash256: sha256,
    signHeaders: {
      Authorization: authtoken,
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  };

  const addConfig = {
    name: file.objectKeyPath,
    file,
    contentType: file.type,
    progress: onProgress
  };

  return Evaporate.create( createConfig ).then( evaporate => {
    evaporate.add( addConfig ).then(
      () => {
        saveMedia( dispatch, authtoken, folderId, file );
      },
      err => {
        dispatch( uploadIsError() );
        console.error( err );
      }
    );
  });
}

// where 'connect' is used, this function converts dispatching functions
// to a generic function signature
//
// so instead of this,
//
//   fetchStoryData: service.getStoryDispatch( dispatch )
//       dispatch( service.getStoryDispatch() )
//
//   deleteSequenceData: ( sceneId, hotspotId, sequenceId ) =>
//       dispatch( service.deleteSequenceDispatch( sceneId, hotspotId, sequenceId ) )
//
// this can be used,
//
//   fetchStoryData: service.getStoryDispatch( dispatch )
//
//   deleteSequenceData: service.deleteSequenceDispatch( dispatch ),
//
const dispatcher = fn => dispatchfn => (
  ( ...args ) => dispatchfn( ( dispatch, getstate ) => fn( dispatchfn, getstate, ...args ) ) );

const getAdvertDispatch = dispatcher(
  ( dispatch, getstate, videoId, sceneId ) => Promise.all([
    getAdvert( dispatch, videoId, sceneId )
  ])
);

const deleteSequenceDispatch = dispatcher(
  ( dispatchfn, getstate, sceneId, hotspotId, sequenceuuid ) => Promise.all([
    deleteSequence( dispatchfn, getstate().data.authtoken, sceneId, hotspotId, sequenceuuid )
  ])
);

const getStoryVideosDispatch = dispatcher(
  ( dispatch, getstate, storyId ) => Promise.all([
    getStoryVideos( dispatch, getstate().data.authtoken, storyId )
  ])
);

const postSceneDispatch = dispatcher(
  ( dispatch, getstate, video ) => {
    const { storyId } = getstate().data.select;
    const { authtoken } = getstate().data;
    if ( video ) {
      Promise.all([
        postScene( dispatch, getstate().data.authtoken, storyId )
      ]).then( ([ sceneres ]) => {
        const scene = sceneres && sceneres.scene;
        if ( scene ) {
          addMediaToScene( dispatch, authtoken, scene, video );
        }
      });
    } else {
      Promise.all([
        postScene( dispatch, authtoken, storyId )
      ]);
    }
  }
);

const deleteSceneDispatch = dispatcher(
  ( dispatch, getstate, sceneId ) => Promise.all([
    deleteScene( dispatch, getstate().data.authtoken, sceneId )
  ])
);

const postHotspotDispatch = dispatcher(
  ( dispatchfn, getstate, sceneId, data ) => Promise.all([
    postHotspot( dispatchfn, getstate().data.authtoken, sceneId, data )
  ])
);

const getStoryDispatch = dispatcher( ( dispatchfn, getState ) => Promise.all([
  getStoryAny( dispatchfn )
]).then( () => {
  // `authorized: true` and `analytics_access: true`
  //
  // begin testing general content route by commenting-out
  // below block
  //
  const { orgId } = getState().data.select;

  if ( orgId ) { // story data
    Promise.all([
      getOrgMediaLibrary( dispatchfn, orgId )
    ]).then( ({ media_library }) => {
      const { root_folder_id } = media_library;
      getFolderMedia( dispatchfn, root_folder_id );
    });
  }
}) );

const getStorySessionDispatch = dispatcher( ( dispatchfn, getState ) => Promise.all([
  getSession( dispatchfn )
]).then( () => getStory( dispatchfn ) ).then( () => {
  const { orgId } = getState().data.select;
  return getOrgMediaLibrary( dispatchfn, orgId );
}).then( ({ media_library }) => {
  const { root_folder_id } = media_library;
  return getFolderMedia( dispatchfn, root_folder_id );
}) );

const getStoryGQLDispatch = dispatcher( dispatchfn => getStoryGQL( dispatchfn ) );

const getBrandBlobDispatch = dispatcher( ( dispatchfn, getstate, sceneid, url ) => {
  getBrandBlob( dispatchfn, sceneid, url );
});

const getPosterBlobDispatch = dispatcher( ( dispatchfn, getstate, sceneid, url ) => {
  getPosterBlob( dispatchfn, sceneid, url );
});

const getFolderVideosDispatch = dispatcher(
  ( dispatch, getstate, folderId ) => Promise.all([
    getFolderMedia( dispatch, folderId, 'Video' ) ])
);

const getFolderImagesDispatch = dispatcher(
  ( dispatch, getstate, folderId ) => Promise.all([
    getFolderMedia( dispatch, folderId, 'Image' ) ])
);

const getFolderAudioDispatch = dispatcher(
  ( dispatch, getstate, folderId ) => Promise.all([
    getFolderMedia( dispatch, folderId, 'Audio' ) ])
);

// call with a media type 'audio', 'image' or 'video'
// defaults to video if no valid media type is specified
const getFolderMediaTypeDispatch = dispatcher(
  ( dispatch, getstate, mediaType, folderId ) => {
    mediaType = String( mediaType ).toLowerCase();

    if ( !/^(audio|image|video)$/.test( mediaType ) ) {
      mediaType = 'video';
    }

    // convert audio to Audio
    mediaType = mediaType.charAt( 0 ).toUpperCase() + mediaType.slice( 1 );
    Promise.all([
      getFolderMedia( dispatch, folderId, mediaType ) ]);
  }
);

const getPlaylistDispatch = dispatcher(
  ( dispatch, getstate, sceneId, mediaId, mediaType, playlisturl ) => {
    dispatch( playlistGetStart(
      sceneId,
      mediaId,
      mediaType,
      playlisturl
    ) );

    if ( isMimeHls( mediaType ) ) {
      Promise.all([
        getPlaylistText( dispatch, playlisturl, sceneId, mediaId )
      ]);
    } else {
      dispatch( playlistGetLocal( sceneId, mediaId ) );
    }
  }
);

const putSequenceDispatch = dispatcher(
  ( dispatchfn, getstate, sceneId, seqId, seqDelta ) => {
    const state = getstate().data;
    const sequence = state && getSceneSequence( state.scenes[sceneId], seqId );

    const serviceSequence = sequenceToService({
      ...sequence,
      ...seqDelta
    });

    Promise.all([
      putSequence( dispatchfn, state.authtoken, sceneId, seqId, serviceSequence )
    ]).then( () => {
      console.log( 'sequence update success' );
    }).catch( err => {
      console.log( 'sequence update error', err );
    });
  }
);

const putHotspotDispatch = dispatcher(
  ( dispatchfn, getstate, sceneId, hotspotId, hotspotDelta ) => {
    // join delta with current hotspot data to persist
    const state = getstate().data;
    const hotspot = getSceneHotspot( state.scenes[sceneId], hotspotId );

    if ( hotspot.fusetime ) { // any fusetime definition results in legacy api error
      hotspotDelta.fusetime = undefined;
    }

    Promise.all([
      putHotspot( dispatchfn, state.authtoken, sceneId, hotspotId, {
        ...hotspot,
        ...hotspotDelta
      })
    ]).then( () => {
      console.log( 'hotspot update success' );
    }).catch( err => {
      console.log( 'hotspot update error', err );
    });
  }
);

const putSceneDispatch = dispatcher(
  ( dispatchfn, getstate, sceneId, sceneDelta ) => {
    const state = getstate().data;
    let sceneData = {
      ...state.scenes[sceneId]
    };
    sceneData = Object.assign( sceneData, sceneDelta );
    Promise.all([
      putScene( dispatchfn, state.authtoken, sceneId, sceneToService( sceneData ) )
    ]).then( () => {
      console.log( 'scene save success' );
    }).catch( err => {
      console.log( 'scene save error', err );
    });
  }
);

// scenid oldseq, video
const putSceneMediaDispatch = dispatcher(
  ( dispatch, getstate, sceneId, oldSequenceArr, newVideo ) => {
    const state = getstate().data;
    const scene = state.scenes[sceneId];

    Promise.all(
      deleteAllMediaFromScene( dispatch, state.authtoken, scene )
    ).then( () => {
      addMediaToScene( dispatch, state.authtoken, scene, newVideo );
    }).then( () => {
      console.log( 'update scene media success' );
    }).catch( err => {
      console.log( 'error scene media', err );
    });
  }
);

const putSceneVideoDispatch = dispatcher(
  ( dispatch, getstate, sceneId, newVideo ) => {
    const state = getstate().data;
    const scene = state.scenes[sceneId];

    Promise.all(
      deleteAllMediaFromScene( dispatch, state.authtoken, scene )
    ).then( () => {
      addMediaToScene( dispatch, state.authtoken, scene, newVideo );
    }).then( () => {
      console.log( 'update scene media success, add sequence' );
      dispatch({
        sceneId,
        video: newVideo,
        type: SCENE_SET_VIDEO,
        // if squence can be created from video...
        // consider removing sequence here altogether
        sequence: getSequenceFromMediaVideo( newVideo )
      });
    }).catch( err => {
      console.log( 'error scene media', err );
    });
  }
);

const uploadMediaDispatch = dispatcher(
  ( dispatchfn, getstate, file, type, onProgress ) => {
    const { authtoken, medialibrary } = getstate().data;
    const { root_folder_id } = medialibrary;
    const { orgId } = getstate().data.select;
    uploadMedia( dispatchfn, authtoken, orgId, root_folder_id, file, type, onProgress );
  }
);

const putMediaPropertiesDispatch = dispatcher(
  ( dispatchfn, getstate, media, mediaProps ) => {
    const { authtoken, medialibrary } = getstate().data;
    const { root_folder_id } = medialibrary;

    putMediaProperties( dispatchfn, authtoken, root_folder_id, media.id, mediaProps );
  }
);

const uploadMediaURLDispatch = dispatcher(
  ( dispatchfn, getstate, fileURL, type ) => {
    const { authtoken, medialibrary } = getstate().data;
    const { root_folder_id } = medialibrary;

    saveExternalMedia( dispatchfn, authtoken, root_folder_id, fileURL, type );
  }
);

export {
  getqueryval,
  endSession,
  getSession,
  getOrgs,
  getPlaylistText,
  getOrgStories,
  getInteractiveStory,
  getStory,
  getContent,
  getVideo,
  getStoryAny,
  getOrgMediaLibrary,
  getFolderMedia,
  getAdvert,
  putSequence,
  putHotspot,
  deleteSequence,
  deleteVideoSequence,
  deleteVideoSequenceCall,
  deleteScene,
  getBrandBlob,
  getPosterBlob,
  getStoryVideos,
  postScene,
  putScene,
  deleteMediaFromScene,
  deleteAllMediaFromScene,
  addMediaToScene,
  postHotspot,
  saveMedia,
  uploadMedia,

  getAdvertDispatch,
  deleteSequenceDispatch,
  getStoryVideosDispatch,
  postSceneDispatch,
  deleteSceneDispatch,
  postHotspotDispatch,
  getStoryDispatch,
  getStorySessionDispatch,
  getStoryGQLDispatch,
  getBrandBlobDispatch,
  getPosterBlobDispatch,
  getFolderMediaTypeDispatch,
  getFolderVideosDispatch,
  getFolderImagesDispatch,
  getFolderAudioDispatch,
  getPlaylistDispatch,
  putHotspotDispatch,
  putSequenceDispatch,
  putSceneDispatch,
  putSceneMediaDispatch,
  putSceneVideoDispatch,
  uploadMediaDispatch,
  uploadMediaURLDispatch,

  putMediaProperties,
  putMediaPropertiesDispatch
};
