import React from 'react';
import Dropdown from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useInputFocus from '../hooks/useInputFocus';

import ExpandMore from '../svg/baseline-expand_more-24px.svg';
import ExpandLess from '../svg/baseline-expand_less-24px.svg';

const Label = styled.label`
    margin: 0;
    border: 0px;
    display: block;
    border-radius: 3px;
    position:relative;
    flex: 1;

    &.isRaised {
        margin: 4px;
        border: 1px solid #222;
        border-bottom: ${props => props.isPressed ? '1px' : '1.6px'} solid #222;
        border-radius: 4px;
        display: inline-block;
    }

    &.isRaised .dropdown-container {
        padding: 2px;
        background: var(--ivy-button-raised-background-color);
        background-color: var(--ivy-button-raised-background-color);
        display: inline-block;
    }

    // main dropdown button/face
    &.isRaised .dropdown-container > div {
        color:#e4e4e4;
        fill:#e4e4e4;
        background: transparent;
        background-color: transparent;
    }

    &.isRaised:hover .dropdown-container {
        background: var(--ivy-button-raised-background-color-hover);
    }

    &.isRaised .Dropdown-root {
        display:inline-block;
    }

    &.isRaised .Dropdown-control {
        line-height: 25px;
        font-size: var(--ivy-input-label-font-size);
        background-color: transparent;
        color: var(--ivy-input-label-color);
        border: 0;
        display: inline-flex;
    }

    &.isRaised.isWide .Dropdown-control {
        padding: 0 4px 0 10px;
    }

    &.isRaised .Dropdown-menu {
        width: inherit;
    }

    & .dropdown-container > div {
       font-family: var(--ivy-font-family-primary);
       font-size: var(--ivy-input-font-size);
       color: var(--ivy-input-field-color);

       background-color: var(--ivy-drop-menu-background-color);
    }

    & .Dropdown-menu {
       bottom: ${props => props.align === 'top' ? '100%' : 'unset'};
       top: ${props => props.align === 'top' ? 'unset' : '100%'};
       background-color: var(--ivy-drop-menu-background-color);
       border-color: var(--ivy-drop-menu-border-color);
       width: inherit;
       min-width: 100%;
    }

    & .Dropdown-control,
    & .Dropdown-option,
    & .Dropdown-menu .Dropdown-group > .Dropdown-title {
       display: flex;
       white-space: nowrap;
       cursor: pointer;
       background-color: var(--ivy-drop-menu-background-color);
       border-color: var(--ivy-drop-menu-border-color);
       color: var(--ivy-input-field-color);
    }

    & .Dropdown-option.is-selected,
    & .Dropdown-option:hover {
       background-color: var(--ivy-drop-menu-background-color-hover);
       border-color: var(--ivy-drop-menu-border-color-hover);
       color: var(--ivy-input-field-color);
    }

    & .Dropdown-arrow-wrapper {
       margin-left: 4px;
       font-size:0;
       line-height: 0;
    }
`;

const DropdownContainer = styled.div`
    &:focus {
        outline: none;
    }
`;

const SettingsLabel = styled.span`
    display: block;
    font-family: var(--ivy-font-family-primary);
    font-size: var(--ivy-input-font-size);
    color: var(--ivy-input-label-color);
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.22;
    white-space: nowrap;
`;

export default function BaseDropdown ( props ) {
  const [ setFocus, elemRef ] = useInputFocus();
  let {
    options, label, selected, selectedValue, onChange, placeholder, align,
    isRaised, isWide, isPressed
  } = props;

  if ( selected )
    selectedValue = selected.value;

  const defaultOption = options.find( o => o.value === selectedValue ) || null;

  return (
    <Label
      className={[
        isPressed ? 'isPressed' : '',
        isRaised ? 'isRaised' : '',
        isWide ? 'isWide' : ''
      ].join( ' ' )}
      align={align}
    >
      <SettingsLabel>{label}</SettingsLabel>
      <DropdownContainer
        ref={elemRef}
        tabIndex="0"
        className="dropdown-container">
        <Dropdown
          options={options}
          onFocus={setFocus}
          onChange={onChange}
          value={defaultOption}
          placeholder={placeholder || label}
          arrowClosed={isRaised ? <ExpandMore width="25px" height="25px" /> : null}
          arrowOpen={isRaised ? <ExpandLess width="25px" height="25px" /> : null}
        />
      </DropdownContainer>
    </Label>
  );
}

BaseDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  align: PropTypes.string,
  isPressed: PropTypes.bool,
  isRaised: PropTypes.bool,
  isWide: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.object,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};
