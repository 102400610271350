import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const defaultLocalFeatures = {
  featuresState: {
    __typename: 'FeaturesState',
    id: 'featuresstate',
    isHotspotDraw: false,
    isFeatureChangeMediaProjection: true
  }
};

export const GET_FEATURES_STATE = gql`{
  featuresState @client {
    id
    isHotspotDraw
    isFeatureChangeMediaProjection
  }
}`;

export default () => {
  const { featuresState = {} } = useQuery( GET_FEATURES_STATE ).data || {};

  return { featuresState };
};
