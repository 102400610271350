import { useRef, useState, useEffect } from 'react';
import useStageSize from './useStageSize';

export default function usePlayerStage () {
  const stageTimeoutRef = useRef( null );
  const { stageElemRef, stageSize } = useStageSize();
  const [ stageIsEngage, stageIsEngageSet ] = useState( false );

  const onEngageStart = () => {
    stageIsEngageSet( true );
  };

  const onEngageEnd = () => {
    if ( stageTimeoutRef.current ) {
      window.clearTimeout( stageTimeoutRef.current );
    }

    stageTimeoutRef.current = window.setTimeout( () => {
      stageIsEngageSet( false );
    }, 1000 );
  };

  const addEventListeners = stageElem => {
    if ( stageElem ) {
      stageElem.addEventListener( 'mousemove', onEngageStart );
      stageElem.addEventListener( 'touchstart', onEngageStart );

      stageElem.addEventListener( 'mouseup', onEngageEnd );
      stageElem.addEventListener( 'mouseout', onEngageEnd );
      stageElem.addEventListener( 'touchend', onEngageEnd );
    }
  };

  const removeEventListeners = stageElem => {
    if ( stageElem ) {
      stageElem.removeEventListener( 'mouseover', onEngageStart );
      stageElem.removeEventListener( 'touchstart', onEngageStart );

      stageElem.removeEventListener( 'mouseup', onEngageEnd );
      stageElem.removeEventListener( 'mouseout', onEngageEnd );
      stageElem.removeEventListener( 'touchend', onEngageEnd );
    }
  };

  useEffect( () => {
    const stageElem = stageElemRef.current;

    if ( stageElem ) {
      addEventListeners( stageElemRef.current );

      return () => removeEventListeners( stageElemRef.current );
    }
  }, [ stageElemRef.current ]);

  return {
    stageSize,
    stageElemRef,
    stageIsEngage
  };
}
