import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CameraSVG from '../svg/videocam-24px.svg';
import BaseButtonIcon from './BaseButtonIcon';

const InfoList = styled.ul`
    margin:10px 0 0 0;
    padding:0;
`;

const InfoListItem = styled.li`
    list-style:none;
    margin: 0;
    display:block;
    text-align: center;
    &:hover {
        color: #fff;
    }
`;

const Label = styled.b`
    display:block;
    text-align:center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    font-style: normal;
    cursor: pointer;
`;

export default function CameraInfo ({ name, isActive, onSelect }) {
  return (
    <InfoList>
      <InfoListItem>
        <BaseButtonIcon
          onClick={onSelect}
          title={'name'}
          isPressed={isActive}>
          <div>
            <CameraSVG width="60px" height="60px" />
            <Label>{name}</Label>
          </div>
        </BaseButtonIcon>
      </InfoListItem>
    </InfoList>
  );
}

CameraInfo.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};
