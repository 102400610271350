// https://developer.apple.com/documentation/ \
//   http_live_streaming/hls_authoring_specification_for_apple_devices/ \
//   hls_authoring_specification_for_apple_devices_appendices
//
// https://tools.ietf.org/html/ \
//   draft-pantos-http-live-streaming-23#section-4.3.4.2

const videolevelCodecsBaseSample = [
  'ac-3', // 'AC-3 audio'
  'avc1', // 'H.264 (Advanced Video Coding)'
  'dvh1', // 'Dolby Vision (based on hvc1)'
  'ec-3', // 'Enchanced AC-3 audio'
  'hvc1', // 'HEVC (High Efficiency Video Coding)'
  'mp4a', // 'MPEG-4 audio'
  'stpp', // 'Subtitles (Timed Text)'
  'wvtt' // 'WebVTT data
];

const videolevelCodecsFormats = [
  'ac-3', // AC-3 audio
  'avc1.4200lf', // H.264 Baseline Profile, Level 3.1 video
  'avc1.4d0028', // H.264 Main Profile, Level 4.0 video
  'avc1.640029', // H.264 High Profile, Level 4.1 video
  'dvh1.05.01', // Dolby Vision Profile 5 (10-bit HEVC), Level 1 (720p24) video
  'dvh1.05.06', // Dolby Vision Profile 5 (10-bit HEVC), Level 6 (2160p24) video
  'ec-3', // Enhanced AC-3 audio
  'hvc1.1.4.L126.B0', // HEVC Main Profile, Main Tier, Level 4.2 video
  'hvc1.2.4.L123.B0', // HEVC Main-10 Profile, Main Tier, Level 4.1 video
  'hvc1.2.4.L150.B0', // HEVC Main-10 Profile, Main Tier, Level 5.0 video
  'mp4a.40.2', // AAC-LC audio
  'mp4a.40.5', // HE-AAC audio
  'mp4a.40.29', // HE-AACv2 audio
  'mp4a.40.34', // MP3 audio
  'stpp.ttml.im1t', // IMSC1 text-only subtitles
  'wvtt' // WebVTT subtitle
];

const videolevelCodecsParse = codecsString => (
  String( codecsString ).split( ',' ).filter( next => (
    videolevelCodecsBaseSample.some( format => next.includes( format ) )
  ) ) );

// H.264/AVC
const videolevelCodecIsH264 = codec => /avc/.test( codec );

// HEVC/H.265
const videolevelCodecIsH265 = codec => /hvc/.test( codec );

const videolevelCodecsIsH264 = codecs => ( codecs || []).find( videolevelCodecIsH264 );

const videolevelCodecsIsH265 = codecs => ( codecs || []).find( videolevelCodecIsH265 );

export {
  videolevelCodecsIsH264,
  videolevelCodecsIsH265,
  videolevelCodecsFormats,
  videolevelCodecsParse
};
