import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const defaultLocalModals = {
  modalsState: {
    id: 'modalsstate',
    __typename: 'ModalsState',

    activeModalType: null,
    activeModal: null
  }
};

export const GET_MODALS_STATE = gql`{
  modalsState @client {
    id
    activeModalType
    activeModal
  }
}`;

export const SET_MODALS_STATE = gql`
mutation SetModalsState( $input: SetModalsInput! ) {
  modalsState( input: $input ) @client
}`;

export default function useLocalModals () {
  const [ modalsMutation ] = useMutation( SET_MODALS_STATE );
  const { modalsState = {} } = useQuery( GET_MODALS_STATE ).data || defaultLocalModals;

  const modalClose = () => {
    modalsMutation({
      variables: {
        input: {
          activeModalType: null,
          activeModal: null
        }
      }
    });
  };

  const modalConfirm = opts => {
    modalsMutation({
      variables: {
        input: {
          activeModalType: 'confirm',
          activeModal: {
            ...opts,
            isOpen: true,
            onCancel: () => {
              if ( opts.onCancel )
                opts.onCancel();

              modalClose();
            },
            onConfirm: () => {
              if ( opts.onConfirm )
                opts.onConfirm();

              modalClose();
            }
          }
        }
      }
    });
  };

  return {
    modalsState,

    modalConfirm
  };
}
