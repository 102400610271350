import {
  TOGGLE_PLAYING,
  TOGGLE_VOLUME,
  TOGGLE_MUTE,
  SET_VIDEO_TIME,
  SET_VIDEO_PROGRESS,
  VIDEO_IS_READY,
  VIDEO_IS_RECOVERING,
  VIDEO_PLAY,
  VIDEO_PAUSE,
  MEDIA_BUFFER,
  MEDIA_BUFFEREND
} from '../actions/ActionTypes';

// import secConverter from '../utils/seconds-converter';

export const videoplaybackDefault = {
  isReady: false,
  url: null,
  playing: false,
  loop: false,
  mute: false,
  buffering: false,
  recovering: false,
  volume: 0.8,
  timess: 0,
  lastPlayTS: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0
};

export function isVideoHLS ( videodata ) {
  return /^application\/x-mpegURL/.test( videodata.type );
}

export function isVideoHTTP ( videodata ) {
  return /^http:/.test( videodata.file_url );
}

export function isMediaVideo ( media ) {
  return Boolean( media && /video/i.test( media.type ) );
}

export function setIsPlaying ( state, bool ) {
  return {
    ...state,
    videoplayback: {
      ...state.videoplayback,
      lastPlayTS: bool ? Date.now() : state.videoplayback.lastPlayTS,
      playing: bool
    }
  };
}

export function setUrl ( state, url ) {
  return {
    ...state,
    videoplayback: {
      ...state.videoplayback,
      url
    }
  };
}


export function addVideo ( state, media, scene ) {
  return {
    isReady: false,
    url: media && media.file_url,
    playing: state.playing,
    // postplayback type 2 is 'loop'
    loop: Boolean( scene.postplayback && scene.postplayback.type === 2 ),
    timess: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0
  };
}

export function addVideoResolution ( state, media ) {
  return {
    // isReady: false,
    url: media && media.file_url,
    playing: state.playing
    /*
        // postplayback type 2 is 'loop'
        loop: Boolean( scene.postplayback && scene.postplayback.type === 2 ),
        time: { minutes: 0, seconds: 0, milliseconds: 0 },
        timess: 0,
        playedSeconds: 0,
        loaded: 0,
        loadedSeconds: 0
        */
  };
}

export function updateVideoProgress ( state, action ) {
  const loaded = typeof action.loaded === 'number'
    ? action.loaded
    : state.loaded;
  const loadedSeconds = typeof action.loadedSeconds === 'number'
    ? action.loadedSeconds
    : state.loadedSeconds;

  const playedSeconds = action.playedSeconds || 0;

  return {
    ...state,
    timess: playedSeconds,
    playedSeconds,
    loaded,
    loadedSeconds
  };
}

// 'state' here is top-level reducer's 'state.videoplayback'
export const videoplaybackReducer = ( state = videoplaybackDefault, action ) => {
  switch ( action.type ) {
  case SET_VIDEO_PROGRESS:
    return updateVideoProgress( state, action );

  case VIDEO_IS_RECOVERING:
    return {
      ...state,
      recovering: true,
      isReady: false
    };

  case VIDEO_IS_READY:
    return {
      ...state,
      recovering: false,
      isReady: action.isReady
    };

  case VIDEO_PLAY:
    return {
      ...state,
      lastPlayTS: Date.now(),
      playing: true
    };

  case VIDEO_PAUSE:
    return {
      ...state,
      playing: false
    };

  case TOGGLE_PLAYING:
    return {
      ...state,
      lastPlayTS: action.playing || !state.playing ? Date.now() : state.lastPlayTS,
      playing: action.playing || !state.playing
    };

  case TOGGLE_VOLUME:
    return {
      ...state,
      volume: Number( action.volume )
    };

  case TOGGLE_MUTE:
    return {
      ...state,
      mute: action.mute || !state.mute
    };

  case SET_VIDEO_TIME:
    return {
      ...state,
      timess: action.playedSeconds
    };

  case MEDIA_BUFFER:
    return {
      ...state,
      buffering: true
    };

  case MEDIA_BUFFEREND:
    return {
      ...state,
      buffering: false
    };

  default:
    return state;
  }
};
