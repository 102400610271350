const pixelweightarea = ([ w, h ]) => 180 / Math.max( w, h );

const windowpixelweight = () => pixelweightarea([ window.innerWidth, window.innerHeight ]);

const elempixelweight = elem => pixelweightarea([ elem.offsetWidth, elem.offsetHeight ]);

const pixeltodegree = ( p, pw ) => p * pw;

const degreetopixel = ( d, pw ) => d / pw;

const degreetoradian = d => d * ( Math.PI / 180 );

const radiantodegree = r => r * ( 180 / Math.PI );

const radiantopixel = ( r, pw ) => degreetopixel( radiantodegree( r ), pw );

const π2 = Math.PI * 2;

const π = Math.PI;

const unitsBitsToMegabits = bits => (
  typeof bits === 'number'
    ? bits / 1000000
    : 0
);

export {
  unitsBitsToMegabits,
  pixelweightarea,
  windowpixelweight,
  elempixelweight,
  pixeltodegree,
  degreetopixel,
  degreetoradian,
  radiantodegree,
  radiantopixel,
  π2,
  π
};
