import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = styled.a`
    cursor: pointer;
    font-size: 14px;
    font-family: var(--ivy-font-family-primary);
    color: #fff;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

export default function LinkUnstyled ( props ) {
  return <Link {...props}>{props.children}</Link>;
}

LinkUnstyled.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ])
};
