// An enum just has keys and values that are the same. MyEnum.Value = 'Value';
// Values can be accessed with `.values`
// Non-alphanumberic characters are removed in keys (Equilateral 3D R/L => Equilateral3DRL)
function makeEnum ( enumName, arr = []) {
  const toIdentifier = name => name.replace( /[^a-zA-Z0-9_]/g, '' );

  const enumObj = arr.reduce( ( obj, val ) => {
    if ( Array.isArray( val ) )
      return Object.assign( obj, { [val[0]]: val[1] });
    if ( typeof val === 'string' )
      return Object.assign( obj, { [toIdentifier( val )]: val });
    return obj;
  }, {});

  const values = Object.values( enumObj );

  Object.defineProperties( enumObj, {
    name: { value: enumName },
    values: { value: values },
    valuesAlphabetical: { value: values.sort() },
    [Symbol.toStringTag]: { value: `[Enum ${enumName}: ${values.join( ' | ' )}]` },
    has: { value: val => values.includes( val ) }
  });

  return new Proxy( enumObj, {
    get ( target, prop, receiver ) {
      const existing = Reflect.get( target, prop, receiver );
      if ( !existing )
        throw new Error( `Enum ${enumName} does not have value ${String( prop )}.`
                    + ` Valid values are [ ${values.join( ' | ' )} ]` );
      return existing;
    },

    set () {
      throw new Error( `Enum ${enumName} cannot be modified after being created.` );
    }
  });
}

export const KEY_VENOM_USER_TOKEN = 'auth-token';
export const KEY_VENOM_USER_EMAIL = 'venom-user-email';

export const adType = makeEnum( 'adType', [ 'BannerAd', 'PromotedAd', 'VideoAd' ]);

export const CollectionType = makeEnum( 'CollectionType', [ 'SEASON', 'SERIES', 'YEAR', 'CUSTOM' ]);

export const AccessType = makeEnum( 'AccessType', [ 'PRIVATE', 'TESTING', 'PUBLIC' ]);

export const DeviceType = makeEnum( 'DeviceType', [
  'IOS',
  'ANDROID',
  'GEARVR',
  'OCULUS_RIFT',
  'HTML5',
  'PSVR',
  'HTV_VIVE',
  'DAYDREAM'
]);

export const BannerAdSize = makeEnum( 'BannerAdSize', [ 'INTERSTITIAL', 'MREC', 'SMALL_BANNER', 'MEDIUM_BANNER' ]);

export const MediaClass = makeEnum( 'MediaClass', [
  'VIDEO',
  'AUDIO',
  'IMAGE',
  'FILE',
  '3DMODEL',
  'LIVE'
]);

export const toObject = list => Object.freeze(
  list.reduce( ( p, val ) => Object.assign( p, { [val]: val }), {})
);

export const AdTypeEnum = toObject([
  'BannerAd',
  'PromotedAd',
  'VideoAd'
]);

// correspondant legacy playback types
//
// Postplayback = 1, transition to scene destScene
// Postplayback = 2, restart scene
// Postplayback = 3
// Postplayback = 4, stop video
export const PostPlaybackEnum = toObject([
  'GO_TO_SCENE',
  'LOOP',
  'RETURN_TO_PREVIOUS_SCENE',
  'DO_NOTHING'
]);

export const MediaProjectionTypeEnum = toObject([
  'FLAT',
  'CUBE_MAP',
  'TILED_CLEARVR',
  'EQUIRECTANGULAR',
  'EQUIRECTANGULAR_3D_L_OVER_R',
  'EQUIRECTANGULAR_3D_R_OVER_L'
  // 'EQUISOLID_FISHEYE'
  // 'EQUILATERAL_3D_SBS',
  // 'EQUIRECTANGULAR_180',
  // 'EQUIRECTANGULAR_180_3D_SBS',
  // 'EQUIRECTANGULAR_180_3D_TAB_L_OVER_R',
  // 'EQUIRECTANGULAR_180_3D_TAB_R_OVER_L'
]);

export const MagentaPhotosphereTypeEnum = toObject([
  'LOBBY',
  'MUSIC',
  'SPORTS',
  'ENTERTAINMENT',
  'CINEMA'
]);

export const ContentTypeEnum = toObject([
  'AD',
  'VOD',
  'STREAM',
  'INTERACTIVE',
  'COLLECTION'
]);
