import {
  DRAWMODE_TOGGLE,
  DRAWMODE_START,
  DRAWMODE_END
} from '../actions/ActionTypes';

export const drawmodeDefault = {
  isenabled: false
};

export function drawmodeStart ( state ) {
  return {
    ...state,
    isenabled: true
  };
}

export function drawmodeEnd ( state ) {
  return {
    ...state,
    isenabled: false
  };
}

export function drawmodeToggle ( state ) {
  return {
    ...state,
    isenabled: !state.isenabled
  };
}

export const drawmodeReducer = ( state, action ) => {
  switch ( action.type ) {
  case DRAWMODE_START:
    return drawmodeStart( state );
  case DRAWMODE_END:
    return drawmodeEnd( state );
  case DRAWMODE_TOGGLE:
    return drawmodeToggle( state );
  default:
    return state;
  }
};
