import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const seekresolution = 1000;

const noselect = css`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    border:0;
    outline:none;
    box-shadow:none;
    border:transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const progressPos = css`
    position:absolute;
    top:0;left:0;
    height:100%;
    width:100%;
`;

const nobrules = css`
    -webkit-appearance: none;
    border: none;
    border-radius: 50%;
    background: var(--ivy-seek-bar-nob-color);
    height: var(--ivy-seek-bar-nob-height);
    width: var(--ivy-seek-bar-nob-width);
    margin-left:-2px;
`;

const Container = styled.div`
    font-size: 0;
    display: inline-block;
    cursor: pointer;

    position:relative;
    margin:0 12px;
    transition: padding 0.4s;
    height:4px;
    display:block;
    margin-top: 13px;

    display: flex;
    align-items: center;
    justify-content: center;    

    input[type=range] {
        //removes default webkit styles
        -webkit-appearance: none;

        //fix for FF unable to apply focus style bug
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        transition: opacity 0.4s;
        opacity:0;
    }

    &:hover input[type=range] {
        opacity:1;
    }

    // hide the outline behind the border
    input[type=range]::-moz-focusring {
       outline: 0 solid transparent;
       outline-offset: 0;
   }

    input[type=range]::-moz-range-track {
       background:transparent; border:0;
    }

    // hide the entire range
    //
    // do not use hover rules to style the seek nob
    // chrome hover/non-hover styles on psuedo element not updated correctly
    input[type=range]::-moz-range-thumb { ${nobrules} }
    input[type=range]::-webkit-slider-thumb { ${nobrules}; }
    input[type=range]::-ms-thumb { ${nobrules}; }
`;

const ProgressLoad = styled.progress`
    ${progressPos}

    background-color: var(--ivy-seek-bar-load-background-color);
    border-radius: var(--ivy-seek-bar-load-radius);
    color: var(--ivy-seek-bar-load-foreground-color);
    border:none;
    &::-webkit-progress-value {
        background-color: var(--ivy-seek-bar-load-foreground-color);
        border-radius: var(--ivy-seek-bar-load-radius);
    }
    &::-moz-progress-bar {
        background-color: var(--ivy-seek-bar-load-foreground-color);
        border-radius: var(--ivy-seek-bar-load-radius);
    }
`;

const ProgressSeek = styled.progress`
    ${progressPos}

    background-color: var(--ivy-seek-bar-input-background-color);
    border-radius: var(--ivy-seek-bar-input-radius);
    color: var(--ivy-seek-bar-input-foreground-color);

    border: 0;
    border-radius: 9px;
    padding:0;

    &::-webkit-progress-value {
        background-color: var(--ivy-seek-bar-input-foreground-color);
        border-radius: var(--ivy-seek-bar-input-radius);
    }
    &::-webkit-progress-bar {
        background-color: var(--ivy-seek-bar-input-background-color);
    }
    &::-moz-progress-bar {
        background-color: var(--ivy-seek-bar-input-foreground-color);
        border-radius: var(--ivy-seek-bar-input-radius);
    }
`;

const ProgressInput = styled.input`
    ${progressPos}

    background-color:transparent;
    border:0;
    padding:0;
    margin:0;

    &:focus {
        ${noselect}
    }
`;

export default function SeekBar ( props ) {
  const onInput = ev => {
    const inputElem = ev.srcElement || ev.target;
    const seekPer = parseInt( inputElem.value, 10 ) / parseInt( inputElem.max, 10 );

    props.onSeek( seekPer, seekPer * props.durationss );
  };

  //
  // https://developer.mozilla.org/en-US/Apps/Fundamentals/Audio_and_video_delivery/buffering_seeking_time_ranges
  //
  const getBufferedPercent = mediaElem => {
    let buffered = 0;
    const duration = mediaElem ? mediaElem.duration || 0 : 0;
    const mbuffered = mediaElem && mediaElem.buffered;
    const mbufferlen = mbuffered && mbuffered.length;

    if ( duration <= 0 ) {
      return 0;
    }

    for ( let i = 0; i < mbufferlen; i += 1 ) {
      if ( mbuffered.start( mbufferlen - 1 - i ) < mediaElem.currentTime ) {
        buffered = mbuffered.end( mbufferlen - 1 - i ) / duration;
        break;
      }
    }

    return buffered;
  };

  const internalGetVideoElement = () => {
    return document.getElementById( 'videochild' );
  };

  const { durationss, timess, getVideoElement = internalGetVideoElement } = props;
  const seekpos = durationss <= 0 ? 0 : timess / durationss;

  return (
    <Container>
      <ProgressLoad
        value={getBufferedPercent( getVideoElement( props ) ) * 100}
        min={0}
        max={100} />
      <ProgressSeek
        tabindex={0}
        value={seekpos}
        min={0}
        max={1} />
      <ProgressInput
        id="ProgressInput"
        type={'range'}
        value={seekpos * seekresolution}
        min={0}
        max={seekresolution}
        onInput={e => onInput( e )}
        onChange={e => onInput( e )} />
    </Container>
  );
}

SeekBar.propTypes = {
  onSeek: PropTypes.func.isRequired,
  durationss: PropTypes.number.isRequired,
  loadedPercent: PropTypes.number.isRequired,
  timess: PropTypes.number.isRequired,

  // used by test to mock the video element
  getVideoElement: PropTypes.func
};
