import {
  radiantodegree,
  unitsBitsToMegabits
} from './units';

import { MediaProjectionTypeEnum } from '../enums';
import { isMimeHls } from './hls';
import {
  videolevelCodecsIsH264,
  videolevelCodecsIsH265
} from './videolevelCodecs.js';

// re credit stack overflow
//
// https://stackoverflow.com/questions/680929/ \
//    how-to-extract-extension-from-filename-string-in-javascript
//
export const getFileExtn = ( filename, extnre = /(?:\.([^.]+))?$/ ) =>
  extnre.exec( String( filename ) )[1];

export const getLabelNarrow = ( label, width = 18 ) =>
  ( typeof label === 'string' && label.length > width )
    ? `${label.slice( 0, width - 2 )}…${getFileExtn( label ) || ''}`
    : label;

export const getLabelSize = size => {
  const { width, height } = size || { width: 0, height: 0 };

  return `${width}x${height}`;
};

export const getLabelPercent = val => `${Math.round( val * 100 )}%`;

export const getLabelRoundedNumber = num =>
  +Number.parseFloat( Math.round( num * 100 ) / 100 ).toFixed( 0 );


export const getLabelRotation = rad => (
  `${getLabelRoundedNumber( radiantodegree( rad ) )}°`
);

export const getLabelRotations = radList => radList
  .map( getLabelRotation ).join( ', ' );

export const getWithoutURLProtocol = url => String( url )
  .replace( /^https?:/, '' );

// Non-alphanumberic characters are removed in keys (Equilateral 3D R/L => Equilateral3DRL)
export const getLabelEnum = name => name
  .replace( /_/g, ' ' );

export const getLabelFriendlyResolution = ({ width, height }) => {
  const k = width > 7200
    ? '8'
    : width > 6000
      ? '6'
      : width > 3800
        ? '4'
        : width >= 2000 ? '2' : '';

  const kText = k ? `  (${k}K)` : '';

  return `${height}p${kText}`;
};

export const getLabelBitrate = bps =>
  typeof bps === 'number' ? `${bps} bps` : '---';

export const getLabelMimeType = mimeType =>
  isMimeHls( mimeType ) ? 'x-mpegurl' : mimeType;

export const labelProjectionMap = {
  [MediaProjectionTypeEnum.FLAT]: 'Flat',
  [MediaProjectionTypeEnum.CUBE_MAP]: 'Cube Map',
  [MediaProjectionTypeEnum.TILED_CLEARVR]: 'Tiled ClearVR',
  [MediaProjectionTypeEnum.EQUIRECTANGULAR]: 'Equirectangular',
  [MediaProjectionTypeEnum.EQUIRECTANGULAR_3D_L_OVER_R]: 'Equirectangular 3D L/R',
  [MediaProjectionTypeEnum.EQUIRECTANGULAR_3D_R_OVER_L]: 'Equirectangular 3D R/L'
};

export const labelNarrowProjectionMap = {
  'Equirectangular 180 3D SBS': '180 3D SBS'
};

export const labelWideProjectionMap = {
  'Equirectangular 180 3D SBS': 'Equirectangular 180 3D Side By Side'
};

export const labelCodecsMap = {
  'ac-3': 'AC-3 audio',
  'avc1.4200lf': 'H.264 Baseline Profile, Level 3.1 video',
  'avc1.4d0028': 'H.264 Main Profile, Level 4.0 video',
  'avc1.640029': 'H.264 High Profile, Level 4.1 video',
  'dvh1.05.01': 'Dolby Vision Profile 5 (10-bit HEVC), Level 1 (720p24) video',
  'dvh1.05.06': 'Dolby Vision Profile 5 (10-bit HEVC), Level 6 (2160p24) video',
  'ec-3': 'Enhanced AC-3 audio',
  'hvc1.1.4.L126.B0': 'HEVC Main Profile, Main Tier, Level 4.2 video',
  'hvc1.2.4.L123.B0': 'HEVC Main-10 Profile, Main Tier, Level 4.1 video',
  'hvc1.2.4.L150.B0': 'HEVC Main-10 Profile, Main Tier, Level 5.0 video',
  'mp4a.40.2': 'AAC-LC audio',
  'mp4a.40.5': 'HE-AAC audio',
  'mp4a.40.29': 'HE-AACv2 audio',
  'mp4a.40.34': 'MP3 audio',
  'stpp.ttml.im1t': 'IMSC1 text-only subtitles',
  wvtt: 'WebVTT subtitles'
};

export const labelProjectionNarrow = projection => (
  labelNarrowProjectionMap[projection]
        || projection );

export const labelProjectionWide = projection => (
  labelWideProjectionMap[projection]
        || projection );

export const labelBandwidth = bps => (
  typeof bps === 'number'
    ? `${unitsBitsToMegabits( bps ).toFixed( 0 )}Mbps`
    : '---'
);

// H.264/AVC or HEVC/H.265
export const labelCodecsVariant = codecs => {
  if ( videolevelCodecsIsH264( codecs ) )
    return 'H.264';
  if ( videolevelCodecsIsH265( codecs ) )
    return 'H.265';

  return '';
};

export const labelResolution = ( resolution, defaultLabel = '---' ) => {
  const { width, height } = resolution || {};

  return typeof width === 'number' && typeof height === 'number'
    ? `${width}x${height}`
    : defaultLabel;
};
