import { getSequenceId } from '../utils/contentUtils';
import { START_HOTSPOT, END_HOTSPOT } from '../actions/ActionTypes';
import { createMoment, sortMoments } from './moments';

// add the hotspot sequence to moments to moments list
const momentsAddSequenceHotspot = ( moments, sequence ) => {
  const sequenceId = getSequenceId( sequence );

  moments.push( createMoment({
    time: sequence.start,
    meta: {
      action: START_HOTSPOT,
      sequenceId,
      hotspotId: sequenceId
    }
  }) );

  moments.push( createMoment({
    time: sequence.end,
    meta: {
      action: END_HOTSPOT,
      sequenceId,
      hotspotId: sequenceId
    }
  }) );

  return moments;
};

const momentsRemoveSequenceHotspot = ( moments, sequence ) => moments
  .filter( moment => moment.meta.sequenceId !== sequence.uuid );

export {
  momentsAddSequenceHotspot,
  momentsRemoveSequenceHotspot,
  sortMoments
};
