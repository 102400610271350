import parseURL from 'url-parse';
import * as canplayhls from 'canplayhls';
import { isSupported } from 'hls.js';
import { mimeTypeOfPath } from './url';

import { isMimeHls } from './hls'; // eslint-disable-line import/no-cycle

const getVideoDimensions = videoElement => ( videoElement ? [ videoElement.videoWidth, videoElement.videoHeight ] : [ 0, 0 ]);

const getFriendlyNameForResolution = ({ width, height }) => {
  const k = width > 7200
    ? '8'
    : width > 6000
      ? '6'
      : width > 3800
        ? '4'
        : width >= 2000 ? '2' : '';

  const kText = k ? `  (${k}K)` : '';

  return `${height}p${kText}`;
};

const isFileTypeNativelySupported = mime => {
  // always returns maybe :(. interesting advice here,
  // http://stackoverflow.com/questions/29382760/canplaytype-always-returning-maybe-am-i-missing-something
  // doc.createElement('video').canPlayType(mime)
  if ( isMimeHls( mime ) ) {
    return canplayhls || isSupported();
  }

  //
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canPlayType
  //
  return /maybe|probably/gi.test(
    document
      .createElement( 'video' )
      .canPlayType( mime )
  );
};

const isVODMimeSupported = mime => !isMimeHls( mime ) && isFileTypeNativelySupported( mime );

const shouldBeAbleToPlayMime = mime => isFileTypeNativelySupported( mime );

const shouldBeAbleToPlayVideo = url => shouldBeAbleToPlayMime( mimeTypeOfPath( url ) );

const isSourceCrossOrigin = videosrc => parseURL( videosrc ).origin !== window.location.origin;

export {
  shouldBeAbleToPlayVideo,
  getFriendlyNameForResolution,
  getVideoDimensions,
  isVODMimeSupported,
  isSourceCrossOrigin
};
