import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonClose from './ButtonClose';
import CameraInfo from './CameraInfo';

const Container = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    width:200px;
    z-index:4;
    flex:1;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Top = styled.div`
    text-align:right;
`;

const CameraList = styled.ul`
    margin:0;
    padding:0;
`;

const CameraItem = styled.li`
    list-style:none;
`;

export default function CamerasPanel ({ actions, data, scene }) {
  const onClose = () => {
    actions.panelToggle( 'cameras' );
  };

  return (
    <Container>
      <Top>
        <ButtonClose onClick={onClose.bind( this )} />
      </Top>
      <CameraList>
        {Object.keys( data.scenes ).map( sceneId => (
          <CameraItem key={sceneId}>
            <CameraInfo
              isActive={sceneId === scene.id}
              name={data.scenes[sceneId].title || data.scenes[sceneId].name}
              onSelect={() => actions.sceneSelect( sceneId )} />
          </CameraItem>
        ) )}
      </CameraList>
    </Container>
  );
}

CamerasPanel.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired
};
