import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as THREE from 'three';
import CanvasThreeGQL from './CanvasThreeGQL';
import {
  getVertex,
  createRingMesh,
  getCanvasGLRenderer
} from '../utils/canvasShapes';

import useCanvas3D from '../hooks/useCanvas3D';

const Container = styled.div`
    width: ${props => `${props.w}px`};
    height: ${props => `${props.h}px`};
    position: ${props => props.disableStaticPosition ? 'inherit' : 'absolute'};
    pointer-events:none;
    top: ${props => `${-( props.h + 20 )}px`};
    right: 20px;
`;

export default function CanvasRadar ( props ) {
  const { disableStaticPosition, dimensions } = props;
  const [ w, h ] = dimensions;
  const {
    sceneState,
    lifecycleSet,
    useSceneEffect
  } = useCanvas3D({
    canvasId: 'CanvasRadar',
    dimensions: {
      width: props.dimensions[0],
      height: props.dimensions[1]
    }
  });

  const outerRingOpts = {
    color: '#EFEFEF',
    opacity: 1,
    innerRadius: 0.09,
    outerRadius: 0.1
  };

  const innerRingOpts = {
    color: '#EFEFEF',
    opacity: 1,
    innerRadius: 0.001,
    outerRadius: 0.01
  };

  const containerRingOpts = {
    color: '#000000',
    opacity: 0.5,
    innerRadius: 0.001,
    outerRadius: 0.12
  };

  const visRingOpts = {
    color: '#EFEFEF',
    opacity: 1,
    innerRadius: 0.01,
    outerRadius: 0.09,

    // projection canvas uses three default camera fov, 50 degrees
    //
    thetaLength: Math.PI / 2,
    thetaSegments: 32,
    phiSegments: 3,
    thetaStart: Math.PI / 2
  };

  //
  // for speed, consider a different approach...
  // adding more faces (resolution), then
  // painting faces within range
  //
  const updateringgeometry = ( opts, percent, ringgeometry ) => {
    const thetaEnd = percent * Math.PI * 2;
    const thetaStart = Number( opts.thetaStart );
    const thetaSegments = Number( opts.thetaSegments );
    let hypotenuse = Number( opts.innerRadius );
    const radiusstep = ( opts.outerRadius - hypotenuse ) / opts.phiSegments;

    ringgeometry.vertices.forEach( ( vertex, i ) => {
      const segmenttheta = i % ( thetaSegments + 1 );
      const segmentpercent = segmenttheta / thetaSegments;

      if ( i && segmenttheta === 0 ) {
        hypotenuse += radiusstep; // longer, each time around
      }

      vertex = Object.assign( vertex, getVertex( hypotenuse, (
        thetaStart + ( thetaEnd * segmentpercent ) // segment angle
      ) ) );
    });

    ringgeometry.verticesNeedUpdate = true;

    return ringgeometry;
  };

  // camera uses threejs default of 50 degrees
  //
  // assumes main canvas fills window area
  const getWidthFovDegree = ( heightFovDegree = 50 ) => {
    return heightFovDegree * ( window.innerWidth / window.innerHeight );
  };

  const getWidthFovPercent = ( fullWidthDegree = 360 ) => {
    return getWidthFovDegree() / fullWidthDegree;
  };

  // for easier updates and control when changing size of radar,
  // radar composed of two ring shapes beside one another each
  // marking 50 percent of the 'viewable area'
  //
  const getRadarVisGroup = () => {
    const visGroup = new THREE.Object3D();
    const visRingRightMesh = createRingMesh( visRingOpts );
    const visRingLeftMesh = createRingMesh( visRingOpts );
    const visPercent = getWidthFovPercent() / 2;

    visRingRightMesh.rotation.y = 180 * ( Math.PI / 180 ); // make clockwise
    visRingRightMesh.geometry = updateringgeometry(
      visRingOpts, visPercent, visRingRightMesh.geometry
    );

    visRingLeftMesh.geometry = updateringgeometry(
      visRingOpts, visPercent, visRingLeftMesh.geometry
    );

    visGroup.add( visRingRightMesh );
    visGroup.add( visRingLeftMesh );
    // visGroup.position.z = 1;

    return visGroup;
  };

  // update 'visibility' area of vis group
  const updateVisGroup = sceneState => {
    const visPercent = getWidthFovPercent() / 2;

    if ( sceneState.visGroup ) {
      sceneState.visGroup.children.forEach( child => {
        child.geometry = updateringgeometry(
          visRingOpts, visPercent, child.geometry
        );
      });
    }

    return sceneState;
  };

  const threeSceneCreateRadar = ( canvasElem, sceneState ) => {
    const wharr = [ canvasElem.offsetWidth, canvasElem.offsetHeight ];
    const scene3D = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 60, wharr[0] / wharr[1], 0.1, 10000 );
    const renderer = getCanvasGLRenderer( canvasElem );
    const parentContainer = new THREE.Object3D();
    const visGroup = getRadarVisGroup();
    const containerRing = createRingMesh( containerRingOpts );

    // container ring becomes a background shape
    containerRing.position.z = -0.001;

    parentContainer.add( containerRing );
    parentContainer.add( createRingMesh( outerRingOpts ) );
    parentContainer.add( createRingMesh( innerRingOpts ) );
    parentContainer.add( visGroup );
    parentContainer.position.z = -0.2; // to front

    camera.add( parentContainer );
    scene3D.renderUpdate = 0;
    scene3D.add( camera );

    return {
      ...sceneState,
      visGroup,
      wharr,
      renderer,
      camera,
      scene3D
    };
  };

  useSceneEffect( () => {
    sceneState.visGroup.rotation.z = props.camerarot[1] || 0;
    sceneState.scene3D.renderUpdate += 1;
  }, [ props.camerarot ]);

  useSceneEffect( () => {
    updateVisGroup( sceneState );

    sceneState.scene3D.renderUpdate += 1;
  }, [ dimensions ]);

  useEffect( () => lifecycleSet({
    threeSceneCreate: threeSceneCreateRadar
  }), []);

  return (
    <Container w={w} h={h} disableStaticPosition={disableStaticPosition}>
      <CanvasThreeGQL id="CanvasRadar" />
    </Container>
  );
}

CanvasRadar.propTypes = {
  camerarot: PropTypes.array.isRequired,
  dimensions: PropTypes.array.isRequired,
  stageDimensionsWH: PropTypes.array.isRequired,

  disableStaticPosition: PropTypes.bool
};
