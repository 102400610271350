import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import * as heatmapping from 'heatmapping-lib-js';
import Heatmap from './Heatmap';
import SlateLoad from './SlateLoad';
import { π2 } from '../utils/units';
import { APPTYPE, heatmapIsEnabled } from '../env.js';

import {
  isMediaAdImage
} from '../utils/mediaUtils';

import {
  getSceneMedia,
  isSceneSame
} from '../utils/sceneUtils';

import Sounds from './Sounds';
import BannerPlayer from './BannerPlayer';

import SceneVideoPlayer from './SceneVideoPlayer';
import SceneVideoPlayerGQL from './SceneVideoPlayerGQL';

import CanvasPublish from './CanvasPublish';
import CanvasPublishGQL from './CanvasPublishGQL';

import CanvasEdit from './CanvasEdit';
import CanvasEditGQL from './CanvasEditGQL';


const Container = styled.div`
    position:relative;
    width:100%;
    height:100%;
`;

class CanvasPlayer extends Component {
  constructor () {
    super();

    this.onUnload = this.onUnload.bind( this );
    this.isRequesting = {};
  }

  onUnload () {
    this.props.actions.windowUnload();
  }

  componentDidMount () {
    window.addEventListener( 'beforeunload', this.onUnload );
  }

  componentWillUnmount () {
    window.removeEventListener( 'beforeunload', this.onUnload );
  }

  getVideoElem ( ) {
    return document.getElementById( 'videochild' );
  }

  getBannerElem () {
    return document.getElementById( 'bannerimg' );
  }

  componentWillUpdate ( nextProps ) {
    const { scene, actions } = this.props;

    if ( !isSceneSame( scene, nextProps.scene ) ) {
      actions.sceneChanged(
        scene ? scene.id : null, // prevSceneId
        nextProps.scene.id
      ); // nextSceneId
    }
  }

  getTargetRequiredElem ( media ) {
    const targetElem = isMediaAdImage( media )
      ? this.getBannerElem()
      : this.getVideoElem();

    return targetElem;
  }

  // if media w/ pending unrequested adverts requested
  //   begin advert request inside primitive lock
  // else
  //   return media
  getSceneMedia ( props, scene ) {
    const adverts = scene && scene.adverts;
    let media = {};

    if ( adverts && !adverts.ismerged && !this.isRequesting[adverts.advertid]) {
      this.isRequesting[scene.advertid] = true;

      props.actions.getAdvertDisp( adverts );
    } else {
      media = getSceneMedia( scene );
    }

    return media;
  }

  getCanvas ( canvastype = this.props.canvastype ) {
    return canvastype === 'rectangle'
      ? CanvasEdit
      : CanvasPublish;
  }

  getCanvasGQL ( canvastype = this.props.canvastype ) {
    return canvastype === 'rectangle'
      ? CanvasEditGQL
      : CanvasPublishGQL;
  }

  isEmitHeatmap () {
    return APPTYPE === 'player'
            && heatmapIsEnabled
            && this.props.canvastype === 'projection'
            && this.props.data.heatmap.isHeatEvents;
  }

  // yaw - The yaw you are looking in the video, in radians. A yaw of 0 means
  // that you are looking at the center of the video. Keep this number between
  // 0 and 2π. 0 and 2π both mean looking at the center of the video. π
  // means looking backward.
  //
  // pitch - The pitch you are looking in the video, in radians. A pitch of 0
  // means that you are looking at the vertical center of the video. Keep this number
  // between -π/2 and π/2. A value of -π/2 means looking straight down. A value
  // of π/2 means looking straight up
  //
  // timeInVideo - The timestamp in the video, in milliseconds. This should be synced
  // with the seek bar of the video. If the video is paused, this number should not change.
  //
  onRender ( ) {
    return this.isEmitHeatmap()
      ? canvasInst => {
        let yaw = canvasInst.headgroup.rotation.y;

        while ( yaw < 0 ) yaw += π2;
        while ( yaw > π2 ) yaw -= π2;

        yaw = π2 - yaw;

        if ( this.props.data.heatmap.isHeatEvents ) {
          // heatmapping.update(
          //   yaw, // yaw
          //   canvasInst.camera.rotation.x, // pitch
          //   this.getTargetRequiredElem().currentTime * 1000 // timeInVideo
          // );
        }
      } : () => {};
  }

  // same video element should be always-present
  // once a click-event inititates play, the element can be
  // programatically stopped and started
  //
  // react-player does not provide access to video element,
  // which is created and loaded after the player is mounted
  // threecanvas anticipates this target element, so check
  // that it exists before including Canvas component
  //
  // note: video projection needs to support other, non-spherical
  // projection types
  render () {
    const {
      data, actions, dimensions, refresh, canvastype
    } = this.props;
    const scene = this.props.scene || {};
    const media = this.getSceneMedia( this.props, scene ) || {};
    const isbanner = isMediaAdImage( media );
    const Canvas = this.getCanvas();
    const CanvasGQL = this.getCanvasGQL();
    const isCanvasGQL = false;
    const isVideoGQL = false;

    return (
      <Container>
        <Sounds
          actions={actions}
          scenesounds={data.sounds.scenesounds} />
        {/* scene video player needs to use the media hook */}
        {isVideoGQL && (
          <SceneVideoPlayerGQL
            scene={scene}
            mediaItem={data.select.video}
            isTransition={data.istransition}
            refresh={refresh} />
        )}
        {!isVideoGQL && (
          <SceneVideoPlayer
            id={'video'}
            width='100%'
            height='100%'
            data={data}
            actions={actions}
            scene={scene}
            media={media}
            dimensions={dimensions}
            progressFrequency={100}
            canvastype={canvastype}
            refresh={refresh} />
        )}
        {isbanner && (
          <BannerPlayer
            onClick={actions.adEngage( media )}
            onEnded={actions.mediaEnded( scene.id, media.id )}
            onPresented={actions.mediaStarted( scene.id, media.id )}
            media={media}
          />
        )}
        {!isCanvasGQL && (
          <Canvas
            getTargetElem={() => this.getTargetRequiredElem()}
            onRenderFn={this.onRender()}
            data={data}
            actions={actions}
            scene={scene}
            media={media}
            dimensions={dimensions}
            refresh={refresh} />
        )}
        {isCanvasGQL && (
          <CanvasGQL
            getMediaElem={() => this.getTargetRequiredElem()}
            onRenderFn={this.onRender()}
            dimensions={dimensions}
            actions={actions}
            media={media}
            scene={scene}
            camerarot={data.camerarot}
            playback={data.videoplayback}
            selected_hotspotId={data && data.select && data.select.hotspotId}
          />
        )}
        {data.isplayer && data.heatmap.isHeatEnabled && heatmapIsEnabled && (
          <Heatmap
            canvastype={canvastype}
            dimensions={dimensions}
            data={data}
            media={media}
            scene={scene} />
        )}
        <SlateLoad videoplayback={data.videoplayback} />
      </Container>
    );
  }
}

CanvasPlayer.propTypes = {
  refresh: PropTypes.number,
  canvastype: PropTypes.string.isRequired, // 'projection' | 'rectangle'
  dimensions: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  scene: PropTypes.object,
  data: PropTypes.object.isRequired
};

export default CanvasPlayer;
