import { useState, useEffect } from 'react';

export default function useFetchBlob ( url ) {
  const [ state, stateSet ] = useState({
    result: null,
    error: null,
    loading: false
  });

  // eslint-disable-next-line no-shadow
  const fetchBlob = async url => {
    stateSet({ ...state, loading: true });

    const response = await fetch( url ).catch( e => e );
    if ( response.ok ) {
      const myBlob = await response.blob();

      stateSet({
        ...state,
        loading: false,
        result: URL.createObjectURL( myBlob )
      });
    } else {
      stateSet({ ...state, error: true });
    }
  };

  useEffect( () => {
    if ( url ) fetchBlob( url );
  }, [ url ]);

  return state;
}
