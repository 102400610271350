import React from 'react';
import PropTypes from 'prop-types';
import BaseButtonIcon from './BaseButtonIcon';
import CogSVG from '../svg/baseline-settings-20px.svg';

export default function ButtonCog ( props ) {
  return (
    <BaseButtonIcon
      {...props}
      title={props.title || 'edit'}>
      <CogSVG width="25px" height="25px" />
    </BaseButtonIcon>
  );
}

ButtonCog.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  isEnabled: PropTypes.bool
};
