import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReplayOverSVG from '../svg/btn-replay-over-95-x-95.svg';
import ReplayOutSVG from '../svg/btn-replay-out-95-x-95.svg';
import PlayOverSVG from '../svg/btn-play-over-95-x-95.svg';
import PlayOutSVG from '../svg/btn-play-out-95-x-95.svg';

const Container = styled.div`
    top:0;left:0;
    width: 100%;
    height:100%;
    position:absolute;
    pointer-events:none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Link = styled.button`
    border:0;
    background-color:transparent;
    font-size: 0;
    fill: #bebebe;
    display: inline-block;
    cursor: pointer;
    vertical-align:middle;
    pointer-events: ${props => !props.isPlaying ? 'all' : 'none'};
    transition: opacity ${props => !props.isPlaying ? '0s' : '0.2s'} ease-in-out;
    opacity: ${props => Number( !props.isPlaying )};
    transition-delay: ${props => !props.isPlaying ? '0s' : '0.2s'};
`;

const getBttnSet = ( scene, videoplayback ) => scene && videoplayback && scene.isPlayed && scene.isPlayed > videoplayback.lastPlayTS
  ? [ ReplayOverSVG, ReplayOutSVG ]
  : [ PlayOverSVG, PlayOutSVG ];

export default function SlatePlayPause ( props ) {
  const { onClick, isPlaying, scene, videoplayback } = props;
  const [ bttnover, bttnout ] = getBttnSet( scene, videoplayback );
  const SVGIcon = isPlaying ? bttnover : bttnout;

  return (
    <Container>
      <Link onClick={onClick} isPlaying={isPlaying}>
        <SVGIcon width="100px" height="100px" />
      </Link>
    </Container>
  );
}

SlatePlayPause.propTypes = {
  videoplayback: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  scene: PropTypes.object
};
