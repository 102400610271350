import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotationBuilder = degree => keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(${degree}deg);
    }
`;

const Container = styled.div`
    &.buffering-true {
        animation: ${rotationBuilder( 360 )} 2s linear infinite
    }
    position: relative;
    font-size: 120px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    background-color: #000;

    &:hover:after {
        top: 0.04em;
        left: 0.04em;
        width: 0.92em;
        height: 0.92em;
    }

    &:hover {
        cursor:default;
    }

    &:hover > span {
        font-size: 0.3em;
        color: var(--ivy-highlight-color-primary);
    }

    &:after {
        position: absolute;
        top: 0.08em;
        left: 0.08em;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: #000;

        width: 0.84em;
        height: 0.84em;
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: ease-in;
    }
`;

const Label = styled.span`
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 120px;
    line-height: 120px;
    font-size: 0.2em;
    color: #cccccc;
    display: block;
    text-align: center;
    white-space: nowrap;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
`;

const Bar = styled.div`
    position: absolute;
    border: 0.08em solid #307bbb;
    width: 0.84em;
    height: 0.84em;
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    transform: rotate(${props => 360 * props.percentnum}deg);
    border-color: var(--ivy-highlight-color-primary) !important;
`;

const Slice = styled.div`
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
`;

const Slice51To100 = styled( Slice )`
    clip: rect(auto, auto, auto, auto);
`;

const Slice0To50 = styled( Slice )``;

const Fill = styled.div`
    border-color: var(--ivy-highlight-color-primary) !important;
`;

const Fill51To100 = styled( Fill )`
    position: absolute;
    border: 0.08em solid #307bbb;
    width: 0.84em;
    height: 0.84em;
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    transform: rotate(180deg);
`;

const Fill0To50 = styled( Fill )`
    transform: rotate(0deg);
`;


export default function PercentCircle ( props ) {
  const { percentnum, buffering } = props;
  const isBufferAnimation = buffering || percentnum === 0;
  const labelstr = isBufferAnimation
    ? ''
    : `${Math.floor( 100 * ( percentnum || 0 ) )}%`;
  const SliceElem = percentnum > 0.51
    ? Slice51To100
    : Slice0To50;
  const FillElem = percentnum > 0.51
    ? Fill51To100
    : Fill0To50;

  return (
    <Container
      className={`buffering-${isBufferAnimation}`}
      title={isBufferAnimation ? 'loading' : `${labelstr} loaded`}
    >
      <Label id="label">{labelstr}</Label>
      <SliceElem>
        <Bar percentnum={isBufferAnimation ? 0.4 : percentnum} />
        <FillElem />
      </SliceElem>
    </Container>
  );
}

PercentCircle.propTypes = {
  percentnum: PropTypes.number.isRequired,
  buffering: PropTypes.bool
};
