import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PercentCircle from './PercentCircle';

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: ${props => !props.isReady ? 'all' : 'none'};
    transition: opacity ${props => !props.isReady ? '0s' : '0.2s'} ease-in-out;
    opacity: ${props => Number( !props.isReady )};
    transition-delay: ${props => !props.isReady ? '0s' : '0.2s'};
`;

export default function SlateLoad ({ videoplayback }) {
  const { isReady, loaded, buffering, recovering } = videoplayback || {
    isReady: false,
    loaded: 40,
    buffering: true
  };

  return (
    <Container isReady={isReady}>
      <PercentCircle
        buffering={buffering || recovering}
        percentnum={loaded || 0} />
    </Container>
  );
}

SlateLoad.propTypes = {
  videoplayback: PropTypes.object
};
