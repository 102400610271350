import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast as toastify, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorType, getError, customError } from '../errors';
import useLocalToast from '../hooks/useLocalToast';
import ButtonClose from './ButtonClose';

const Message = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #b3b2b2;
    display: block;
    margin-top: 10px;
    padding-left: 12px;
`;

toastify.configure();

export const toastMessageType = errorType;

export const showToast = ({
  type, message, toastOpts, name
}) => {
  const toastId = toastify[type](
    <Message dangerouslySetInnerHTML={{ __html: message }} />,
    {className: `toastify-content ${name} type-${type}`, ...toastOpts }
  );

  console.log({ toastId });
};

export const showSelectToast = toastName => {
  const selectedToast = getError( toastName );

  showToast( selectedToast || customError( toastName ) );
};

const CloseButton = props => (
  <ButtonClose onClick={props.closeToast} />
);

CloseButton.propTypes = {
  closeToast: PropTypes.func
};

export default function Toasts ({ toast }) {
  useEffect( () => {
    if ( toast.showToast )
      showSelectToast( toast.showToast );
  }, [ toast ]);

  return (
    <ToastContainer
      closeButton={<CloseButton />}
      className="toastify"
      progressClassName="toastify_progress" />
  );
}

Toasts.propTypes = {
  toast: PropTypes.object
};
