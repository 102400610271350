import {
  TYPEACTIONS,
  ADD_ERROR_TOAST
} from '../actions/ActionTypes';

import { errorType } from '../errors';

export const toastDefault = {
  closeToastId: undefined,
  showToast: undefined,
  toastIds: {}
};

export const showToast = ( state, action, message ) => ({
  ...state,
  showDate: Date.now(),
  showToast: message
});

export const setCloseId = ( state, action, id ) => ({
  ...state,
  closeToastId: id
});

export const toastReducer = ( state = toastDefault, action ) => {
  switch ( action.type ) {
  case TYPEACTIONS.STORY_GET.REQ_LOADING:
    return action.isLoading
      ? showToast( state, action, errorType.infoLoadingStory )
      : setCloseId( state, action, errorType.infoLoadingStory );

  case TYPEACTIONS.VIDEO_GET.REQ_LOADING:
    return action.isLoading
      ? showToast( state, action, errorType.infoLoadingVideo )
      : setCloseId( state, action, errorType.infoLoadingVideo );

    // not a great experience showing info boxes...
    // they disappear too fast or linger on the screen too long
    //
    // case TYPEACTIONS.SCENE_CREATE.REQ_LOADING:
    //     return action.isLoading
    //         ? showToast( state, action, errorType.infoCreatingScene )
    //         : setCloseId( state, action, errorType.infoCreatingScene );
    //
    // case TYPEACTIONS.SCENE_DELETE.REQ_LOADING:
    //     return action.isLoading
    //         ? showToast( state, action, errorType.infoDeletingScene )
    //         : setCloseId( state, action, errorType.infoDeletingScene );

  case ADD_ERROR_TOAST:
    return action.errorMsg
      ? showToast( state, action, action.errorMsg )
      : state;

  default:
    return state;
  }
};
