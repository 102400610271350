import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Canvas = styled.canvas`
    user-select: none;

    border:0;
    outline:none;
    box-shadow:none;
    border:transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    color:#fff;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &.israyover-true {
        cursor:pointer;
    }
    &.israyover-false {
        cursor:auto;
    }
`;

export const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

export default function CanvasThreeGQL ( props ) {
  return useMemo( () => (
    <CanvasContainer id={`${props.id}-container`}>
      <Canvas id={`${props.id}`} tabIndex="0" />
    </CanvasContainer>
  ), [ props.id ]);
}

CanvasThreeGQL.propTypes = {
  id: PropTypes.string.isRequired
};
