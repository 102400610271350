import omit from 'lodash.omit';
import winurl from 'winurl';
import castas from 'castas';
import getBlockedInfo from '../utils/mediaCompatibility';
import { videoplaybackDefault } from './videoplayback';
import { MediaProjectionTypeEnum } from '../enums';
import {
  getSceneMainVideoSequence,
  getSceneMainVideoInfo
} from '../utils/sceneUtils';
import * as typeContent from '../utils/typeContent';
import {
  getMediaProjectionTypeFromLegacyProjection
} from '../utils/legacyProjectionUtils';

import {
  setPosterBlob,
  setBrandBlob,
  setMediaPlaylistCreating,
  setQualityLevelsMP4,
  setQualityLevelsPlaylist,
  findQualityLevel,
  isBrandIconic,
  isMediaQualityLevels
} from '../utils/mediaUtils';

import {
  isAdvertSupported
} from './advert';

import {
  SCENE_SELECT,
  TYPEACTIONS,
  CAMERA_SET_ROTATION,
  ADD_ERROR_TOAST,
  QUALITY_SET_ID,
  PROJECTION_SET_ID,
  PLAYLIST_GET_LOCAL,
  PLAYLIST_GET_START
} from '../actions/ActionTypes';

import {
  setSceneMountComplete,
  getSceneAdapted,
  getSceneGQLAdapted,
  mountStateScene,
  getVideoAsScene,
  getAdvertAsScene,
  linkScenePostplayback,
  applySceneMedia
} from './scene';

import {
  applyQualityLevel
} from '../utils/quality';

import { toastReducer } from './toast';
import { toastMessageType } from '../components/toasts';

export function getVideoAsStory ( video ) {
  return {
    embedType: video.media_type,

    scenes: [
      getVideoAsScene( video, Boolean( video.cameras ) && video.id ),
      ...( video.cameras || []).map( camera => (
        getVideoAsScene({ ...video, ...camera }, video.id )
      ) )
    ]
  };
}

export function getStoryAdapted ( state, storydata ) {
  const toasterror = null;

  state.scenes = storydata.scenes.reduce( ( scenes, scene ) => {
    scenes[scene.id] = getSceneAdapted( scene, scene.embedType || storydata.embedType );

    return scenes;
  }, state.scenes );

  let [ selectedscene ] = storydata.scenes;

  if ( storydata.embedType === 'story' ) {
    const [ sceneid ] = storydata.scene_order;
    [ selectedscene ] = storydata.scenes.filter( s => s.id === sceneid );
  }

  // return top level-story properties and
  // do not include scene, scene_order --not needed
  const getStoryMeta = story => {
    const meta = { ...story };

    delete meta.scene_order;
    delete meta.scenes;

    return meta;
  };

  if ( selectedscene ) { // get the 'adapted' scene
    selectedscene = state.scenes[selectedscene.id];
  }

  let newState = {
    ...state,
    toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: toasterror }),
    story: getStoryMeta( storydata ),
    select: {
      ...state.select,
      storyId: storydata.id,
      sceneId: selectedscene ? selectedscene.id : null,
      orgId: storydata.organization_id,
      collectionId: storydata.collection_id,
      propertyId: storydata.property_id
    }
  };

  if ( selectedscene ) newState = mountStateScene( newState, selectedscene );

  return newState;
}

export function getStoryGQLAdapted ( state, content, embedType ) {
  state.scenes = content.ref.interactive.scenes.reduce( ( scenes, scene ) => {
    scenes[scene.id] = getSceneGQLAdapted( scene, scene.embedType || embedType );

    return scenes;
  }, []);

  let [ selectedscene ] = state.scenes;

  if ( content.ref.interactive ) {
    const sceneid = content.ref.interactive.startScene.id;
    [ selectedscene ] = state.scenes.filter( s => s.id === sceneid );
  }

  // return top level-story properties and
  // do not include scene, scene_order --not needed
  const getStoryMeta = story => {
    const meta = { ...story };

    delete meta.scene_order;
    delete meta.scenes;

    return meta;
  };

  if ( selectedscene ) { // get the 'adapted' scene
    selectedscene = state.scenes[selectedscene.id];
  }

  const storydata = content.ref.interactive;
  let newState = {
    ...state,
    toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: null }),
    story: getStoryMeta( content.ref.interactive ),
    select: {
      ...state.select,
      storyId: storydata.id,
      sceneId: selectedscene ? selectedscene.id : null,
      orgId: typeContent.getParentsOrg( content ).id,
      collectionId: typeContent.getParentsCollection( content ).id,
      propertyId: typeContent.getParentsProperty( content ).id
    }
  };

  if ( selectedscene ) newState = mountStateScene( newState, selectedscene );

  return newState;
}

export function clearScene ( state ) {
  return {
    ...state,
    videoplayback: videoplaybackDefault,
    select: {
      ...state.select,
      video: null,
      sceneId: null,
      hotspotId: null,
      postplaybacksceneId: null,
      linkedsceneId: null
    }
  };
}

export function deleteScene ( state, scene ) {
  let newState = {
    ...state,
    scenes: omit( state.scenes, scene.id )
  };
  if ( state.select.sceneId === scene.id ) {
    const newscene = Object.values( newState.scenes )[0];
    if ( newscene ) {
      return mountStateScene( newState, newscene );
    }

    newState = clearScene( newState );
  }
  return newState;
}

export function putScene ( state, scene ) {
  return {
    ...state,
    scenes: {
      ...state.scenes,
      [scene.id]: {
        ...scene
      }
    }
  };
}

//
// add blobdata to branding of media item associated w/ sceneId
//
export const addBrandBlob = ( state, sceneId, blobdata ) => {
  const scene = state.scenes[sceneId];
  const videoseq = getSceneMainVideoSequence( scene );
  const videomedia = scene.media_dict[videoseq.uuid];

  return putScene( state, applySceneMedia( scene, setBrandBlob( videomedia, blobdata ) ) );
};

export const addPosterBlob = ( state, sceneId, blobdata ) => {
  const scene = state.scenes[sceneId];
  const videoseq = getSceneMainVideoSequence( scene );
  const videomedia = scene.media_dict[videoseq.uuid];

  return putScene( state, applySceneMedia( scene, setPosterBlob( videomedia, blobdata ) ) );
};

export const setQualityLevel = ( state, sceneId, mediaId, qualityLevel, media ) => {
  const scene = getSceneAdapted( state.scenes[sceneId]);
  const videoseq = getSceneMainVideoSequence( scene );
  let videomedia = media || scene.media_dict[videoseq.uuid];

  if ( qualityLevel ) {
    videomedia = applyQualityLevel( videomedia, qualityLevel );

    let newstate = putScene(
      state, applySceneMedia( scene, videomedia )
    );

    newstate = {
      ...newstate,
      videoplayback: newstate.videoplayback,
      select: {
        ...newstate.select,
        video: videomedia
      }
    };

    return newstate;
  }

  return state;
};

export const setPlaylistCreating = ( state, sceneId, mediaId ) => {
  const scene = state.scenes[sceneId];
  const media = scene.media_dict[mediaId];

  // mark isPlaylistCreating on media...
  // so as a flag to prevent subsequent requests. ex,
  //
  // if ( !media.isPlaylistCreating ) playlistCreate
  //
  return putScene( state, applySceneMedia( scene, setMediaPlaylistCreating( media ) ) );
};

export const parsePlaylistLocal = ( state, sceneId, mediaId ) => {
  const scene = state.scenes[sceneId];
  let media = scene.media_dict[mediaId];
  let toasterror = null;
  let blockedInfo;
  const isReducedQuality = state.appSettings.startQuality === 'lowest';
  media = setQualityLevelsMP4( media, isReducedQuality );

  if ( media.resolution > 2000 && isReducedQuality ) {
    toasterror = toastMessageType.errorForecastDeviceQualityDifficulty;
  }

  blockedInfo = getBlockedInfo( media );
  if ( blockedInfo.shouldBlock ) {
    if ( blockedInfo.canRedirectToMobileApp && isBrandIconic( media ) ) {
      toasterror = toastMessageType.errorTRY_MOBILE_APP;
    } else {
      toasterror = blockedInfo.name;
    }
  }

  state = setSceneMountComplete( state, sceneId, !blockedInfo.shouldBlock );
  state = setQualityLevel( state, sceneId, mediaId, media.qualityLevel, media );

  if ( !isMediaQualityLevels( media ) ) {
    toasterror = toastMessageType.errorQualityLevelsNotFound;
  }

  console.log( 'set quality level (MP4)' );

  return {
    ...state,
    toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: toasterror })
  };
};

export const parsePlaylist = ( state, playlist, sceneId, mediaId, playlistURL ) => {
  const scene = state.scenes[sceneId];
  let media = scene.media_dict[mediaId];
  let toasterror = null;
  let blockedInfo;

  const isReducedQuality = state.appSettings.startQuality === 'lowest';
  media = setQualityLevelsPlaylist( media, isReducedQuality, playlist, playlistURL );

  blockedInfo = getBlockedInfo( media );

  if ( blockedInfo.shouldBlock ) {
    //
    // hash to media w/ 'formats' usable to test fallback/mp4 switching
    //
    // * h=w99tTgVkkc1IFTyjknID#play
    //
    if ( blockedInfo.canFallBackToMp4 ) {
      return parsePlaylistLocal( state, sceneId, mediaId );
    }

    if ( blockedInfo.canRedirectToMobileApp && isBrandIconic( media ) ) {
      toasterror = toastMessageType.errorTRY_MOBILE_APP;
    } else {
      toasterror = blockedInfo.name;
    }
  }

  state = setSceneMountComplete( state, sceneId, !blockedInfo.shouldBlock );
  state = setQualityLevel( state, sceneId, mediaId, media.qualityLevel, media );

  console.log( 'set quality level (HLS)', { media });
  return {
    ...state,
    toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: toasterror })
  };
};

export const setQualityId = ( state, sceneId, qualityId ) => {
  const scene = state.scenes[sceneId];
  const videoseq = getSceneMainVideoSequence( scene );
  const videomedia = scene.media_dict[videoseq.uuid];
  const qualityLevel = findQualityLevel( videomedia, qualityId );

  return setQualityLevel( state, sceneId, videomedia.id, qualityLevel );
};

export const setProjectionId = ( state, sceneId, projectionType ) => {
  const projectionState = getMediaProjectionTypeFromLegacyProjection(
    projectionType,
    MediaProjectionTypeEnum.EQUIRECTANGULAR
  );
  const scene = state.scenes[sceneId];

  state = {
    ...state,
    canvastype: projectionState === MediaProjectionTypeEnum.FLAT
      ? 'rectangle'
      : 'projection'
  };

  return putScene( state, {
    ...scene,
    projectionState
  });
};

// returns updated state and scene definitions, where
// scenes are 'linked' through their postplayback definition
//
export function linkScenes ( state, startScene, endScene ) {
  [ startScene, endScene ] = linkScenePostplayback( startScene, endScene );

  state = putScene( state, startScene );
  state = putScene( state, endScene );

  return [ state, startScene, endScene ];
}

// advert includes pre-roll and post-roll media meta data
//
// for each advert media defintion, a new scene is created and linked with
// corresponding content scene
//
export function getAdvertMerged ( state, advertres, advert ) {
  let contentscene = state.scenes[advert.sceneId];
  let { pre_roll, post_roll } = advertres;

  if ( !isAdvertSupported( post_roll ) ) {
    post_roll = false;
  }

  if ( !isAdvertSupported( pre_roll ) ) {
    pre_roll = false;
  }

  if ( post_roll ) {
    [ state, contentscene, post_roll ] = linkScenes(
      state, contentscene, getAdvertAsScene( post_roll, 'ad', 'post_roll' )
    );
  }

  if ( pre_roll ) {
    [ state, pre_roll, contentscene ] = linkScenes(
      state, getAdvertAsScene( pre_roll, 'ad', 'pre_roll' ), contentscene
    );
  }

  // if contentscene is mounted, mount pre_roll instead
  if ( state.select.sceneId === advert.sceneId && pre_roll ) {
    state = mountStateScene( state, pre_roll );
  }

  state = putScene( state, {
    ...contentscene,
    adverts: {
      ...contentscene.adverts,
      ismerged: true
    }
  });

  return state;
}

export const setCameraRotation = ( story, radianxy ) => ({
  ...story,
  camerarot: radianxy
});

const isNonZeroNumber = num => !!castas.num( num, 0 );

const isValidVod = vod => (
  vod.type
        && isNonZeroNumber( vod.duration )
        && vod.resolution
        && isNonZeroNumber( vod.resolution.width )
        && isNonZeroNumber( vod.resolution.height )
);

// each scene should have at least one video sequence / video
// each story should have at least one scene
const isValidStory = story => (
  story
        && story.scenes
        && story.scenes.length
        && story.scenes.every( getSceneMainVideoInfo )
);

export const storyReducer = ( state, action ) => {
  switch ( action.type ) {
  case CAMERA_SET_ROTATION:
    return setCameraRotation( state, action.radianxy );

  case TYPEACTIONS.VIDEO_GET.REQ_ERROR:
    return {
      ...state,
      toasts: toastReducer( state.toasts, {
        type: ADD_ERROR_TOAST,
        errorMsg: toastMessageType.errorMediaNotFound
      })
    };

  case TYPEACTIONS.VIDEO_GET.REQ_SUCCESS:
    if ( action.res.media_type === 'story' && !isValidStory( action.res.story ) ) {
      return {
        ...state,
        toasts: toastReducer( state.toasts, {
          type: ADD_ERROR_TOAST,
          errorMsg: toastMessageType.errorInvalidStory
        })
      };
    }

    if ( !/media|live|vod/.test( action.res.media_type ) ) {
      return state;
    }

    if ( action.res.media_type === 'vod' && !isValidVod( action.res ) ) {
      return {
        ...state,
        toasts: toastReducer( state.toasts, {
          type: ADD_ERROR_TOAST,
          errorMsg: toastMessageType.errorInvalidVod
        })
      };
    }

    // video data associate with an id and hash...
    // hash is (currently) not stored at the video data and so is added here
    action.res = Object.assign( action.res, {
      hash: action.hash,
      // other parts of penguin still using legacy
      // videoType --future updates should remove this
      videoType: action.res === 'live' ? 'stream' : 'vod'
    });

    return getStoryAdapted({ ...state }, getVideoAsStory( action.res ) );

  case TYPEACTIONS.BRANDBLOB_GET.REQ_SUCCESS:
    return addBrandBlob( state, action.sceneId, winurl.createObjectURL( action.res ) );

  case TYPEACTIONS.POSTERBLOB_GET.REQ_SUCCESS:
    return addPosterBlob( state, action.sceneId, winurl.createObjectURL( action.res ) );

  case QUALITY_SET_ID:
    return setQualityId( state, action.sceneId, action.qualityId );

  case PROJECTION_SET_ID:
    return setProjectionId( state, action.sceneId, action.projectionId );

  case TYPEACTIONS.PLAYLIST_GET.REQ_SUCCESS:
    return parsePlaylist( state, action.res, action.sceneId, action.mediaId, action.playlisturl );

  case PLAYLIST_GET_START:
    return setPlaylistCreating( state, action.sceneId, action.mediaId );

  case PLAYLIST_GET_LOCAL:
    return parsePlaylistLocal( state, action.sceneId, action.mediaId );

  case TYPEACTIONS.ADVERT_GET.REQ_SUCCESS:
    return getAdvertMerged( state, action.res, action.advert );

  case TYPEACTIONS.SCENE_DELETE.REQ_SUCCESS:
    return deleteScene( state, action.res.deleted );

  case SCENE_SELECT:
    return mountStateScene( state, state.scenes[action.sceneId], action );

  default:
    return state;
  }
};
