/* eslint-disable */
module.exports = {
    "metadata":{
        "type":"BufferGeometry",
        "position":36,
        "normal":36,
        "generator":"io_three",
        "uv":36,
        "version":3
    },
    "data":{
        "index":{
            "type":"Uint16Array",
            "array":[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35],
            "itemSize":1
        },
        "attributes":{
            "normal":{
                "type":"Float32Array",
                "array":[6.21725e-15,1,-2.98023e-08,6.21725e-15,1,-2.98023e-08,6.21725e-15,1,-2.98023e-08,-1.5099e-14,-1,2.98023e-08,-1.5099e-14,-1,2.98023e-08,-1.5099e-14,-1,2.98023e-08,-1,-2.98023e-07,-5.36442e-07,-1,-2.98023e-07,-5.36442e-07,-1,-2.98023e-07,-5.36442e-07,5.0664e-07,-1.19209e-07,-1,5.0664e-07,-1.19209e-07,-1,5.0664e-07,-1.19209e-07,-1,1,1.49012e-07,2.68221e-07,1,1.49012e-07,2.68221e-07,1,1.49012e-07,2.68221e-07,-2.08616e-07,-1.78814e-07,1,-2.08616e-07,-1.78814e-07,1,-2.08616e-07,-1.78814e-07,1,3.55271e-15,1,-2.98023e-08,3.55271e-15,1,-2.98023e-08,3.55271e-15,1,-2.98023e-08,-3.55271e-15,-1,2.98023e-08,-3.55271e-15,-1,2.98023e-08,-3.55271e-15,-1,2.98023e-08,-1,2.38419e-07,-7.10543e-15,-1,2.38419e-07,-7.10543e-15,-1,2.38419e-07,-7.10543e-15,8.9407e-08,2.98023e-07,-1,8.9407e-08,2.98023e-07,-1,8.9407e-08,2.98023e-07,-1,1,1.19209e-07,2.38419e-07,1,1.19209e-07,2.38419e-07,1,1.19209e-07,2.38419e-07,-2.08616e-07,-1.78814e-07,1,-2.08616e-07,-1.78814e-07,1,-2.08616e-07,-1.78814e-07,1],
                "itemSize":3
            },
            "position":{
                "type":"Float32Array",
                "array":[64,-64,-64,-64,-64,-64,-64,-64,64,64,64,64,-64,64,64,-64,64,-64,64,-64,64,64,64,64,64,64,-64,-64,-64,64,-64,64,64,64,64,64,-64,-64,64,-64,-64,-64,-64,64,-64,-64,64,-64,-64,-64,-64,64,-64,-64,64,-64,64,64,-64,-64,-64,-64,64,64,64,-64,64,64,64,-64,64,-64,64,-64,-64,64,-64,64,64,64,-64,64,-64,64,-64,-64,64,64,64,64,-64,64,64,-64,-64,64,-64,64,-64,64,64,-64,-64,64,-64,64,-64,-64],
                "itemSize":3
            },
            "uv":{
                "type":"Float32Array",
                "array":[0.332532,0.00120139,0.332532,0.498799,0.000800894,0.498799,0.667468,0.998799,0.667468,0.501202,0.999199,0.501201,0.999199,0.00120139,0.999199,0.498799,0.667468,0.498799,0.665866,0.501201,0.665866,0.998799,0.334134,0.998799,0.334134,0.00120142,0.665866,0.00120136,0.665866,0.498799,0.000800926,0.998799,0.000800926,0.501201,0.332532,0.501201,0.000800974,0.00120127,0.332532,0.00120139,0.000800894,0.498799,0.999199,0.998799,0.667468,0.998799,0.999199,0.501201,0.667468,0.00120139,0.999199,0.00120139,0.667468,0.498799,0.334134,0.501201,0.665866,0.501201,0.334134,0.998799,0.334134,0.498799,0.334134,0.00120142,0.665866,0.498799,0.332532,0.998799,0.000800926,0.998799,0.332532,0.501201],
                "itemSize":2
            }
        }
    }
};
