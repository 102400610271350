import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import addquery from 'addquery';
import styled from 'styled-components';
import urlencoded from 'form-urlencoded';
import ReactPlayer from 'react-player/lib/players/FilePlayer';
import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';
import useLocalPlaylist from '../hooks/useLocalPlaylist';
import { getpathmimetype } from '../utils/mime';
import { isScenePostPlaybackLoop } from '../utils/contentUtils';

const Container = styled.div`
    height:100%;
    width:100%;

    & video {
        display: ${props => props.isVisible ? 'block' : 'none'};
    }

    /*
    color: red;
    background-color: orange;
    position: relative;
    z-index: 10000
    */
`;

const getSrcArr = ( qualityLevel, qualityLevels, scene ) => {
  let { url, mimeType } = qualityLevel || {};
  const srcarr = [];

  // TODO should be handled at level parser (and not here)
  if ( url && !mimeType ) {
    mimeType = getpathmimetype( url );
  }

  if ( url && qualityLevels ) {
    srcarr.push({
      src: addquery( url, urlencoded({
        id: qualityLevel.id,
        sceneid: scene && scene.id,
        startTime: qualityLevel.startTime
      }) ),
      type: mimeType
    });
  }

  return srcarr;
};

export default function SceneVideoPlayerGQL ( props ) {
  const {
    isVisible,
    isTransition,
    isControls,
    scene,
    content,
    mediaItem
  } = props;
  const [ srcArr, srcArrSet ] = useState([]);
  const {
    playerRef,
    mediaPlaybackState,
    mediaSetSrc,
    mediaElem,
    onMediaPlay,
    onMediaPause,
    onMediaStart,
    onMediaReady,
    onMediaEnded,
    onMediaError,
    onMediaSeek,
    onMediaBuffer,
    onMediaBufferEnd,
    onMediaProgress,
    isHLSLogEnabled
  } = useLocalMediaPlayback();
  const {
    playlistState
  } = useLocalPlaylist();
    // let isplaying = !isTransition && playback.playing;
    // let srcarr = getSrcArr( mediaItem, scene );

  useEffect( () => {
    if ( playlistState.qualityLevel ) {
      srcArrSet( getSrcArr(
        playlistState.qualityLevel,
        playlistState.qualityLevels,
        scene || mediaItem
      ) );
    }
  }, [ playlistState.qualityLevel ]);

  // react-player does not provide handler to manage this situation,
  // when src is changed AND a specific start time must be used
  useEffect( () => {
    if ( mediaElem
             && srcArr[0]
             && playlistState.qualityLevel.startTime ) {
      mediaSetSrc( srcArr[0].src, playlistState.qualityLevel.startTime );
    }
  }, [ srcArr[0] ]);

  return useMemo( () => (
    <Container isVisible={isVisible || true}>
      <ReactPlayer
        ref={playerRef}
        url={srcArr[0] && srcArr[0].src}
        playing={!isTransition && mediaPlaybackState.playing}
        playsinline={true}
        onPlay={() => onMediaPlay( content )}
        onStart={onMediaStart}
        onReady={onMediaReady}
        onPause={() => onMediaPause( content )}
        onEnded={() => onMediaEnded( content )}
        onError={onMediaError}
        onSeek={onMediaSeek}
        onBuffer={() => onMediaBuffer( content )}
        onBufferEnd={() => onMediaBufferEnd( content )}
        onProgress={onMediaProgress}
        progressInterval={100}
        volume={mediaPlaybackState.volume}
        muted={mediaPlaybackState.mute}
        loop={isScenePostPlaybackLoop( scene )}
        controls={Boolean( isControls )}
        width='100%'
        height='100%'
        id='video'
        style={{ visibility: isVisible ? '' : 'hidden' }}
        config={{
          file: {
            // enable hls debug
            //
            // node_modules/react-player/lib/players/
            hlsVersion: '0.12.4',
            hlsOptions: {
              startLevel: -1,
              debug: isHLSLogEnabled,
              autoStartLoad: true
            },

            attributes: {
              crossOrigin: 'anonymous',
              id: 'videochild'
            }
          }
        }}
      />
    </Container>
  ), [
    srcArr,
    mediaPlaybackState.playing,
    mediaPlaybackState.mute,
    mediaPlaybackState.volume
  ]);
}

SceneVideoPlayerGQL.propTypes = {
  isTransition: PropTypes.bool,
  isControls: PropTypes.bool,
  isVisible: PropTypes.bool,

  mediaItem: PropTypes.object,
  content: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired

  // playback: PropTypes.object.isRequired
};
