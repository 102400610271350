import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import touchmouse from 'touchboom';
import * as THREE from 'three';
import { isVideoElem } from '../utils/elemUtils';
import { isInsideTime, nextCursor } from '../utils/moments';
import { hotspotDataSanitised } from '../adapter';

import {
  START_HOTSPOT,
  END_HOTSPOT
} from '../actions/ActionTypes';

import {
  isMediaAdImage
} from '../utils/mediaUtils';

import {
  getSceneMedia,
  getSceneProjectionType,
  isSceneSequencesChanged,
  isSceneHotspotsChanged
} from '../utils/sceneUtils';

import {
  isSequenceHotspot
} from '../utils/seqUtils';

import {
  getIconMesh,
  getIconMeshDotted,
  getBoundingLine,
  disposeNodeHierarchy,
  isTriangulateVertexError,
  getIcon3DWeak,
  getIcon3DStrong,
  getUniversalXYAsProjectionVector,
  getRelativePosition,
  getUniversalPosition,
  setUniversalPositionMesh,
  setUniversalScaleMesh
} from '../utils/canvasShapes';

export const Canvas = styled.canvas`
    user-select: none;

    border:0;
    outline:none;
    box-shadow:none;
    border:transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    color:#fff;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &.israyover-true {
        cursor:pointer;
    }
    &.israyover-false {
        cursor:auto;
    }
`;

export const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

// canvas representing '1' is 240x100
//
const DEFAULTVISIBILITY = false;

export default class CanvasThree extends Component {
  constructor ( props ) {
    super( props );

    this.hotspot3DCache = {};
    this.hotspotIdHover = null;
    this.lastTime = 0;
    this.lastMoment = -1;
    this.lastDirection = 'forward';
    this.threeInitialized = false;
    this.raycaster = new THREE.Raycaster();
    this.dragging = false;
    this.loopStarted = false;
    this.objectBeingDrawn = null;
    this.objectBeingDrawnVertices = [];
    this.shapeVertice = null;
    this.lineVertice = null;

    this.onmousemove = this.onMouseMove.bind( this );
    this.onmousedown = this.onMouseDown.bind( this );
    this.onmouseup = this.onMouseUp.bind( this );

    this.state = {
      noScenes: false,
      initialLoad: true
    };

    // behind first moment at index 0,
    this.momentindex = -1;
    this.colliders = [];

    if ( typeof props.getTargetElem === 'function' ) {
      this.targetElem = props.getTargetElem( props );
    }
  }

  // ex vertexarr, [[x,y],[x,y]]
  // ex vertex, [x,y]
  //
  // callee's vertexarr reference is not mutated
  // returns a new vertexarr that includes 'vertex'
  addDrawableVertex = ( vertexarr, vertex ) => {
    vertexarr = vertexarr.slice();
    vertexarr.push( vertex );

    return vertexarr;
  }

  getSceneHotspot3DArr = scene => scene
    ? scene.children.filter( child => child && child.isMeshHotspot )
    : [];

  logSceneHotspots = scene => console.log(
    this.getSceneHotspot3DArr( scene )
      .map( child => child.name )
      .join( '\n' )
  );

  getHotspot3D ( id, def = null ) {
    return this.hotspot3DCache[id] || def;
  }

  setHotspot3D ( id, mesh ) {
    return this.hotspot3DCache[id] = mesh;
  }

  isHotspot3D ( id ) {
    return this.hotspot3DCache[id];
  }

  getHotspotData ( hotspots, hotspotId ) {
    const hotspotData = hotspots[hotspotId];

    return hotspotDataSanitised( hotspotData );
  }

  getCanvas () {
    return document.getElementById( `CanvasThree-${this.props.data.canvastype}` );
  }

  // typically returns target <video> or <img> element
  getTargetElem ( props = this.props ) {
    const { video } = props.data.select;
    return props.getTargetElem( video );
  }

  componentDidMount () {
    this.container = document.getElementById( 'three-container' );
    this.refreshThree( this.props );

    // if canvas is defined in return value of render
    //
    // listeners should be mounted here
    //
    // let domElem = this.renderer.domElement;
    //
    // domElem.addEventListener( 'mousemove', this.onmousemove );
    // domElem.addEventListener( 'mousedown', this.onmousedown );
    // domElem.addEventListener( 'mouseup', this.onmouseup );
  }

  clearState ( ) {
    disposeNodeHierarchy( this.scene );
    delete this.scene;
  }

  refreshThree ( nextProps ) {
    this.setCanvasState( 'israyover', false );
    if ( getSceneMedia( nextProps.scene ) && this.getTargetElem() ) {
      this.clearState( nextProps );
      this.initThree( nextProps );
      this.threeRender();
      if ( !this.loopStarted ) {
        this.loopStarted = true;
        this.threeAnimate();
      }
    }
  }

  componentWillReceiveProps ( nextProps ) {
    const { scene: nextScene, data: nextData } = nextProps;
    const { scene: prevScene, data: prevData } = this.props;
    const nextMedia = getSceneMedia( nextScene );
    const prevMedia = getSceneMedia( prevScene );
    const targetElem = this.getTargetElem( nextProps );

    if ( this.container ) {
      this.container.style.visibility = nextMedia ? 'visible' : 'hidden';
    }

    if ( targetElem || isMediaAdImage( nextMedia ) ) {
      if ( !this.threeInitialized
                || nextMedia !== prevMedia
                || targetElem !== this.targetElem
                || prevData.canvastype !== nextData.canvastype
                || this.props.refresh !== nextProps.refresh
                || this.props.dimensions.width !== nextProps.dimensions.width
                || this.props.dimensions.height !== nextProps.dimensions.height
                || prevScene.id !== nextScene.id
                || getSceneProjectionType( prevScene ) !== getSceneProjectionType( nextScene ) ) {
        this.targetElem = targetElem;
        this.refreshThree( nextProps );
        this.cursorVideoMomentsRefresh( targetElem, nextScene.moments );
        this.refreshMoments( nextScene );
      }

      // do not render hotspots until transition is complete
      // prevent previous scene transition from rendering next scene hotspots
      if ( this.threeInitialized
                 && ( ( prevData.select.sceneId === nextData.select.sceneId
                        && ( isSceneSequencesChanged( prevScene, nextScene )
                             || isSceneHotspotsChanged( prevScene, nextScene )
                        ) )
                || ( !nextData.istransition
                     && prevData.istransition !== nextData.istransition ) ) ) {
        this.refreshHotspots( nextScene.sequences, nextScene.hotspots_dict );
        this.connectScene( nextProps );
        this.cursorVideoMomentsRefresh( targetElem, nextScene.moments );
      }

      if ( prevData.videoplayback.playedSeconds !== nextData.videoplayback.playedSeconds ) {
        this.cursorVideoMoments( targetElem, nextScene.moments );
      }
      if ( prevData.select.hotspotId !== nextData.select.hotspotId ) {
        this.selectHotspot( nextProps );
      }
      if ( prevData.drawmode.isenabled !== nextData.drawmode.isenabled
                && !nextData.drawmode.isenabled ) {
        this.saveObjectBeingDrawn();
      }
    }
  }

  componentDidUpdate ( prevProps ) {
    const { storyId } = prevProps.data.select;
    const hasStoryId = typeof storyId === 'string';
    const hasAuthToken = this.props.data.authtoken.length !== 0;
    const hasScenes = Object.keys( prevProps.data.scenes ).length === 0;

    if ( hasStoryId && hasAuthToken && hasScenes && this.state.initialLoad ) {
      this.setState({ noScenes: true, initialLoad: false }, () => {

      });
    }
  }

  connectScene ( ) {
    // override me
  }

  shouldComponentUpdate () {
    return false;
  }

  initScene ( /* props */ ) {
    // override me to define this.scene
  }

  // GQL version renamed threeSceneCreate
  initThree ( props ) {
    const targetElem = this.getTargetElem();

    if ( targetElem ) {
      this.initRenderer( props );
      this.initScene( props );
      this.refreshMoments( props.scene );
      this.connectScene( props );
      this.threeInitialized = true;
    }
  }

  // GQL version renamed threeSceneSelectHotspot
  selectHotspot ( props ) {
    this.clearHoverState( props );

    const hotspot3D = this.getHotspot3D( props.data.select.hotspotId );

    if ( hotspot3D !== null ) {
      const line = hotspot3D.children.filter( c => c.type === 'Line' );
      const dots = hotspot3D.children.filter( c => c.name === 'Dots' );
      if ( line.length ) line[0].visible = true;
      if ( dots.length ) dots[0].visible = true;
    }
  }

  clearHoverState ( props = this.props ) {
    const selectedid = props.data.select.hotspotId;
    Object.values( this.hotspot3DCache ).forEach( h => {
      const line = h.children.filter( c => c.type === 'Line' );
      const dots = h.children.filter( c => c.name === 'Dots' );
      if ( h.hotspotId !== selectedid ) {
        if ( line.length ) line[0].visible = false;
        if ( dots.length ) dots[0].visible = false;
      }
    });
  }

  onRayoutMesh ( hotspot3D ) {
    getIcon3DWeak( hotspot3D );
  }

  onRayoverMesh ( hotspot3D ) {
    getIcon3DStrong( hotspot3D );
  }

  setCanvasState ( statename, statebool ) {
    const canvas = this.getCanvas();
    const oldclass = `${statename}-${!statebool}`;
    const newclass = `${statename}-${statebool}`;

    // https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
    if ( canvas && canvas.classList.replace ) {
      if ( canvas.classList.contains( oldclass ) ) {
        canvas.classList.replace( oldclass, newclass );
      } else {
        canvas.classList.add( newclass );
      }
    }

    return canvas;
  }

  // calculate objects intersecting the picking ray
  getintersecting ( ) {
    const {
      colliders, raycaster, camera, mouse
    } = this;

    raycaster.setFromCamera( mouse, camera );

    return raycaster.intersectObjects( colliders )[0];
  }

  getintersectingobject ( ) {
    const intersecting = this.getintersecting( );

    return intersecting && intersecting.object;
  }

  mouseMoveNotDragging ( intersect ) {
    // this.clearHoverState();
    const [ i ] = intersect;

    const rayoverHotspotId = i && i.object && i.object.hotspotId;
    const hotspotIdHoverPrev = this.hotspotIdHover;
    if ( hotspotIdHoverPrev !== rayoverHotspotId ) {
      if ( hotspotIdHoverPrev ) {
        this.onRayoutMesh( this.hotspot3DCache[hotspotIdHoverPrev]);
        this.setCanvasState( 'israyover', false );
      }

      if ( rayoverHotspotId ) {
        this.onRayoverMesh( this.hotspot3DCache[rayoverHotspotId]);
        this.setCanvasState( 'israyover', true );
      }

      this.hotspotIdHover = rayoverHotspotId;
    }
  }

  onMouseMove ( e ) {
    const mousePos = this.getMousePos( e );
    const isDrawMode = this.props.data.drawmode.isenabled;

    if ( isDrawMode ) {
      return null;
    }

    this.raycaster.setFromCamera( mousePos, this.camera );

    if ( this.dragging ) {
      //  || ( intersect.length > 0
      //      && mouseBtn === 1 ) ) {
      // on my trackpad, any mouse movement returns e.which === 1
      // causes hotspot to 'stick' to mouse
      this.onDragMove( this.dragging.object, mousePos );
    } else {
      const intersect = this.raycaster
        .intersectObjects( Object.values( this.hotspot3DCache ), true )
        .filter( i => (
          // shaptype 'custom' not enabled (yet)
          // position is currently 'baked' into vertex coords
          // i.object.type === 'Sprite' || i.object.shapetype === 'custom'
          /Sprite|Mesh|Dot|Object3D/.test( i.object.type )
        ) );

      this.mouseMoveNotDragging( intersect );
    }

    return null;
  }

  mouseMoveDragging ( hotspot, mousePos ) {
    hotspot.position.x = mousePos.truex;
    hotspot.position.y = mousePos.truey;
  }

  onMouseUp () {
    if ( this.dragging ) {
      this.onDragEnd( this.dragging.object );
      this.dragging = false;
    }
  }

  onMouseDown ( e ) {
    const mousePos = this.getMousePos( e );
    const isDrawMode = this.props.data.drawmode.isenabled;

    if ( isDrawMode ) { // default mode
      if ( this.objectBeingDrawn === null ) {
        this.objectBeingDrawn = new THREE.Object3D();
        this.scene.add( this.objectBeingDrawn );
      }
      this.draw( mousePos );
    } else {
      this.raycaster.setFromCamera( mousePos, this.camera );

      const hotspotobj = this.getIntersectedHotspotFirst(
        this.raycaster, Object.values( this.hotspot3DCache )
      );

      if ( hotspotobj && hotspotobj.hotspotId ) {
        if ( !this.dragging ) {
          this.dragging = { object: hotspotobj };

          this.onDragStart( this.dragging.object, mousePos );
        }
      }
    }
  }

  saveObjectBeingDrawn () {
    if ( this.objectBeingDrawn === null ) return;

    const { media } = this.props;

    this.props.actions.postHotspotDisp( this.props.data.select.sceneId, {
      shape: this.objectBeingDrawnVertices, // coords
      opacity: 0.5,
      startTime: 0,
      endTime: Math.min( media.duration || 20, 30 )
    });
    this.scene.remove( this.objectBeingDrawn );
    this.objectBeingDrawn = null;
    this.objectBeingDrawnVertices = [];
  }

  isSafeVertexList ( vertexarr ) {
    const vertices = vertexarr.map( xy => (
      getUniversalXYAsProjectionVector( xy ) ) );
    const error = isTriangulateVertexError( vertices );

    if ( error ) {
      console.log( 'this position breaks triangulation', error );
    }

    return !error;
  }

  draw ( mousePos ) {
    // remove old mesh and line
    this.objectBeingDrawn.children = this.objectBeingDrawn.children
      .filter( child => ( child.type !== 'Line' && child.type !== 'Mesh' ) );
    const dot = this.makeDot( mousePos );
    const vertexlist = this.addDrawableVertex(
      this.objectBeingDrawnVertices,
      getUniversalPosition([
        dot.geometry.vertices[0].x,
        dot.geometry.vertices[0].y
      ], this.positionFactor )
    );
    const issafe = this.isSafeVertexList( vertexlist );

    if ( issafe ) {
      // explicitly managed vertex list ensures vertices persisted
      // and triangulated in same order they are added
      this.objectBeingDrawnVertices = vertexlist;
      this.objectBeingDrawn.add( dot );
    }

    const points = this.objectBeingDrawn.children
      .filter( c => c.type === 'Points' ).map( p => p.geometry.vertices[0]);

    if ( points.length === 2 ) { // draw a line
      const line = this.makeLine( points );
      this.objectBeingDrawn.add( line );
    } else if ( points.length > 2 ) { // draw a shape
      const mesh = this.makeShape( points.map( ({ x, y }) => [ x, y ]) );
      this.objectBeingDrawn.add( mesh );
    }
  }


  makeHandles ( vertices ) {
    const obj = new THREE.Object3D();
    for ( let i = 0; i < vertices.length; i += 1 ) {
      const v = vertices[i];
      const pos = {
        x: v.x, y: v.y, truex: v.x, truey: v.y
      };
      obj.add( this.makeDot( pos ) );
    }
    obj.name = 'Dots';
    return obj;
  }

  makeDot ( pos ) {
    const geometry = new THREE.Geometry();
    const material = new THREE.PointsMaterial({ size: 20 });

    // +2 to center the dot
    geometry.vertices.push( new THREE.Vector3(
      pos.truex + 2, pos.truey, this.overlayzpos + 2
    ) );

    const object3d = new THREE.Points( geometry, material );

    return object3d;
  }

  makeLine ( points, color = 0xffffff ) {
    const geometry = new THREE.Geometry();

    points.map( ({ x, y }) => (
      geometry.vertices.push( new THREE.Vector3( x, y, this.overlayzpos + 1 ) ) ) );

    geometry.vertices.push( geometry.vertices[0]);
    const material = new THREE.LineBasicMaterial({ color });
    const line = new THREE.Line( geometry, material );
    return line;
  }


  makeShape ( points ) {
    const shape = new THREE.Shape();
    const drawFrom = points[0];

    shape.moveTo( drawFrom[0], drawFrom[1]);

    points.map( ([ x, y ]) => shape.lineTo( x, y ) );
    shape.lineTo( drawFrom[0], drawFrom[1]);

    const geometry = new THREE.ShapeGeometry( shape );
    const material = new THREE.MeshBasicMaterial();
    const mesh = new THREE.Mesh( geometry, material );
    mesh.position.z = this.overlayzpos;
    mesh.material.transparent = true;
    mesh.material.opacity = 0.5;
    mesh.shapetype = 'custom';

    return mesh;
  }

  // returns x, y percentage,
  //  {1,1} at edge,
  //  {0,0} at center
  getMousePos ( e ) {
    const canvasElem = this.getCanvas();
    const mousePos = new THREE.Vector2();

    mousePos.x = ( ( e.offsetX / canvasElem.offsetWidth ) * 2 ) - 1;
    mousePos.y = ( -( e.offsetY / canvasElem.offsetHeight ) * 2 ) + 1;

    let [ x, y ] = touchmouse.getevxyrelativeelem( e, canvasElem );

    x -= canvasElem.offsetWidth / 2;
    y -= canvasElem.offsetHeight / 2;

    mousePos.truex = x;
    mousePos.truey = -y;

    return mousePos;
  }

  attachMouse () {
    const canvasElem = this.getCanvas();

    canvasElem.addEventListener( 'mousemove', this.onmousemove );
    canvasElem.addEventListener( 'mousedown', this.onmousedown );
    canvasElem.addEventListener( 'mouseup', this.onmouseup );
  }

  detachMouse () {
    const canvasElem = this.getCanvas();

    canvasElem.removeEventListener( 'mousemove', this.onmousemove );
    canvasElem.removeEventListener( 'mousedown', this.onmousedown );
    canvasElem.removeEventListener( 'mouseup', this.onmouseup );
  }

  componentWillUnmount () {
    this.detachMouse();

    disposeNodeHierarchy( this.scene );
    delete this.scene;
    this.loopStarted = false;
  }

  initRenderer ( ) {
    if ( typeof this.renderer !== 'undefined' ) {
      this.detachMouse();
    }

    this.attachMouse();
  }

  threeAnimate () {
    this.renderer.setAnimationLoop( () => {
      this.threeRender();
    });
  }

  // GQL version renamed threeSceneRenderFrame
  renderFrame () {
    if ( this.scene ) {
      this.renderer.render( this.scene, this.camera );

      this.props.onRenderFn( this );
    }
  }

  // GQL version renamed threeSceneRender
  threeRender () {
    const targetElem = this.getTargetElem();

    if ( !targetElem ) {
      return null;
    }

    if ( isVideoElem( targetElem ) ) {
      if ( targetElem.readyState === targetElem.HAVE_ENOUGH_DATA ) {
        if ( !targetElem.paused && this.props.scene.moments.length ) {
          this.cursorVideoMoments( targetElem, this.props.scene.moments );
        }
      }
    }

    return this.renderFrame();
  }

  refreshMoments ( sceneData ) {
    const { moments, hotspots_dict, sequences } = sceneData;
    const selectedId = this.props.data.select.hotspotId;

    this.scene = this.clearAllHotspots( this.scene );

    sequences.forEach( sequence => {
      if ( isSequenceHotspot( sequence ) )
        this.addSequenceHotspot(
          this.getHotspotData( hotspots_dict, sequence.uuid ),
          sequence.uuid === selectedId
        );
    });

    this.cursorVideoMomentsRefresh( this.getTargetElem(), moments );
  }

  clearAllHotspots ( scene = this.scene ) {
    this.getSceneHotspot3DArr( scene ).forEach( mesh => {
      scene.remove( mesh );
    });

    this.hotspot3DCache = {};
    this.colliders = [];
    return scene;
  }

  getRelativePositionArr ( arr ) {
    return arr.map( xy => getRelativePosition(
      [ ...xy.slice( 0, 2 ),
        this.overlayzpos // just in front of mesh
      ], this.positionFactor
    ) );
  }

  applyHotspotPosition ( hotspot3D, hotspotData ) {
    hotspot3D = setUniversalPositionMesh( hotspot3D, [
      ...hotspotData.pos.slice( 0, 2 ), this.overlayzpos
    ], this.positionFactor );

    return hotspot3D;
  }

  applyHotspotScale ( hotspot3D, hotspotData ) {
    return setUniversalScaleMesh(
      hotspot3D,
      hotspotData.dimensions,
      this.scale
    );
  }

  applyShapeColor ( hotspot, hotspot3D ) {
    const mesh = hotspot3D.children.filter( c => c.type === 'Mesh' )[0] || false;
    if ( !mesh ) return;
    mesh.material.color.setHex( `0x${hotspot.color.split( '#' )[1]}` );
    mesh.material.opacity = hotspot.opacity;
  }

  refreshHotspots ( sequences, hotspots ) {
    const hotspotsRefreshed = {};

    sequences.forEach( sequence => {
      if ( isSequenceHotspot( sequence ) ) {
        const hotspotData = this.getHotspotData( hotspots, sequence.uuid );
        const hotspot3D = this.getHotspot3D( sequence.uuid );
        const isselected = hotspotData.id === this.props.data.select.hotspotId;

        // completely rebuild
        if ( hotspot3D ) {
          this.scene.remove( hotspot3D );
        }

        hotspotsRefreshed[sequence.uuid] = true;
        this.addSequenceHotspot( hotspotData, isselected );

        // if ( !hotspot3D ) {
        // } else if ( hotspotData.shape.length === 0 && isselected ) {
        //    hotspot3D = this.applyHotspotPosition( hotspot3D, hotspotData );
        //    hotspot3D = this.applyHotspotScale( hotspot3D, hotspotData );
        //    hotspot3D = this.setHotspot3DVisible( hotspot3D, !hotspotData.hidden );
        // } else if ( hotspotData.shape.length > 0 && isselected ) {
        //    let mesh = this.makeShape( this.getRelativePositionArr( hotspotData.shape ) );
        //    mesh.hotspotId = hotspotData.id;
        //    mesh.position.z = this.overlayzpos;
        //    let { children } = hotspot3D;
        //    let index = children.findIndex( c => c.type === 'Mesh' );
        //    hotspot3D.remove( hotspot3D.children[index]);
        //    hotspot3D.add( mesh );
        //    this.applyShapeColor( hotspotData, hotspot3D );
        // }
      }
    });

    Object.keys( this.hotspot3DCache ).forEach( key => {
      if ( !hotspotsRefreshed[key]) {
        this.scene.remove( this.getHotspot3D( key ) );
      }
    });

    if ( this.props.scene.moments.length ) {
      this.cursorVideoMoments(
        this.getTargetElem(),
        this.props.scene.moments
      );
    }
  }

  // 'nextCursor' returns new index of moment w/ greatest time, lt 'time'.
  // function is called for the moment at each index between given index
  // and new index
  //
  // startindex is the cursor index which will commonly be the last cursor
  // position. to apply all moments from '0' to 'time', use startindex -1
  //
  cursorMoments ( time, moments, startindex ) {
    this.momentindex = nextCursor( startindex, moments, time, moment => {
      this.executeMoment( moment, isInsideTime( moment.time, time ) );
    });
  }

  cursorVideoMoments ( videoElem, moments ) {
    if ( isVideoElem( videoElem ) ) {
      this.cursorMoments( videoElem.currentTime, moments, this.momentindex );
    }
  }

  cursorVideoMomentsRefresh ( videoElem, moments ) {
    if ( isVideoElem( videoElem ) ) {
      this.cursorMoments( videoElem.currentTime, moments, -1 );
    }
  }

  // an object is 'visible' if its sequence occurs at the current time
  setHotspot3DVisible ( hotspot3D, isVisible ) {
    if ( hotspot3D )
      hotspot3D.visible = isVisible;

    if ( hotspot3D && hotspot3D.collider )
      hotspot3D.collider.visible = isVisible;

    return hotspot3D;
  }

  executeMoment ( moment, isForward ) {
    const { action, hotspotId } = moment.meta;
    const hotspot3D = this.getHotspot3D( hotspotId );

    if ( hotspot3D ) {
      if ( action === START_HOTSPOT ) {
        this.setHotspot3DVisible( hotspot3D, isForward );
      } else if ( action === END_HOTSPOT ) {
        this.setHotspot3DVisible( hotspot3D, !isForward );
      }
    }
  }

  getHoverMaterial ( color ) {
    return new THREE.LineBasicMaterial({ color });
  }

  createHotspot3DShape ( hotspotData ) {
    const hotspot3D = new THREE.Object3D();
    let shape;

    hotspot3D.name = `hotspot-${hotspotData.id}`;
    hotspot3D.hotspotId = hotspotData.id;
    hotspot3D.visible = false;
    hotspot3D.hidden = hotspotData.hidden;

    shape = this.makeShape( this.getRelativePositionArr( hotspotData.shape ) );
    shape.hotspotId = hotspotData.id;
    hotspot3D.add( shape );
    this.applyShapeColor( hotspotData, hotspot3D );

    const arr = this.getRelativePositionArr( hotspotData.shape )
      .map( p => ({ x: p[0], y: p[1], z: this.overlayzpos }) );
    const outline = this.makeLine( arr, 0xffffff );

    outline.hotspotId = hotspotData.id;
    outline.visible = false;

    hotspot3D.add( outline );
    hotspot3D.visible = DEFAULTVISIBILITY;

    const handles = this.makeHandles( arr );
    handles.visible = false;
    hotspot3D.add( handles );
  }

  createHotspot3DSphere ( hotspotData, isSelected ) {
    let hotspot3D = hotspotData.hidden
      ? getIconMeshDotted( hotspotData.id )
      : getIconMesh( hotspotData.id );

    hotspot3D.name = `hotspot-${hotspotData.id}`;
    hotspot3D.hotspotId = hotspotData.id;
    hotspot3D.hidden = hotspotData.hidden;

    const hoverSquare = getBoundingLine( hotspot3D );
    hoverSquare.name = 'hoverSquare';
    hoverSquare.hotspotId = hotspotData.id;
    hoverSquare.visible = Boolean( isSelected );

    hotspot3D = this.applyHotspotScale( hotspot3D, hotspotData );
    hotspot3D = this.applyHotspotPosition( hotspot3D, hotspotData );
    hotspot3D.add( hoverSquare );
    hotspot3D.visible = DEFAULTVISIBILITY;
    hotspot3D.isMeshHotspot = true;

    if ( isSelected )
      hotspot3D = getIcon3DStrong( hotspot3D );

    return hotspot3D;
  }

  createHotspot3D ( hotspotData, isSelected ) {
    return hotspotData.shape.length
      ? this.createHotspot3DShape( hotspotData, isSelected )
      : this.createHotspot3DSphere( hotspotData, isSelected );
  }

  // ex hotspot,
  //
  //   { id: 'no-id', x: 0, y: 0 }
  //
  addSequenceHotspot ( hotspotData, isSelected ) {
    if ( !this.scene ) {
      return null;
    }

    const hotspot3D = this.createHotspot3D( hotspotData, isSelected );
    this.setHotspot3D( hotspotData.id, hotspot3D );
    return this.scene.add( hotspot3D );
  }

  render () {
    return (
      <CanvasContainer id="three-container">
        <Canvas id={`CanvasThree-${this.props.data.canvastype}`} tabIndex="0" />
      </CanvasContainer>
    );
  }
}

CanvasThree.propTypes = {
  media: PropTypes.object,
  data: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  getTargetElem: PropTypes.func.isRequired,
  refresh: PropTypes.number,
  onRenderFn: PropTypes.func.isRequired
};
