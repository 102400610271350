//
// convenience function for getting data out of volatile
//

const getVolatileSceneId = state =>
  state.select.sceneId;

const getVolatileScene = ( state, sceneId ) =>
  state.scenes[sceneId || getVolatileSceneId( state )];

const getVolatileStoryId = state =>
  state.story && state.story.id;

export {
  getVolatileScene,
  getVolatileSceneId,
  getVolatileStoryId
};
