const createMoment = obj => ({
  time: obj.time,
  meta: obj.meta
});

const sortMoments = moments => moments
  .sort( ( a, b ) => a.time < b.time ? -1 : 1 );

const getMomentTime = ( moment, defaultTime = -Infinity ) =>
  moment && typeof moment.time === 'number'
    ? moment.time
    : defaultTime;

// if return the time on the index moment
// if index outside moment array, return Infinity
const getIndexTime = ( index, momentarr ) => {
  let indextime;

  if ( index < 0 ) {
    indextime = -Infinity;
  } else if ( index >= momentarr.length ) {
    indextime = getMomentTime( momentarr[momentarr.length - 1]);
  } else {
    indextime = getMomentTime( momentarr[index]);
  }

  return indextime;
};

// safety function, time condition must use `<=` not `<`
//
const isInsideTime = ( momenttime, time ) =>
  typeof momenttime === 'number' && momenttime <= time;

// index: start position in the momentarr
// momentarr: list of moment objects
// time: the index (cursor) will be incremented or decremented
//       until moment at index satisfies closest to 'time' w/out
//       passing it
//
// note that returned index values may fall outside the array,
// for example -1. this index identifies a time positions before
// the first moment with no definition inside the list
//
// 'engagefn' is called for with the moment corresponding to
// every index update.
//
// returns final index
const nextCursor = ( index, momentarr, time, engagefn ) => {
  // this check needed for non-GQL editor scene creation, no moments at scene init
  momentarr = Array.isArray( momentarr ) ? momentarr : [];
  let nextindex = +index;
  const indextime = getIndexTime( index, momentarr );
  // sometimes moment not defined, safety-check
  const onMoment = ( moment, i ) => moment && engagefn( moment, i );
  const max = momentarr.length - 1;
  const min = 0;

  if ( !momentarr.length ) {
    return nextindex;
  }

  if ( index < max + 1 && indextime < time ) {
    // only go forward if not already beyond max
    while ( nextindex + 1 <= max && isInsideTime( getMomentTime( momentarr[nextindex + 1], null ), time ) ) {
      onMoment( momentarr[++nextindex], nextindex );
    }
  } else if ( index > min - 1 && indextime > time ) {
    while ( nextindex >= min && !isInsideTime( getMomentTime( momentarr[nextindex], null ), time ) ) {
      onMoment( momentarr[nextindex], nextindex-- );
    }
  }

  return nextindex;
};

export {
  createMoment,
  sortMoments,
  isInsideTime,
  getIndexTime,
  nextCursor
};
