import {
  timeRecordsCreate,
  timeRecordsStart,
  timeRecordsEnd,
  timeRecordsIsOpen,
  timeRecordsIsOpened,
  timeRecordsGetElapsedTime
} from './utils/timeRecord';

// state persisted externally to make unit-testing easier
const analyticsEventStateCreate = () => ({
  events: {},
  timeSets: timeRecordsCreate()
});

const analyticsEventStart = ( eventState, id ) => {
  eventState.timeSets = timeRecordsStart( eventState.timeSets, id );

  return eventState;
};

const analyticsEventEnd = ( eventState, id ) => {
  eventState.timeSets = timeRecordsEnd( eventState.timeSets, id );

  return eventState;
};

const analyticsEventIsOpen = ( eventState, id ) => (
  timeRecordsIsOpen( eventState.timeSets, id )
);

const analyticsEventIsOpened = ( eventState, id ) => (
  timeRecordsIsOpened( eventState.timeSets, id )
);

const analyticsEventGetElapsedTime = ( eventState, id ) => (
  timeRecordsGetElapsedTime(
    eventState.timeSets, id
  ) / 1000 // convert to seconds
);

const analyticsEventGroupGetStartId = ( group, eventState ) => (
  eventState[group[0]]);

const analyticsEventGroupGetEndId = ( group, eventState ) => (
  eventState[group[1]]);

const analyticsEventGroupOpen = ( group, eventState, id ) => {
  eventState[group[0]] = id;
  eventState[group[1]] = null;
};

const analyticsEventGroupClose = ( group, eventState, id ) => {
  eventState[group[1]] = id;
};

const analyticsEventGroupIsOpen = ( group, eventState, id ) => {
  const startId = analyticsEventGroupGetStartId( group, eventState );
  const closeId = analyticsEventGroupGetEndId( group, eventState );

  return id
    ? startId === id && !closeId
    : startId && !closeId;
};

const analyticsEventGroupIsClosed = ( group, eventState, id ) => {
  const startId = analyticsEventGroupGetStartId( group, eventState );
  const closeId = analyticsEventGroupGetEndId( group, eventState );

  return id
    ? id === startId && startId === closeId
    : startId && startId === closeId;
};

const analyticsEventGroupIsOpened = ( group, eventState, id ) => {
  id = id || analyticsEventGroupGetStartId( group, eventState );

  return analyticsEventGroupIsOpen( group, eventState, id )
        || analyticsEventGroupIsClosed( group, eventState, id );
};

const analyticsEventGroupGetOpen = ( group, eventState ) => {
  if ( analyticsEventGroupIsOpen( group, eventState ) )
    return analyticsEventGroupGetStartId( group, eventState );

  return null;
};

const analyticsEventGroupGetOpened = ( group, eventState ) => {
  if ( analyticsEventGroupIsOpened( group, eventState ) )
    return analyticsEventGroupGetStartId( group, eventState );

  return null;
};

const analyticsEventGroupBuild = ( ( group, eventState ) => ({
  open: ( id = analyticsEventGroupGetStartId( group, eventState ) ) => {
    analyticsEventGroupOpen( group, eventState, id );
    analyticsEventStart( eventState, `${group[0]}-${id}` );
  },
  close: ( id = analyticsEventGroupGetStartId( group, eventState ) ) => {
    analyticsEventGroupClose( group, eventState, id );
    analyticsEventEnd( eventState, `${group[0]}-${id}` );
  },
  isOpen: id => analyticsEventGroupIsOpen( group, eventState, id ),
  getOpen: () => analyticsEventGroupGetOpen( group, eventState ),
  getOpened: () => analyticsEventGroupGetOpened( group, eventState ),
  isClosed: id => analyticsEventGroupIsClosed( group, eventState, id ),
  isOpened: id => analyticsEventGroupIsOpened( group, eventState, id ),
  getElapsedTime: id => analyticsEventGetElapsedTime( eventState, `${group[0]}-${id}` )
}) );

export {
  analyticsEventStateCreate,
  analyticsEventStart,
  analyticsEventEnd,
  analyticsEventIsOpen,
  analyticsEventIsOpened,
  analyticsEventGetElapsedTime,
  analyticsEventGroupGetStartId,
  analyticsEventGroupGetEndId,
  analyticsEventGroupOpen,
  analyticsEventGroupClose,
  analyticsEventGroupIsOpen,
  analyticsEventGroupIsClosed,
  analyticsEventGroupIsOpened,
  analyticsEventGroupGetOpen,
  analyticsEventGroupGetOpened,
  analyticsEventGroupBuild
};
