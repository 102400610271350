import ismobilejs from 'ismobilejs';
import { isHLS, isMediaStartFuture, isMediaEndPast } from './mediaUtils';
import {
  isStockAndroidBrowser, isSafari, isIosSafari, getIEVersion, isFirefoxMobile
} from './crossPlatform';

import { isCanvasContextIntel } from './canvasShapes';
import { shouldBeAbleToPlayVideo, isSourceCrossOrigin } from './video';
import { errorType, getError } from '../errors';

const ismobile = ismobilejs();

// server directives. the server may direct the video to be 'stopped', though
// it's resources may be technically available and playable
const META_STATUS_STOPPED = 'Stopped';
const META_STATUS_READY = 'Ready';
const META_STATUS_STREAMING = 'Streaming';

const getMediaStatusError = media => {
  let error = null;

  if ( !media.status ) {
    error = null;
  } else if ( isMediaStartFuture( media ) ) {
    error = errorType.errorMediaStartInFuture;
  } else if ( isMediaEndPast( media ) ) {
    error = errorType.errorMediaEndInPast;
  } else if ( media.status === META_STATUS_STOPPED ) {
    error = errorType.errorMediaStopped;
  } else if ( media.status !== META_STATUS_STREAMING && media.status !== META_STATUS_READY ) {
    error = errorType.errorMediaNotReady;
  }

  return error;
};

const getCompatError = media => {
  let error = null;
  const ishls = isHLS( media );
  const videosrc = media.file_url;

  if ( isSafari() && isIosSafari() === false && ishls && isCanvasContextIntel() ) {
    error = errorType.errorSAFARI_ON_INTEL_GPU;
  } else if ( isIosSafari() && isSourceCrossOrigin( videosrc ) ) {
    error = errorType.errorSAFARI_IOS_CROSS_ORIGIN;
  } else if ( getIEVersion() && getIEVersion() <= 10 ) {
    error = errorType.errorOLD_IE;
  } else if ( isFirefoxMobile() ) {
    error = errorType.errorFIREFOX_MOBILE;
  } else if ( ishls && isIosSafari() ) {
    error = errorType.errorIOS_HLS;
  } else if ( ismobile.apple.device && !window.matchMedia( '(-webkit-video-playable-inline)' ).matches ) {
    error = errorType.errorNON_INLINE_APPLE_DEVICE;
  } else if ( isStockAndroidBrowser() ) {
    error = errorType.errorSTOCK_ANDROID_BROWSER;
  } else if ( !shouldBeAbleToPlayVideo( videosrc ) ) {
    error = errorType.errorPLATFORM_NOT_SUPPORTED;
  } else if ( ismobile.apple.device && media.resolution.width > 2000 ) {
    error = errorType.errorAD_HOC;
  } else {
    error = getMediaStatusError( media );
  }

  return error;
};

const getBlockedInfo = media => {
  const error = getCompatError( media );
  const block = error && getError( error );

  return block
    ? { shouldBlock: true, ...block }
    : { shouldBlock: false };
};

export default getBlockedInfo;
