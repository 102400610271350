import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    vertical-align:middle;
    width: 100%;
    height: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    display:none;
`;

const Link = styled.a`
    width: 100%;
    cursor: pointer;
`;

const Image = styled.img`
    width: 100%;
`;

export default function BannerPlayer ( props ) {
  const { onClick, onEnded, onPresented, media = {} } = props;
  const { file_url, destination_url, name } = media;

  useEffect( () => {
    onPresented();

    setTimeout( () => {
      onEnded();
    }, media.duration * 1000 ); // media.duration is seconds
  }, []);

  if ( !file_url )
    return null;

  return (
    <Container id='Banner'>
      <Link href={destination_url} onClick={onClick} target="_blank" title={name}>
        <Image id='bannerimg' src={file_url} alt={name} crossorigin={'anonymous'} />
      </Link>
    </Container>
  );
}

BannerPlayer.propTypes = {
  media: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onEnded: PropTypes.func,
  onPresented: PropTypes.func
};
