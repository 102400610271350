import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useFetchBlob from '../hooks/useFetchBlob';

const Container = styled.div`
    pointer-events:none;
    position: absolute;
    right: 36px;
    top: 30px;
    opacity: 0.5;
    z-index:5;
`;

const Image = styled.img`
    width: 70px;
    height: 70px;
`;

export default function ImgWatermark ( props ) {
  // The need for 'blob' was not well documented.
  // Certain platforms (iphone?) required images to be loaded as 'blob'
  const { result } = useFetchBlob( props.imgSrc );

  return (
    <Container>
      {result && (
        <Image src={result} />
      )}
    </Container>
  );
}

ImgWatermark.propTypes = {
  imgSrc: PropTypes.string
};
