import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../actions';
import * as service from '../service';
import PlayerStage from './PlayerStage';
import Toasts from './toasts';

const FullscreenDiv = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    color: #fff;
`;

export class Player extends React.Component {
  onReady () {
    console.log( 'Player: onReady()', this );
  }

  onPlay () {
    console.log( 'Player: onPlay()', this );
  }

  onStart () {
    console.log( 'Player: onStart()', this );
  }

  componentDidMount () {
    this.props.actions.getStoryDisp();
  }

  render () {
    return (
      <FullscreenDiv>
        <PlayerStage data={this.props.data} actions={this.props.actions} uipanels={this.props.uipanels} />
        <Toasts data={this.props.data} actions={this.props.actions} />
      </FullscreenDiv>
    );
  }
}

Player.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  uipanels: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  data: state.data,
  uipanels: state.uipanels
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators( Actions, dispatch ),

    // attach all service dispatching functions
    //
    // after reduce call,
    // if 'service.putHotspotDispatch',
    // then 'actions.putHotspotDisp' is defined
    //
    // when auto-attached dispatch methods are used
    // manually defined dispatch methods below may be removed
    ...Object.keys( service ).reduce( ( dispatchers, name ) => {
      if ( /Dispatch$/i.test( name ) ) {
        dispatchers[name.replace( /Dispatch$/, 'Disp' )] = service[name]( dispatch );
      }

      return dispatchers;
    }, {})
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Player );
