import amplitudeJs from 'amplitude-js';

import { toObject, AdTypeEnum } from './enums';
import {
  VERSION,
  analyticsIsTestMode,
  analyticsIsLog,
  analyticsIsEnabled
} from './env';

import {
  isContentAd,
  isContentStream,
  isContentVOD,
  isContentInteractive,
  isContentMedia,
  getContentInteractive
} from './utils/contentUtils';

import {
  analyticsEventStateCreate,
  analyticsEventGroupBuild
} from './analyticsEvent.js';

export const analyticsAdStartedType = toObject([
  'pre_roll',
  'post_roll',
  'user_initiated',
  'user_initiated_from_details'
]);

const ad_id = 'ad_id';
const story_id = 'story_id';
const stream_id = 'stream_id';
const media_id = 'media_id';

export const analyticsContentType = toObject([
  ad_id,
  story_id,
  stream_id,
  media_id
]);

// Based on analytics taxonomy.
//
//   * https://docs.google.com/spreadsheets/d/ \
//       1QBCoEzREuuy8gIC9YcamX909RbqZWTHao8WaTWKb57U/edit#gid=366309364
//
export const EV_CONTENT_PLAYERSTARTED = 'Content-playerStarted';
export const EV_CONTENT_PLAYEREXITED = 'Content-playerExited';
export const EV_CONTENT_VIEWITEMDETAILS = 'Content-viewItemDetails';
export const EV_CONTENTCAMERA_PLAYERSTARTED = 'ContentCamera-playerStarted';
export const EV_CONTENTCAMERA_PLAYERFINISHED = 'ContentCamera-playerFinished';
export const EV_INTERACTIVE_PLAYERSTARTED = 'Interactive-playerStarted';
export const EV_INTERACTIVE_PLAYERFINISHED = 'Interactive-playerFinished';
export const EV_INTERACTIVE_HOTSPOTCLICKED = 'Interactive-hotspotClicked';
export const EV_INTERACTIVE_SCENESTARTED = 'Interactive-sceneStarted';
export const EV_INTERACTIVE_SCENEFINISHED = 'Interactive-sceneFinished';
export const EV_APP_HEADSETSTARTED = 'App-headsetStarted';
export const EV_APP_HEADSETFINISHED = 'App-headsetFinished';
export const EV_AD_PLAYERSTARTED = 'Ad-playerStarted';
export const EV_AD_PLAYERFINISHED = 'Ad-playerFinished';
export const EV_AD_CONVERSION = 'Ad-conversion';
export const EV_AD_DISMISSED = 'Ad-dismissed';

export const analyticsState = {
  analyticsIsEnabled,
  eventsEmitted: [],
  events: analyticsEventStateCreate()
};

export const getTimeSetList = () => analyticsState.timeSets;

export const analyticsLog = msgStr => {
  if ( analyticsIsLog ) {
    console.log( `[...] analytics: ${JSON.stringify( msgStr )}` );
  }
};

export const analyticsTimesLog = msgStr => {
  analyticsLog([ msgStr, analyticsState.events.timeSets ]);
};

export const analyticsEventLog = ( eventType, eventProperties ) => {
  analyticsLog([ eventType, eventProperties ]);
};

export const analyticsStateAssign = props => Object
  .assign( analyticsState, props );

export const analyticsEventGroupPlayer = analyticsEventGroupBuild([
  EV_CONTENT_PLAYERSTARTED,
  EV_CONTENT_PLAYEREXITED
], analyticsState.events );

export const analyticsEventGroupScene = analyticsEventGroupBuild([
  EV_INTERACTIVE_SCENESTARTED,
  EV_INTERACTIVE_SCENEFINISHED
], analyticsState.events );

export const analyticsEventGroupStory = analyticsEventGroupBuild([
  EV_INTERACTIVE_PLAYERSTARTED,
  EV_INTERACTIVE_PLAYERFINISHED
], analyticsState.events );

export const analyticsEventGroupAd = analyticsEventGroupBuild([
  EV_AD_PLAYERSTARTED,
  EV_AD_PLAYERFINISHED
], analyticsState.events );

export const analyticsEventGroupCamera = analyticsEventGroupBuild([
  EV_CONTENTCAMERA_PLAYERSTARTED,
  EV_CONTENTCAMERA_PLAYERFINISHED
], analyticsState.events );

export const analyticsEventGroupHeadset = analyticsEventGroupBuild([
  EV_APP_HEADSETSTARTED,
  EV_APP_HEADSETFINISHED
], analyticsState.events );

export const analyticsWhen = fn => (
  analyticsIsEnabled ? fn : () => analyticsState );

// analytics service requires different property
// name for media id, depending upon media type
//
// return the property name for the given media
export const analyticsGetMediaPropName = content => {
  let propname = '';

  if ( isContentAd( content ) ) {
    propname = ad_id;
  } else if ( isContentInteractive( content ) ) {
    propname = story_id;
  } else if ( isContentStream( content ) ) {
    propname = stream_id;
  } else if ( isContentVOD( content ) || isContentMedia === 'media' ) {
    propname = media_id;
  }

  if ( !propname ) {
    analyticsLog( 'media propname not found' );
  }

  return propname;
};

export const analyticsEmit = ( eventType, eventProperties ) => {
  analyticsEventLog( eventType, eventProperties );
  analyticsState.eventsEmitted.push([
    eventType, Date.now(), eventProperties
  ]);
  amplitudeJs.getInstance().logEvent( eventType, eventProperties );
};

export const analyticsInit = analyticsWhen( ( appId, apiKey, amplitude = amplitudeJs ) => {
  const state = analyticsState;
  const amplitudeAnalyticsInstance = amplitude.getInstance();

  analyticsLog( state, 'initializing' );

  amplitudeAnalyticsInstance.init( apiKey );
  amplitudeAnalyticsInstance.setVersionName( VERSION );
  amplitudeAnalyticsInstance.setUserProperties({
    app: 'html5',
    app_id: appId,
    app_version: VERSION,
    test_mode: analyticsIsTestMode ? '1' : '0'
  });

  return state;
});

// 1. **Content-playerStarted** Fires when the user first starts playing a
//    stream or a vod. Not an ad or an interactive video. This event must not
//    fire when a new camera of a stream has started. Only when the stream has
//    first started should this event fire.
//
//     * stream_id [string-uuid] (if stream)
//     * media_id [string-uuid] (if vod)
//     * source [string] (see note #1)
//     * media_source [string] (see note #2)
//
//    note #1
//
//    Where was the user before this item started playing. 'homepage' - If the
//    content started playing immediately from the homepage and bypassed viewing
//    of the details page. 'details_page' - If the user was viewing the media or
//    live stream details page. 'Previous'/'Next' User pressed previous or next
//    button in player to start playing this content
//
//    note #2
//
//    Where the media is being played from. Current valid values:
//      'remote' - The user is streaming from a server.
//      'local' - The user is playing from their own device (downloaded)"
//
export const analyticsPlayerStarted = analyticsWhen( content => {
  if ( analyticsEventGroupPlayer.isOpen() ) {
    return analyticsState;
  }

  analyticsEventGroupPlayer.open( content.id );

  return analyticsEmit( EV_CONTENT_PLAYERSTARTED, {
    [analyticsGetMediaPropName( content )]: content.id,
    source: 'details_page',
    media_source: 'remote'
  });
});


// 2. **Content-playerExited** Fires when the video finishes playing, whether
//    by user interaction or not. If the user presses back, or navigates away,
//    or the content finishes playing.
//
//     * *stream_id* [string-uuid] (if stream)
//     * *media_id* [string-uuid] (if vod)
//     * *seconds_viewed* [float] (see note #1)
//
//    Total time in seconds the user spent watching the content (regardless of
//    camera switching, if any) ie (playerExited timestamp - playerStarted
//    timestamp ). Pausing should pause the timer. Going back should stop the
//    timer. Content finishing should stop the timer. Putting the app in the
//    background should pause the timer. Pressing play (after a pause) should
//    resume a paused timer.
export const analyticsPlayerExited = analyticsWhen( content => {
  if ( !analyticsEventGroupPlayer.isOpened() ) {
    return analyticsState;
  }

  analyticsEventGroupPlayer.close( content.id );

  return analyticsEmit( EV_CONTENT_PLAYEREXITED, {
    [analyticsGetMediaPropName( content )]: content.id,
    seconds_viewed: analyticsEventGroupPlayer.getElapsedTime( content.id )
  });
});

// 3. **Content-viewItemDetails** Web: Fires when the page loads.
//    iOS & Android: Fires when the user navigates to a media or stream
//      details/description page.
//    GearVR: Fires when the details/description of the content is viewed
//      (when they click a video and the details expand out).
//
//    DONT fire this event if a user clicks, for example, on a featured item
//    on the homepage that immediately starts playing the content ie bypassing
//    the details/description page/view.
//
//     * *stream_id* [string-uuid] (if stream)
//     * *media_id* [string-uuid] (if vod)
//
export const analyticsPageLoaded = analyticsWhen( content => {
  // Taxonomy: "Web: Fires when the page loads."
  analyticsEmit( EV_CONTENT_VIEWITEMDETAILS, {
    [analyticsGetMediaPropName( content )]: content.id
  });

  return analyticsState;
});

// 7. **ContentCamera-playerStarted** Fired when a user starts streaming a
//    camera. For live streams with multiple cameras.
//
//    * *stream_id* [string-uuid] (ID of the stream)
//    * *camera_id* [string-uuid] (ID of the camera)
//    * *from_camera_id* [string-uuid] (see note #1)
//
//     "The ID of the camera this camera is being loaded from 'initial'
//      - This camera was the initial camera loaded when starting the
//      stream. eg user started playing camera xyz from camera abc,
//      from_camera_id would be 'abc'"
//
export const analyticsCameraStarted = analyticsWhen( ( content, camera ) => {
  const cameraOpenId = analyticsEventGroupCamera.getOpen();

  if ( cameraOpenId ) {
    if ( cameraOpenId === camera.id ) {
      return analyticsState;
    }

    // eslint-disable-next-line no-use-before-define
    analyticsCameraFinished( content, { id: cameraOpenId });
  }

  analyticsEventGroupCamera.open( camera.id );

  return analyticsEmit( EV_CONTENTCAMERA_PLAYERSTARTED, {
    stream_id: content.id,
    camera_id: camera.id,
    from_camera_id: cameraOpenId
  });
});

// 8. **ContentCamera-playerFinished** Fired when a user switches away from this
//    camera to another camera that is a part of this content, or exits the
//    content entirely
//
//      * *stream_id* [string-uuid] (ID of the stream)
//      * *camera_id* [string-uuid] (ID of the camera)
//      * *seconds_viewed* [float] (see note #1)
//      * *player_exited* [string - "0" or "1"] (see note #2)
//
export const analyticsCameraFinished = analyticsWhen( ( content, camera, isexit = false ) => {
  if ( !analyticsEventGroupCamera.isOpen( camera.id ) ) {
    return analyticsState;
  }

  analyticsEventGroupCamera.close( camera.id );

  return analyticsEmit( EV_CONTENTCAMERA_PLAYERFINISHED, {
    stream_id: content.id,
    camera_id: camera.id,
    seconds_viewed: analyticsEventGroupCamera.getElapsedTime( camera.id ),
    player_exited: String( Number( isexit ) ) // '1' or '0'
  });
});

// 11. **App-headsetStarted** Fires when the app enters a headset mode, e.g. entering Cardboard mode
//
//    * *media_id* [string-uuid] (if happens during vod)
//    * *stream_id* [string-uuid] (if happens during stream)
//    * *story_id* [string-uuid] (if happens during interactive video)
//    * *ad_id* [string-uuid] (if happens during ad)
//
export const analyticsHeadsetStarted = analyticsWhen( ( content, media ) => {
  if ( analyticsEventGroupHeadset.isOpen() ) {
    return analyticsState;
  }

  analyticsEventGroupHeadset.open( content.id );

  return analyticsEmit( EV_APP_HEADSETSTARTED, {
    [analyticsGetMediaPropName( content )]: media.id
  });
});

// 12. **App-headsetFinished** Fires when the user leaves a headset mode they started, e.g. leaving Cardboard
//
//    * *seconds_viewed* [float] (see note #1)
//    * *media_id* [string-uuid] (if happens during vod)
//    * *stream_id* [string-uuid] (if happens during stream)
//    * *story_id* [string-uuid] (if happens during interactive video)
//    * *ad_id* [string-uuid] (if happens during ad)
//
//    Total time in seconds that the user spent in headset mode.
//    The same rules as Content-playerExited apply for seconds_viewed
//
export const analyticsHeadsetFinished = analyticsWhen( ( content, media ) => {
  if ( !analyticsEventGroupHeadset.isOpen( content.id ) ) {
    return analyticsState;
  }

  analyticsEventGroupHeadset.close( content.id );

  return analyticsEmit( EV_APP_HEADSETFINISHED, {
    [analyticsGetMediaPropName( content )]: media.id,
    seconds_viewed: analyticsEventGroupHeadset.getElapsedTime( content.id )
  });
});

// 13. **Ad-playerStarted** Must fire in any of these circumstances:
//
//    1. A pre-roll or post-roll ad starts (MAY be non-video ads)
//    2. A user initiates a video playback by clicking a video ad on an
//       "explore" page
//    3. A user intiates a video playback by clicking play on the details page
//       of a video ad
//
//    properties
//
//    * *ad_id* [string-uuid] (id of the ad viewed)
//    * *ad_item_id* optional [string-uuid] (id of the AdItem containing the Ad)
//    * *type* [string] See note #2
//    * See Note #1
//
//    ad_id -> collection.ads[].ad.id OR collction.ads[].ad_id
//    ad_item_id -> collection.ads[].id
//
//    Valid values:
//
//      * pre_roll
//      * post_roll
//      * user_initiated
//      * user_initiated_from_details
//
export const analyticsAdStarted = analyticsWhen( ( content, ad, adStartedType ) => {
  if ( analyticsEventGroupAd.isOpen( content.id ) ) {
    return analyticsState;
  }

  analyticsEventGroupAd.open( content.id );

  return analyticsEmit( EV_AD_PLAYERSTARTED, {
    ad_item_id: content.id,
    ad_id: ad.id,
    type: adStartedType
  });
});

// 14. **Ad-playerFinished** Must fire when any of:
//
//    1. A pre-roll or post-roll ad finishes and returns to the main content
//    2. An ad initiated by (2) or (3) above returns to the previous state (e.g. details page or explore page)
//    3. An Ad-conversion event is fired after (2) or (3) above.
//
//    properties
//
//    * *ad_id* [string-uuid] (id of the ad viewed)
//    * *ad_item_id* optional [string-uuid] (id of the AdItem containing the Ad)
//    * *type* [string] same as Ad-playerStarted type
//    * *seconds_viewed* [float] (see note #1)
//
//    Seconds that the ad was viewed before it expired or was dismissed by the user. If the video stops, or the user converts, either count.
//
export const analyticsAdPlayerFinished = analyticsWhen( ( content, ad, adStartedType ) => {
  if ( !analyticsEventGroupAd.isOpen( content.id ) ) {
    return analyticsState;
  }

  analyticsEventGroupAd.close( content.id );

  return analyticsEmit( EV_AD_PLAYERFINISHED, {
    ad_id: content.id,
    type: adStartedType,
    seconds_viewed: analyticsEventGroupAd.getElapsedTime( content.id )
  });
});

// 15. **Ad-conversion** Fired when the user visits the URL associated with
//     an ad. This may be a deep link or a website URL. This may be from the
//     player, or from the "details" page of an ad.
//
//     * *ad_id* [string-uuid] (id of the ad interacted with)
//     * *ad_item_id* optional [string-uuid] (id of the AdItem containing the
//         Ad)
//     * *seconds_viewed* [float] Seconds that the ad or details page was
//         viewed before before the conversion.
//
//     ad_item_id may be left out for pre/post roll ads, as the user did not
//     opt in to these.
//
export const analyticsAdEngaged = analyticsWhen( ( content, ad ) => {
  analyticsEmit( EV_AD_CONVERSION, {
    ad_item_id: content.id,
    ad_id: ad.id,
    seconds_viewed: analyticsEventGroupAd.getElapsedTime( content.id )
  });

  return analyticsState;
});

// 17. **Ad-dismissed** Fires when a details page is closed, or a video without
//     a details page is closed, or an interruptable pre-roll or post-roll video
//     is dismissed, or a video of an ad with a details page is exited early (in
//     addition to Ad-playerFinished)
//
//    * *ad_id* [string-uuid] (id of the ad interacted with)
//    * *ad_item_id* [string-uuid] (id of the AdItem containing the Ad)
//    * *seconds_viewed* [float] (See note #1)
//    * *type* [string] (details or video or link)
//
//    Seconds that the details page was viewed, or seconds that the video was
//    viewed, before being dismissed.
//
export const analyticsAdDismissed = analyticsWhen( ( content, ad ) => {
  analyticsEmit( EV_AD_DISMISSED, {
    ad_item_id: content.id,
    ad_id: ad.id,
    type: ad.__typename === AdTypeEnum.VideoAd ? 'video' : 'link',
    seconds_viewed: analyticsEventGroupAd.getElapsedTime( content.id )
  });

  return analyticsState;
});

// 20. **Interactive-playerStarted** Fired when interactive starts playback
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * See Note #1
//
//   story_id -> collection.stories[].story.id OR collection.stories[].story_id
//   story_item_id -> collection.stories[].id
//
export const analyticsStoryStarted = analyticsWhen( content => {
  if ( analyticsEventGroupStory.isOpen() ) {
    return analyticsState;
  }

  analyticsEventGroupStory.open( content.id );

  // `story_item` contains `story`
  return analyticsEmit( EV_INTERACTIVE_PLAYERSTARTED, {
    story_item_id: content.id,
    story_id: getContentInteractive( content, {}).id
  });
});

// 21. **Interactive-playerFinished** Fired when user finishes, exits, or
//     ends the interactive experience
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//
export const analyticsStoryExited = analyticsWhen( content => {
  // if ( !analyticsEventGroupStory.isOpen() ) {
  if ( !analyticsEventGroupStory.isOpened() ) {
    return analyticsState;
  }

  analyticsEventGroupStory.close( content.id );

  // `story_item` contains `story`
  return analyticsEmit( EV_INTERACTIVE_PLAYERFINISHED, {
    story_item_id: content.id,
    story_id: getContentInteractive( content, {}).id
  });
});

// 22. **Interactive-hotspotClicked** Fired when an interactive hotspot is acted upon
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *hotspot_id* [string-uuid] (id of the hotspot interacted with)
//
export const analyticsHotspotEngaged = analyticsWhen( ( content, hotspot_id ) => {
  // `story_item` contains `story`
  analyticsEmit( EV_INTERACTIVE_HOTSPOTCLICKED, {
    story_item_id: content.id,
    story_id: getContentInteractive( content, {}).id,
    hotspot_id
  });

  return analyticsState;
});

// 23. **Interactive-sceneStarted** Fired when a scene is loaded
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *scene_id* [string-uuid] (id of the scene loaded)
//
export const analyticsSceneStarted = analyticsWhen( ( content, scene ) => {
  const sceneOpenedId = analyticsEventGroupScene.getOpened();

  if ( sceneOpenedId ) {
    if ( sceneOpenedId === scene.id ) {
      return analyticsState;
    }

    // eslint-disable-next-line no-use-before-define
    analyticsSceneFinished( content, { id: sceneOpenedId });
  }

  analyticsEventGroupScene.open( scene.id );

  // `story_item` contains `story`
  return analyticsEmit( EV_INTERACTIVE_SCENESTARTED, {
    story_item_id: content.id,
    story_id: getContentInteractive( content, {}).id,
    scene_id: scene.id
  });
});

// 24. **Interactive-sceneFinished** Fired when a scene is switched or exited
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *scene_id* [string-uuid] (id of the scene loaded)
//   * *seconds_viewed* [float] (see note #1)
//
//   Seconds that the scene was viewed before switching to another scene or
//   the scene ended on its own.
//
export const analyticsSceneFinished = analyticsWhen( ( content, scene ) => {
  if ( !analyticsEventGroupScene.isOpened( scene.id ) ) {
    return analyticsState;
  }

  analyticsEventGroupScene.close( scene.id );

  // `story_item` contains `story`
  return analyticsEmit( EV_INTERACTIVE_SCENEFINISHED, {
    story_item_id: content.id,
    story_id: getContentInteractive( content, {}).id,
    scene_id: scene.id,
    seconds_viewed: analyticsEventGroupScene.getElapsedTime( scene.id )
  });
});

export const analyticsContentStarted = analyticsWhen( ( content, sceneOrCamera ) => {
  sceneOrCamera = sceneOrCamera || {};

  if ( isContentAd( content ) ) {
    analyticsAdStarted( content );
  } else if ( isContentInteractive( content ) ) {
    if ( !analyticsEventGroupStory.isOpened() ) {
      analyticsStoryStarted( content );
    }
    analyticsSceneStarted( content, sceneOrCamera );
  } else if ( isContentStream( content ) ) {
    if ( analyticsEventGroupPlayer.isOpen() ) {
      analyticsCameraStarted( content, sceneOrCamera );
    } else {
      analyticsPlayerStarted( content );
      analyticsCameraStarted( content, sceneOrCamera );
    }
  } else if ( isContentVOD( content ) ) {
    analyticsPlayerStarted( content );
  } else if ( isContentMedia( content ) ) {
    analyticsPlayerStarted( content );
  }

  return analyticsState;
});

export const analyticsContentExited = analyticsWhen( ( content, sceneOrCamera ) => {
  sceneOrCamera = sceneOrCamera || {};

  if ( isContentAd( content ) ) {
    analyticsAdPlayerFinished( content );
  } else if ( isContentInteractive( content ) ) {
    analyticsSceneFinished( content, sceneOrCamera );
    analyticsStoryExited( content );
  } else if ( isContentStream( content ) ) {
    if ( analyticsEventGroupCamera.isOpen() ) {
      analyticsCameraFinished( content, sceneOrCamera, true );
    }
    analyticsPlayerExited( content );
  } else if ( isContentVOD( content ) ) {
    analyticsPlayerExited( content );
  } else if ( isContentMedia( content ) ) {
    analyticsPlayerExited( content );
  }

  return analyticsState;
});

// seconds_viewed...
//
//   * Total time in seconds the user spent watching the content (regardless
//     of camera switching, if any) ie (playerExited timestamp - playerStarted
//     timestamp ). Pausing should pause the timer. Going back should stop the
//     timer. Content finishing should stop the timer. Putting the app in the
//     background should pause the timer. Pressing play (after a pause) should
//     resume a paused timer. _note from slack_: seconds_viewed does not include
//     buffering and loading times
//
// pause when,
//   switching out of a camera
//   switching out of a scene
//   when pausing
//
export const analyticsContentTimerPause = analyticsWhen( content => {
  // end the current scene or camera
  if ( isContentInteractive( content ) ) {
    analyticsEventGroupScene.close();
    analyticsEventGroupStory.close( content.id );
  } else if ( isContentStream( content ) ) {
    analyticsEventGroupCamera.close();
    analyticsEventGroupPlayer.close( content.id );
  } else {
    analyticsEventGroupPlayer.close( content.id );
  }
  analyticsTimesLog( 'PAUSE' );
});

// resume when,
//   pressing play
//   resuming play from buffering
//
export const analyticsContentTimerResume = analyticsWhen( content => {
  // start the current scene or camera
  if ( isContentInteractive( content ) ) {
    analyticsEventGroupStory.open( content.id );
    analyticsEventGroupScene.open();
  } else if ( isContentStream( content ) ) {
    analyticsEventGroupPlayer.open( content.id );
    analyticsEventGroupCamera.open();
  } else {
    analyticsEventGroupPlayer.open( content.id );
  }
  analyticsTimesLog( 'RESUME' );
});
