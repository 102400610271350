import {
  PANEL_HEIGHT,
  TOGGLE_SCENE_PANEL
} from '../actions/ActionTypes';

export const uipanelsDefault = {
  isOpenScenePanel: false,
  activePanelName: false,
  timelinePanelHeight: 400
};

export function setPanelHeight ( state, panelName, panelHeight ) {
  return {
    activePanelName: state.activePanelName !== panelName && panelName,
    timelinePanelHeight: panelHeight
  };
}

export default function uipanelsReducer ( state = uipanelsDefault, action ) {
  switch ( action.type ) {
  case PANEL_HEIGHT:
    return setPanelHeight( state, action.panelName, action.height );
  case TOGGLE_SCENE_PANEL:
    return {
      ...state,
      isOpenScenePanel: !state.isOpenScenePanel
    };
  default:
    return state;
  }
}
