import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CanvasRadar from './CanvasRadar';
import SlideSeekbar from './SlideSeekbar';
import PlayerControlsButtons from './PlayerControlsButtons';

const Container = styled.div`
    display: ${props => props.isDisableUI ? 'none' : 'block'};
`;

export default function PlayerControlsSeek ( props ) {
  const getVideoElem = () => typeof props.getVideoElement === 'function'
    ? props.getVideoElement( this )
    : document.getElementById( 'video' ).firstChild;

  const getVideo = () => {
    return props.data.select && props.data.select.video;
  };

  // returns length of media in seconds...
  //
  // streaming media do not have duration, so return two hours default
  //
  const getDuration = ( video = getVideo() ) => {
    return video ? ( video.duration || 120 ) : 0;
  };

  // https://github.com/CookPete/react-player/blob/HEAD/src/demo/App.js#L58
  //
  // react-player demo uses reactplayerref.seekTo( seekTime ) method
  //
  const onSeek = ( seekPer, seekTime ) => {
    const videoElem = getVideoElem();

    if ( videoElem ) {
      props.actions.setVideoTime( seekTime );
      videoElem.currentTime = seekTime;
    }
  };

  // https://github.com/alexanderwallin/react-player-controls/blob/master/src/components/ProgressBar.js
  // react-player-controls uses
  // totalTime, currentTime, isSeekable,
  const { isDisableUI, data, actions, uipanels, scene, stageDimensionsWH } = props;
  const { timess, loaded } = data.videoplayback;
  const durationss = getDuration();

  return (
    <Container isDisableUI={isDisableUI}>
      {data.canvastype === 'projection' && (
        <CanvasRadar
          stageDimensionsWH={stageDimensionsWH}
          dimensions={[ 65, 65 ]}
          camerarot={data.camerarot} />
      )}
      <SlideSeekbar
        timess={timess}
        durationss={durationss}
        loadedPercent={loaded}
        onSeek={onSeek} />
      <PlayerControlsButtons
        uipanels={uipanels}
        timess={timess}
        durationss={durationss}
        scene={scene}
        data={data} actions={actions} />
    </Container>
  );
}

PlayerControlsSeek.propTypes = {
  data: PropTypes.object.isRequired,
  scene: PropTypes.object,
  uipanels: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isDisableUI: PropTypes.bool,
  stageDimensionsWH: PropTypes.array.isRequired,

  // used by test to mock the video element
  getVideoElement: PropTypes.func
};
