export const SESSIONUSER = 'SESSIONUSER';
export const SESSION = 'SESSION';
export const ORGS = 'ORGS';
export const ADVERT = 'ADVERT';
export const SCENE = 'SCENE';
export const STORY = 'STORY';
export const VIDEO = 'VIDEO';
export const VIDEOS = 'VIDEOS';
export const PLAYLIST = 'PLAYLIST';
export const IMAGE = 'IMAGE';
export const IMAGES = 'IMAGES';
export const AUDIO = 'AUDIO';
export const MEDIA = 'MEDIA';
export const MEDIALIBRARY = 'MEDIALIBRARY';
export const HOTSPOT = 'HOTSPOT';
export const SEQUENCE = 'SEQUENCE';
export const TRANSITION = 'TRANSITION';
export const UPLOAD = 'UPLOAD';
export const SCENEMEDIA = 'SCENEMEDIA';
export const BRANDBLOB = 'BRANDBLOB';
export const POSTERBLOB = 'POSTERBLOB';

export const REQ_LOADING = 'REQ_LOADING';
export const REQ_SUCCESS = 'REQ_SUCCESS';
export const REQ_SUCCESS_GQL = 'REQ_SUCCESS_GQL';
export const REQ_ERROR = 'REQ_ERROR';
export const GET = 'GET';
export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';

export const overlayTypes = [
  SESSION,
  SESSIONUSER,
  ORGS,
  SCENE,
  STORY,
  VIDEO,
  VIDEOS,
  IMAGES,
  IMAGE,
  AUDIO,
  MEDIA,
  SCENEMEDIA,
  MEDIALIBRARY,
  ADVERT,
  HOTSPOT,
  PLAYLIST,
  SEQUENCE,
  TRANSITION,
  UPLOAD,
  BRANDBLOB,
  POSTERBLOB
];

export const overlayTypeActions = [
  GET,
  CREATE,
  UPDATE,
  DELETE
];

export const overlayActionsPrefixes = [
  REQ_LOADING,
  REQ_SUCCESS,
  REQ_SUCCESS_GQL,
  REQ_ERROR
];

// construct
//
// {
//    HOTSPOT: {
//        REQ_ERROR: 'HOTSPOT_REQ_ERROR',
//        REQ_LOADING: 'HOTSPOT_REQ_LOADING',
//        REQ_SUCCESS: 'HOTSPOT_REQ_SUCCESS'
//    },
//    SCENE: {
//        REQ_ERROR: 'SCENE_REQ_ERROR',
//        REQ_LOADING: 'SCENE_REQ_LOADING',
//        REQ_SUCCESS: 'SCENE_REQ_SUCCESS'
//    }
//    ...
// }
export const overlayActions = overlayTypes.reduce( ( prev, key ) => Object.assign( prev, {
  [key]: overlayActionsPrefixes.reduce( ( ns, prefix ) => {
    ns[prefix] = `${prefix}_${key}`;

    return ns;
  }, {})
}), {});

// construct
//
// {
//    HOTSPOT_CREATE: {
//        REQ_ERROR: 'HOTSPOT_CREATE_REQ_ERROR',
//        REQ_LOADING: 'HOTSPOT_CREATE_REQ_LOADING',
//        REQ_SUCCESS: 'HOTSPOT_CREATE_REQ_SUCCESS'
//    },
//    HOTSPOT_DELETE: {
//        REQ_ERROR: 'HOTSPOT_DELETE_REQ_ERROR',
//        REQ_LOADING: 'HOTSPOT_DELETE_REQ_LOADING',
//        REQ_SUCCESS: 'HOTSPOT_DELETE_REQ_SUCCESS'
//    }
//    ...
// }
export const typeActions = overlayTypes.reduce( ( prev, key ) => (
  overlayTypeActions.reduce( ( prevns, action ) => Object.assign( prevns, {
    [`${key}_${action}`]: overlayActionsPrefixes.reduce( ( ns, prefix ) => {
      ns[prefix] = `${key}_${action}_${prefix}`;
      return ns;
    }, {})
  }), prev ) ), {});

// where overlay is loading 'HOTSPOT'
// return overlay {
//   type: 'REQ_LOADING_HOTSPOT',
//   isLoading: true
// }
const overlayReqLoading = ( overlay, isLoading, resProps, loadtype = REQ_LOADING ) => ({
  loadtype,
  type: overlay[loadtype],
  ...resProps,
  isLoading
});

const overlayReqError = ( overlay, res, resProps, loadtype = REQ_ERROR ) => ({
  loadtype,
  type: overlay[loadtype],
  ...resProps,
  res
});

const overlayReqSuccess = ( overlay, res, resProps, loadtype = REQ_SUCCESS ) => ({
  loadtype,
  type: overlay[loadtype],
  ...resProps,
  res
});

// overlayFetch to send generic requests for hotspot-related actionTypes
// each requests publishes actions for 'REQ_LOADING' (true then false), followed
// by 'REQ_ERROR' or 'REQ_SUCCESS'
//
// ex,
//
// await overlayFetch( dispatch, typeActions.HOTSPOT_DELETE, uri, {
//     method: 'DELETE',
//     headers: new Headers({
//         Authorization: authtoken,
//         'Content-Type': 'application/x-www-form-urlencoded'
//     })
// }, {
//     sceneId: '123',
//     hotspotId: '123',
//     sequenceId: '123'
// });
export const overlayFetch = async ( dispatch, actionType, uri, fetchArgs, resProps ) => {
  dispatch( overlayReqLoading( actionType, true, resProps || {}) );

  if ( typeof uri !== 'string' ) {
    throw new Error( 'Invalid uri' );
  }

  const response = await fetch( uri, fetchArgs );
  // current default 'json', sometimes 'blob' is used
  const responseType = fetchArgs.responseType || 'json';

  dispatch( overlayReqLoading( actionType, false, resProps || {}) );

  const returnval = responseType && response.ok
    ? await response[responseType]()
    : response;

  if ( response.ok ) {
    dispatch( overlayReqSuccess( actionType, returnval, resProps || {}) );
  } else {
    dispatch( overlayReqError( actionType, returnval, resProps || {}) );
  }

  return returnval;
};
