import React from 'react';
import PropTypes from 'prop-types';
import BaseDropdown from './BaseDropdown.js';
import {
  labelBandwidth,
  labelResolution
} from '../utils/labels.js';

const levelsCacheSet = ( cache, level ) => {
  const resolution = `${level.resolution.width}x${level.resolution.height}`;

  if ( !cache[resolution])
    cache[resolution] = [ level ];
  else
    cache[resolution].push( level );

  return cache;
};

// ex,
//
//   840x1920, 29.97fps @ 31Mbps
//   840x1920, 31Mbps
//   840x1920
const levelsCreateLabelWide = level => [
  [
    labelResolution( level.resolution ),
    level.frameRate ? `${Number( level.frameRate || 0 ).toFixed( 0 )}fps @` : ' '
  ].filter( l => l ).join( ', ' ),
  level.bitrate ? `${labelBandwidth( level.bitrate )}` : '  '
].join( ' ' ).trim().replace( /,$/, '' );

const levelsSetLabelWide = level => ({
  ...level,
  label: /auto/i.test( level.label )
    ? level.label
    : levelsCreateLabelWide( level )
});

// return dropdown 'groups' where same-resolution variants are differentiated by
// codec and bitrate
const levelsCreateGroups = levels => Object.values(
  levels.reduce( ( cache, level ) => (
    levelsCacheSet( cache, level )
  ), {})
).map( levelsGroup => {
  if ( levelsGroup.length === 1 ) {
    return levelsSetLabelWide( levelsGroup[0]);
  }

  return ({
    type: 'group',
    name: labelResolution( levelsGroup[0].resolution ),
    items: levelsGroup.map( levelsSetLabelWide )
  });
});

const levelsCreateLabels = levels => {
  if ( levels.length < 2 )
    return levels.map( levelsSetLabelWide );

  // the first level is the 'auto' level --do not group it
  return [
    levelsSetLabelWide( levels[0]),
    ...levelsCreateGroups( levels.slice( 1 ) )
  ];
};

export default function DropdownLevels ( props ) {
  return (
    <BaseDropdown
      {...props}
      label={''}
      options={levelsCreateLabels( props.qualityLevels )}
      selected={props.selected}
      onChange={props.onChange} />
  );
}

DropdownLevels.propTypes = {
  qualityLevels: PropTypes.array.isRequired,
  selected: PropTypes.object,

  onChange: PropTypes.func.isRequired
};
