import React from 'react';
import PropTypes from 'prop-types';

import VolumeMuteSVG from '../svg/baseline-volume_mute-24px.svg';
import VolumeUpSVG from '../svg/baseline-volume_up-24px.svg';
import BaseButtonIcon from './BaseButtonIcon';

export default function ButtonToggleVolume ( props ) {
  const { onClick = () => {}, isEnabled, iconSquare = '25px' } = props;
  const VolumeSVG = isEnabled ? VolumeMuteSVG : VolumeUpSVG;
  const title = isEnabled ? 'un-mute' : 'mute';

  return (
    <BaseButtonIcon
      {...props}
      onClick={() => onClick({ value: !isEnabled })}
      title={title}
      isEnabled={isEnabled}>
      <VolumeSVG width={iconSquare} height={iconSquare} />
    </BaseButtonIcon>
  );
}

ButtonToggleVolume.propTypes = {
  onClick: PropTypes.func,
  iconSquare: PropTypes.string,
  isEnabled: PropTypes.bool.isRequired
};
