import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getBestFormat, getTimecodeFormatted } from '../utils/timecode';

const Container = styled.div`
    height: 23px;
    font-family: var(--ivy-font-family-primary);
    font-size: var(--ivy-label-time-font-size);
    color: var(--ivy-label-time-color);
    line-height: 24px;
    padding:0;
    vertical-align:middle;
    display:inline-block;

    & > span + span:before {
        content: " / ";
    }
`;

export default function LabelTime ({ durationss, timess }) {
  const bestFormat = getBestFormat( typeof durationss === 'number'
    ? durationss
    : timess );

  return (
    <Container>
      <span>
        {getTimecodeFormatted( timess, bestFormat )}
      </span>
      {typeof durationss === 'number' && (
        <span>
          {getTimecodeFormatted( durationss, bestFormat )}
        </span>
      )}
    </Container>
  );
}

LabelTime.propTypes = {
  durationss: PropTypes.number.isRequired,
  timess: PropTypes.number.isRequired
};
