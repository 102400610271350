import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const defaultLocalToast = {
  __typename: 'ToastState',
  id: 'toaststate',
  closeToastId: '',
  showToast: '',
  toastIds: [],
  showDate: null,
  lastUpdate: Date.now()
};

export const GET_TOAST_STATE = gql`{
  toastState @client {
    id
    closeToastId
    showToast
    toastIds
    showDate
    lastUpdate
  }
}`;

export const SET_TOAST_STATE = gql`
mutation SetToastState( $input: SetToastInput! ) {
  toastState( input: $input ) @client
}`;

export default function useLocalToast () {
  const { data } = useQuery( GET_TOAST_STATE );

  return {
    toast: ( data && data.toastState ) || {}
  };
}

// experiement w/ separating read/write context action to reduce occurrences of 'read'
//
// https://github.com/facebook/react/issues/15156#issuecomment-474590693
//
export const useLocalToastAction = () => {
  const [ toastMutation ] = useMutation( SET_TOAST_STATE );
  const hideToast = () => {};

  const showToast = toastId => {
    return toastMutation({
      variables: {
        input: {
          showToast: toastId
        }
      }
    });
  };

  return {
    hideToast,
    showToast
  };
};
