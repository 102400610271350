import * as types from './ActionTypes';

export const addHotspot = () => ({ type: types.ADD_HOTSPOT });
export const deleteHotspot = id => ({ type: types.DELETE_HOTSPOT, id });
export const hotspotEngage = ( sceneId, hotspotId ) => ({
  type: types.HOTSPOT_ENGAGE,
  sceneId,
  hotspotId
});
export const hotspotRayover = id => ({ type: types.HOTSPOT_RAYOVER, id });

export const soundsEnd = id => ({ type: types.SOUNDS_END, id });
export const soundsReplay = id => ({ type: types.SOUNDS_REPLAY, id });

export const selectHotspot = id => ({ type: types.SELECT_HOTSPOT, id });
export const selectSequence = id => ({ type: types.SELECT_SEQUENCE, id });
export const togglePlaying = isPlaying => ({ type: types.TOGGLE_PLAYING, isPlaying });
export const toggleMute = mute => ({ type: types.TOGGLE_MUTE, mute });
export const toggleVolume = volume => ({ type: types.TOGGLE_VOLUME, volume });
export const setVideoTime = playedSeconds => ({ type: types.SET_VIDEO_TIME, playedSeconds });

export const setVideo = ( video, sequence, sceneId ) => ({
  type: types.SCENE_SET_VIDEO,
  video,
  sequence,
  sceneId
});

export const setZoom = zoomFactor => ({ type: types.SET_ZOOM, zoomFactor });
export const togglePreviewMode = isPreviewMode => ({ type: types.TOGGLE_PREVIEW_MODE, isPreviewMode });
export const toggleOverlay = isVisible => ({ type: types.TOGGLE_OVERLAY, isVisible });

export const updateSaveIndicator = ( isUpdating, isUpdatingSuccess ) => ({ type: types.UPDATE_SAVE_INDICATOR, isUpdating, isUpdatingSuccess });

export const startVideoLoading = isLoadingStarted => ({ type: types.IS_VIDEO_REQ_LOADING, isLoadingStarted });

export const videoIsReady = isReady => ({
  type: types.VIDEO_IS_READY,
  isReady
});

export const videoIsRecovering = () => ({
  type: types.VIDEO_IS_RECOVERING,
  isRecovering: true
});

export const videoPlay = () => ({
  type: types.VIDEO_PLAY,
  play: true
});

export const videoPause = () => ({
  type: types.VIDEO_PAUSE,
  pause: true
});


export const videoLoaded = ( toastId, isVideoLoaded ) => ({ type: types.IS_VIDEO_LOADED, toastId, isVideoLoaded });

// progress properties from react-player,
//
//   https://www.npmjs.com/package/react-player#callback-props
//
export const setVideoProgress = ({ playedSeconds, loaded, loadedSeconds }) => ({
  type: types.SET_VIDEO_PROGRESS,
  playedSeconds,
  loaded,
  loadedSeconds
});
export const mediaEnded = ( sceneId, videoId ) => ({
  type: types.MEDIA_ENDED,
  sceneId,
  videoId
});
export const mediaStarted = ( sceneId, videoId ) => ({
  type: types.MEDIA_STARTED,
  sceneId,
  videoId
});
export const mediaSeek = ( sceneId, mediaId ) => ({
  type: types.MEDIA_SEEK,
  sceneId,
  mediaId
});
export const mediaBuffer = ( sceneId, mediaId ) => ({
  type: types.MEDIA_BUFFER,
  sceneId,
  mediaId
});
export const mediaBufferEnd = ( sceneId, mediaId ) => ({
  type: types.MEDIA_BUFFEREND,
  sceneId,
  mediaId
});

export const windowUnload = () => ({
  type: types.WINDOW_BEFORE_UNLOAD,
  date: Date.now()
});

export const login = ( email, bearerToken ) => ({
  type: types.LOGIN,
  email,
  bearerToken
});

export const logout = () => ({
  type: types.LOGOUT
});

export const modalShut = () => ({
  type: types.MODAL_SHUT
});

export const modalOpen = ( panelId, panelOpts = {}) => ({
  type: types.MODAL_OPEN,
  panelId,
  modalType: 'big',
  panelOpts: {mediaType: panelOpts.mediaType || 'video', ...panelOpts }
});

export const modalOpenNoScene = () => ({
  type: types.MODAL_OPEN,
  panelId: 'NoScene',
  modalType: 'YesNo'
});

export const modalOpenConfirm = ( panelOpts = {}) => ({
  type: types.MODAL_OPEN,
  panelId: 'Confirm',
  modalType: 'YesNo',
  panelOpts
});

export const modalOpenMediaSelect = mediaType => ({
  type: types.MODAL_OPEN,
  panelId: 'MediaSelect',
  panelOpts: {
    mediaType: mediaType || 'video'
  }
});

// panelOpts should include currentSceneId
export const modalOpenVideoSelect = ( panelOpts = {}) => ({
  type: types.MODAL_OPEN,
  panelId: 'MediaSelect',
  modalType: 'big',
  panelOpts: {mediaType: panelOpts.mediaType || 'video', ...panelOpts }
});

export const modalOpenSceneSelect = ( panelOpts = {}) => ({
  type: types.MODAL_OPEN,
  panelId: 'SceneSelect',
  modalType: 'medium',
  panelOpts
});

export const vrmodeToggle = domElem => ({
  type: types.VRMODE_TOGGLE,
  domElem
});

export const vrmodeStart = domElem => ({
  type: types.VRMODE_START,
  domElem
});

export const vrmodeEnd = domElem => ({
  type: types.VRMODE_END,
  domElem
});

export const heatmodeToggle = () => ({
  type: types.HEATMODE_TOGGLE
});

export const heatmodeStart = () => ({
  type: types.HEATMODE_START
});

export const heatmodeEnd = () => ({
  type: types.HEATMODE_END
});

export const adEngage = ad => ({
  type: types.AD_ENGAGE,
  ad
});

export const adPresenting = ad => ({
  type: types.AD_PRESENTING,
  ad
});

export const adPresentingDetails = ad => ({
  type: types.AD_PRESENTINGDETAILS,
  ad
});

export const adCompleted = ad => ({
  type: types.AD_FINISHED,
  ad
});

export const fullscreenToggle = domElem => ({
  type: types.FULLSCREEN_TOGGLE,
  domElem
});

export const fullscreenStart = domElem => ({
  type: types.FULLSCREEN_START,
  domElem
});

export const fullscreenEnd = domElem => ({
  type: types.FULLSCREEN_END,
  domElem
});


export const qualitySetId = ( sceneId, qualityId ) => ({
  type: types.QUALITY_SET_ID,
  qualityId,
  sceneId
});

export const projectionSetId = ( sceneId, projectionId ) => ({
  type: types.PROJECTION_SET_ID,
  projectionId,
  sceneId
});


//
// drawmode
//
export const drawmodeToggle = domElem => ({
  type: types.DRAWMODE_TOGGLE,
  domElem
});

export const drawmodeStart = domElem => ({
  type: types.DRAWMODE_START,
  domElem
});

export const drawmodeEnd = domElem => ({
  type: types.DRAWMODE_END,
  domElem
});


//
// canvas selection
//
export const canvasToggle = () => ({
  type: types.CANVAS_TOGGLE
});

//
// scene selection
//
export const sceneUp = sceneId => ({
  type: types.SCENE_UP,
  sceneId
});
export const sceneDown = sceneId => ({
  type: types.SCENE_DOWN,
  sceneId
});

export const sceneChanged = ( prevSceneId, nextSceneId ) => ({
  type: types.SCENE_CHANGED,
  prevSceneId,
  nextSceneId
});

export const sceneSelect = sceneId => ({
  type: types.SCENE_SELECT,
  sceneId
});

export const sceneSetVideo = ( sceneId, video, seqindex, seq ) => ({
  type: types.SCENE_SET_VIDEO,
  sceneId,
  video,
  seqindex,
  seq
});

export const cameraSetRotation = ( sceneId, radianxy ) => ({
  type: types.CAMERA_SET_ROTATION,
  sceneId,
  radianxy
});


export const uploadIsError = () => ({
  type: types.UPLOAD_IS_ERROR
});

export const uploadIsFinished = ( media, file ) => ({
  type: types.UPLOAD_IS_FINISHED,
  media,
  file
});

export const deleteConfirmationModalIsOpen = ( isOpen, deleteWhat, id ) => ({
  type: types.DELETE_CONFIRMATION_MODAL_IS_OPEN,
  isOpen,
  deleteWhat,
  id
});

export const clearShowToast = () => ({
  type: types.CLEAR_SHOW_TOAST
});

export const addErrorToast = errorMsg => ({
  type: types.ADD_ERROR_TOAST,
  errorMsg
});


export const playlistGetStart = ( sceneId, mediaId, mediaType, playlisturl ) => ({
  sceneId,
  mediaId,
  mediaType,
  playlisturl,
  type: types.PLAYLIST_GET_START
});

export const playlistGetLocal = ( sceneId, mediaId ) => ({
  sceneId,
  mediaId,
  type: types.PLAYLIST_GET_LOCAL
});


export const panelToggle = ( panelName, height ) => ({
  type: types.PANEL_HEIGHT,
  panelName,
  height
});

export const toggleScenePanel = () => ({
  type: types.TOGGLE_SCENE_PANEL
});

// form TYPEACTIONS namespaces like this...
//
// STORY_GET: {
//   REQ_ERROR: "STORY_GET_REQ_ERROR"
//   REQ_LOADING: "STORY_GET_REQ_LOADING"
//   REQ_SUCCESS: "STORY_GET_REQ_SUCCESS"
//   REQ_SUCCESS_GQL: "STORY_GET_REQ_SUCCESS_GQL"
// }
//
// return functions like this...
//
// "STORY_GET_REQ_ERROR": function () => {}
// "STORY_GET_REQ_LOADING": function () => {}
// "STORY_GET_REQ_SUCCESS": function () => {}
// "STORY_GET_REQ_SUCCESS_GQL": function () => {}
//
// allows component to call legacy request actions
//
export const reqActions = Object.keys( types.TYPEACTIONS ).reduce( ( prev, k ) => (
  Object.keys( types.TYPEACTIONS[k]).reduce( ( prevactions, actionname ) => {
    prevactions[`${k}_${actionname}`] = data => ({
      type: types.TYPEACTIONS[k][actionname],
      ...data
    });

    return prevactions;
  }, prev ) ), {});
