import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const defaultLocalTiles = {
  tilesState: {
    id: 'tilesstate',
    __typename: 'TilesState',

    selectedTab: 1,
    propertyTileMetaList: []
  }
};

export const GET_TILES_STATE = gql`{
  tilesState @client {
    id

    selectedTab
    propertyTileMetaList
  }
}`;

export const SET_TILES_STATE = gql`
mutation SetTilesState( $input: SetTilesInput! ) {
  tilesState( input: $input ) @client
}`;

export default function useLocalTiles () {
  const [ tilesMutation ] = useMutation( SET_TILES_STATE );
  const { tilesState = {} } = useQuery( GET_TILES_STATE ).data || defaultLocalTiles;

  const tilesSelectTab = ( selectedTab = 0 ) => tilesMutation({
    variables: {
      input: { selectedTab }
    }
  });

  return {
    tilesState,
    tilesSelectTab
  };
}
