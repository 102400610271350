import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const GET_MEDIA = gql`
query getMedia ( $mediaId: UUID! ) {
    media ( id: $mediaId ) {
        id
        title
        filename
        projection
        resolution { width height }
        size { mb }
        mediaType
        blindspotType
        videoCodec
        videoBitrate
        audioBitrate
        duration { ms seconds }
        file { url __typename }
        hlsPlaylist { uri }
        transcodeStatus
   }
}`;

export default function useMedia ( opts ) {
  return useLazyQuery( GET_MEDIA, {
    fetchPolicy: 'no-cache',
    variables: {
      mediaId: opts.mediaId
    }
  });
}
