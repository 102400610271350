// this file not the recommended apollo way, but allows app to use venom-api sooner

import gql from 'graphql-tag';
import client from './apolloClient';

export const fragments = {
  parentData: gql`
fragment ParentData on Parent {
    __typename
    name
    id
}`,
  videoInformation: gql`
fragment VideoInformationData on VideoInformation {
    projection
    mimeType
    blindspotType
    resolution {
        width
        height
    }
}`,
  contentInternationalization: gql`
fragment LanguageData on ContentInternationalization {
    id
    title
    language
    tags
}`,
  hotspotSequence: gql`
fragment HotspotSequence on SceneSequence {
    __typename
    start
    end
    ... on VideoSceneSequence {
      media {
        filename
        projection
        resolution {
          width
          height
        }
      }
   }
}`
};

export const GET_CONTENT = gql`
query getContent($contentId: UUID!) {
  content(id: $contentId) {
    parents {
      ...ParentData
    }
    ref {
      ... on InteractiveRef {
        interactive {
          id
          startScene { id }
          scenes {
            postPlayback {
              action
              destinationScene { id }
            }
            sequences {
              ...HotspotSequence
            }
          }
        }
      }
      ... on VodRef {
        formats {
          id
          formatDescription
          isPrimary
          createdAt
          media {
            id
            projection
            mimeType
          }
          video {
            ...VideoInformationData
          }
        }
        primaryFormat {
          video {
            ...VideoInformationData
          }
        }
      }
      ... on StreamRef {
        cameras {
          video {
            ...VideoInformationData
          }
        }
      }
      ... on AdRef {
        ad {
          __typename
          id
          title
          description
        }
      }
    }
    primaryLanguage {
      ...LanguageData
    }
    languages {
      ...LanguageData
    }
    access
    category
    hash
    allowDownloads
    allowSharing
    createdAt
    id
    type
    __typename
    access
    collection {
      id
    }
    geoRestrictions {
      allowOnly
      deny
    }
    appRestrictions {
      allowOnly {
        id
        name
      }
      deny {
        id
        name
      }
    }
    availability {
      start
      end
    }
    preRollAds {
      id
      media { id }
      title
      description
    }
    postRollAds {
      id
      media { id }
      title
      description
    }
    art {
      tile {
        path
        url
      }
      key {
        path
        url
      }
      thumbnails {
        asset {
          url
        }
      }
    }
  }
}
${fragments.parentData}
${fragments.videoInformation}
${fragments.contentInternationalization}
${fragments.hotspotSequence}`;

// anaolgous to legacy 'getStory'
export const getContent = async contentId => {
  try {
    const res = await client.query({
      query: GET_CONTENT,
      variables: {
        // id and load_story_id (same value on legacy data) do not return content here :(
        // use a hard-coded id for venom-api to use
        contentId: '7806f0d7-4d45-4cd3-919f-2447ea6e8f11'
      }
    });

    return res;
    // console.log( 'res', res, contentId );
  } catch ( e ) {
    console.error( contentId, e );
  }
};
