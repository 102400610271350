

const getScrollWH = elem => elem instanceof Element
  ? [ elem.scrollWidth, elem.scrollHeight ]
  : [ 0, 0 ];

const getWH = elem => elem instanceof Element
  ? [ elem.innerWidth, elem.innerHeight ]
  : [ 0, 0 ];

const getTL = elem => {
  const clientRect = elem instanceof Element
        && elem.getBoundingClientRect();

  return clientRect
    ? [ elem.top, elem.left ]
    : [ 0, 0 ];
};

const getInputElemValue = elem =>
  elem instanceof HTMLVideoElement && elem.value;

const getInputElemIdValue = elemId =>
  getInputElemValue( document.getElementById( elemId ) );

const isVideoElem = elem =>
  elem instanceof HTMLVideoElement;

const isFocusedElem = ( elem, activeElem = document.activeElement ) =>
  elem instanceof Element && activeElem && elem.isEqualNode( activeElem );

const isFocusedElemId = elemId =>
  isFocusedElem( document.getElementById( elemId ) );

// if the element at elemId has focus, return its value,
// else return default value
const getFocusedInputElemIdValue = ( elemId, defaultValue ) =>
  isFocusedElemId( elemId )
    ? getInputElemIdValue( elemId )
    : defaultValue;

export {
  isVideoElem,
  isFocusedElem,
  isFocusedElemId,
  getInputElemValue,
  getInputElemIdValue,
  getFocusedInputElemIdValue,
  getScrollWH,
  getWH,
  getTL
};
