import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { APPTYPE } from '../env.js';

import {
  getContentInteractiveSequence,
  getMediaProjection,
  getContentStartSceneId
} from '../utils/contentUtils';

import {
  ContentTypeEnum
} from '../enums';

import {
  analyticsHotspotEngaged
} from '../analytics';

// this file used by BOTH player AND editor

export const PANELS = {
  PANELSTATS: 'statsPanel',
  PANELCAMERAS: 'camerasPanel'
};

export const defaultLocalIVE = {
  iveState: {
    id: 'ivestate',
    __typename: 'IVEState',

    isPreviewMode: false,
    isInitialized: false,
    isOverlayVisible: false,
    isHotspotDrawMode: false,
    isScenePanelOpen: false,
    isDisabledUI: false,
    activePanel: false,

    selectedHotspotId: '',
    selectedSequenceId: '',
    selectedStoryId: '',
    selectedSceneId: '',
    selectedCameraId: '',
    selectedProjection: '',

    canvasType: APPTYPE === 'editor' ? 'rectangle' : 'projection'
  }
};

export const GET_IVE_STATE = gql`{
  iveState @client {
    id

    isPreviewMode
    isInitialized
    isOverlayVisible
    isHotspotDrawMode
    isScenePanelOpen
    isDisabledUI
    activePanel

    selectedHotspotId
    selectedSequenceId
    selectedStoryId
    selectedSceneId
    selectedCameraId
    selectedProjection

    canvasType
  }
}`;

export const SET_IVE_STATE = gql`
mutation SetIVEState( $input: SetIVEInput! ) {
  iveState( input: $input ) @client
}`;

export default () => {
  const [ iveMutation ] = useMutation( SET_IVE_STATE );
  const { iveState = {} } = useQuery( GET_IVE_STATE ).data || defaultLocalIVE;

  // pre-process/initialize scene data
  const initializeContentInteractive = ( content, media ) => iveMutation({
    variables: {
      input: {
        isInitialized: true,
        selectedProjection: getMediaProjection( media ),
        selectedSceneId: getContentStartSceneId( content ),
        selectedStoryId: content.id
      }
    }
  });

  const initializeContentVOD = ( content, media ) => {
    iveMutation({
      variables: {
        input: {
          isInitialized: true,
          selectedProjection: getMediaProjection( media )
        }
      }
    });
  };

  const initializeContentStream = ( content, media ) => {
    iveMutation({
      variables: {
        input: {
          isInitialized: true,
          selectedProjection: getMediaProjection( media )
        }
      }
    });
  };

  const initializeContent = ( content, media ) => {
    if ( content.type === ContentTypeEnum.INTERACTIVE ) {
      initializeContentInteractive( content, media );
    } else if ( content.type === ContentTypeEnum.VOD ) {
      initializeContentVOD( content, media );
    } else if ( content.type === ContentTypeEnum.STREAM ) {
      initializeContentStream( content, media );
    }
  };

  const iveScenePanelOpen = ( isScenePanelOpen = true ) => iveMutation({
    variables: {
      input: { isScenePanelOpen }
    }
  });

  const iveScenePanelToggle = () => {
    iveScenePanelOpen( !iveState.isScenePanelOpen );
  };

  const iveToggleCanvasType = canvasType => iveMutation({
    variables: {
      input: {
        canvasType: /^(rectangle|projection)$/.test( canvasType )
          ? canvasType
          : iveState.canvasType === 'rectangle' ? 'projection' : 'rectangle'
      }
    }
  });

  const iveTogglePreviewMode = isPreviewMode => iveMutation({
    variables: {
      input: {
        isPreviewMode: typeof isPreviewMode === 'boolean'
          ? isPreviewMode
          : !iveState.isPreviewMode
      }
    }
  });

  const iveSetSelectedSequenceId = selectedSequenceId => {
    iveMutation({
      variables: {
        input: {
          selectedSequenceId,
          selectedHotspotId: selectedSequenceId
        }
      }
    });
  };

  const iveSetSelectedSceneId = selectedSceneId => {
    iveMutation({
      variables: {
        input: {
          selectedSequenceId: null,
          selectedSceneId
        }
      }
    });
  };

  const iveSetSelectedCameraId = selectedCameraId => {
    iveMutation({
      variables: {
        input: {
          selectedCameraId
        }
      }
    });
  };

  const iveSetSelectedProjection = selectedProjection => {
    iveMutation({
      variables: {
        input: {
          selectedProjection
        }
      }
    });
  };

  const iveTogglePanelStats = isStatsPanel => {
    isStatsPanel = typeof isStatsPanel === 'boolean'
      ? isStatsPanel
      : iveState.activePanel !== PANELS.PANELSTATS;

    iveMutation({
      variables: {
        input: {
          activePanel: isStatsPanel ? PANELS.PANELSTATS : ''
        }
      }
    });
  };

  const iveTogglePanelCameras = isPanelCameras => {
    isPanelCameras = typeof isPanelCameras === 'boolean'
      ? isPanelCameras
      : iveState.activePanel !== PANELS.PANELCAMERAS;

    iveMutation({
      variables: {
        input: {
          activePanel: isPanelCameras ? PANELS.PANELCAMERAS : ''
        }
      }
    });
  };

  const iveSceneSequenceEngage = ( content, sceneId, hotspotId ) => {
    const sequence = getContentInteractiveSequence( content, sceneId, hotspotId );
    analyticsHotspotEngaged( content, hotspotId );

    if ( sequence ) {
      if ( sequence.destination ) {
        iveSetSelectedSceneId( sequence.destination.id );
      } else {
        console.error( 'sequence has no destination: ', sequence );
      }
    }
  };

  return {
    iveState,
    iveScenePanelToggle,
    iveSceneSequenceEngage,
    iveSetSelectedCameraId,
    iveSetSelectedSequenceId,
    iveSetSelectedProjection,
    iveSetSelectedSceneId,
    iveTogglePanelStats,
    iveTogglePanelCameras,
    iveToggleCanvasType,
    iveTogglePreviewMode,
    initializeContent,
    initializeContentVOD,
    initializeContentStream,
    initializeContentInteractive
  };
};
