import { getThumb, getDuration } from './mediaUtils';
import { getVolatileSceneId } from './volatileUtils';
import { isHotspotChanged } from './hotspotUtils';

const scenePostPlaybackTypes = [
  { value: 1, label: 'Go To Scene' },
  { value: 2, label: 'Loop' },
  { value: 3, label: 'Return To Previous Scene' },
  { value: 4, label: 'Do Nothing', selected: 'selected' }
];

//
// should be more strict
//
const isScene = scene =>
  scene && scene.id;

// true if both scenes are not scenes
const isSceneSame = ( scenea, sceneb ) =>
  isScene( sceneb )
    ? ( sceneb.id === ( scenea && scenea.id ) )
    : !isScene( scenea );

const getHotspots = scene =>
  scene.hotspots_dict;

const getSceneHotspot = ( scene, hotspotId ) =>
  isScene( scene ) && scene.hotspots_dict && scene.hotspots_dict[hotspotId];

const getSceneSequence = ( scene, uuid ) =>
  isScene( scene ) && scene.sequences.find( seq => seq.uuid === uuid );

const getSceneModifiedTS = ( scene, defaultTS ) =>
  ( isScene( scene ) && scene.modified_ts ) || defaultTS;

const getVideoSequence = seqArr => Array.isArray( seqArr )
  ? seqArr.find( seq => /video/i.test( seq.type ) )
  : null;

const getSceneMainVideoSequence = scene =>
  getVideoSequence( scene.sequences );

const getMediaSequence = seqArr => Array.isArray( seqArr )
  ? seqArr.find( seq => /video|advert/i.test( seq.type ) )
  : null;

const getSceneMediaSequence = scene =>
  getMediaSequence( scene.sequences );

// returns a video that is not an ad
const getSceneMainVideoInfo = scene => {
  const videoseq = scene && getSceneMainVideoSequence( scene );

  return ( videoseq && scene.media_dict )
    ? scene.media_dict[videoseq.uuid]
    : null;
};

// note: HLS or MP4 is totally different from something being a
// 'vod' vs a 'live' stream
//
// returns 'response.media_type' from /embed/?hash=:hash
// named 'getSceneEmbedType' to avoid confusion with 'getMediaType'
//
// seen to return these values: 'media', 'live', 'vod', 'story'
const getSceneEmbedType = scene =>
  scene && scene.embedType;

const isSceneLive = scene =>
  scene && scene.embedType === 'live';

const getSceneMedia = ( scene, defaultMedia = null ) => {
  const mediaseq = scene && getSceneMediaSequence( scene );

  return ( mediaseq && scene.media_dict )
    ? scene.media_dict[mediaseq.uuid] || defaultMedia
    : defaultMedia;
};

const getSceneLength = scene => getDuration( getSceneMedia( scene ) );

const getSceneVideoThumb = scene => {
  const videomedia = getSceneMainVideoInfo( scene );
  const videothumb = videomedia
    ? videomedia.tile_image_url
    : null;

  return videothumb || getThumb( videomedia );
};

// 1, 'Go To Scene'
// 2, 'Loop'
// 3, 'Return To Previous Scene'
// 4, 'Do Nothing', selected: 'selected
const getScenePostPlayback = ( scene, defaultPostPlayback = { id: 4 }) =>
  scene && scene.postplayback ? scene.postplayback : defaultPostPlayback;

const getScenePostPlaybackDestSceneId = ( scene, defaultSceneId ) =>
  getScenePostPlayback( scene ).dest_scene || defaultSceneId;

const getScenePostPlaybackType = ( scene, defaultType = 4 ) =>
  getScenePostPlayback( scene ).type || defaultType;

const isScenePostPlaybackLoop = scene =>
  getScenePostPlaybackType( scene ) === 2;

const getName = ( scene, defaultName ) =>
  scene && typeof scene.name === 'string'
    ? scene.name
    : defaultName;

const getSceneName = getName;

const hasScene = ( state, sceneid ) =>
  isScene( state.scenes[sceneid]);

const isSceneMounted = ( state, sceneId = getVolatileSceneId( state ) ) => {
  const scene = state.scenes[sceneId];

  return isScene( scene ) && scene.isMountComplete;
};

const isSceneModifiedNewer = ( newScene, oldScene ) =>
  getSceneModifiedTS( newScene, 0 ) > getSceneModifiedTS( oldScene, 0 );

const isSceneModifiedSame = ( newScene, oldScene ) =>
  getSceneModifiedTS( newScene, 0 ) === getSceneModifiedTS( oldScene, 0 );

const isSceneChanged = ( newScene, oldScene ) =>
  !isSceneModifiedSame( newScene, oldScene ) || !isSceneSame( newScene, oldScene );

const isSceneSequenceChanged = ( scene, newSeq, oldSeq ) =>
  newSeq !== oldSeq
        || isHotspotChanged(
          newSeq && getSceneHotspot( scene, newSeq.uuid ),
          oldSeq && getSceneHotspot( scene, oldSeq.uuid )
        );

const isSceneSequencesChanged = ( sceneA, sceneB ) => (
  sceneA.sequences_modified_ts !== sceneB.sequences_modified_ts
);

const isSceneHotspotsChanged = ( sceneA, sceneB ) => (
  sceneA.hotspots_modified_ts !== sceneB.hotspots_modified_ts
);

const getSceneProjectionState = scene =>
  scene && scene.projectionState;

const getSceneProjectionType = getSceneProjectionState;

const getHotspot = getSceneHotspot;

export {
  scenePostPlaybackTypes,
  getHotspots,
  getHotspot,
  getSceneHotspot,
  getSceneSequence,
  getName,
  getScenePostPlayback,
  getScenePostPlaybackDestSceneId,
  getScenePostPlaybackType,
  getSceneProjectionState,
  getSceneProjectionType,
  getSceneMainVideoSequence,
  getSceneMainVideoInfo,
  getSceneMedia,
  getSceneMediaSequence,
  getSceneVideoThumb,
  getSceneEmbedType,
  getSceneLength,
  getVideoSequence,
  getSceneName,
  hasScene,
  isScene,
  isSceneLive,
  isSceneSame,
  isSceneMounted,
  isSceneModifiedNewer,
  isSceneModifiedSame,
  isSceneChanged,
  isScenePostPlaybackLoop,
  isSceneSequenceChanged,
  isSceneSequencesChanged,
  isSceneHotspotsChanged
};
