import {
  overlayActions,
  typeActions
} from './action-overlays';

export { overlayActions as OVERLAYACTIONS };
export { typeActions as TYPEACTIONS };

export const ADD_HOTSPOT = 'ADD_HOTSPOT';
export const DELETE_HOTSPOT = 'DELETE_HOTSPOT';
export const LOAD_HOTSPOTS = 'LOAD_HOTSPOTS';

export const WINDOW_BEFORE_UNLOAD = 'WINDOW_BEFORE_UNLOAD';
export const SELECT_HOTSPOT = 'SELECT_HOTSPOT';
export const SELECT_SEQUENCE = 'SELECT_SEQUENCE';
export const TOGGLE_PLAYING = 'TOGGLE_PLAYING';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_VOLUME = 'TOGGLE_VOLUME';
export const MEDIA_ENDED = 'MEDIA_ENDED';
export const MEDIA_STARTED = 'MEDIA_STARTED';
export const MEDIA_SEEK = 'MEDIA_SEEK';
export const MEDIA_BUFFER = 'MEDIA_BUFFER';
export const MEDIA_BUFFEREND = 'MEDIA_BUFFEREND';
export const SET_VIDEO_PROGRESS = 'SET_VIDEO_PROGRESS';
export const SET_VIDEO_TIME = 'SET_VIDEO_TIME';
export const SET_ZOOM = 'SET_ZOOM';
export const TOGGLE_PREVIEW_MODE = 'TOGGLE_PREVIEW_MODE';
export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const IS_VIDEO_REQ_LOADING = 'IS_VIDEO_REQ_LOADING';
export const IS_VIDEO_LOADED = 'IS_VIDEO_LOADED';
export const VIDEO_IS_RECOVERING = 'VIDEO_IS_RECOVERING';
export const VIDEO_IS_READY = 'VIDEO_IS_READY';
export const VIDEO_PLAY = 'VIDEO_PLAY';
export const VIDEO_PAUSE = 'VIDEO_PAUSE';

// hls playlists are parsed from master playlist m3u8,
// mp4 playlists are parsed locally using media data
//
// 'start' is called for all playlist constructions,
// 'local' or through a request to a master hls playlist
//
export const PLAYLIST_GET_START = 'PLAYLIST_GET_START';
export const PLAYLIST_GET_LOCAL = 'PLAYLIST_GET_LOCAL';

// moment actions
export const START_HOTSPOT = 'START_HOTSPOT';
export const END_HOTSPOT = 'END_HOTSPOT';
export const HOTSPOT_ENGAGE = 'HOTSPOT_ENGAGE';
export const HOTSPOT_RAYOVER = 'HOTSPOT_RAYOVER';

export const SOUNDS_END = 'SOUNDS_END';
export const SOUNDS_REPLAY = 'SOUNDS_REPLAY';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_SHUT = 'MODAL_SHUT';

export const VRMODE_END = 'VRMODE_END';
export const VRMODE_START = 'VRMODE_START';
export const VRMODE_TOGGLE = 'VRMODE_TOGGLE';

export const HEATMODE_END = 'HEATMODE_END';
export const HEATMODE_START = 'HEATMODE_START';
export const HEATMODE_TOGGLE = 'HEATMODE_TOGGLE';

export const CANVAS_TOGGLE = 'CANVAS_TOGGLE';

export const FULLSCREEN_END = 'FULLSCREEN_END';
export const FULLSCREEN_START = 'FULLSCREEN_START';
export const FULLSCREEN_TOGGLE = 'FULLSCREEN_TOGGLE';

export const QUALITY_SET_ID = 'QUALITY_SET_ID';
export const PROJECTION_SET_ID = 'PROJECTION_SET_ID';

export const DRAWMODE_END = 'DRAWMODE_END';
export const DRAWMODE_START = 'DRAWMODE_START';
export const DRAWMODE_TOGGLE = 'DRAWMODE_TOGGLE';
export const AD_ENGAGE = 'AD_ENGAGE';
export const AD_DISMISSED = 'AD_DISMISSED';
export const AD_PRESENTING = 'AD_PRESENTING';
export const AD_PRESENTINGDETAILS = 'AD_PRESENTINGDETAILS';
export const AD_FINISHED = 'AD_FINISHED';

export const CAMERA_SET_ROTATION = 'CAMERA_SET_ROTATION';

export const SCENE_UP = 'SCENE_UP';
export const SCENE_DOWN = 'SCENE_DOWN';
export const SCENE_SELECT = 'SCENE_SELECT';
export const SCENE_DELETE = 'SCENE_DELETE';
export const SCENE_CREATE = 'SCENE_CREATE';
export const SCENE_SET_VIDEO = 'SCENE_SET_VIDEO';
export const SCENE_CHANGED = 'SCENE_CHANGED';

export const UPLOAD_IS_FINISHED = 'UPLOAD_IS_FINISHED';
export const UPLOAD_IS_ERROR = 'UPLOAD_IS_ERROR';
export const UPDATE_SAVE_INDICATOR = 'UPDATE_SAVE_INDICATOR';
export const DELETE_CONFIRMATION_MODAL_IS_OPEN = 'DELETE_CONFIRMATION_MODAL_IS_OPEN';
export const UPLOAD = 'UPLOAD';
// toast actions
export const TOAST_SET_ID = 'TOAST_SET_ID';
export const CLEAR_SHOW_TOAST = 'CLEAR_SHOW_TOAST';
export const CLEAR_CLOSE_TOAST_ID = 'CLEAR_CLOSE_TOAST_ID';
export const ADD_ERROR_TOAST = 'ADD_ERROR_TOAST';

export const PANEL_HEIGHT = 'PANEL_HEIGHT';
export const PANEL_MOVE = 'PANEL_MOVE';
export const TOGGLE_SCENE_PANEL = 'TOGGLE_SCENE_PANEL';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
