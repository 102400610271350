// return a seuqnce of unknown origin w/
// predictably formatted sequence
//
// be careful for neither startTime nor start
//
const getSequenceAdapted = seq => ({
  start: typeof seq.startTime === 'number'
    ? seq.startTime : seq.start,
  end: typeof seq.endTime === 'number'
    ? seq.endTime : seq.end,
  dest_scene: seq.dest_scene
});

const getSequenceFromMediaVideo = video => ({
  name: video.title,
  start: 0,
  end: parseInt( video.duration, 10 ),
  isactive: true,
  type: 'video',
  uuid: video.id
});

const setSequenceListVideoSeq = ( seqArr, seq ) => {
  seqArr = seqArr.filter( s => s.type !== 'video' );
  seqArr.push( seq );

  return seqArr;
};

const isSequenceHotspot = seq => seq.type === 'actionportal';

export {
  setSequenceListVideoSeq,
  getSequenceFromMediaVideo,
  getSequenceAdapted,
  isSequenceHotspot
};
