import {
  HOTSPOT_RAYOVER,
  HOTSPOT_ENGAGE,
  SOUNDS_END,
  TYPEACTIONS
} from '../actions/ActionTypes';

export const soundsDefault = {
  allsounds: {},

  // scene sounds are always empty
  // until someone adds action and filter
  // to define scenesounds from all sounds
  scenesounds: {}
};


// helps to avoid writing things like this...
//
// state = {
//     ...state,
//     sounds : {
//         ...state.sounds,
//         [uuid] : { uuidstate }
//     }
// };
//
// instead use this...
//
// assignSounds( state, {
//     uuid : { uuidstate }
// })
export const assignSounds = ( state, obj ) => ({
  ...state,
  sounds: { ...state.sounds, ...obj }
});

//
// assignSoundsItem( state, itemId, { itemstate });
//
export const assignSoundItem = ( state, itemId, obj ) => ({
  ...state,
  sounds: {
    ...state.sounds,
    [itemId]: { ...state.sounds[itemId], ...obj }
  }
});

// loops through media_dict to return filtered list
// of audio items only for the entire story
//
// ex,
//
// state.sounds = {
//     1111: {
//         playing: false,
//         uuid: 1111,
//         src: '/223[kb]lo_vibey.wav.mp3'
//     },
//     2222: {
//         playing: false,
//         uuid: 2222,
//         src: '/35[kb]Plink.wav.mp3'
//     }
// }
//
export const constructedStoryAudio = ( state, mediaLibrary ) => assignSounds( state, {
  allsounds: mediaLibrary.media_items.reduce( ( sounds, item ) => {
    if ( /audio\//.test( item.type ) ) {
      sounds[item.id] = {
        ...item,
        src: item.file_url,
        playing: false,
        uuid: item.id
      };
    }

    return sounds;
  }, {})
});

// return full `audio` namespace from hotspot,
//
// hotspot.audio = {click: null, mouseOver: null}
//
// ex,
//
//   {click: null, mouseOver: null}
//
export const getHotspotAudioSpec = ( state, hotspotId ) => {
  const hotspotInfo = state.hotspots[hotspotId];

  return hotspotInfo && hotspotInfo.audio;
};

// return full `audio` namespace from hotspot,
//
// hotspot.audio = {click: null, mouseOver: null}
//
// ex,
//
//   null
//
export const getHotspotAudioEventId = ( state, hotspotId, eventType ) => {
  const hotspotSpec = getHotspotAudioSpec( state, hotspotId );

  return hotspotSpec && hotspotSpec[eventType];
};

// return full `audio` namespace from hotspot,
//
// hotspot.audio = {click: 1234, mouseOver: null}
//
// ex,
//
//   { ...media_dict[1234] }
//
export const getHotspotAudioSpecMedia = ( state, hotspotId, eventType ) => {
  const refId = getHotspotAudioEventId( state, hotspotId, eventType );

  return state.sounds[refId];
};

export const hotspotRayover = ( state, hotspotId ) => {
  const rayoverMedia = getHotspotAudioSpecMedia( state, hotspotId, 'mouseOver' );

  // if ( !rayoverMedia ) { // for test... send a canned media
  //      rayoverMedia = state.sounds[1111];
  // }

  if ( rayoverMedia ) {
    state = assignSoundItem( state, rayoverMedia.uuid, {
      playing: Date.now()
    });
  }

  return state;
};

export const hotspotEngage = ( state, sceneId, hotspotId ) => {
  const clickMedia = getHotspotAudioSpecMedia( state, hotspotId, 'click' );

  // if ( !clickMedia ) { // for test... send a canned media
  //      clickMedia = state.sounds[1111];
  // }

  if ( clickMedia ) {
    state = assignSoundItem( state, clickMedia.uuid, {
      playing: Date.now()
    });
  }

  return state;
};

export const soundsEnd = ( state, soundId ) =>
  assignSoundItem( state, soundId, {
    playing: false
  });

export const soundsReducer = ( state, action ) => {
  switch ( action.type ) {
  case TYPEACTIONS.MEDIA_GET.REQ_SUCCESS:
    return ( action.res && action.res.media_folder )
      ? constructedStoryAudio( state, action.res.media_folder, action.res )
      : state;

  case HOTSPOT_RAYOVER:
    return hotspotRayover( state, action.id );

  case HOTSPOT_ENGAGE:
    return hotspotEngage( state, action.sceneId, action.hotspotId );

  case SOUNDS_END:
    return soundsEnd( state, action.id );

  default:
    return state;
  }
};
