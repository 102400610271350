import amplitude from 'amplitude-js';
import uuidv4 from 'uuid-v4';

import {
  getVolatileScene
} from '../utils/volatileUtils';

import {
  getSceneMedia,
  getSceneEmbedType
} from '../utils/sceneUtils';

import {
  isMediaAd,
  isMediaAdVideo
} from '../utils/mediaUtils';

import {
  TYPEACTIONS,
  VRMODE_END,
  VRMODE_START,
  VRMODE_TOGGLE,
  MEDIA_STARTED,
  MEDIA_ENDED,
  HOTSPOT_ENGAGE,
  AD_DISMISSED,
  AD_ENGAGE
} from '../actions/ActionTypes';

import { APPTYPE, VERSION } from '../env.js';

// Based on analytics taxonomy https://docs.google.com/spreadsheets/d/1PkLCncAcmmq4_H5h0oul01t_-MaGNWQG8TSrEQMQ_sU/edit?pli=1#gid=2039533994
// version 4 (supported features)
//
// note: web does not include events for
//
//   App-launch, App-background, Content-download*
//
const EV_CONTENT_PLAYERSTARTED = 'Content-playerStarted';
const EV_CONTENT_PLAYEREXITED = 'Content-playerExited';
const EV_CONTENT_VIEWITEMDETAILS = 'Content-viewItemDetails';
const EV_CONTENTCAMERA_PLAYERSTARTED = 'ContentCamera-playerStarted';
const EV_CONTENTCAMERA_PLAYERFINISHED = 'ContentCamera-playerFinished';
const EV_APP_HEADSETSTARTED = 'App-headsetStarted';
const EV_APP_HEADSETFINISHED = 'App-headsetFinished';
const EV_AD_PLAYERSTARTED = 'Ad-playerStarted';
const EV_AD_PLAYERFINISHED = 'Ad-playerFinished';
const EV_AD_CONVERSION = 'Ad-conversion';
const EV_AD_DISMISSED = 'Ad-dismissed';
const EV_INTERACTIVE_PLAYERSTARTED = 'Interactive-playerStarted';
const EV_INTERACTIVE_PLAYERFINISHED = 'Interactive-playerFinished';
const EV_INTERACTIVE_HOTSPOTCLICKED = 'Interactive-hotspotClicked';
const EV_INTERACTIVE_SCENESTARTED = 'Interactive-sceneStarted';
const EV_INTERACTIVE_SCENEFINISHED = 'Interactive-sceneFinished';

const amplitudeAnalyticsDefault = {
  isLocalLog: /isanalyticslog=true/gi.test( window.location.search ),
  // different key may be provided in app querystring
  // below key is the DD api key
  amp_apikey: 'e5e2d2853fa4709451135b7fda5d8f99',
  isanalytics: APPTYPE === 'player' && !/isanalytics=false/gi.test( window.location.search )
};

const isAnalyticsEnabled = state =>
  state.amplitudeAnalytics.isanalytics;

const localLog = ( state, msgStr ) => {
  if ( state.amplitudeAnalytics.isLocalLog ) {
    console.log( `[...] amplitudeAnalytics: ${msgStr}` );
  }
};

const eventLog = ( state, eventType, eventProperties ) => {
  localLog( state, [ '',
    ` type: ${eventType},`,
    ` props: ${JSON.stringify( eventProperties, null, '  ' )}`
  ].join( '\n' ) );
};

// helps to avoid writing things like this...
//
// state = {
//     ...state,
//     amplitudeAnalytics : {
//         ...state.amplitudeAnalytics,
//         playerStartedAt : Date.now()
//     }
// };
//
// instead use this...
//
// assignAmp( state, {
//     playerStartedAt : Date.now()
// })
const assignAmp = ( state, obj ) => ({
  ...state,
  amplitudeAnalytics: { ...state.amplitudeAnalytics, ...obj }
});

const setStartTime = ( state, prop ) =>
  assignAmp( state, {
    [`${prop}startts`]: Date.now()
  });

const isStartTime = ( state, prop ) =>
  typeof state.amplitudeAnalytics[`${prop}startts`] === 'number';

const getElapsedTime = ( state, prop ) =>
  ( Date.now() - state.amplitudeAnalytics[`${prop}startts`]) / 1000;

// analytics service requires different property
// name for media id, depending upon media type
//
// return the property name for the given media
const getMediaPropName = ( state, sceneId ) => {
  const scene = getVolatileScene( state, sceneId );
  const media = getSceneMedia( scene );
  const sceneEmbedType = getSceneEmbedType( scene );
  let propname = '';

  if ( isMediaAd( media ) ) {
    propname = 'ad_id';
  } else if ( sceneEmbedType === 'story' ) {
    propname = 'story_id';
  } else if ( sceneEmbedType === 'live' ) {
    propname = 'stream_id';
  } else if ( sceneEmbedType === 'vod' || sceneEmbedType === 'media' ) {
    propname = 'media_id';
  }

  if ( !propname ) {
    console.error( 'media propname not found' );
  }
};

const analyticsInit = ( state, embedResData ) => {
  const { amplitudeAnalytics } = state;
  const instance = amplitude.getInstance();
  const app_id = embedResData.app_id || uuidv4();

  localLog( state, 'initializing' );
  instance.init( amplitudeAnalytics.analytics_id || amplitudeAnalytics.amp_apikey );

  // app_id, use app_id from /embed response else use hardcoded uuid...
  instance.setUserProperties({
    app: 'html5',
    app_id,
    app_version: VERSION,
    test_mode: embedResData.authorized ? '1' : '0' // '1' if logged in to CMS
  });

  instance.setVersionName( VERSION );

  return state;
};

const emitEvent = ( state, eventType, eventProperties ) => {
  eventLog( state, eventType, eventProperties );

  amplitude.getInstance().logEvent( eventType, eventProperties );
};

// 13. **Ad-playerStarted** Must fire in any of these circumstances:
//
//    1. A pre-roll or post-roll ad starts (MAY be non-video ads)
//    2. A user initiates a video playback by clicking a video ad on an
//       "explore" page
//    3. A user intiates a video playback by clicking play on the details page
//       of a video ad
//
//    properties
//
//    * *ad_id* [string-uuid] (id of the ad viewed)
//    * *ad_item_id* optional [string-uuid] (id of the AdItem containing the Ad)
//    * *type* [string] See note #2
//    * See Note #1
//
//    ad_id -> collection.ads[].ad.id OR collction.ads[].ad_id
//    ad_item_id -> collection.ads[].id
//
//    Valid values:
//
//      * pre_roll
//      * post_roll
//      * user_initiated
//      * user_initiated_from_details
//
const adStarted = ( state, media ) => {
  emitEvent( state, EV_AD_PLAYERSTARTED, {
    ad_id: media.id,
    type: media.containertype
  });

  return setStartTime( state, `ad-${media.id}` );
};

// 14. **Ad-playerFinished** Must fire when any of:
//
//    1. A pre-roll or post-roll ad finishes and returns to the main content
//    2. An ad initiated by (2) or (3) above returns to the previous state (e.g. details page or explore page)
//    3. An Ad-conversion event is fired after (2) or (3) above.
//
//    properties
//
//    * *ad_id* [string-uuid] (id of the ad viewed)
//    * *ad_item_id* optional [string-uuid] (id of the AdItem containing the Ad)
//    * *type* [string] same as Ad-playerStarted type
//    * *seconds_viewed* [float] (see note #1)
//
//    Seconds that the ad was viewed before it expired or was dismissed by the user. If the video stops, or the user converts, either count.
//
const adPlayerFinished = ( state, media ) => {
  if ( !isStartTime( state, `ad-${media.id}` ) ) {
    localLog( state, 'Missing analytics_adStartedAt - was adStarted not called?' );
    return state;
  }

  emitEvent( state, EV_AD_PLAYERFINISHED, {
    ad_id: media.id,
    type: media.containertype,
    seconds_viewed: getElapsedTime( state, `ad-${media.id}` )
  });

  return state;
};

// 3. **Content-viewItemDetails** Web: Fires when the page loads.
//    iOS & Android: Fires when the user navigates to a media or stream
//      details/description page.
//    GearVR: Fires when the details/description of the content is viewed
//      (when they click a video and the details expand out).
//
//    DONT fire this event if a user clicks, for example, on a featured item
//    on the homepage that immediately starts playing the content ie bypassing
//    the details/description page/view.
//
//     * *stream_id* [string-uuid] (if stream)
//     * *media_id* [string-uuid] (if vod)
//
const pageLoaded = ( state, embedResData ) => {
  // Taxonomy: "Web: Fires when the page loads."
  emitEvent( state, EV_CONTENT_VIEWITEMDETAILS, {
    [embedResData.media_type]: embedResData.id
  });

  return state;
};

// 20. **Interactive-playerStarted** Fired when interactive starts playback
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * See Note #1
//
//   story_id -> collection.stories[].story.id OR collection.stories[].story_id
//   story_item_id -> collection.stories[].id
//
const storyStarted = ( state, story /* , media, scene */ ) => {
  if ( isStartTime( state, 'story' ) ) {
    return state;
  }

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_INTERACTIVE_PLAYERSTARTED, {
    story_id: `unknown${story.id}`,
    story_item_id: 'unknown'
  });

  return setStartTime( state, 'story' );
};

// 23. **Interactive-sceneStarted** Fired when a scene is loaded
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *scene_id* [string-uuid] (id of the scene loaded)
//
const sceneStarted = ( state, story, media, scene ) => {
  if ( isStartTime( state, `scene-${scene.id}` ) ) {
    return state;
  }

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_INTERACTIVE_SCENESTARTED, {
    story_id: `unknown${story.id}`,
    story_item_id: 'unknown',
    scene_id: scene.id
  });

  return setStartTime( state, `scene-${scene.id}` );
};

// 24. **Interactive-sceneFinished** Fired when a scene is switched or exited
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *scene_id* [string-uuid] (id of the scene loaded)
//   * *seconds_viewed* [float] (see note #1)
//
//   Seconds that the scene was viewed before switching to another scene or
//   the scene ended on its own.
//
const sceneExited = ( state, story, media, scene ) => {
  if ( isStartTime( state, `scene-${scene.id}` ) ) {
    return state;
  }

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_INTERACTIVE_SCENEFINISHED, {
    story_id: `unknown${story.id}`,
    story_item_id: 'unknown',
    scene_id: scene.id,
    seconds_viewed: getElapsedTime( state, `scene-${scene.id}` )
  });

  return state;
};

// 21. **Interactive-playerFinished** Fired when user finishes, exits, or
//     ends the interactive experience
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//
const storyExited = ( state, story /* , media, scene */ ) => {
  if ( isStartTime( state, 'story' ) ) {
    return state;
  }

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_INTERACTIVE_PLAYERFINISHED, {
    story_id: `unknown${story.id}`,
    story_item_id: 'unknown'
  });

  return state;
};


// 1. **Content-playerStarted** Fires when the user first starts playing a
//    stream or a vod. Not an ad or an interactive video. This event must not
//    fire when a new camera of a stream has started. Only when the stream has
//    first started should this event fire.
//
//     * *stream_id* [string-uuid] (if stream)
//     * *media_id* [string-uuid] (if vod)
//     * _source_ [string] (see note #1)
//
//    Where was the user before this item started playing.
//    'homepage' - If the content started playing immediately from the homepage
//      and bypassed viewing of the details page.
//    'details_page' - If the user was viewing the media or live stream details
//      page.
//    'Previous'/'Next' User pressed previous or next button in player to start
//      playing this content
const playerStarted = ( state, sceneId /* , scene */ ) => {
  const scene = getVolatileScene( state, sceneId );
  const media = getSceneMedia( scene );
  const source = 'details_page';

  if ( isStartTime( state, 'player' ) ) {
    return state;
  }

  emitEvent( state, EV_CONTENT_PLAYERSTARTED, {
    [getMediaPropName( state, sceneId )]: media.id,
    source
  });

  return setStartTime( state, 'player' );
};

// 7. **ContentCamera-playerStarted** Fired when a user starts streaming a
//    camera. For live streams with multiple cameras.
//
//    * *stream_id* [string-uuid] (ID of the stream)
//    * *camera_id* [string-uuid] (ID of the camera)
//    * *from_camera_id* [string-uuid] (see note #1)
//
//     "The ID of the camera this camera is being loaded from 'initial'
//      - This camera was the initial camera loaded when starting the
//      stream. eg user started playing camera xyz from camera abc,
//      from_camera_id would be 'abc'"
//
const cameraStarted = ( state, media, scene ) => {
  emitEvent( state, EV_CONTENTCAMERA_PLAYERSTARTED, {
    stream_id: media.id,
    camera_id: scene.camerastreamid,
    from_camera_id: state.previousScenes[state.previousScenes.length - 1]
  });

  return state;
};

// 8. **ContentCamera-playerFinished** Fired when a user switches away from this
//    camera to another camera that is a part of this content, or exits the
//    content entirely
//
//      * *stream_id* [string-uuid] (ID of the stream)
//      * *camera_id* [string-uuid] (ID of the camera)
//      * *seconds_viewed* [float] (see note #1)
//      * *player_exited* [string - "0" or "1"] (see note #2)
//
const cameraFinished = ( state, media, scene, isexit = false ) => {
  emitEvent( state, EV_CONTENTCAMERA_PLAYERFINISHED, {
    stream_id: media.id,
    camera_id: scene.camerastreamid,
    seconds_viewed: state.amplitudeAnalytics.seconds_viewed,
    player_exited: Number( isexit )
  });

  return state;
};

// 2. **Content-playerExited** Fires when the video finishes playing, whether
//    by user interaction or not. If the user presses back, or navigates away,
//    or the content finishes playing.
//
//     * *stream_id* [string-uuid] (if stream)
//     * *media_id* [string-uuid] (if vod)
//     * *seconds_viewed* [float] (see note #1)
//
//    Total time in seconds the user spent watching the content (regardless of
//    camera switching, if any) ie (playerExited timestamp - playerStarted
//    timestamp ). Pausing should pause the timer. Going back should stop the
//    timer. Content finishing should stop the timer. Putting the app in the
//    background should pause the timer. Pressing play (after a pause) should
//    resume a paused timer.
const playerExited = ( state, sceneId /* , scene */ ) => {
  const scene = getVolatileScene( state, sceneId );
  const media = getSceneMedia( scene );
  const mediaid = media && media.id;

  if ( !isStartTime( state, 'player' ) ) {
    return state;
  }

  emitEvent( state, EV_CONTENT_PLAYEREXITED, {
    [getMediaPropName( state, sceneId )]: mediaid,
    seconds_viewed: getElapsedTime( state, 'player' )
  });

  return state;
};

// 22. **Interactive-hotspotClicked** Fired when an interactive hotspot is acted upon
//
//   * *story_id* [string-uuid] (id of the story)
//   * *story_item_id* [string-uuid] (id of the story container for the Story)
//   * *hotspot_id* [string-uuid] (id of the hotspot interacted with)
//
const hotspotEngaged = ( state, hotspot_id ) => {
  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_INTERACTIVE_HOTSPOTCLICKED, {
    story_id: `unknown${state.story.id}`,
    story_item_id: 'unknown',
    hotspot_id
  });

  return state;
};

// 15. **Ad-conversion** Fired when the user visits the URL associated with
//     an ad. This may be a deep link or a website URL. This may be from the
//     player, or from the "details" page of an ad.
//
//     * *ad_id* [string-uuid] (id of the ad interacted with)
//     * *ad_item_id* optional [string-uuid] (id of the AdItem containing the
//         Ad)
//     * *seconds_viewed* [float] Seconds that the ad or details page was
//         viewed before before the conversion.
//
//     ad_item_id may be left out for pre/post roll ads, as the user did not
//     opt in to these.
//
const adEngaged = ( state, media ) => {
  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_AD_CONVERSION, {
    ad_id: media.id,
    ad_item_id: `unkown${media.id}`,
    seconds_viewed: getElapsedTime( state, `ad-${media.id}` )
  });

  return state;
};

// 17. **Ad-dismissed** Fires when a details page is closed, or a video without
//     a details page is closed, or an interruptable pre-roll or post-roll video
//     is dismissed, or a video of an ad with a details page is exited early (in
//     addition to Ad-playerFinished)
//
//    * *ad_id* [string-uuid] (id of the ad interacted with)
//    * *ad_item_id* [string-uuid] (id of the AdItem containing the Ad)
//    * *seconds_viewed* [float] (See note #1)
//    * *type* [string] (details or video or link)
//
//    Seconds that the details page was viewed, or seconds that the video was
//    viewed, before being dismissed.
//
const adDismissed = ( state, sceneId /* , scene */ ) => {
  const scene = state.scenes[sceneId];
  const media = getSceneMedia( scene );

  // ad_item_id currently unknown to player
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_AD_DISMISSED, {
    ad_id: media.id,
    ad_item_id: `unkown${media.id}`,
    type: isMediaAdVideo( media ) ? 'video' : 'link',
    seconds_viewed: getElapsedTime( state, `ad-${media.id}` )
  });

  return state;
};

// 11. **App-headsetStarted** Fires when the app enters a headset mode, e.g. entering Cardboard mode
//
//    * *media_id* [string-uuid] (if happens during vod)
//    * *stream_id* [string-uuid] (if happens during stream)
//    * *story_id* [string-uuid] (if happens during interactive video)
//    * *ad_id* [string-uuid] (if happens during ad)
//
const headsetStarted = state => {
  const scene = getVolatileScene( state );
  const media = getSceneMedia( scene );

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_APP_HEADSETSTARTED, {
    [getMediaPropName( state )]: media.id
  });

  return setStartTime( state, 'headset' );
};

// 12. **App-headsetFinished** Fires when the user leaves a headset mode they started, e.g. leaving Cardboard
//
//    * *seconds_viewed* [float] (see note #1)

//    * *media_id* [string-uuid] (if happens during vod)
//    * *stream_id* [string-uuid] (if happens during stream)
//    * *story_id* [string-uuid] (if happens during interactive video)
//    * *ad_id* [string-uuid] (if happens during ad)
//
//    Total time in seconds that the user spent in headset mode.
//    The same rules as Content-playerExited apply for seconds_viewed
//
const headsetFinished = state => {
  const scene = getVolatileScene( state );
  const media = getSceneMedia( scene );

  // per andy story_id and story_item_id corresponding to server data
  // are currently not defined in story data send from the service :(
  emitEvent( state, EV_APP_HEADSETFINISHED, {
    [getMediaPropName( state )]: media.id,
    seconds_viewed: getElapsedTime( state, 'headset' )
  });

  return state;
};

//
// this h for testing multi cam, ?h=4ku232lewutfja64yozl&islive=true
//
const mediaStarted = ( state, sceneId ) => {
  const scene = state.scenes[sceneId];
  const media = getSceneMedia( scene );
  const sceneEmbedType = getSceneEmbedType( scene );

  if ( isMediaAd( media ) ) {
    state = adStarted( state, media, scene );
  } else if ( sceneEmbedType === 'story' ) {
    state = storyStarted( state, state.story, media, scene );
    state = sceneStarted( state, state.story, media, scene );
  } else if ( sceneEmbedType === 'live' ) {
    // for scenes constructed around cameras,
    //
    // * 'Content-playerStarted' when stream first starts
    // * 'ContentCamera-playerStarted' when switching cameras
    if ( state.amplitudeAnalytics.playerstarttts ) {
      state = cameraStarted( state, media, scene );
    } else {
      state = playerStarted( state, sceneId );
    }
  } else if ( sceneEmbedType === 'vod' || sceneEmbedType === 'media' ) {
    state = playerStarted( state, sceneId );
  }

  return state;
};

const mediaExited = ( state, sceneId ) => {
  const scene = state.scenes[sceneId];
  const media = getSceneMedia( scene );
  const sceneEmbedType = getSceneEmbedType( scene );

  if ( isMediaAd( media ) ) {
    state = adPlayerFinished( state, media, scene );
  } else if ( sceneEmbedType === 'story' ) {
    // to be modified, emits exit at media end but
    // should only exit when app exits
    state = storyExited( state, state.story, media, scene );
    state = sceneExited( state, state.story, media, scene );
  } else if ( sceneEmbedType === 'live' ) {
    if ( scene.camerastreamid ) {
      state = cameraFinished( state, media, scene );
    } else {
      state = playerExited( state, sceneId );
    }
  } else if ( sceneEmbedType === 'vod' || sceneEmbedType === 'media' ) {
    state = playerExited( state, sceneId );
  }

  return state;
};

// this reducer operates with the full 'data' state
// of the amp, that it may inter
const amplitudeAnalyticsReducer = ( state, action ) => {
  if ( !isAnalyticsEnabled( state ) ) {
    return state;
  }

  switch ( action.type ) {
  case TYPEACTIONS.VIDEO_GET.REQ_SUCCESS:
    return isAnalyticsEnabled( state )
      ? pageLoaded( analyticsInit( state, action.res ), action.res )
      : state;

  case MEDIA_STARTED:
    return mediaStarted( state, action.sceneId );

  case MEDIA_ENDED:
    return mediaExited( state, action.sceneId );

  case HOTSPOT_ENGAGE:
    return hotspotEngaged( state, action.id );

  case AD_ENGAGE:
    return adEngaged( state, action.ad );

  case AD_DISMISSED:
    return adDismissed( state, action.sceneId );

    // consider below if problem using media_ended|started
    // case SCENE_CHANGED:
    //     return sceneChanged( state, action.prevSceneId, action.nextSceneId );

  case VRMODE_START:
    return headsetStarted( state );

  case VRMODE_END:
    return headsetFinished( state );

  case VRMODE_TOGGLE:
    return state.isvrenabled
      ? headsetFinished( state )
      : headsetStarted( state );

  default:
    return state;
  }
};

export {
  amplitudeAnalyticsDefault,
  amplitudeAnalyticsReducer
};
