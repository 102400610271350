// service ad data come with properties of ad_type and ad_diaplay_type
//
// `ad_type`:
//
//      `Video Ad` | `Flat Graphic` | `Promoted`
//
// `ad_display_type`:
//
//      `Interstitial` | `MREC` | `Small Banner` | `Medium Banner`
//
//
// Video Ad are for <video>
// Flat Graphic are for <img>
// Promoted -- defunct (talk to Andy)

export const advertDefault = { };

export function getVideoAdvertDefault ( video, sceneId ) {
  return {
    id: video.id,
    hash: video.hash,
    key: getAdvertNSKey( video.id ),
    sceneId,
    ismerged: false
  };
}

// remove un-supported and un-specified ad_type, eg 'Promoted'
export function isAdvertSupported ( advert ) {
  return /video ad|flat graphic/i.test( advert.ad_type );
}

// containertype is 'pre_roll' or 'post_roll'
//
export function getAdvertDefault ( advert, containertype ) {
  advert.containertype = containertype;

  return advert;
}

export function getAdvertNSKey ( videoId, sceneId ) {
  return sceneId
    ? `${sceneId}-${videoId}`
    : videoId;
}
