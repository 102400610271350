import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useFetchBlob from '../hooks/useFetchBlob';

const PosterContainer = styled.div`
    pointer-events:none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
`;

const PosterImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export default function ImgPoster ( props ) {
  // The need for 'blob' was not well documented.
  // Certain platforms (iphone?) required images to be loaded as 'blob'
  const { result } = useFetchBlob( props.imgSrc );

  return (
    <PosterContainer>
      {result && (
        <PosterImage src={result} />
      )}
    </PosterContainer>
  );
}

ImgPoster.propTypes = {
  imgSrc: PropTypes.string
};
