const HELVETICA = 'Helvetica Neue, Helvetica, Arial, sans-serif';
const BG_CHARCOAL = '#242424';
const BG_LIGHTCHARCOAL = '#343434';
const BG_LIGHTERCHARCOAL = '#393939';
const BG_BLACK = '#000';

const BG_BACKGROUND = '#111';
const BG_FARGROUND = BG_CHARCOAL;
const BG_MIDGROUND = BG_LIGHTCHARCOAL;
const BG_FOREGROUND = BG_LIGHTERCHARCOAL;

const BORDER_DARKCHARCOAL = '#171515';

const FONT_LIGHTGREY = '#B3B2B2';
const FONT_DARKGREY = '#555';

// const COLOR_BRIGHT_ORANGE = '#ff7700';

export default {
  FontPrimary: HELVETICA,

  PanelBGColor: BG_LIGHTERCHARCOAL,
  PanelMGColor: BG_LIGHTCHARCOAL,
  PanelFGColor: BG_CHARCOAL,

  ScenePanelColorBorder: BORDER_DARKCHARCOAL,
  ScenePanelColorBackground: BG_CHARCOAL,
  ScenePanelColorPropLabel: FONT_DARKGREY,
  ScenePanelColorPropInput: FONT_LIGHTGREY,
  ScenePanelColorButton: BORDER_DARKCHARCOAL,
  ScenePanelColorPropSelect: BG_BLACK,

  BG_BACKGROUND,
  BG_FARGROUND,
  BG_MIDGROUND,
  BG_FOREGROUND
};
