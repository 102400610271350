import { combineReducers } from 'redux';
import data from './data';
import uipanels from './uipanels';

const rootReducer = combineReducers({
  data,
  uipanels
});

export default rootReducer;
