import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BaseStyles = css`
    transition: 0.2s ease color, background-color;
    background: transparent;
    border: 0;
    outline: none;
    font-family: var(--ivy-font-family-primary);
    font-size: ${props => props.type === 'icon' ? 0 : '20px'};
    color: var(--ivy-input-label-color);
    fill: #bebebe;
    
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    background-color: ${props => props.isPressed
    ? 'rgba(0,0,0,0.2)'
    : 'transparent'};

    &.isPressed:not(.isRaised),
    &:hover {
        fill: #fff;
        color: #fff;
    }

    &:disabled {
        pointer-events: none;
    }

    &:disabled > * {
        opacity: 0.7;
    }

    & > svg {
        // show button title not svg title on mouseover
        flex-shrink: 0;
        /* pointer-events: none; */
    }

    & > svg + span {
        margin: 0 4px;
    }

    &.isRaised {
        color:#e4e4e4;
        fill:#e4e4e4;

        background: var(--ivy-button-raised-background-color);
        background-color: var(--ivy-button-raised-background-color);

        padding: 2px;
        margin: 4px;
        border: 1px solid #222;
        border-bottom: ${props => props.isPressed ? '1px' : '1.6px'} solid #222;
        border-radius: 4px;
    }

    &.isRaised > span {
        line-height: 25px;
        font-size: var(--ivy-input-label-font-size);
    }
    &.isRaised.isPressed,
    &.isRaised.isPressed:hover {
        background: var(--ivy-button-raised-pressed-background-color);
        background-color: var(--ivy-button-raised-pressed-background-color);
    }

    &.isRaised:hover {
        background: var(--ivy-button-raised-background-color-hover);
    }

    &.isRaised.isSolid {
        background: var(--ivy-button-solid-background-color);
    }

    min-width:0;
    max-width:100%

    & > span {
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.isWide > span,
    &.isWide > svg {
        padding: 0 10px;
    }
`;

const ButtonContainer = styled.button`
    ${BaseStyles}
`;

const LinkContainer = styled.a`
    ${BaseStyles}
    text-decoration: none;
`;

export const RaisedGroup = styled.div`
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
    min-width: 0;
    & > .isRaised:first-child:not(:last-child) {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > .isRaised.input-container:first-child:not(:last-child) .panel {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > .isRaised + .isRaised {
        border-left: 0;
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    & > .isRaised + .isRaised:not(:last-child) {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export default function BaseButtonIcon ( props ) {
  const { onClick, isPressed, isSolid, isEnabled, isRaised, isWide, title, children, type, disabled, target, href, className } = props;
  const Container = href ? LinkContainer : ButtonContainer;

  return (
    <Container
      href={href}
      target={target}
      className={[
        isPressed ? 'isPressed' : '',
        isRaised ? 'isRaised' : '',
        isSolid ? 'isSolid' : '',
        isWide ? 'isWide' : ''
      ].join( ' ' ) + ' ' + ( className || '' )}
      disabled={disabled && 'disabled'}
      isPressed={isPressed}
      onClick={() => onClick && onClick({ value: !isEnabled })}
      title={title || ( typeof children === 'string' ? children : '' )}
      type={type || 'icon'}
      isEnabled={/false/.test( isEnabled )}>
      {typeof children === 'string' ? (
        <span>{children}</span>
      ) : (
        children
      )}
    </Container>
  );
}

BaseButtonIcon.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  isRaised: PropTypes.bool,
  isSolid: PropTypes.bool,
  isWide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  isEnabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
};
