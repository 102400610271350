
const getUserOrganizations = user =>
  ( user && user.organizations ) || [];

const isUserOrganizationId = ( user, organizationId ) =>
  getUserOrganizations( user ).some( org => org.id === organizationId );

const isUserAdmin = user => user && user.role === 'Admin';

const isUserAuthorizedStory = ( user, story ) =>
  isUserAdmin( user ) || isUserOrganizationId( user, story.organization_id );

export {
  getUserOrganizations,
  isUserOrganizationId,
  isUserAdmin,
  isUserAuthorizedStory
};
