import { useQuery, useMutation } from '@apollo/client';
import ismobilejs from 'ismobilejs';
import gql from 'graphql-tag';
import { APPTYPE } from '../env.js';

import {
  getPrimaryQualityLevel,
  getLowestQualityLevel
} from '../utils/quality';

import {
  getCameraMediaOrVideo,
  getSceneVideoPrimary,
  getMediaMimeType,
  getMediaUrl
} from '../utils/contentUtils';

import {
  isMimeHls
} from '../utils/hls';

import {
  videolevelCreateFromMedia,
  videolevelsFromContentVOD,
  videolevelsFromPlaylist
} from '../utils/videolevel';

const ismobile = ismobilejs();

export const defaultLocalPlaylist = {
  playlistState: {
    id: 'playliststate',
    __typename: 'PlaylistState',

    qualityLevels: [],
    qualityLevel: null,
    qualityLevelStart: ( APPTYPE === 'editor' || ismobile.any ) ? 'lowest' : 'auto'
  }
};

export const GET_PLAYLIST_STATE = gql`{
    playlistState @client {
        id

        qualityLevels
        qualityLevel
        qualityLevelStart
    }
}`;

export const SET_PLAYLIST_STATE = gql`
mutation SetPlaylistState( $input: SetPlaylistInput! ) {
    playlistState( input: $input ) @client
}`;

/*
export const parsePlaylistLocal = ( state, sceneId, mediaId ) => {
    let scene = state.scenes[sceneId];
    let media = scene.media_dict[mediaId];
    let toasterror = null;
    let blockedInfo;
    let isReducedQuality = state.appSettings.startQuality === 'lowest';
    media = setQualityLevelsMP4( media, isReducedQuality );

    if ( media.resolution > 2000 && isReducedQuality ) {
        toasterror = toastMessageType.errorForecastDeviceQualityDifficulty;
    }

    blockedInfo = getBlockedInfo( media );
    if ( blockedInfo.shouldBlock ) {
        if ( blockedInfo.canRedirectToMobileApp && isBrandIconic( media ) ) {
            toasterror = toastMessageType.errorTRY_MOBILE_APP;
        } else {
            toasterror = blockedInfo.name;
        }
    }

    state = setSceneMountComplete( state, sceneId, !blockedInfo.shouldBlock );
    state = setQualityLevel( state, sceneId, mediaId, media.qualityLevel, media );

    if ( !isMediaQualityLevels( media ) ) {
        toasterror = toastMessageType.errorQualityLevelsNotFound;
    }

    console.log( 'set quality level (MP4)' );

    return {
        ...state,
        toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: toasterror })
    };
};
*/

export default function useLocalPlaylist () {
  const [ playlistMutation ] = useMutation( SET_PLAYLIST_STATE );
  const { playlistState = {} } = useQuery( GET_PLAYLIST_STATE ).data || defaultLocalPlaylist;

  const playlistSetLevels = ( qualityLevels, qualityLevel ) => {
    qualityLevel = qualityLevel || playlistState.qualityLevelStart === 'lowest'
      ? getLowestQualityLevel( qualityLevels )
      : ( getPrimaryQualityLevel( qualityLevels ) || qualityLevels[0]);

    if ( !qualityLevel ) {
      console.log( '[!!!] useLocalPlaylist: quality level not found' );
      return;
    }

    playlistMutation({
      variables: {
        input: {
          qualityLevel,
          qualityLevels
        }
      }
    });
  };

  const playlistSetQualityLevel = qualityLevel => playlistMutation({
    variables: {
      input: {
        qualityLevel
      }
    }
  });

  const playlistSetLevelsHLS = async mediaItem => {
    const response = await window.fetch( getMediaUrl( mediaItem ) );

    if ( response.text().then( text => {
      const qualityLevels = videolevelsFromPlaylist( mediaItem, text );

      playlistSetLevels( qualityLevels );
    }) );
  };

  const playlistSetContentVOD = contentVOD => {
    const qualityLevels = videolevelsFromContentVOD( playlistState, contentVOD );

    playlistSetLevels( qualityLevels );
  };

  const toggleQualityLevel = ( qualityLevelValue, startTime = 0 ) => {
    const { qualityLevels } = playlistState;
    const prevQualityLevel = playlistState.qualityLevel;
    const prevQualityLevelValue = prevQualityLevel && prevQualityLevel.value;

    if ( prevQualityLevelValue !== qualityLevelValue ) {
      const qualityLevel = qualityLevels
        .find( level => level.value === qualityLevelValue );

      if ( qualityLevel ) {
        playlistSetQualityLevel({
          ...qualityLevel,
          startTime
        });
      }
    }
  };

  const playlistSetContentStream = ( contentStream, camera ) => {
    playlistSetLevelsHLS( getCameraMediaOrVideo( camera ) );
  };

  const playlistSetContentInteractive = ( contentInteractive, scene ) => {
    const mediaItem = getSceneVideoPrimary( scene );

    if ( isMimeHls( getMediaMimeType( mediaItem ) ) ) {
      playlistSetLevelsHLS( mediaItem );
    } else {
      playlistSetLevels([ videolevelCreateFromMedia( mediaItem ) ]);
    }
  };

  return {
    playlistState,
    playlistSetContentVOD,
    playlistSetContentStream,
    playlistSetContentInteractive,
    playlistSetQualityLevel,

    toggleQualityLevel
  };
}


// https://cms.iconicengine.com/
//   organizations/5de9ed72-01a2-4e8b-9892-81cbe8e1921b/
//   publishing/010b1732-17ec-4c18-bd4e-50758baef580/
//   video/f204fe11-1e3a-4610-8d92-b45f815e9fbd/properties
//
// collection 010b1732-17ec-4c18-bd4e-50758baef580


// destination collection
//
// 729cf432-817b-4afd-8f56-2bb25ca5ee53
