import PropTypes from 'prop-types';
import scalewh from 'scalewh';
import * as THREE from 'three';

import CanvasThree from './CanvasThree';
import {
  getCanvasGLRenderer,
  setCanvasWidthHeight,
  getVideoTexture,
  getVideoShaderType,
  getVideoMeshTypePlane,
  getUniversalPosition,
  getUniversalPositionMesh,
  createPositionFactor,
  createScaleFactor,
  createPerspectiveCameraForCanvas,
  setMeshFitCanvasCamera
} from '../utils/canvasShapes';

import { getSceneMedia } from '../utils/sceneUtils';

const DRAGTHRESHOLD = 10;

export default class CanvasEdit extends CanvasThree {
  constructor ( props ) {
    super( props );

    // z-position found just in front of
    // video mesh
    this.overlayzpos = null;

    // values mapped to-fro relative and universal
    // position need this position factor applied to them :S
    this.positionFactor = {};
  }

  getSelectedHotspotId () {
    return this.props.data.select.hotspotId;
  }

  isSelectedHotspotId ( id ) {
    return this.getSelectedHotspotId() === id;
  }

  getIntersectedHandleFirst ( raycaster, hotspotarr ) {
    const intersected = this.getIntersectedHotspots( raycaster, hotspotarr );
    return intersected.length && intersected[0];
  }

  getIntersectedHotspots = ( raycaster, hotspotarr ) =>
    raycaster
      .intersectObjects( hotspotarr, true )
      .filter( i => /Sprite|Mesh/.test( i.object.type ) )

  getIntersectedHotspotFirst ( raycaster, hotspotarr ) {
    const intersected = this.getIntersectedHotspots( raycaster, hotspotarr );

    return intersected.length && intersected[0].object;
  }

  onDragMove ( dragObj, mousePos ) {
    if ( dragObj.type === 'Mesh' ) {
      this.mouseMoveDragging( dragObj.parent, mousePos );
      if ( !this.isSelectedHotspotId( dragObj.hotspotId ) ) {
        this.props.actions.selectSequence( dragObj.hotspotId );
      }
    } else if ( dragObj.type === 'Points' ) {
      const hotspot = dragObj.parent.parent;
      // Edit all three children
      // type Mesh and Line move the vertices
      // type point find the propper child (dragObj?)
      for ( let i = 0; i < hotspot.children.length; i += 1 ) {
        const child = hotspot.children[i];
        switch ( child.type ) {
        case 'Object3D':
          dragObj.geometry.verticesNeedUpdate = true;
          dragObj.geometry.vertices[0].x = mousePos.truex;
          dragObj.geometry.vertices[0].y = mousePos.truey;
          break;
        case 'Mesh':
          child.material.opacity = 0.3;
          //  //Making the mesh invisible while moving
          //  child.geometry.verticesNeedUpdate = true;
          //  if ( this.shapeVertice === null ) this.shapeVertice = child.geometry.vertices.filter( v => {
          //      let x1 = Math.abs( dragObj.geometry.vertices[0].x );
          //      let x2 = Math.abs( v.x );
          //      let max = Math.max( x1, x2 );
          //      let min = Math.min( x1, x2 );
          //      return max - min < DRAGTHRESHOLD;
          //  })[0] || null;
          //  if ( this.shapeVertice ) {
          //      this.shapeVertice.x = mousePos.truex;
          //      this.shapeVertice.y = mousePos.truey;
          //  }
          break;
        case 'Line':
          child.geometry.verticesNeedUpdate = true;
          if ( this.lineVertice === null ) {
            this.lineVertice = child.geometry.vertices.filter( v => {
              const x1 = Math.abs( dragObj.geometry.vertices[0].x );
              const x2 = Math.abs( v.x );
              const max = Math.max( x1, x2 );
              const min = Math.min( x1, x2 );
              return max - min < DRAGTHRESHOLD;
            })[0] || null;
          }

          if ( this.lineVertice ) {
            this.lineVertice.x = mousePos.truex;
            this.lineVertice.y = mousePos.truey;
          }

          break;
        default:
          break;
        }
      }
    }
  }

  onDragStart ( dragObj, mousePos ) {
    const { hotspotId } = dragObj;

    if ( hotspotId && !this.isSelectedHotspotId( hotspotId ) ) {
      this.props.actions.selectSequence( hotspotId );
    }

    const selectedHotspot = this.hotspot3DCache[this.props.data.select.hotspotId];
    if ( selectedHotspot ) {
      const dots = selectedHotspot.children.filter( c => c.name === 'Dots' );
      if ( dots.length ) {
        const intersectedDots = dots[0].children.filter( d => {
          const x1 = mousePos.truex;
          const x2 = d.geometry.vertices[0].x;
          const xmax = Math.max( x1, x2 );
          const xmin = Math.min( x1, x2 );

          const y1 = mousePos.truey;
          const y2 = d.geometry.vertices[0].y;
          const ymax = Math.max( y1, y2 );
          const ymin = Math.min( y1, y2 );

          return ( Math.abs( xmax - xmin ) < DRAGTHRESHOLD )
                        && ( Math.abs( ymax - ymin ) < DRAGTHRESHOLD );
        });
        if ( intersectedDots.length ) this.dragging = { object: intersectedDots[0] };
      }
    }
  }

  onDragEnd ( dragObj ) {
    const { type } = dragObj;
    const { scene, actions } = this.props;
    const hotspot3D = type === 'Mesh'
      ? dragObj : dragObj.parent.parent;

    if ( type === 'Mesh' ) {
      const hotspotData = this.getHotspotData( this.props.scene.hotspots_dict, hotspot3D.hotspotId );
      const [ x, y ] = getUniversalPositionMesh( dragObj.parent, this.positionFactor );
      const pos = hotspotData.pos.slice();

      pos[0] = x;
      pos[1] = y;

      actions.putHotspotDisp( scene.id, hotspot3D.hotspotId, { pos });
    } else if ( type === 'Points' ) {
      const shape = dragObj.parent.children
        .map( c => getUniversalPosition([
          c.geometry.vertices[0].x,
          c.geometry.vertices[0].y
        ], this.positionFactor ) );

      actions.putHotspotDisp( scene.id, hotspot3D.hotspotId, { shape });

      this.shapeVertice = null;
      this.lineVertice = null;
    }
  }

  initScene ( props ) {
    this.scene = new THREE.Scene();
    this.scene.add( new THREE.AmbientLight( 0x505050 ) );

    const light = new THREE.SpotLight( 0xffffff, 1.5 );
    light.position.set( 0, 500, 2000 );
    light.castShadow = true;

    light.shadow = new THREE.LightShadow( new THREE.PerspectiveCamera( 50, 1, 200, 1000 ) );
    light.shadow.bias = -0.00022;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;

    this.scene.add( light );

    if ( !this.getTargetElem() ) {
      throw new Error( '[!!!] targetElem must be defined' );
    }

    const media = getSceneMedia( props.scene );
    const { resolution } = media;

    const [ w, h ] = scalewh.max(
      [ resolution.width, resolution.height ],
      [ props.dimensions.width, props.dimensions.height ]
    );

    // this could probably just use target plane dimensions... (do later)
    this.scale = createScaleFactor( scalewh.max(
      [ resolution.width, resolution.height ],
      [ props.dimensions.width, props.dimensions.height ]
    ) );

    const canvasElem = setCanvasWidthHeight( this.getCanvas(), props.dimensions );

    this.camera = createPerspectiveCameraForCanvas( canvasElem );

    this.positionFactor = createPositionFactor( w, h );

    this.renderer = getCanvasGLRenderer( canvasElem );
    this.renderer.sortObjects = false;

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;

    const targetElem = this.getTargetElem( props );
    this.texture = getVideoTexture( targetElem );

    const videoMaterial = getVideoShaderType( this.texture, media.projection, media.type );

    this.mesh = getVideoMeshTypePlane( videoMaterial, media.resolution, props.dimensions );
    this.mesh = setMeshFitCanvasCamera( this.mesh, canvasElem, this.camera );

    this.overlayzpos = this.mesh.position.z + 0.1;
    this.scene.add( this.mesh );
  }
}

CanvasEdit.propTypes = {
  data: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  getTargetElem: PropTypes.func.isRequired,
  refresh: PropTypes.number,
  onRenderFn: PropTypes.func.isRequired
};
