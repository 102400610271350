import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CanvasPlayerGQL from './CanvasPlayerGQL';
import CamerasPanelGQL from './CamerasPanelGQL';
import PanelPlayerSettingsGQL from './PanelPlayerSettingsGQL';
import PlayerControlsSeekGQL from './PlayerControlsSeekGQL';
import SlatePlayPause from './SlatePlayPause';
import SlateCountdown from './SlateCountdown';
import ImgWatermark from './ImgWatermark';
import ImgPoster from './ImgPoster';
import StageTitle from './StageTitle';
import AdsPanel from './AdsPanel';

import {
  isContentAd,
  isContentStartFuture,
  getContentArtKeyUrl,
  getContentArtTileUrl,
  getContentTitle,
  getContentAvailabilityStartTime
} from '../utils/contentUtils';

import usePlayerStage from '../hooks/usePlayerStage';
import useLocalIVE, { PANELS } from '../hooks/useLocalIVE';
import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';

const StageContainer = styled.div`
   width: 100%;
   height: 100%;

   &.isEngage-true.isAd-false .stagetitle {
      transition-delay: 0s, 0s;
      opacity:1;
      top:0;
   }
   &.isEngage-true.isAd-false .stagefoot {
      transition-delay: 0s;
      bottom:0;
   }
   &.isEngage-true.isAd-false .stagefootcontent {
      transition-delay: 0s;
      opacity:1;
   }
`;

const SettingsContainer = styled.div`
    position: ${props => props.stagew > 700 ? 'absolute' : 'fixed'};
    top: ${props => props.stagew > 700 ? '-440px' : '0'};
    right: ${props => props.stagew > 700 ? '20px' : '0'};
    width: ${props => props.stagew > 700 ? '400px' : '100%'};
    z-index:4;
`;

const Title = styled.div`
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s, top .2s;
    transition-delay: 2s, 2s;
    opacity: 0;
    top: -80px;

    position: absolute;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: 3;
`;

const FootContainer = styled.div`
    position:relative;
    height:100%;
`;

const Foot = styled.div`
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s, bottom .2s;
    transition-delay: 2s, 2s;
    opacity: 0;

    transition: bottom .2s;
    transition-delay: 2s;
    opacity: 1;
    bottom: -80px;

    color:#fff;
    position: absolute;
    right: 0;
    left: 0;
    height:70px;
`;

const FootContent = styled.div`
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.4) 90%);
    transition: opacity .5s;
    transition-delay: 2s;
    opacity: 0;

    color:#fff;
    height:100%;
    overflow: hidden;
`;

export default function PlayerStageGQL ( props ) {
  const { media, content, scene, moments, camera } = props;
  const {
    stageSize,
    stageIsEngage,
    stageElemRef
  } = usePlayerStage();

  const {
    togglePlaying,
    mediaPlaybackState
  } = useLocalMediaPlayback();

  const {
    iveState
  } = useLocalIVE();

  const isAd = isContentAd( content );

  const adCompleted = () => {
    // TODO Ad completed
  };

  const artKeyUrl = getContentArtKeyUrl( content );
  const artTileUrl = getContentArtTileUrl( content );

  return (
    <StageContainer
      id="Stage"
      className={`isEngage-${stageIsEngage} isAd-${isAd}`}
      ref={stageElemRef} >
      <CanvasPlayerGQL
        refresh={0}
        content={content}
        dimensions={stageSize}
        canvasType={iveState.canvasType}
        scene={scene}
        media={media}
        moments={moments}
      />
      {artKeyUrl && !mediaPlaybackState.currentTime && (
        <ImgPoster imgSrc={artKeyUrl} />
      )}
      <Title className='stagetitle'>
        <StageTitle>{getContentTitle( content, 'loading…' )}</StageTitle>
      </Title>
      <CamerasPanelGQL
        isRenderHooksOnly={iveState.activePanel !== PANELS.PANELCAMERAS}
        content={content}
        camera={camera}
        scene={scene} />
      {mediaPlaybackState.isReady && (
        <SlatePlayPause
          scene={scene}
          videoplayback={mediaPlaybackState}
          isPlaying={mediaPlaybackState.playing}
          onClick={() => togglePlaying()} />
      )}
      {!mediaPlaybackState.isReady && isContentStartFuture( content ) && (
        <SlateCountdown
          title={getContentTitle( content, 'Untitled' )}
          startTime={getContentAvailabilityStartTime( content )} />
      )}
      {isAd && (
        <AdsPanel
          onCompleted={() => adCompleted( media )}
          media={media} />
      )}
      {artTileUrl && (
        <ImgWatermark imgSrc={artTileUrl} />
      )}
      <Foot className='stagefoot'>
        <FootContainer>
          <SettingsContainer stagew={stageSize.width}>
            <PanelPlayerSettingsGQL
              isRenderHooksOnly={iveState.activePanel !== 'statsPanel'}
              stageSize={stageSize}
              scene={scene}
              media={media} />
          </SettingsContainer>
          <FootContent className={'stagefootcontent'}>
            <PlayerControlsSeekGQL
              media={media}
              content={content}
              stageDimensionsWH={stageSize}
              isRenderHooksOnly={!mediaPlaybackState.isReady}
            />
          </FootContent>
        </FootContainer>
      </Foot>
    </StageContainer>
  );
}

PlayerStageGQL.propTypes = {
  media: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  camera: PropTypes.object,
  scene: PropTypes.object,
  moments: PropTypes.array
};
