import { mountNextStateScene } from './scene';
import { toastReducer } from './toast';
import {
  ADD_ERROR_TOAST,
  HOTSPOT_ENGAGE
} from '../actions/ActionTypes';

import {
  getSceneSequence,
  getSceneHotspot
} from '../utils/sceneUtils';

export function applyHotspotGoToScene ( state, hotspotInfo ) {
  const { dest_scene, dest_start } = hotspotInfo;
  const nextscene = dest_scene && state.scenes[dest_scene];

  if ( nextscene ) {
    state = mountNextStateScene( state, nextscene, dest_start || 0 );
  } else {
    state = {
      ...state,
      toasts: toastReducer( state.toasts, {
        type: ADD_ERROR_TOAST,
        errorMsg: 'infoDestSceneNotFound'
      })
    };
  }

  return state;
}

export function applyHotspotURL ( state, hotspotInfo ) {
  let { url } = hotspotInfo;

  url = url || 'http://www.google.com';

  window.location = url;
  // window.open(url, '_blank');
  // console.log('hotspot url open()', url);

  return state;
}

const DONOTHING = 0;
const GOTOSCENE = 1;
const GOTOURL = 2;
const SHOWINFOBOX = 4;

export function getHotspotActionType ( sequence, hotspot ) {
  let actionType = DONOTHING;

  if ( !sequence ) {
    actionType = DONOTHING;
  } else if ( 'infobox' in hotspot && hotspot.infobox.linkedImage ) {
    actionType = SHOWINFOBOX;
  } else if ( 'url' in hotspot ) {
    actionType = GOTOURL;
  } else if ( sequence.dest_scene && 'dest_start' in sequence ) {
    actionType = GOTOSCENE;
  }

  return actionType;
}

export function onHotspotEngage ( state, sceneId, hotspotId ) {
  const sequence = getSceneSequence( state.scenes[sceneId], hotspotId );
  const hotspot = getSceneHotspot( state.scenes[sceneId], hotspotId );
  const actionType = getHotspotActionType( sequence, hotspot );

  if ( actionType === GOTOSCENE ) {
    return applyHotspotGoToScene( state, sequence );
  }

  if ( actionType === GOTOURL ) {
    return applyHotspotURL( state, hotspot );
  }

  return {
    ...state,
    toasts: toastReducer( state.toasts, {
      type: ADD_ERROR_TOAST,
      errorMsg: 'infoActionNotFound'
    })
  };
}

export const hotspotReducer = ( state, action ) => {
  switch ( action.type ) {
  case HOTSPOT_ENGAGE:
    return onHotspotEngage( state, action.sceneId, action.hotspotId );
  default:
    return state;
  }
};
