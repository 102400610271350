import { FLAG_GQL_ENABLED } from '../env.js';

let vrDisplay;

const isVRMode = () => vrDisplay && vrDisplay.isPresenting;

function exitPresent ( ) {
  if ( isVRMode() ) {
    console.log( 'exit present' );
    // vrDisplay.exitPresent( cfg, domUtils );
  } else {
    console.log( '[...] currently not presenting' );
  }
}

function requestPresent ( sourceelem ) {
  if ( !isVRMode() ) {
    vrDisplay.requestPresent([ {
      source: sourceelem,
      predistorted: false
    } ]);
  }
}

function fireDisplayChanged () {
  vrDisplay.fireVRDisplayPresentChange_();
}

// pose information is only accessible within reqAnimFrame context
function setDeviceOrientation ( device, frameData, object ) {
  if ( device ) {
    device.requestAnimationFrame( () => {
      device.getFrameData( frameData );
      object.quaternion.set( ...frameData.pose.orientation );

      return true;
    });
  }
}

function getNativeDisplays ( fn ) {
  if ( 'getVRDisplays' in navigator ) {
    navigator.getVRDisplays().then( displays => {
      fn( displays.length && displays[0]);
    });
  } else {
    fn( false );
  }
}

function loadPolyfill ( fn ) {
  return import( 'webvr-polyfill' ).then( WebVRPolyfill => {
    const WebVRPolyfillDefault = WebVRPolyfill.default;

    window.webvr = new WebVRPolyfillDefault({
      FORCE_ENABLE_VR: true,
      ALWAYS_APPEND_POLYFILL_DISPLAY: true,
      TOUCH_PANNER_DISABLED: false
    });

    navigator.getVRDisplays().then( displays => {
      [ vrDisplay ] = displays;
      fn( displays );
    });
  });
}

function vrload ( fn ) {
  getNativeDisplays( display => {
    if ( display ) {
      vrDisplay = display;
    } else if ( !FLAG_GQL_ENABLED ) {
      loadPolyfill( fn );
    }
  });
}

function getVRDisplay () {
  return vrDisplay;
}

export {
  vrload, requestPresent, exitPresent, isVRMode, setDeviceOrientation, fireDisplayChanged, getVRDisplay
};
