import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import HeatmapOffSVG from 'player-assets/web/svg/btn_heatmap_off.svg';
// import HeatmapOnSVG from 'player-assets/web/svg/btn_heatmap_on.svg';

const Container = styled.button`
    font-size: 0;
    fill: #bebebe;
    display: inline-block;
    cursor: pointer;
    vertical-align:middle;
    border:0;
    background-color:transparent;
    fill: ${props => props.isActive ? 'orange' : '#bebebe'};
    &:hover {
       fill: orange;
    }
`;

export default function ButtonToggleHeatmap ( props ) {
  const { onClick, isHeatEnabled } = props;
  // const HeatSVG = isHeatEnabled ? HeatmapOnSVG : HeatmapOffSVG;
  const HeatSVG = <span />;

  return (
    <Container onClick={onClick} isActive={isHeatEnabled}>
      <HeatSVG width="25px" height="25px" />
    </Container>
  );
}

ButtonToggleHeatmap.propTypes = {
  onClick: PropTypes.func.isRequired,
  isHeatEnabled: PropTypes.bool.isRequired
};
