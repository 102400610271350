import {
  mountPreviousStateScene,
  mountNextStateScene,
  mountStateScene
} from './scene';

import { toastReducer } from './toast';
import { toastMessageType } from '../components/toasts';

import * as sceneUtils from '../utils/sceneUtils';

import {
  MEDIA_ENDED,
  ADD_ERROR_TOAST
} from '../actions/ActionTypes';

import { setIsPlaying } from './videoplayback';


export const getSelectSceneId = state =>
  state.select.sceneId;

export const getSelectScene = ( state, sceneId = getSelectSceneId( state ) ) =>
  state.scenes[sceneId];

// helps to avoid writing things like this...
//
// state = {
//     ...state,
//     state.scenes, : {
//         ...state.scenes[action.sceneId],
//         sequences: [ ... ]
//     }
// };
//
// instead use this...
//
// assignScene( state, sceneId, {
//     sequences: [ ... ]
// })
export const assignScene = ( state, sceneId, obj ) => ({
  ...state,
  scenes: {
    ...state.scenes,
    [sceneId]: { ...state.scenes[sceneId], ...obj }
  }
});


// ex postplayback.type 1,
//
//    {type: 1, dest_scene: "1dd8e8fa-0e5b-41af-aaba-b72740a9155f"}
//
export const applyPostPlaybackGotoScene = ( state, postplayback ) =>
  sceneUtils.hasScene( state, postplayback.dest_scene )
    ? mountNextStateScene( state, state.scenes[postplayback.dest_scene])
    : {
      ...state,
      toasts: toastReducer( state.toasts, {
        type: ADD_ERROR_TOAST,
        errorMsg: toastMessageType.errorDestSceneNotFound
      })
    };

// ex postplayback.type 2,
//
//    {type: 2, dest_scene: false}
//
export const applyPostPlaybackLoop = state =>
  mountStateScene( state, state.scenes[state.select.sceneId]);

// ex postplayback.type 3,
//
//    {type: 3, dest_scene: false}
//
export const applyPostPlaybackReturnPrevious = state =>
  mountPreviousStateScene( state );

// ex postplayback.type 4,
//
//    {type: 4, dest_scene: false}
//
export const applyPostPlaybackNone = ( state, sceneId ) =>
  assignScene( state, sceneId, {
    isPlayed: Date.now()
  });


// ex postplayback,
//
//    {type: 1, dest_scene: "1dd8e8fa-0e5b-41af-aaba-b72740a9155f"}
//    {type: 2, dest_scene: false}
//    {type: 3, dest_scene: false}
//    {type: 4, dest_scene: false}
//
export function applyPostPlayback ( state, postplayback, sceneId ) {
  const GOTOSCENE = 1;
  const LOOP = 2;
  const RETURNTOPREVIOUS = 3;
  const DONOTHING = 4;

  if ( postplayback.type === GOTOSCENE ) {
    return applyPostPlaybackGotoScene( state, postplayback );
  }

  if ( postplayback.type === LOOP ) {
    return applyPostPlaybackLoop( state, postplayback );
  }

  if ( postplayback.type === RETURNTOPREVIOUS ) {
    return applyPostPlaybackReturnPrevious( state, postplayback );
  }

  if ( postplayback.type === DONOTHING ) {
    return applyPostPlaybackNone( setIsPlaying( state, false ), sceneId );
  }

  return state;
}

export function onVideoEnd ( state, sceneId ) {
  const scene = state.scenes[sceneId];
  const { postplayback } = scene || {};

  return applyPostPlayback( state, postplayback, sceneId );
}

export const transitionReducer = ( state, action ) => {
  switch ( action.type ) {
  case MEDIA_ENDED:
    return onVideoEnd( state, action.sceneId, action.videoId );
  default:
    return state;
  }
};
