import ismobilejs from 'ismobilejs';

const ismobile = ismobilejs();

export const isSafari = () => ( 'WebkitAppearance' in document.documentElement.style && !window.chrome );

export const isIosSafari = () => isSafari() && ismobile.apple.device;

// http://stackoverflow.com/questions/14403766/how-to-detect-the-stock-android-browser
// solution from here
// http://stackoverflow.com/questions/14701951/javascript-detect-android-native-browser
export function isStockAndroidBrowser () {
  const ua = navigator.userAgent;
  return (
    ( ua.indexOf( 'Mozilla/5.0' ) > -1 && ua.indexOf( 'Android ' ) > -1 && ua.indexOf( 'AppleWebKit' ) > -1 ) && ua.indexOf( 'Version' ) > -1 );
}

/**
 * https://codepen.io/gapcode/pen/vEJNZN
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function getIEVersion () {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf( 'MSIE ' );
  if ( msie > 0 ) {
    // IE 10 or older => return version number
    return parseInt( ua.substring( msie + 5, ua.indexOf( '.', msie ) ), 10 );
  }

  const trident = ua.indexOf( 'Trident/' );
  if ( trident > 0 ) {
    // IE 11 => return version number
    const rv = ua.indexOf( 'rv:' );
    return parseInt( ua.substring( rv + 3, ua.indexOf( '.', rv ) ), 10 );
  }

  const edge = ua.indexOf( 'Edge/' );
  if ( edge > 0 ) {
    // Edge (IE 12+) => return version number
    return parseInt( ua.substring( edge + 5, ua.indexOf( '.', edge ) ), 10 );
  }

  // other browser
  return false;
}

export function isFirefoxMobile () {
  return ismobile.any && navigator.userAgent.toLowerCase().indexOf( 'firefox' ) > -1;
}
