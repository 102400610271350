import { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import useThreeMoments from './useThreeMoments';
import { hotspotDataSanitised } from '../adapter';
import { isVideoElem } from '../utils/elemUtils';
import { FLAG_GQL_ENABLED } from '../env';

import {
  START_HOTSPOT,
  END_HOTSPOT
} from '../actions/ActionTypes';

import {
  isLegacyProjectionSphereRe
} from '../utils/legacyProjectionUtils';

import {
  disposeNodeHierarchy,
  setHotspot3DVisible,
  setHotspot3DSelected,
  scene3DClearHotspots3D,
  createHotspot3D,
  createHotspot3DCollider
} from '../utils/canvasShapes';

import {
  getSequenceId,
  getSequenceHotspot,
  getSceneSequencesTypeHotspot
} from '../utils/contentUtils';

import {
  isSequenceHotspot
} from '../utils/seqUtils';

export default function useCanvas3D ( props ) {
  const {
    canvasId,
    getMediaElem = () => null,
    xrIsEnabled,
    moments,
    projection,
    animationLoop
  } = props;
  const objects3DRef = useRef({
    colliders: [],
    hotspot3DMap: {}
  });
  const [ lifecycle, lifecycleSet ] = useState( null );
  const {
    cursorVideoMoments,
    cursorVideoMomentsRefresh
  } = useThreeMoments();

  const [ sceneState, sceneStateSet ] = useState({
    projection,
    initialized: false,
    threeInitUpdate: Date.now(),
    raycaster: new THREE.Raycaster(),
    scene3D: null,
    camera: null,
    dragging: false,
    hotspotIdHover: null,
    positionFactor: {},
    xrIsEnabled
  });

  const sceneStateSetProps = nextStateProps => sceneStateSet( prevState => ({
    ...prevState,
    ...nextStateProps
  }) );

  const getHotspotData = ( hotspots, hotspotId ) => {
    const hotspotData = hotspots[hotspotId];

    return hotspotDataSanitised( hotspotData );
  };

  // ex hotspot,
  //
  //   { id: 'no-id', x: 0, y: 0 }
  //
  // eslint-disable-next-line no-shadow
  const scene3DSetSequenceHotspot = ( sceneState, scene3D, hotspotData, isSelected ) => {
    if ( !scene3D )
      return scene3D;

    const hotspot3D = createHotspot3D( hotspotData, isSelected, sceneState );
    let collider;

    // kludge for now... edit canvas does not use collider
    if ( isLegacyProjectionSphereRe.test( sceneState.projection ) ) {
      collider = createHotspot3DCollider( hotspot3D );

      hotspot3D.add( collider );
      hotspot3D.collider = collider;
    }

    scene3D.add( hotspot3D );

    return [ scene3D, hotspot3D, collider ];
  };

  const momentExecute = ( moment, isForward ) => {
    const { action, hotspotId } = moment.meta;
    const hotspot3D = objects3DRef.current.hotspot3DMap[hotspotId];

    if ( hotspot3D ) {
      if ( action === START_HOTSPOT ) {
        setHotspot3DVisible( hotspot3D, isForward );
      } else if ( action === END_HOTSPOT ) {
        setHotspot3DVisible( hotspot3D, !isForward );
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const momentsRefresh = ( sceneState, selected_hotspotId ) => {
    const { hotspots_dict, sequences } = props.scene;
    let { scene3D } = sceneState;
    let collider;
    const nextColliders = [];
    const nextHotspot3DCache = {};

    scene3D = scene3DClearHotspots3D( scene3D );

    if ( FLAG_GQL_ENABLED ) {
      getSceneSequencesTypeHotspot( props.scene ).forEach( sequence => {
        let hotspot3D;
        const hotspotData = getSequenceHotspot( sequence );

        [ scene3D, hotspot3D, collider ] = scene3DSetSequenceHotspot(
          sceneState,
          scene3D,
          hotspotData,
          getSequenceId( sequence ) === selected_hotspotId
        );

        nextHotspot3DCache[hotspotData.id] = hotspot3D;

        if ( collider ) {
          nextColliders.push( collider );
        }

        if ( isLegacyProjectionSphereRe.test( sceneState.projection ) ) {
          hotspot3D.lookAt( sceneState.camera.position );
        }
      });
    } else {
      sequences.forEach( sequence => {
        if ( isSequenceHotspot( sequence ) ) {
          let hotspot3D;
          const hotspotData = getHotspotData( hotspots_dict, sequence.uuid );

          [ scene3D, hotspot3D, collider ] = scene3DSetSequenceHotspot(
            sceneState,
            scene3D,
            hotspotData,
            sequence.uuid === selected_hotspotId
          );

          nextHotspot3DCache[hotspotData.id] = hotspot3D;

          if ( collider ) {
            nextColliders.push( collider );
          }

          if ( isLegacyProjectionSphereRe.test( sceneState.projection ) ) {
            hotspot3D.lookAt( sceneState.camera.position );
          }
        }
      });
    }

    objects3DRef.current.hotspot3DMap = nextHotspot3DCache;
    objects3DRef.current.colliders = nextColliders;

    cursorVideoMomentsRefresh(
      getMediaElem(), moments, momentExecute
    );

    return sceneState;
  };

  const isMediaReady = ({ media }) => {
    return Boolean( media && getMediaElem() ) || !props.media;
  };

  // only call effect function if scene exists eg if state.renderer is defined
  const useSceneEffect = ( fn, effectProps ) => (
    useEffect( () => sceneState.renderer && fn(), effectProps )
  );

  const getCanvasElem = () => document.getElementById( canvasId );

  const threeScene3DSelectHotspot3D = selectedHotspotId => {
    Object.values( objects3DRef.current.hotspot3DMap ).forEach( h => {
      setHotspot3DSelected( h, h.hotspotId === selectedHotspotId );
    });
  };

  const threeSceneRenderFrame = ( threeSceneState, renderer, scene, camera ) => {
    if ( scene ) {
      renderer.render( scene, camera );

      // no analog at canvasEditGQL
      if ( lifecycle.threeSceneRendered )
        lifecycle.threeSceneRendered( threeSceneState );
    }
  };

  const threeSceneRender = ( threeSceneState, props ) => {
    const mediaElem = getMediaElem();
    const { renderer, scene3D, camera } = threeSceneState;

    if ( !isMediaReady( props ) ) {
      return null;
    }

    if ( isVideoElem( mediaElem ) ) {
      if ( mediaElem.readyState === mediaElem.HAVE_ENOUGH_DATA ) {
        if ( !mediaElem.paused && moments.length ) {
          cursorVideoMoments( mediaElem, moments, momentExecute );
        }
      }
    }

    threeSceneRenderFrame( threeSceneState, renderer, scene3D, camera );

    return threeSceneState;
  };

  const refreshThree = ( threeSceneState, nextProps ) => {
    if ( isMediaReady( props ) ) {
      disposeNodeHierarchy( threeSceneState.scene3D );
      threeSceneState = lifecycle.threeSceneCreate(
        getCanvasElem(),
        threeSceneState,
        nextProps
      );
      threeSceneState.threeInitUpdate = Date.now();
      threeSceneState = threeSceneRender( threeSceneState, nextProps );
    }

    return threeSceneState;
  };

  // a unique stamp for this scene/media combination
  const getSceneStamp = ( media, scene ) => (
    `${media && media.id}-${scene && scene.id}`
  );

  const fullSceneRefresh = () => {
    let state = refreshThree( sceneState, props );
    if ( lifecycle.threeSceneRefreshed ) {
      state = lifecycle.threeSceneRefreshed( state, getCanvasElem() );
    }

    state.initialized = getSceneStamp( props.media, props.scene );

    sceneStateSetProps( state );
  };

  useEffect( () => {
    if ( !isMediaReady( props ) ) {
      console.log( '[...] useCanvas3D: media not ready' );
      return;
    }

    if ( sceneState.initialized === getSceneStamp( props.media, props.scene ) ) {
      console.log( '[...] useCanvas3D: scene already initialized' );
      return;
    }

    if ( lifecycle
             && !props.xrIsEnabled
             && props.dimensions.width
             && props.dimensions.height ) {
      fullSceneRefresh();
    }
  }, [
    lifecycle,
    props.media && props.media.id,
    props.scene && props.scene.id,
    props.dimensions.width,
    props.dimensions.height,
    isMediaReady( props )
  ]);

  useEffect( () => {
    if ( isMediaReady( props )
             && lifecycle
             && props.dimensions.width
             && props.dimensions.height ) {
      fullSceneRefresh();
    }
  }, [
    props.projection,
    props.dimensions.width,
    props.dimensions.height
  ]);

  useSceneEffect( () => {
    threeScene3DSelectHotspot3D( props.selected_hotspotId );
  }, [ props.selected_hotspotId ]);

  useSceneEffect( () => {
    if ( props.projection )
      sceneStateSetProps({
        projection: props.projection
      });
  }, [
    props.projection
  ]);

  useSceneEffect( () => {
    if ( props.scene && props.scene.sequences ) {
      momentsRefresh(
        sceneState, props.selected_hotspotId
      );
    }
  }, [
    sceneState.projection,
    sceneState.threeInitUpdate
  ]);

  useSceneEffect( () => {
    cursorVideoMoments( getMediaElem(), moments, momentExecute );
  }, [ props.playback && props.playback.playedSeconds ]);

  useSceneEffect( () => {
    if ( !animationLoop )
      return undefined;

    sceneState.renderer.setAnimationLoop( null );
    sceneState.renderer.setAnimationLoop( () => (
      threeSceneRender( sceneState, props )
    ) );
    return () => {
      sceneState.renderer.setAnimationLoop( null );
    };
  }, [
    sceneState.initialized,
    sceneState.threeInitUpdate
  ]);

  // parent-triggered rerenders possible by redefining scene3D.renderUpdate
  useSceneEffect( () => {
    threeSceneRender( sceneState, props );
  }, [ sceneState.scene3D && sceneState.scene3D.renderUpdate ]);

  return {
    sceneState,
    sceneStateSet,
    lifecycleSet,
    sceneStateSetProps,

    objects3DRef,
    useSceneEffect,
    getCanvasElem
  };
}
