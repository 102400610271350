import { useState, useEffect, useRef } from 'react';

export default function useCountdown ( seconds, options ) {
  const intervalRef = useRef( seconds );
  const [ state, stateSet ] = useState({ seconds });

  useEffect( () => { // storybook component may redefine seconds
    intervalRef.current = seconds;
  }, [ seconds ]);

  useEffect( () => {
    if ( intervalRef.current > 0 ) {
      window.setTimeout( () => {
        intervalRef.current = Math.max( 0, intervalRef.current - 1 );

        stateSet({ seconds: intervalRef.current });
      }, Math.min( 1000, intervalRef.current * 1000 ) );
    } else if ( seconds > 0 ) {
      options.onCompleted();
    }
  }, [ state.seconds ]);

  return state;
}
