import { MediaProjectionTypeEnum } from '../enums';

// legacy venom projection types (different from those at newer venom-api),
//
//  * 'Spherical',
//  * 'Flat',
//  * 'Tiled',
//  * 'Equilateral 3D L/R',
//  * 'Equilateral 3D R/L',
//  * 'Equilateral 3D SBS',
//  * 'Cube Map',
//  * 'Equisolid Fisheye'
//

const isLegacyProjectionCylinderRe = /^cylinder$/i;
const isLegacyProjectionSphereRe = /^(EQUIRECTANGULAR|equilateral|spherical)/i;
const isLegacyProjectionFlatRe = /^(FLAT|flat)$/i;
const isLegacyProjectionCubeRe = /^cube$/i;

// only certain values such as 'Flat' and 'Spherical' values are
// submitted to the legacy API. Other values may be accepted there,
// however IVE sources have only submitted these values and won't be
// expected to support other "legacy" values moving forward
const isSupportedLegacyProjection = legacyProjectionType => (
  ( isLegacyProjectionSphereRe.test( legacyProjectionType ) && !/3D/.test( legacyProjectionType ) )
    || isLegacyProjectionFlatRe.test( legacyProjectionType )
);

const getMediaProjectionTypeFromLegacyProjection = ( legacyProjectionType, defaultType ) => {
  if ( isLegacyProjectionSphereRe.test( legacyProjectionType ) )
    defaultType = MediaProjectionTypeEnum.EQUIRECTANGULAR;
  else if ( isLegacyProjectionFlatRe.test( legacyProjectionType ) )
    defaultType = MediaProjectionTypeEnum.FLAT;
  else if ( isLegacyProjectionCubeRe.test( legacyProjectionType ) )
    defaultType = MediaProjectionTypeEnum.CUBE_MAP;

  return defaultType;
};

export {
  isLegacyProjectionCylinderRe,
  isLegacyProjectionSphereRe,
  isLegacyProjectionFlatRe,
  isLegacyProjectionCubeRe,
  isSupportedLegacyProjection,
  getMediaProjectionTypeFromLegacyProjection
};
