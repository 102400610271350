//
// attempting to have a single modal reducer somewhat complicated
// to make this change, because video modal state is spread out
// across the reducer data.
//

import {
  UPLOAD_IS_FINISHED,

  MODAL_OPEN,
  MODAL_SHUT
} from '../actions/ActionTypes';

const getModalDefault = () => ({
  selectSceneModal: {
    modalIsOpen: false,
    scenetype: null
  },
  noSceneModal: {
    modalIsOpen: false
  },
  deleteConfirmationModal: {
    modalIsOpen: false,
    deleteWhat: null,
    id: null
  },
  uploadModal: {
    modalIsOpen: false,
    modifying: ''
  }
});

const assignModalBig = ( state, obj ) => ({
  ...state,
  ...obj
});

const modalOpen = ( state, panelId, modalType, panelOpts ) => (
  assignModalBig( state, {
    modalType: modalType || 'big',
    panelOpts,
    panelId
  }) );

const modalOpenMediaSelect = state =>
  assignModalBig( state, { mediaId: 'MediaSelect' });

const modalShut = state =>
  assignModalBig( state, { panelId: null });

const isModalOpen = state =>
  state.panelId !== null;

const modalReducer = ( state, action ) => {
  switch ( action.type ) {
  case MODAL_SHUT:
    return modalShut( state );

  case MODAL_OPEN:
    return modalOpen( state, action.panelId, action.modalType, action.panelOpts );

    // change modal contant to mediaselect panel, where new media will appear
  case UPLOAD_IS_FINISHED:
    return isModalOpen( state )
      ? modalOpen( state, 'MediaSelect', 'big', state.panelOpts )
      : state;

  default:
    return state;
  }
};


export {
  getModalDefault,
  modalOpen,
  modalOpenMediaSelect,
  modalShut,
  modalReducer
};
