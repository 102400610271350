import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import ToastContainer from './toastsGQL';
import PlayerStageGQL from './PlayerStageGQL';
import useRootPlayer from '../hooks/useRootPlayer';
import useLocalToast from '../hooks/useLocalToast';
import {
  queryHash,
  queryContent,
  queryMediaId,
  queryInteractiveId
} from '../env';

const Container = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    color: #fff;
`;

// contentId ex,
//
// '7806f0d7-4d45-4cd3-919f-2447ea6e8f11'
// '1504ce12-cd3d-4bfc-9893-a10d3e34c6bb' interactive
//
export default function RootPlayerGQL () {
  const { toast } = useLocalToast();
  const { rootPlayer, iveState } = useRootPlayer({
    interactiveId: queryInteractiveId,
    contentId: queryContent,
    mediaId: queryMediaId,
    hash: queryHash
  });
  const ToastMemo = useMemo( () => (
    <ToastContainer toast={toast} />
  ), [ toast.lastUpdate ]);

  return (
    <Container className="fullscreenDiv">
      {rootPlayer.media && iveState.isInitialized && (
        <PlayerStageGQL
          media={rootPlayer.media}
          camera={rootPlayer.camera}
          moments={rootPlayer.moments}
          content={rootPlayer.content}
          sequence={rootPlayer.sequence}
          scene={rootPlayer.scene}
        />
      )}
      {ToastMemo}
    </Container>
  );
}
