
// [[ id, [ start, end ], [ start, end ] ],
//  [ id, [ start, end ], [ start ] ],
//  [ id, [ start ]]
//
export const recordCreate = id => [ id, [ Date.now() ] ];

export const recordGetPeriods = record => ( record || []).slice( 1 );

export const recordGetPeriodFirst = record => (
  recordGetPeriods( record ) || []
)[0];

export const recordGetPeriodLast = record => (
  recordGetPeriods( record ) || []
).slice( -1 )[0];

export const recordIsSame = ( recordA, recordB ) => recordA[0] === recordB[0];

export const recordIsEnded = record => {
  const periodLast = recordGetPeriodLast( record );

  return Boolean( periodLast && typeof periodLast[1] === 'number' );
};

export const recordIsOpen = record => {
  const periodLast = recordGetPeriodLast( record );

  return Boolean( periodLast && typeof periodLast[1] !== 'number' );
};

export const recordIsOpened = record => Boolean( recordGetPeriodLast( record ) );

export const recordFinish = record => {
  const [ id, ...rest ] = record || [];
  const [ start, end ] = rest[rest.length - 1];

  // add end date only if one does not exist
  rest[rest.length - 1] = [ start, end || Date.now() ];

  return [ id, ...rest ];
};

export const recordStart = record => (
  recordIsOpen( record ) ? record : [ ...record, [ Date.now() ] ]
);

export const recordGetId = record => ( record || [])[0];

export const recordGetElapsedTime = record => record
  .slice( 1 ).reduce( ( time, [ start, end ]) => (
    time + ( end || Date.now() ) - start ), 0 );

export const timeRecordsCreate = () => [];

export const timeRecordsFind = ( timeRecords, id ) => timeRecords
  .find( record => recordGetId( record ) === id );

export const timeRecordsIsOpen = ( timeRecords, id ) => (
  recordIsOpen( timeRecordsFind( timeRecords, id ) )
);

export const timeRecordsIsOpened = ( timeRecords, id ) => (
  recordIsOpened( timeRecordsFind( timeRecords, id ) )
);

export const timeRecordsStart = ( state, id ) => {
  const idSetList = timeRecordsFind( state, id );

  if ( idSetList && recordIsOpen( idSetList ) ) {
    return state;
  }

  if ( idSetList ) {
    return state.map( // add a new timeset
      setList => recordIsSame( idSetList, setList )
        ? recordStart( setList )
        : setList
    );
  }

  return [ ...state, recordCreate( id ) ];
};

export const timeRecordsEnd = ( state, id ) => {
  const idSetList = timeRecordsFind( state, id );

  if ( idSetList && !recordIsEnded( state ) )
    return state.map(
      setList => recordIsSame( idSetList, setList )
        ? recordFinish( setList )
        : setList
    );

  return state;
};

export const timeRecordsGetElapsedTime = ( state, id ) => (
  recordGetElapsedTime( timeRecordsFind( state, id ) )
);
