import { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  xrSessionIsSupported,
  xrSessionRequest
} from '../utils/xr.js';

export const defaultLocalStage = {
  stageState: {
    id: 'stagestate',
    __typename: 'StageState',

    xrIsEnabled: false,
    xrIsSupported: false,
    cameraRotationRadians: [ 0, 0 ]
  }
};

export const GET_STAGE_STATE = gql`{
  stageState @client {
    id

    xrIsSupported
    xrIsEnabled
    cameraRotationRadians
  }
}`;

export const SET_STAGE_STATE = gql`
mutation SetStageState( $input: SetStageInput! ) {
  stageState( input: $input ) @client
}`;

export const rendererRef = { current: {} };

export default function useLocalStage () {
  const [ stageMutation ] = useMutation( SET_STAGE_STATE );
  const { stageState = {} } = useQuery( GET_STAGE_STATE ).data || defaultLocalStage;

  const stageRendererSet = glRenderer => {
    rendererRef.current = glRenderer;
  };

  const stageCameraRotationRadiansSet = radiansXY => {
    stageMutation({
      variables: {
        input: {
          cameraRotationRadians: radiansXY
        }
      }
    });
  };

  const stageXrIsEnabledSet = xrIsEnabled => {
    stageMutation({
      variables: {
        input: {
          xrIsEnabled
        }
      }
    });
  };

  const stageXrIsSupportedSet = xrIsSupported => {
    stageMutation({
      variables: {
        input: {
          xrIsSupported
        }
      }
    });
  };

  const stageXrSessionEnd = () => {
    if ( stageState.xrIsEnabled ) {
      stageXrIsEnabledSet( false );
    }
  };

  const stageXrSessionStart = () => {
    if ( stageState.xrIsSupported && rendererRef.current ) {
      xrSessionRequest( rendererRef.current, () => {
        stageXrIsEnabledSet( false );
      });
      stageXrIsEnabledSet( true );
    }
  };

  const stageXrSessionToggle = () => (
    stageState.xrIsEnabled
      ? stageXrSessionEnd()
      : stageXrSessionStart() );

  useEffect( () => {
    // https://html.spec.whatwg.org/multipage/interaction.html#triggered-by-user-activation
    //
    // intiating fullscreen mode requires a user-generated non-async sequence
    // as a result, this sequence fails,
    //
    //  1. user clicks webxr button
    //  2. async-load of webxr-scripts
    //  3. request xrmode from webxr-scripts
    //
    // because of this, the webxr polyfill scripts are loaded here
    // if the botton is rendered and if webxr mode is supported
    ( async () => {
      const xrIsSupported = await xrSessionIsSupported();

      if ( xrIsSupported )
        stageXrIsSupportedSet( xrIsSupported );
    })();
  }, []);

  return {
    stageState,
    stageRendererSet,
    stageCameraRotationRadiansSet,
    stageXrSessionEnd,
    stageXrSessionStart,
    stageXrSessionToggle
  };
}
