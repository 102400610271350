import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonClose from './ButtonClose';
import CameraInfo from './CameraInfo';

import useLocalIVE from '../hooks/useLocalIVE';

import {
  isCamera,
  getCameraTitle,
  getSceneName,
  getContentCamerasOrScenes
} from '../utils/contentUtils';

const Container = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    width:200px;
    z-index:4;
    flex:1;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Top = styled.div`
    text-align:right;
`;

const CameraList = styled.ul`
    margin:0;
    padding:0;
`;

const CameraItem = styled.li`
    list-style:none;
`;

const getName = cameraOrScene => isCamera( cameraOrScene )
  ? getCameraTitle( cameraOrScene )
  : getSceneName( cameraOrScene );

const createName = ( cameraOrScene, i ) => isCamera( cameraOrScene )
  ? `Camera ${i}`
  : `Scene ${i}`;

export default function CamerasPanelGQL ({ content, camera, scene, isRenderHooksOnly }) {
  const {
    iveSetSelectedSceneId,
    iveSetSelectedCameraId,
    iveTogglePanelCameras
  } = useLocalIVE();

  if ( isRenderHooksOnly === true )
    return <Container style={{ display: 'none' }}/>;

  const camerasOrScenes = getContentCamerasOrScenes( content );
  const cameraOrSceneId = ( camera || scene || {}).id;

  return (
    <Container>
      <Top>
        <ButtonClose onClick={iveTogglePanelCameras} />
      </Top>
      <CameraList>
        {camerasOrScenes.map( ( cameraOrScene, i ) => (
          <CameraItem key={cameraOrScene.id}>
            <CameraInfo
              isActive={cameraOrScene.id === cameraOrSceneId}
              name={getName( cameraOrScene ) || createName( cameraOrScene, i )}
              onSelect={() => {
                if ( scene ) {
                  iveSetSelectedSceneId( cameraOrScene.id );
                }

                if ( camera ) {
                  iveSetSelectedCameraId( cameraOrScene.id );
                }
              } } />
          </CameraItem>
        ) )}
      </CameraList>
    </Container>
  );
}

CamerasPanelGQL.propTypes = {
  content: PropTypes.object.isRequired,
  camera: PropTypes.object,
  scene: PropTypes.object,
  isRenderHooksOnly: PropTypes.bool
};
