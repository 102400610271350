import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
//
// `hotspots.audio { mouseOver: 'some-uuid-here', click: 'some-uuid-here' }`
//

class SoundsItem extends Component {
  componentDidUpdate ( prevProps ) {
    const { playing } = this.props.sound;

    if ( prevProps.sound.playing !== playing ) {
      if ( playing && this.player ) {
        this.player.seekTo( 0 );
      }
    }
  }

  onEnded ( soundId ) {
    this.props.actions.soundsEnd( soundId );
  }

  ref = player => {
    this.player = player;
  }

  render () {
    const { sound } = this.props;

    return (
      <ReactPlayer
        ref={this.ref.bind( this )}
        key={sound.uuid}
        url={sound.src}
        onEnded={() => this.onEnded( sound.uuid )}
        playing={Boolean( sound.playing )} />
    );
  }
}

SoundsItem.propTypes = {
  sound: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default SoundsItem;
