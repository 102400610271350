import React, { Component } from 'react';
import PropTypes from 'prop-types';
import addquery from 'addquery';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lib/players/FilePlayer';
import { getpathmimetype } from '../utils/mime';
import { isMediaSame } from '../utils/mediaUtils';
import { isSceneLive, isScenePostPlaybackLoop } from '../utils/sceneUtils';

const Container = styled.div`
    height:100%;

    & video {
        display: ${props => props.isVisible ? 'block' : 'none'};
    }
`;

class SceneVideoPlayer extends Component {
  constructor () {
    super();

    const { search } = window.location;

    this.isLogEnabled = /isVideoLog=true/gi.test( search );
    this.isHLSLogEnabled = /isHLSLog=true/gi.test( search );

    // when changing streams, save previous seeks position
    // and restore the position when next scene is loaded
    this.seekPosContinue = 0;

    this.persistenceBuffer = null;
  }

  getContainerElem = () =>
    document.getElementById( 'video' );

  getVideoElem = () =>
    document.getElementById( 'videochild' );

  loadVideoElem = ( videoElem = this.getVideoElem() ) =>
    videoElem && videoElem.load();

  log = ( msg, e ) => {
    if ( this.isLogEnabled ) {
      console.log( `[...] video: ${msg}`, e || '' );
    }
  }

  persistSeekPosition () {
    const videoElem = this.getVideoElem();

    if ( videoElem ) {
      this.seekPosContinue = videoElem.currentTime;
    }
  }

  restoreSeekPosition () {
    const videoElem = this.getVideoElem();

    if ( videoElem && this.seekPosContinue ) {
      videoElem.currentTime = this.seekPosContinue;
      this.seekPosContinue = 0;
    }
  }

  // https://www.npmjs.com/package/react-player#callback-props
  // contrary to documentation played and playedSeconds are undefined --redefine here
  //
  // ex, ({ played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 })
  //
  pulse ({ loaded, loadedSeconds }, videoElem = this.getVideoElem() ) {
    if ( videoElem ) {
      this.props.actions.setVideoProgress({
        playedSeconds: videoElem.currentTime,
        loaded,
        loadedSeconds
      });
    }
  }

  getPropsVideo ( props ) {
    return props.data.select.video;
  }

  urlNormalise ( url ) {
    return url && url.replace( /#.*$/, '' ).replace( /[^/]*\/\//, '' );
  }

  getMediaUrl ( media ) {
    return media && this.urlNormalise( media.file_url );
  }

  didVideoChange ( nextprops ) {
    const nexturl = this.getMediaUrl( this.getPropsVideo( nextprops ) );
    const prevurl = this.getMediaUrl( this.getPropsVideo( this.props ) );

    return nexturl !== prevurl;
  }

  shouldComponentUpdate ( nextProps ) {
    const { data: prevData, media: prevMedia } = this.props;
    const { data: nextData, media: nextMedia, scene: nextScene } = nextProps;
    const prevvideo = prevData.videoplayback || {};
    const nextvideo = nextData.videoplayback || {};

    if ( this.didVideoChange( nextProps ) || (
      prevMedia && nextMedia && (
        !nextMedia.qualityLevel
                    || prevMedia.qualityLevel !== nextMedia.qualityLevel ) ) ) {
      // kludge, load() video use new source after render
      //
      // https://github.com/CookPete/react-player/issues/177
      //
      if ( !prevData.volatile.isVideoRequestLoading && !prevData.volatile.isVideoLoaded ) {
        this.props.actions.startVideoLoading( true );
      }

      if ( nextMedia && nextMedia.id && !nextMedia.qualityLevels && !nextMedia.isPlaylistCreating ) {
        // if hls, creates additional request for playlist
        // else parses local media properties analagous to 'playlist'
        this.props.actions.getPlaylistDisp(
          nextScene.id, nextMedia.id, nextMedia.type, nextMedia.file_url
        );
      }

      if ( isMediaSame( prevMedia, nextMedia ) && !isSceneLive( nextScene ) ) {
        this.persistSeekPosition();
      }

      return true;
    }

    return ( !prevMedia.qualityLevel && nextMedia.qualityLevel )
            || prevData.istransition !== nextData.istransition
            || prevvideo.mute !== nextvideo.mute
            || prevvideo.volume !== nextvideo.volume
            || prevvideo.playing !== nextvideo.playing;
  }

  // define id attribute on <video>, for direct access in other contexts
  //
  // react-player doesn't provide a way to set the id attribute on <video>,
  // and <video> is created at an unknown time, video.id is defined here
  addIdAttribute () {
    const videoElem = this.getVideoElem();

    if ( videoElem ) {
      videoElem.crossOrigin = 'anonymous';
    }
  }

  onReady ( ) {
    // onReady is called for every media but onStart is only
    // called for first media. isStarted used to manage psuedo-onStart
    this.isStarted = false;
    this.addIdAttribute();

    if ( !isSceneLive( this.props.scene ) ) {
      this.restoreSeekPosition();
    }

    this.props.actions.videoIsReady( true );
    this.props.actions.startVideoLoading( false );

    const isAutoplay = window.location.href.indexOf( 'autoplay=1' ) !== -1;
    if ( isAutoplay ) {
      this.props.actions.videoPlay();
    }

    this.log( 'onReady' );
  }

  onPlay ( ) {
    this.startMedia( this.props );
    this.log( 'onPlay' );
  }

  startMedia ( props ) {
    let id;

    this.log( `onStartMedia ${!this.isStarted}` );
    if ( !this.isStarted ) {
      this.isStarted = true;

      id = props.media && props.media.id;

      this.props.actions.mediaStarted( props.data.select.sceneId, id );
    }
  }

  onStart ( ) {
    this.log( 'onStart' );
  }

  isVideoElemPaused () {
    const videoElem = this.getVideoElem();

    return videoElem && videoElem.paused;
  }

  componentDidUpdate () {
    const { videoplayback, istransition } = this.props.data;
    const isplaying = !istransition && videoplayback.playing;

    if ( isplaying && this.isVideoElemPaused() ) {
      this.getVideoElem().play();
    }
  }

  onSeek () {
    const { scene, media } = this.props;

    this.props.actions.mediaSeek( scene.id, media && media.id );
  }

  onBuffer () {
    const { scene, media } = this.props;

    this.props.actions.mediaBuffer( scene.id, media && media.id );
  }

  onBufferEnd () {
    const { scene, media } = this.props;

    this.props.actions.mediaBufferEnd( scene.id, media && media.id );
  }

  // DOMException: The element has no supported sources.
  //
  //
  onError ( e, data, hlsInstance, hlsGlobal ) {
    const { actions } = this.props;
    this.log( 'onError', { e, data });

    // https://github.com/CookPete/react-player/pull/355
    if ( !hlsGlobal ) {
      return;
    }

    switch ( data.type ) {
    case hlsGlobal.ErrorTypes.NETWORK_ERROR:
      // try to recover network error
      // hls: fatal network error encountered, trying to recover
      actions.videoIsRecovering();
      hlsInstance.startLoad();
      break;
    case hlsGlobal.ErrorTypes.MEDIA_ERROR:
      // hls: fatal media error encountered, trying to recover
      actions.videoIsRecovering();
      hlsInstance.recoverMediaError();
      break;
    default:
      actions.addErrorToast( 'HLS: fatal error occurred' );
      // cannot recover
      hlsInstance.destroy();
      break;
    }
  }

  onEnded ( ) {
    const { media, data, actions } = this.props;
    const { id } = media || {};
    const { sceneId } = data.select;

    actions.mediaEnded( sceneId, id );
  }

  //
  // return array of source definitions, used by reactplayer
  //
  // ex, [{ src: 'video.mpg', type: 'video/mpg' }]
  //
  getSrcArr ( media, scene ) {
    let { file_url, mime_type } = media || {};
    const srcarr = [];

    if ( file_url && !mime_type ) {
      mime_type = getpathmimetype( file_url );
    }

    if ( file_url && media.qualityLevels ) {
      srcarr.push({
        src: addquery( file_url, `sceneid=${scene.id}` ),
        type: mime_type
      });
    }

    return srcarr;
  }

  ref = player => {
    this.player = player;
  }

  render () {
    const { data, scene, isVisible, isControls } = this.props;
    const media = this.getPropsVideo( this.props );
    const { istransition, videoplayback } = data;
    const srcarr = this.getSrcArr( media, scene );
    const isplaying = !istransition && videoplayback.playing;

    // array of media objects should/did work, but using w/ beta
    // react-player causes player to never load. pass specific src
    // url rather than array of media objects
    //
    // url definition is different for application/x-mpegURL
    //
    //  * bug: https://github.com/CookPete/react-player/issues/283
    //
    return (
      <Container isVisible={isVisible}>
        <ReactPlayer
          ref={this.ref.bind( this )}
          url={srcarr[0] && srcarr[0].src}
          playing={isplaying}
          playsinline={true}
          onPlay={this.onPlay.bind( this )}
          onStart={this.onStart.bind( this )}
          onReady={this.onReady.bind( this )}
          onEnded={this.onEnded.bind( this )}
          onError={this.onError.bind( this )}
          onSeek={e => this.onSeek( e )}
          onBuffer={e => this.onBuffer( e )}
          onBufferEnd={e => this.onBufferEnd( e )}
          volume={videoplayback.volume}
          muted={videoplayback.mute}
          loop={isScenePostPlaybackLoop( scene )}
          controls={Boolean( isControls )}
          width='100%'
          height='100%'
          id='video'
          style={{ visibility: isVisible ? '' : 'hidden' }}
          config={{
            file: {
              // enable hls debug
              //
              // node_modules/react-player/lib/players/
              hlsVersion: '0.13.0',
              hlsOptions: {
                startLevel: -1,
                debug: this.isHLSLogEnabled,
                autoStartLoad: true
              },

              attributes: {
                crossOrigin: 'anonymous',
                id: 'videochild'
              }
            }
          }}
          progressInterval={100}
          onProgress={this.pulse.bind( this )}
        />
      </Container>
    );
  }
}

SceneVideoPlayer.propTypes = {
  data: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,

  isControls: PropTypes.bool,
  isVisible: PropTypes.bool
};

export default SceneVideoPlayer;
