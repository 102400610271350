import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SoundsItem from './SoundsItem';

const SoundContainer = styled.div`
    display:none;
`;

export default function Sounds ({ actions, scenesounds }) {
  return (
    <SoundContainer>
      { Object.values( scenesounds ).map( sound => (
        <SoundsItem key={sound.uuid} sound={sound} actions={actions} />
      ) ) }
    </SoundContainer>
  );
}

Sounds.propTypes = {
  actions: PropTypes.object.isRequired,
  scenesounds: PropTypes.object.isRequired
};
