import { useState, useEffect, useRef } from 'react';
import useWindowSize from './useWindowSize';

// stage width, 100% width of parent
// stage height, 60% height of parent
const getStageWH = stage => stage
  ? [ Math.floor( stage.offsetWidth ),
    Math.floor( stage.offsetHeight ) ]
  : [ 0, 0 ];

// return media width and height values, scaled to fit stage width and height
// const getScaleStageWH = ( elemId, media ) => media
//     ? scalewh.max([
//         media.resolution.width,
//         media.resolution.height
//     ], getStageWH( elemId ) )
//     : getStageWH( elemId );

// should be updated to publish dimnsions on resize events
// export default function useStageSize ( stageId ) {
export default function useStageSize () {
  const windowSize = useWindowSize();
  const stageElemRef = useRef( null );
  const [ [ width, height ], stageWHSet ] = useState( getStageWH() );

  useEffect( () => {
    const stageElem = stageElemRef.current;
    if ( stageElem ) {
      stageWHSet( getStageWH( stageElem ) );
    }
  }, [ stageElemRef.current, windowSize ]);

  return {
    stageSize: { width, height },
    stageElemRef
  };
}
