import castas from 'castas';
import ismobilejs from 'ismobilejs';
import caramelcookie from 'caramelcookie';

const { hostname } = window.location;

export const urlParams = new URLSearchParams( window.location.search );
export const ismobile = ismobilejs();

export const queryToken = urlParams.get( 'token' );
export const queryHash = urlParams.get( 'hash' );
export const queryContent = urlParams.get( 'content' );
export const queryMediaId = urlParams.get( 'mediaId' );
export const queryInteractiveId = urlParams.get( 'interactiveId' );

export const isIconicHost = /iconicengine\.com$/.test( hostname );

// hash=56siqb64ot5au6zhvuf4&token=TXZfGbxpQJkXfOKd_NxDf
// ?load_story_id=1504ce12-cd3d-4bfc-9893-a10d3e34c6bb
//
// gql host uses GQL login and REST content. ex,
//
//     gql.ive.iconicengine.com # ✔ venom-api
//  gql.player.iconicengine.com
//  gql.editor.iconicengine.com
export const isGqlIconicHost = isIconicHost && /^gql\./.test( hostname );

// beta host uses GQL login and REST content. ex,
//
//    beta.ive.iconicengine.com # venom, legacy
// beta.player.iconicengine.com
// beta.editor.iconicengine.com
export const isBetaIconicHost = isIconicHost && /^beta\./.test( hostname );

// prod host uses GQL login and REST content. ex,
//
//      ive.iconicengine.com # venom, legacy
//   player.iconicengine.com
//   editor.iconicengine.com
export const isProdIconicHost = isIconicHost && ( !isBetaIconicHost && !isGqlIconicHost );

// legacy host uses no login and REST content. ex,
//
//       ive.digitaldomain.com # venom, legacy
// ivyplayer.digitaldomain.com
// workbench.digitaldomain.com
//
export const isLegacyHost = /(venom360|digitaldomain)\.com/.test( hostname );

export const legacy = false;
export const gqlIsSupported = true;
export const gqlIsLogin = true;

// FLAG_GQL_ENABLED is legacy name
export const FLAG_GQL_ENABLED = gqlIsSupported;

export const ICONIC_ENGINE_HOST = 'cms3.iconicengine.com';

export const ICONIC_ENGINE_LABEL = 'Iconic Engine';

export const ICONIC_ENGINE_SIGNIN_URL = 'https://cms3.iconicengine.com/auth/login';

export const HEATMAP_ENDPOINT_URL = 'https://api.iconicengine.com/heatmapping/';

export const ICONIC_PLAYER_URL = 'https://player.iconicengine.com/index.html';

export const ICONIC_EDITOR_HOSTNAME = 'editor.iconicengine.com';

export const SENTRY_DSN = 'https://bd74b867e8a84e579b2ca0dc2f0529ae@sentry.io/229570';


export const VENOM_API_LEGACY = process.env.VENOM_API_LEGACY || '//xr.iconicengine.com';

//  beta: 'https://venom-api-beta.venom360.com'
//  beta-prod: 'https://venom-api-beta-prod.venom360.com'
//  prod: 'https://api.iconic-engine.com'
export const VENOM_API_BASE = urlParams.get( 'venom-api-base' )
    || process.env.VENOM_API_BASE || 'https://venom-api-prod.main.eks.venom360.com'
    || 'https://api.iconicengine.com';

export const AMP_API_KEY = 'e5e2d2853fa4709451135b7fda5d8f99';

// for tiles configuration
export const APPID_MVR_V_TEST = '00efa7c3-68b3-4334-92bf-058e4d17e68c';
export const APPID_MVR_V_COMMON = 'b7e165c7-91f5-43be-8f79-52b5bb7832e9';
export const APPID_MVR_V_STAGING = 'a75d98e6-85d1-4213-954c-e98d6bdb537f';

export const queryTokenPasscode = process.env.QUERY_TOKEN_PASSCODE || '';


export const VERSION = typeof BUILD_VERSION === 'string' ? BUILD_VERSION : '1.0.0';
export const APPTYPE = typeof BUILD_APPTYPE === 'string' ? BUILD_APPTYPE : 'player';
export const ENV = typeof BUILD_ENV === 'string' ? BUILD_ENV : 'development';
export const BEARERTOKEN = process.env.BEARER_TOKEN || '';
export const ENV_ISPROD = ENV === 'production';

export const analyticsIsTestMode = !ENV_ISPROD && castas.bool( urlParams.get( 'analyticsIsTestMode' ), false );
export const analyticsIsLog = castas.bool( urlParams.get( 'analyticsIsLog' ), false );
export const analyticsIsEnabled = APPTYPE === 'player' && castas.bool( urlParams.get( 'analyticsIsEnabled' ), true );

export const vrIsSupportedNative = ismobile.any && !ismobile.windows.device;
export const vrIsSupported = vrIsSupportedNative || castas.bool( urlParams.get( 'vrIsSupported' ), false );

export const xrIsSupportedNative = ismobile.any && !ismobile.windows.device;
export const xrIsSupported = xrIsSupportedNative || castas.bool( urlParams.get( 'xrIsSupported' ), false );

export const heatmapIsEnabled = castas.bool( urlParams.get( 'heatmapIsEnabled' ), false );

if ( typeof window === 'object' ) {
  window.caramelcookie = caramelcookie;
}
