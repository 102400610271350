import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    padding:40px;
    flex:1;
    pointer-events:none;
`;

const Label = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.15;
    color: #fff;
    margin: 0;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
`;

export default function StageTitle ({ children }) {
  return (
    <Container>
      <Label>{children}</Label>
    </Container>
  );
}

StageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ])
};
