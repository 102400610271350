import parseURL from 'url-parse';
import * as m3u8Parser from 'm3u8-parser';

import {
  videolevelCodecsParse,
  videolevelCodecsIsH265
} from './videolevelCodecs.js';

import {
  getContentFormatsFiltered,
  getFormatMediaOrVideo,
  getMediaIdBest,
  getMediaMimeType,
  getMediaUrl
} from './contentUtils';

import {
  getLabelFriendlyResolution
} from './labels';

const parsePlaylist = masterplaylist => {
  const parser = new m3u8Parser.Parser();
  parser.push( masterplaylist );
  parser.end();

  return parser;
};

const videolevelCreateVariantId = ( codecs, resolution, bitrate ) => (
  `variant-${codecs.sort().join( ',' )}-${resolution.width}-${resolution.height}-${bitrate}`
);

const videolevelCreateFromMedia = ( mediaItem, isPrimary = false, formatId ) => ({
  id: getMediaIdBest( mediaItem ),
  value: getMediaIdBest( mediaItem ),
  primary: isPrimary,
  formatId,
  resolution: mediaItem.resolution,
  label: getLabelFriendlyResolution( mediaItem.resolution ),
  type: getMediaMimeType( mediaItem ),
  url: getMediaUrl( mediaItem )
});

const videolevelCreateFromPlaylistItem = ( playlist, index, sourceOrigin, sourceBaseURL ) => {
  const attributes = ( playlist && playlist.attributes ) || {};
  const { RESOLUTION: resolution, BANDWIDTH: bitrate } = attributes;
  const codecs = videolevelCodecsParse( attributes.CODECS );

  return resolution && {
    id: index + 1,
    value: index + 1, // Auto is first
    bitrate,
    resolution,
    codecs,
    frameRate: attributes['FRAME-RATE'],
    variantId: videolevelCreateVariantId( codecs, resolution, bitrate ),
    label: getLabelFriendlyResolution( resolution ),
    type: 'application/x-mpegURL',
    url: playlist.uri.startsWith( 'http' )
      ? playlist.uri // absolute URI
      : playlist.uri.startsWith( '/' )
        ? `${sourceOrigin}${playlist.uri}` // root URI
        : `${sourceBaseURL}/${playlist.uri}` // relative URI;
  };
};

const videolevelCreateAuto = ( url, resolution ) => ({
  id: 0,
  value: 0,
  resolution,
  label: 'Auto',
  primary: true,
  type: 'application/x-mpegURL',
  url
});

const videolevelCreateFromFormat = ( format, isPrimary ) => (
  videolevelCreateFromMedia( getFormatMediaOrVideo( format ), isPrimary, format.id )
);

const videolevelsFilterVariant = videolevels => Object.values(
  videolevels
    .filter( level => !videolevelCodecsIsH265( level.codecs ) )
    .reduce( ( obj, level ) => Object.assign( obj, {
      [level.variantId]: level
    }), {})
);

const videolevelsFromPlaylist = ( mediaItem, masterplaylist ) => {
  const playlistURL = getMediaUrl( mediaItem );
  const sourceBaseURL = playlistURL.split( '/' ).slice( 0, -1 ).join( '/' );
  const sourceOrigin = parseURL( playlistURL ).origin;

  const isPlaylistValidVideo = playlist => {
    return playlist && playlist.attributes && playlist.attributes.RESOLUTION;
  };

  let qualityLevels = ( parsePlaylist( masterplaylist ).manifest.playlists || [])
    .filter( isPlaylistValidVideo )
    .map( ( playlist, i ) => (
      videolevelCreateFromPlaylistItem( playlist, i, sourceOrigin, sourceBaseURL )
    ) );

  qualityLevels = videolevelsFilterVariant( qualityLevels );

  // Sort by height, descending
  qualityLevels.sort( ( a, b ) => b.resolution.height - a.resolution.height );

  if ( !qualityLevels.length ) {
    return qualityLevels;
  }

  // Prepend auto, some VideoInformation data do not include resolution :(
  qualityLevels = [
    videolevelCreateAuto( playlistURL, mediaItem.resolution || qualityLevels[0].resolution ),
    ...qualityLevels
  ];

  return qualityLevels;
};

const videolevelsFromContentVOD = ( playlistState, contentVOD ) => (
  getContentFormatsFiltered( contentVOD ).map( format => (
    videolevelCreateFromFormat( format, format.isPrimary )
  ) )
);

export {
  videolevelCreateVariantId,
  videolevelCreateFromPlaylistItem,
  videolevelCreateFromMedia,
  videolevelCreateFromFormat,
  videolevelsFromContentVOD,
  videolevelsFromPlaylist,
  videolevelsFilterVariant
};
