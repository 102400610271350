import {
  getFriendlyNameForResolution,
  isVODMimeSupported
} from './video';

import { FLAG_GQL_ENABLED } from '../env';

const parseResolutionStr = resolutionStr => {
  const [ width, height ] = resolutionStr
    .split( 'x' )
    .map( num => parseInt( num, 10 ) );

  return { width, height };
};

const parseMp4QualityLevels = media => {
  const formats = Object.values( media.formats || {}).reduce(
    ( formatarr, format ) => {
      const resolution = parseResolutionStr( format.resolution );

      formatarr.push({
        file_url: format.file_url,
        resolution,
        type: 'video/mp4'
      });

      return formatarr;
    }, [ {
      // add top-level format to list
      // ads: 'mime_type', other media: 'type'
      type: media.type || media.mime_type,
      file_url: media.file_url,
      resolution: media.resolution
    } ]
  ).sort(
    // sort by resolution
    ( a, b ) => b.resolution.height - a.resolution.height
  ).filter(
    // remove non-mp4 and duplicate resolution
    // (default media.type could be application/x-mpegURL)
    ( elem, i, arr ) => (
      isVODMimeSupported( elem.type ) && (
        i === 0 || elem.resolution.height !== arr[i - 1].resolution.height )
    )
  ).map( ( format, i ) => ({
    // add additional properties and identify primary url
    ...format,
    label: getFriendlyNameForResolution( format.resolution ),
    value: 32 + i, // arbitrary,
    url: format.file_url,
    primary: format.file_url === media.file_url
  }) );

  // GQL SERVICE returns full formats list
  // legacy api returns formats list, minus primary format
  // because full list is needed, the primary format data is added to list
  if ( !FLAG_GQL_ENABLED && '__typename' in media ) {
    formats.push({
      ...media,
      label: getFriendlyNameForResolution( media.resolution ),
      value: 32,
      url: media.file_url,
      primary: true
    });
  }

  if ( !formats.length ) {
    console.warn( 'Unable to find MP4 quality levels.' );
  }

  return formats || [];
};

// "primary" may not exist when qualityLevels do not include the default
// media url. ex, default url is hls and qualityLevels are mp4
const getPrimaryQualityLevel = qualityLevels =>
  Array.isArray( qualityLevels ) && (
    qualityLevels.find( q => q.primary ) || qualityLevels[0]);

const chooseBestQualityLevel = qualityLevels => {
  let qualityLevel = qualityLevels
    .find( q => q.resolution.width <= 2000 ); // Get the first <2000px wide mp4

  if ( !qualityLevel ) {
    console.warn( 'Unable to find a format less than 2K' );

    [ qualityLevel ] = qualityLevels; // Fall back to first one.
  }

  if ( !qualityLevel ) {
    throw new Error( 'Cannot choose MP4 quality level: there are no formats.' );
  }

  return qualityLevel;
};

const getLowestQualityLevel = qualityLevels => qualityLevels
  .slice().sort( ( qlA, qlB ) => qlA.resolution.width < qlB.resolution.width ? -1 : 1 )[0];

const applyQualityLevel = ( media, qualityLevel ) => {
  if ( qualityLevel ) {
    media = {
      ...media, file_url: qualityLevel.url,
      type: qualityLevel.type,
      resolution: qualityLevel.resolution,
      qualityLevel
    };
  }

  return media;
};

export {
  parseMp4QualityLevels,
  chooseBestQualityLevel,
  getLowestQualityLevel,
  getPrimaryQualityLevel,
  applyQualityLevel
};
