
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorType, getError, customError } from '../errors';
import ButtonClose from './ButtonClose';

const Message = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #b3b2b2;
    display: block;
    margin-top: 10px;
    padding-left: 12px;
`;

toast.configure();

export const toastMessageType = errorType;

class Toasts extends Component {
  constructor ( props ) {
    super();

    this.toastrecords = [];

    this.state = {
      toasts: props.data.toasts
    };
  }

  addrecord = ( messageid, toastid ) => {
    this.toastrecords.push([ messageid, toastid ]);

    return this.toastrecords;
  }

  rmrecord = messageid => {
    this.toastrecords.filter( ([ id ]) => id !== messageid );

    return this.toastrecords;
  }

  getrecord = messageid =>
    this.toastrecords.find( ([ id ]) => id === messageid );

  show = ({
    type, message, toastOpts, name
  }) => {
    const toastId = toast[type](
      <Message dangerouslySetInnerHTML={{ __html: message }} />,
      {className: `toastify-content ${name} type-${type}`, ...toastOpts }
    );

    this.addrecord( name, toastId );
  }

  showSelectToast ( toastName ) {
    const selectedToast = getError( toastName );

    setTimeout( () => {
      this.show( selectedToast || customError( toastName ) );
    }, 200 ); // wait for first render, otherwise react-toastify renders own toast
  }

  close ( messageId ) {
    const message = getError( messageId );
    const record = this.getrecord( message && message.name );

    if ( record ) {
      toast.dismiss( record[1]);
      this.rmrecord( messageId );
    }
  }

  static getDerivedStateFromProps ( nextProps ) {
    return { toasts: nextProps.data.toasts };
  }

  componentDidUpdate ( prevProps, prevState ) {
    const { toasts: prevToasts } = prevState;
    const { toasts: nextToasts } = this.state;

    if ( nextToasts.showToast && nextToasts.showDate !== prevToasts.showDate ) {
      this.showSelectToast( nextToasts.showToast );
    }

    if ( nextToasts.closeToastId && nextToasts.closeToastId !== prevToasts.closeToastId ) {
      this.close( nextToasts.closeToastId );
    }
  }

  shouldComponentUpdate ( nextProps ) {
    const { toasts: nextToasts } = nextProps.data;
    const { toasts: prevToasts } = this.props.data;

    return Boolean( nextToasts && prevToasts && (
      nextToasts.showDate !== prevToasts.showDate
            || nextToasts.showToast !== prevToasts.showToast
            || nextToasts.closeToastId !== prevToasts.closeToastId ) );
  }

  render () {
    return (
      <ToastContainer
        closeButton={<ButtonClose />}
        className="toastify"
        progressClassName="toastify_progress" />
    );
  }
}

Toasts.propTypes = {
  data: PropTypes.object.isRequired
};

export default Toasts;
