import { useEffect, useState, useRef } from 'react';
import * as touchboom from 'touchboom';
import { setCanvasMousePosVector } from '../utils/canvasShapes';
import {
  windowpixelweight,
  elempixelweight,
  pixeltodegree,
  degreetoradian,
  degreetopixel
} from '../utils/units';

const createTouchCoords = ( canvaselem, rotationxy ) => {
  const pw = elempixelweight( canvaselem );

  return touchboom.coords([ {
    autoweight: 50,
    bgn: degreetopixel( rotationxy[0], pw )
  }, {
    autoweight: 50,
    bgn: degreetopixel( rotationxy[1], pw ),
    min: degreetopixel( -90, pw ),
    max: degreetopixel( 90, pw )
  } ]);
};

export default function useMouseGlass () {
  const [ touchCoords, setTouchCoords ] = useState({ coords: null });
  const [ elem, setMouseContainerElem ] = useState();
  const [ onMouseInertia, setMouseOnInertiaFn ] = useState( () => {});
  const [ mouseXYVector, setMouseXYVector ] = useState({ x: 0, y: 0 });
  const isMouseRef = useRef({
    ismousedown: false,
    ismouseup: true,

    ismouseintersecting: false,
    ismousechangeddown: false,
    ismousechangedup: false
  });

  const setIsMouseIntersecting = ismouseintersecting => {
    Object.assign( isMouseRef.current, {
      ismouseintersecting,
      ismousechangeddown: false,
      ismousechangedup: false
    });
  };

  const onMouseUp = () => {
    Object.assign( isMouseRef.current, {
      ismousechangedup: Boolean( isMouseRef.current.ismousedown ),
      ismousedown: false,
      ismouseup: true
    });
  };

  const onMouseDown = () => {
    Object.assign( isMouseRef.current, {
      ismousechangeddown: Boolean( isMouseRef.current.ismouseup ),
      ismousedown: true,
      ismouseup: false
    });
  };

  const onMouseMove = e => {
    const evxy = touchboom.getevxy( e );

    setCanvasMousePosVector( elem, mouseXYVector, evxy );
    // this.setMouseXY( evxy );
  };

  // returns [ radx, rady ];
  const getTouchStateAsRadians = ( touchState, pxwindow ) => (
    touchboom.coordsgettotal( touchState ).map( px => (
      pixeltodegree( px, pxwindow )
    ) ).map( degreetoradian ).reverse()
  );

  const resetCameraRotation = ( elem, camerarot ) => {
    const pxwindow = windowpixelweight();
    // xy-translation coords are peristed on 'this' for mutation elsewhere
    // while still being used here, for example, coords are modified when
    // viewport is rotated or resized. to preserve the cameras rotation
    // xy-translation values here must be updated
    //
    // keep existing coords to preserve previous scene camera rotations
    if ( touchCoords.coords ) {
      // this.applyRotation( getTouchStateAsRadians({ coords: touchCoords }, pxwindow ) );
    } else {
      setTouchCoords({ coords: createTouchCoords( elem, camerarot ) });
    }
  };

  // eslint-disable-next-line no-shadow
  const addEventListeners = elem => {
    const pxwindow = windowpixelweight();

    if ( elem ) {
      // this.touchcfg = touchboom.attach({
      //    coords: this.coords
      // console.log('setting coords', touchCoords.coords );
      touchboom.attach({
        coords: touchCoords.coords
      }, elem, {
        oneventfn: ( cfg, etype, e ) => {
          if ( etype === 'start' ) {
            elem.focus();
            onMouseDown( e );
          }

          if ( etype === 'end' || etype === 'cancel' ) {
            onMouseUp( e );
          }
        },
        oninertiafn: cfg => {
          if ( typeof onMouseInertia.fn === 'function' ) {
            onMouseInertia.fn( getTouchStateAsRadians( cfg, pxwindow ) );
          }
        },
        onmovefn: ( cfg, etype, e ) => {
          onMouseMove( e );
        }
      });
    }
  };

  useEffect( () => {
    if ( elem ) addEventListeners( elem );
  }, [ elem, onMouseInertia ]);

  return {
    isMouseRef,
    mouseXYVector,
    setIsMouseIntersecting,
    setMouseContainerElem,
    setMouseOnInertia: fn => setMouseOnInertiaFn({ fn }),
    resetCameraRotation
  };
}
