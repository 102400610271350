import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getTimecodeFormatted } from '../utils/timecode';

import useCountdown from '../hooks/useCountdown';

const Container = styled.div`
    position:fixed;
    bottom:0;
    right:0;
    left:0;
    width:100%;
    min-height:80px;
    z-index:4;
    display:flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Time = styled.div`
    padding:0 10px;
    color:#fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size:20px;
`;

const Logo = styled.img`
    padding:15px 10px;
    max-height: 50px;
`;

const Label = styled.div`
    padding:0 10px;
    color:#fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size:20px;
`;

export default function AdsPanel ({ media, onCompleted }) {
  const { seconds } = useCountdown( media.duration, { onCompleted });

  return (
    <Container id={'AdsPanel'}>
      {media.logo_url && (
        <Logo src={media.logo_url} />
      )}
      <Label>Advertisement</Label>
      <Time>{getTimecodeFormatted( seconds )}</Time>
    </Container>
  );
}

AdsPanel.propTypes = {
  media: PropTypes.object.isRequired,
  onCompleted: PropTypes.func.isRequired
};
