// known console statements using boilerplate w/ samsung S8+
//
// THREE.WebGLRenderer: OES_texture_float extension not supported.
// THREE.WebGLRenderer: OES_texture_float_linear extension not supported.
// THREE.WebGLRenderer: OES_texture_half_float extension not supported.
// THREE.WebGLRenderer: OES_texture_half_float_linear extension not supported.
// THREE.WebGLRenderer: EXT_texture_filter_anisotropic extension not supported.


import ismobilejs from 'ismobilejs';

import {
  vrload,
  requestPresent,
  exitPresent
} from '../utils/vr';

import {
  VRMODE_TOGGLE,
  VRMODE_START,
  VRMODE_END
} from '../actions/ActionTypes';

const ismobile = ismobilejs();

// webvr polyfill errors on windows phone as of 5/9/17
// cardboard iOS doesn't work for some reason, 5/30/17
const isvrsupportNative = ismobile.any && !ismobile.windows.device;

export const vrmodeDefault = {
  isvrsupportNative,
  isvrsupport: isvrsupportNative || /isvrsupport=true/.test( window.location ),
  isvrenabled: 0
};

// https://html.spec.whatwg.org/multipage/interaction.html#triggered-by-user-activation
//
// intiating fullscreen mode requires a user-generated non-async sequence
// as a result, this sequence fails,
//
//  1. user clicks webvr button
//  2. async-load of webvr-scripts
//  3. request vrmode from webvr-scripts
//
// because of this, the webvr polyfill scripts are loaded here
// if the botton is rendered and if webvr mode is supported
if ( vrmodeDefault.isvrsupport ) {
  vrload( () => console.log( '[...] vrmode: loaded' ) );
}

// see vr script usage in lasher_temp here
//
//  * https://code.venom360.com/platform/lasher-temp/blob/develop/src/360Video.js
//  * https://code.venom360.com/platform/lasher-temp/blob/develop/src/VR.js
//
export function vrModeStart ( state, domElem ) {
  if ( state.isvrsupport ) {
    // prevent webvr runtime error on non-mobile device
    //
    if ( !state.isvrsupportNative ) {
      // window.screen = {
      //    orientation: { lock: () => ({
      //        catch: () => {}
      //    }) }
      // };
    }

    requestPresent( domElem );

    state = {
      ...state,
      isvrenabled: Date.now()
    };
  }

  return state;
}

export function vrModeEnd ( state, domElem ) {
  exitPresent( domElem );

  return {
    ...state,
    isvrenabled: 0
  };
}

export function vrModeToggle ( state, domElem ) {
  return state.isvrenabled
    ? vrModeEnd( state, domElem )
    : vrModeStart( state, domElem );
}

export const vrmodeReducer = ( state, action ) => {
  switch ( action.type ) {
  case VRMODE_START:
    return vrModeStart( state, action.domElem );
  case VRMODE_END:
    return vrModeEnd( state, action.domElem );
  case VRMODE_TOGGLE:
    return vrModeToggle( state, action.domElem );
  default:
    return state;
  }
};
