import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getTimecodeFormatted } from '../utils/timecode';
import useCountdown from '../hooks/useCountdown';

const GUTTERV = 40;

const Container = styled.div`
    top:0;left:0;
    height: 100%;
    position:absolute;
    display: flex;
    flex-direction: column;
    z-index:100;
    justify-content: center;
    padding: 0px ${GUTTERV * 2}px;

    & + > * {
       pointer-events:none;
    }
`;

const TimeLabel = styled.div`
    font-family: Helvetica;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #ff9800;
`;

const TimeList = styled.ul`
    margin:0;
    padding:0;
    margin-top:10px;
    list-style:none;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TimeListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;

    & + li:before {
       content: " ";
       display:inline-block;
       text-align:center;
       width: 1px;
       height: 90px;
       opacity: 0.4;
       background-color:#fff;
       font-family: Helvetica;
       font-size: 86px;
       font-weight: 300;
       font-style: normal;
       font-stretch: normal;
       line-height: 1.16;
       letter-spacing: normal;
       text-align: left;
       color: #ffffff;
       margin:0 15px;
    }
`;

const TimeListItemLabel = styled.span`
    font-family: Helvetica;
    font-size: 86px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display:inline-block;
`;

const PrimaryLabel = styled.div`
    opacity: 0.5;
    font-family: Helvetica;
    font-size: 45px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    margin-top:60px;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
`;

// https://app.zeplin.io/project/58be337c81ed22f98f39cb0b/screen/5aab034afef82379074c6405
//
// coming up...
//
// HHhr | mmm | sss
//
// ward vs rodriguez livestream

const getSecondsRemaining = startTime => {
  const startts = ( new Date( startTime ) ).getTime();
  const nowts = Date.now();

  return ( startts - nowts ) / 1000;
};

const getHoursMinutesSeconds = seconds => (
  getTimecodeFormatted( seconds, 'hh*mm*ss', false ).split( '*' ) );

export default function SlateCountdown ({ startTime, title }) {
  const { seconds } = useCountdown( getSecondsRemaining( startTime ) );
  const [ hh, mm, ss ] = getHoursMinutesSeconds( seconds );

  return (
    <Container>
      <TimeLabel>coming up...</TimeLabel>
      <TimeList>
        <TimeListItem>
          <TimeListItemLabel>{hh}hr</TimeListItemLabel>
        </TimeListItem>
        <TimeListItem>
          <TimeListItemLabel>{mm}m</TimeListItemLabel>
        </TimeListItem>
        <TimeListItem>
          <TimeListItemLabel>{ss}s</TimeListItemLabel>
        </TimeListItem>
      </TimeList>
      <PrimaryLabel>{title || 'Untitled'}</PrimaryLabel>
    </Container>
  );
}

SlateCountdown.propTypes = {
  title: PropTypes.string,
  // timestamp, date or ISO string
  startTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ])
};
