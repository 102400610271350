import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const slideresolution = 1000;

const Label = styled.label`
    width:38px;
`;

const Inputs = styled.div`
    position:relative;
    margin:0 2px;
    transition: padding 0.4s;
    height:24px;
    display:block;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover span {
       background-color: #fff;
    }
    &.vol-1 span:nth-child(1),
    &.vol-2 span:nth-child(1),
    &.vol-2 span:nth-child(2),
    &.vol-3 span:nth-child(1),
    &.vol-3 span:nth-child(2),
    &.vol-3 span:nth-child(3),
    &.vol-4 span:nth-child(1),
    &.vol-4 span:nth-child(2),
    &.vol-4 span:nth-child(3),
    &.vol-4 span:nth-child(4),
    &.vol-5 span:nth-child(1),
    &.vol-5 span:nth-child(2),
    &.vol-5 span:nth-child(3),
    &.vol-5 span:nth-child(4),
    &.vol-5 span:nth-child(5) {
       background-color: var(--ivy-highlight-color-primary);
    }

`;

const InputProgress = styled.progress`
    position:absolute;
    top:0;left:0;
    height:100%;
    width:100%;
    opacity:0;

    border: 0;
    border-radius: 9px;
    padding: 0;
    margin: 0;
`;

const InputRange = styled.input`
    position:absolute;
    top:0;left:0;
    height:100%;
    width:100%;
    opacity:0;
    z-index:3;
    margin: 0;
`;

const Bars = styled.div`
    height: 100%;
    display: inline-flex;
    flex-direction: row;

    & > span + span {
       margin-left: 5px;
    }
`;

const Bar = styled.span`
    height: 100%;
    width: 3px;
    display: inline-block;
    background-color: #bebebe;
    transition: 0.2s ease background-color;
`;

export default function SlideVolume ( props ) {
  const getinputelem = () => {
    return document.getElementById( 'volumeslide' );
  };

  const isslideactive = () => {
    const slideelem = getinputelem( );
    const activeelem = document.activeElement;

    return Boolean(
      slideelem && activeelem && activeelem.isEqualNode( slideelem )
    );
  };

  // returns element from sequential list correspondant to percentnum
  //
  // (['one', 'two', 'three', 'four'], 0)  => 'one'
  // (['one', 'two', 'three', 'four'], .1)  => 'two'
  // (['one', 'two', 'three', 'four'], .49)  => 'two'
  // (['one', 'two', 'three', 'four'], .50)  => 'three'
  // (['one', 'two', 'three', 'four'], .99)  => 'three'
  // (['one', 'two', 'three', 'four'], 1)  => 'four'
  const getpercentelem = ( percentnum, statearr ) => {
    let elem = null;
    const elemlist = statearr || [];
    const len = elemlist.length;
    const getper = ( elemlista, percentnuma ) => (
      percentnuma <= 1 / elemlista.length
        ? elemlista[0]
        : getper( elemlista.slice( 1 ), percentnuma ) );

    if ( percentnum === 0 ) {
      [ elem ] = elemlist;
    } else if ( percentnum === 1 ) {
      elem = elemlist[len - 1];
    } else if ( len < 3 ) {
      elem = elemlist[Math.round( percentnum )];
    } else {
      elem = getper( elemlist.slice( 1, -1 ), percentnum );
    }

    return elem;
  };

  const getslidepos = () => {
    const slideelem = getinputelem( );

    return slideelem && ( slideelem.value / slideelem.max );
  };

  const { volume, mute, onChange, states } = props;
  const slidepos = isslideactive( )
    ? Number( !mute && getslidepos( ) )
    : Number( !mute && volume );
  const state = getpercentelem( slidepos, states );

  return (
    <Label>
      <Inputs className={`vol-${state}`}>
        <InputProgress
          tabindex={0}
          value={slidepos}
          min={0}
          max={1} />
        <InputRange
          id="volumeslide"
          type={'range'}
          value={slidepos * slideresolution}
          min={0}
          max={slideresolution}
          onChange={ e => onChange( e, getslidepos() )} />
        <Bars>
          {states.slice( 1 ).map( s => (
            <Bar key={s} />
          ) ) }
        </Bars>
      </Inputs>
    </Label>
  );
}

SlideVolume.propTypes = {
  states: PropTypes.array.isRequired,
  mute: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool
};
