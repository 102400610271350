import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VERSION } from '../env';
import { getBestFormat, getTimecodeFormatted } from '../utils/timecode';
import ButtonClose from './ButtonClose';
import BaseDropdown from './BaseDropdown';
import LabelArea from './LabelArea';
import LinkUnstyled from './LinkUnstyled';
import DropdownProjection from './DropdownProjection';

import {
  getMediaSizeMB,
  getMediaUrl,
  getMediaDurationSS,
  getMediaDurationMS,
  getSceneProjection
} from '../utils/contentUtils';

import {
  getWithoutURLProtocol,
  getLabelBitrate,
  getLabelMimeType,
  getLabelRotations,
  getLabelPercent,
  getLabelNarrow,
  getLabelSize,
  labelProjectionMap
} from '../utils/labels';

import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';
import useLocalPlaylist from '../hooks/useLocalPlaylist';
import useLocalStage from '../hooks/useLocalStage';
import useLocalIVE from '../hooks/useLocalIVE';

export const Container = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    padding-bottom: 20px;
    @media (max-width: 700px) {
       padding-bottom: 0px;
       height: 100vh;
    }
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const Top = styled.div`
    text-align:right;
`;

export const Columns = styled.div`
    display:flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
    margin: 10px 0;
`;

export const List = styled.ul`
    margin:0;
    padding:0;

    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 20px;
    flex:1;
    &:first-child {
       border-left:0;
       margin-left:0;
    }

    &:last-child {
       margin-right: 20px;
    }

    @media (max-width: 700px) {
       margin-bottom: 20px;
    }
`;

export const ListItem = styled.li`
    margin:0;
    padding:0;
    list-style:none;
    margin-top: 16px;
    &:first-child {
        margin-top: 0;
    }
`;

export const InputColumns = styled.div`
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 20px;
`;

export const InputList = styled.ul`
    margin:0;
    padding:0;

    border-left:0;

    flex:1;
    &:first-child {
       border-left:0;
       margin-left:0;
    }
`;

export default function PanelPlayerSettingsGQL ({ media, scene, isRenderHooksOnly }) {
  const {
    iveState,
    iveSetSelectedProjection,
    iveTogglePanelStats
  } = useLocalIVE();

  const {
    toggleQualityLevel,
    playlistState
  } = useLocalPlaylist();

  const {
    stageState
  } = useLocalStage();

  const {
    mediaPlaybackState
  } = useLocalMediaPlayback();

  if ( isRenderHooksOnly === true )
    return <Container style={{ display: 'none' }}/>;

  const currentProjection = iveState.selectedProjection || getSceneProjection( scene );
  const timeformat = getBestFormat( getMediaDurationMS( media, 120 ) );
  const timessFormatted = getTimecodeFormatted( mediaPlaybackState.currentTime, timeformat );

  return (
    <Container>
      <Top>
        <ButtonClose onClick={iveTogglePanelStats} />
      </Top>
      <InputColumns>
        <InputList>
          <ListItem>
            {playlistState.qualityLevel && (
              <LabelArea label="Quality">
                <BaseDropdown
                  options={playlistState.qualityLevels}
                  selected={playlistState.qualityLevel}
                  onChange={e => toggleQualityLevel(
                    e.value, mediaPlaybackState.currentTime
                  )} />
              </LabelArea>
            )}
          </ListItem>
        </InputList>
        <InputList>
          <ListItem>
            <LabelArea label="Projection">
              <DropdownProjection
                selectedValue={currentProjection}
                onChange={e => iveSetSelectedProjection( e.value )} />
            </LabelArea>
          </ListItem>
        </InputList>
        <InputList />
      </InputColumns>
      <Columns>
        <List>
          <ListItem>
            <LabelArea label="type">
              {getLabelMimeType( media.mediaType )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="projection">
              {labelProjectionMap[currentProjection] || 'default'}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="url">
              none
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="resolution">
              {getLabelSize( media.resolution )}
            </LabelArea>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <LabelArea label="loaded">
              {getLabelPercent( mediaPlaybackState.loaded )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="video size">
              {getMediaSizeMB( media, 'unknown' )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="duration">
              {String( getMediaDurationSS( media, Math.Infinity ) )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="timeposition">
              {timessFormatted}
            </LabelArea>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <LabelArea label="bitrate audio">
              {getLabelBitrate( media.audioBitrate )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="bitrate media">
              {getLabelBitrate( media.videoBitrate )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="rotations">
              {getLabelRotations( stageState.cameraRotationRadians )}
            </LabelArea>
          </ListItem>
          <ListItem>
            <LabelArea label="version">
              {VERSION}
            </LabelArea>
          </ListItem>
        </List>
      </Columns>
    </Container>
  );
}

PanelPlayerSettingsGQL.propTypes = {
  media: PropTypes.object.isRequired,
  scene: PropTypes.object,
  isRenderHooksOnly: PropTypes.bool
};
