import { PostPlaybackEnum, ContentTypeEnum } from '../enums';

const getSequenceHotspot = ( seq, defaultHotspot = null ) => {
  if ( seq && seq.__typename === 'HotspotSceneSequence' )
    defaultHotspot = seq.hotspot;

  return defaultHotspot;
};

// uses id from data found in sequence to return a "sequence id"
const getSequenceId = ( seq, defaultId = null ) => {
  const sequenceHotspot = getSequenceHotspot( seq );

  if ( sequenceHotspot )
    defaultId = sequenceHotspot.id;

  return defaultId;
};

const getSequenceName = ( seq, defaultName = '' ) => (
  ( seq && typeof seq.name === 'string' )
    ? seq.name
    : defaultName
);

const getSequenceDestination = seq =>
  seq.destination;

const getHotspotPosArr = hotspot => {
  const { x, y, z } = hotspot.position;

  return [ x, y, z ];
};

const getHotspotScaleArr = hotspot => {
  const { x, y } = hotspot.scale;

  return [ x, y ];
};

const getLanguageTitle = lang => lang && lang.title;

const isFormat = format => Boolean(
  format && format.__typename === 'VodFormat'
);

const isFormatDefault = format => format && format.isPrimary;

const isCamera = camera => Boolean(
  camera && camera.__typename === 'Camera'
);

const isCameraDefault = camera => camera && camera.isDefault;

const getCameraTitle = camera => (
  camera && getLanguageTitle( camera.primaryLanguage )
);

const getCameraMediaOrVideo = camera => (
  camera && ( camera.media || camera.video )
);

const getFormatMediaOrVideo = format => (
  format && ( format.media || format.video )
);

const getMediaUrl = media =>
  media && media.file && media.file.url;

// VideoInformation data have no id :(, use url
const getMediaIdBest = media =>
  ( media && media.id ) || getMediaUrl( media );

const getMediaType = media =>
  media && media.mediaType;

const getMediaMimeType = getMediaType;

const getMediaClass = media =>
  media && media.mediaClass;

const getMediaTitle = ( media, defaultTitle = '' ) =>
  ( media && media.title ) || defaultTitle;

const getMediaProjection = media =>
  media && media.projection;

const getMediaVideoCodec = media =>
  media && media.videoCodec;

const getMediaDurationMS = ( media, defaultMS = 0 ) =>
  ( media && media.duration && media.duration.ms ) || defaultMS;

const getMediaDurationSS = ( media, defaultSS = 0 ) =>
  ( media && media.duration && media.duration.seconds ) || defaultSS;

const getMediaSizeMB = ( media, defaultMB = 0 ) =>
  ( media && media.size && media.size.mb ) || defaultMB;

const getMediaTranscodeStatus = media =>
  ( media && media.transcodeStatus );

const getMediaResolution = ( media, defaultResolution = { width: 0, height: 0 }) =>
  ( media && media.resolution ) || defaultResolution;

const isMediaResolutionValid = ( media, resolution = getMediaResolution( media ) ) => (
  resolution
        && resolution.height
        && resolution.width
);

const isMediaVideoCodecSupported = media => (
  !/h265|hevc/gi.test( getMediaVideoCodec( media ) ) );

const isMediaPlayable = media => (
  getMediaProjection( media )
        && getMediaUrl( media )
        && getMediaDurationMS( media )
        && !/IN_PROGRESS|ERROR/i.test( getMediaTranscodeStatus( media ) )
        && isMediaResolutionValid( media )
);

const getSceneName = ( scene, defaultName ) =>
  scene && typeof scene.name === 'string'
    ? scene.name
    : defaultName;

const getSceneSequences = scene =>
  ( scene && scene.sequences ) || [];

const getSceneSequencesType = ( scene, seqType ) =>
  getSceneSequences( scene ).filter( seq => seq.__typename === seqType );

const getSceneSequencesTypeHotspot = scene =>
  getSceneSequencesType( scene, 'HotspotSceneSequence' );

const getSceneSequence = ( scene, sequenceId, defaultSequence = null ) => (
  sequenceId
    ? getSceneSequences( scene ).find( seq => getSequenceId( seq ) === sequenceId ) || defaultSequence
    : defaultSequence
);

const getSceneVideoPrimary = scene => {
  const videoSequence = scene && scene.sequences
    .find( seq => seq.__typename === 'VideoSceneSequence' );

  return videoSequence && videoSequence.media;
};

const getSceneMediaPrimary = getSceneVideoPrimary;

const getSceneProjection = scene => (
  getMediaProjection( getSceneMediaPrimary( scene ) ) );

const getScenePostPlayback = scene =>
  scene.postPlayback;

const getScenePostPlaybackAction = scene => scene
    && scene.postPlayback
    && scene.postPlayback.action;

const isScenePostPlaybackLoop = scene =>
  getScenePostPlaybackAction( scene ) === PostPlaybackEnum.LOOP;

const getInteractiveStartSceneId = interactive => (
  interactive && interactive.startScene && interactive.startScene.id
);

const getInteractiveScenes = interactive => (
  ( interactive && interactive.scenes ) || []
);

const getInteractiveScene = ( interactive, sceneId, scenes = getInteractiveScenes( interactive ) ) => (
  scenes.find( scene => scene.id === sceneId )
);

const getInteractiveSceneDefault = ( interactive, scenes = getInteractiveScenes( interactive ) ) => {
  const defaultSceneId = getInteractiveStartSceneId( interactive );

  return ( defaultSceneId && scenes.find( scene => scene.id === defaultSceneId ) )
        || scenes[0];
};

const getInteractiveSceneOrDefault = ( interactive, sceneId ) => (
  sceneId
    ? getInteractiveScene( interactive, sceneId ) || getInteractiveSceneDefault( interactive )
    : getInteractiveSceneDefault( interactive )
);

const getInteractiveSequence = ( interactive, sceneId, sequenceId ) => {
  const scene = getInteractiveScene( interactive, sceneId );

  return getSceneSequence( scene, sequenceId );
};

const getInteractiveHotspot = ( interactive, sceneId, hotspotId ) => {
  const sequence = getInteractiveSequence( interactive, sceneId, hotspotId );

  return sequence && sequence.hotspot;
};

const getContentInteractive = ( content, defaultInteractive ) => (
  content.ref.interactive || defaultInteractive
);

const getContentInteractiveSequence = ( content, ...rest ) => (
  getInteractiveSequence( getContentInteractive( content ), ...rest )
);

const getContentStartSceneId = content => (
  getInteractiveStartSceneId( getContentInteractive( content ) )
);

const getContentScenes = ( content, defaultScenes = []) => (
  getContentInteractive( content ).scenes || defaultScenes
);

const getContentAd = content => (
  content.ref.ad
);

const getContentCameras = ( content, defaultCameras = []) => (
  content.ref.cameras || defaultCameras
);

const getContentCamera = ( content, cameraId, cameras = getContentCameras( content ) ) => (
  cameras.find( camera => camera.id === cameraId )
);

const getContentCameraDefault = ( content, cameras = getContentCameras( content ) ) => (
  cameras.find( isCameraDefault ) || cameras[0]
);

const getContentCameraOrDefault = ( content, cameraId ) => (
  cameraId
    ? getContentCamera( content, cameraId ) || getContentCameraDefault( content )
    : getContentCameraDefault( content )
);

const getContentScene = ( content, sceneId ) => (
  getInteractiveScene( getContentInteractive( content ), sceneId )
);

const getContentSceneDefault = content => (
  getInteractiveSceneDefault( getContentInteractive( content ) )
);

const getContentSceneOrDefault = ( content, sceneId ) => (
  getInteractiveSceneOrDefault( getContentInteractive( content ), sceneId )
);

const getContentAvailability = ( content, defaultAvailability = null ) => (
  content && content.availability
) || defaultAvailability;

const getDateStringTime = dateStr => dateStr && new Date( dateStr ).getTime();

const getContentAvailabilityStart = ( content, defaultStart = null ) => (
  getContentAvailability( content, {}).start || defaultStart
);

const getContentAvailabilityStartTime = content => getDateStringTime(
  getContentAvailabilityStart( content )
);

const getContentAvailabilityEnd = ( content, defaultEnd = null ) => (
  getContentAvailability( content, {}).end || defaultEnd
);

const getContentAvailabilityEndTime = content => getDateStringTime(
  getContentAvailabilityEnd( content )
);

const isContentStartFuture = content => (
  getContentAvailabilityStartTime( content ) || -Math.Infinity ) > Date.now();

const isContentEndPast = content => (
  getContentAvailabilityEndTime( content ) || Math.Infinity ) < Date.now();

const isContentInteractive = content => (
  content && content.type === ContentTypeEnum.INTERACTIVE );

const isContentStream = content => (
  content && content.type === ContentTypeEnum.STREAM );

const isContentVOD = content => (
  content && content.type === ContentTypeEnum.VOD );

const isContentAd = content => (
  content && content.type === ContentTypeEnum.AD );

const isContentMedia = media => (
  media && media.__typename === 'Media' );

const getContentCamerasOrScenes = content => isContentInteractive( content )
  ? getContentScenes( content )
  : getContentCameras( content );

const getContentArt = content =>
  content && content.art;

const getContentArtKeyUrl = content => {
  const art = getContentArt( content );

  return art && art.key && art.key.url;
};

const getContentArtTileUrl = content => {
  const art = getContentArt( content );

  return art && art.tile && art.tile.url;
};

const getContentTitle = ( content, defaultTitle ) => {
  const title = content && getLanguageTitle( content.primaryLanguage );

  return typeof title === 'string' ? title : defaultTitle;
};

const getFormatResolution = format => (
  getFormatMediaOrVideo( format ) || {}
).resolution;

const getFormatId = format => {
  const { width, height } = getFormatResolution( format );

  return `${width}${height}`;
};

const getContentFormats = ( content, defaultFormats = []) => (
  content.ref.formats || defaultFormats
);

const getContentFormat = ( content, formatId, formats = getContentFormats( content ) ) => (
  formats.find( format => format.id === formatId )
);

const getContentFormatDefault = ( content, formats = getContentFormats( content ) ) => (
  formats.find( isFormatDefault ) || formats[0]
);

// removes duplicate formats of same reslution
const getContentFormatsFiltered = ( content, defaultFormats = []) => (
  getContentFormats( content ).reduce( ( o, format ) => {
    const formatId = getFormatId( format );

    if ( !o.map[formatId]) {
      o.map[formatId] = format;
      o.formats.push( format );
    }

    return o;
  }, { map: {}, formats: [] }).formats || defaultFormats
);

// VideoInformation data have no id and url sometimes used instead
const getContentMediaOrVideoFuzzy = ( content, idOrUrl ) => {
  if ( content.type !== ContentTypeEnum.VOD ) {
    throw new Error( 'Not Implemented for this Content type' );
  }

  const format = getContentFormats( content ).find( format => {
    const mediaOrVideo = getFormatMediaOrVideo( format );

    return mediaOrVideo.id === idOrUrl || getMediaUrl( mediaOrVideo ) === idOrUrl;
  });

  return format && getFormatMediaOrVideo( format );
};

function getContentFirstMedia ( content, defaultValue ) {
  let media;

  if ( isContentVOD( content ) )
    media = getFormatMediaOrVideo( getContentFormatDefault( content ) );
  else if ( isContentStream( content ) )
    media = getCameraMediaOrVideo( getContentCameraDefault( content ) );
  else if ( isContentInteractive( content ) )
    media = getSceneMediaPrimary( getContentSceneDefault( content ) );

  return media || defaultValue;
}

const getApplicationId = application => (
  application && application.id
);

const getApplicationAmplitude = ( application, defaultAmplitude ) => (
  application && application.amplitude
) || defaultAmplitude;

const getApplicationAmplitudeKey = ( application, defaultKey = '' ) => (
  getApplicationAmplitude( application, {}).key
) || defaultKey;

const getApplicationAmplitudeProjectId = ( application, defaultId = '' ) => (
  getApplicationAmplitude( application, {}).projectId
) || defaultId;

const isContentInteractiveValid = content => {
  const scenes = getContentScenes( content );

  return scenes.length
        && scenes.every( getSceneMediaPrimary );
};

const isContentVODValid = content => {
  const formats = getContentFormats( content );

  // each needs a non-zero duration, resolution.width and height
  return formats.length
        && formats.every( format => {
          const mediaOrVideo = getFormatMediaOrVideo( format );

          return mediaOrVideo
                && getMediaDurationMS( mediaOrVideo ) > 0
                && isMediaResolutionValid( mediaOrVideo );
        });
};

const isContentVideoSupported = content => (
  isMediaVideoCodecSupported( getContentFirstMedia( content ) ) );

const createContentVODFromMedia = media => ({
  id: media.id,
  primaryLanguage: { title: media.title },
  type: ContentTypeEnum.VOD,
  ref: {
    formats: [ {
      id: media.id,
      isPrimary: true,
      media
    } ]
  }
});

export {
  createContentVODFromMedia,
  getContentAvailability,
  getContentAvailabilityStartTime,
  getContentAvailabilityEndTime,
  getContentArtTileUrl,
  getContentArtKeyUrl,
  getContentAd,
  getContentInteractive,
  getContentInteractiveSequence,
  getContentStartSceneId,
  getContentFirstMedia,
  getContentMediaOrVideoFuzzy,
  getContentCamerasOrScenes,
  getContentFormat,
  getContentFormats,
  getContentFormatsFiltered,
  getContentCameraOrDefault,
  getContentCameraDefault,
  getContentCamera,
  getContentCameras,
  getContentScenes,
  getContentSceneOrDefault,
  getContentSceneDefault,
  getContentScene,
  getContentTitle,
  isContentVideoSupported,
  isContentEndPast,
  isContentStartFuture,
  isContentAd,
  isContentVOD,
  isContentVODValid,
  isContentStream,
  isContentMedia,
  isContentInteractive,
  isContentInteractiveValid,
  isFormat,
  isCamera,
  getCameraTitle,
  getCameraMediaOrVideo,
  getFormatId,
  getFormatMediaOrVideo,
  getMediaUrl,
  getMediaType,
  getMediaIdBest,
  getMediaMimeType,
  getMediaClass,
  getMediaTitle,
  getMediaProjection,
  getMediaDurationMS,
  getMediaDurationSS,
  getMediaSizeMB,
  isMediaPlayable,
  getInteractiveScenes,
  getInteractiveSceneOrDefault,
  getInteractiveSceneDefault,
  getInteractiveScene,
  getInteractiveSequence,
  getInteractiveHotspot,
  getSceneName,
  getSceneVideoPrimary,
  getSceneMediaPrimary,
  getSceneSequencesTypeHotspot,
  getSceneSequence,
  getSceneProjection,
  getScenePostPlayback,
  isScenePostPlaybackLoop,
  getSequenceId,
  getSequenceName,
  getSequenceHotspot,
  getSequenceDestination,
  getHotspotPosArr,
  getHotspotScaleArr,
  getApplicationId,
  getApplicationAmplitude,
  getApplicationAmplitudeKey,
  getApplicationAmplitudeProjectId
};
