import React from 'react';
import PropTypes from 'prop-types';
import CardboardSVG from '../svg/google_cardboard_icon.svg';
import BaseButtonIcon from './BaseButtonIcon';

export default function ButtonToggleVR ( props ) {
  const { onClick = () => {}, isVREnabled, iconSquare = '25px' } = props;
  const title = isVREnabled ? 'disable VR' : 'enable VR';

  return (
    <BaseButtonIcon
      onClick={() => onClick({ value: !isVREnabled })}
      title={title}
      isEnabled={isVREnabled}>
      <CardboardSVG width={iconSquare} height={iconSquare} />
    </BaseButtonIcon>
  );
}

ButtonToggleVR.propTypes = {
  onClick: PropTypes.func,
  iconSquare: PropTypes.string,
  isVREnabled: PropTypes.bool.isRequired
};
