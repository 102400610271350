import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonCog from './ButtonCog';
import ButtonToggleVR from './ButtonToggleVR';
import ButtonToggleHeatmap from './ButtonToggleHeatmap';
import ButtonToggleVolume from './ButtonToggleVolume';
import ButtonToggleFullscreen from './ButtonToggleFullscreen';
import ButtonTogglePlayPauseReplay from './ButtonTogglePlayPauseReplay';

import LabelTime from './LabelTime';
import LabelLive from './LabelLive';
import SlideVolume from './SlideVolume';
import ButtonCameras from './ButtonCameras';

import {
  getMediaDurationSS,
  getContentCamerasOrScenes
} from '../utils/contentUtils';

import useLocalStage from '../hooks/useLocalStage';
import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';
import useLocalIVE, { PANELS } from '../hooks/useLocalIVE';

const Container = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    margin:12px;
`;

const Left = styled.ul`
    flex:1;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
`;

const Item = styled.li`
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Right = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
`;

export default function PlayerControlsButtonsGQL ({ media, content }) {
  const {
    mediaSetVolume,
    mediaIsScrubberTime,
    mediaScrubberTime,
    mediaPlaybackState,
    togglePlaying,
    toggleMute
  } = useLocalMediaPlayback();

  const {
    iveState,
    iveTogglePanelStats,
    iveTogglePanelCameras
  } = useLocalIVE();

  const {
    stageState,
    stageXrSessionToggle
  } = useLocalStage();

  const camerasOrScenes = getContentCamerasOrScenes( content );

  const isLive = false;
  const isHeatSupport = false;

  return (
    <Container>
      <Left>
        <Item>
          <ButtonTogglePlayPauseReplay
            isPlaying={mediaPlaybackState.playing}
            hasPlayed={mediaPlaybackState.hasPlayed}
            onClick={togglePlaying} />
        </Item><Item>
          <LabelTime
            durationss={getMediaDurationSS( media, 0 )}
            timess={mediaIsScrubberTime
              ? mediaScrubberTime
              : mediaPlaybackState.currentTime} />
        </Item><Item>
          <ButtonToggleVolume
            isEnabled={Boolean( mediaPlaybackState.mute && mediaPlaybackState.volume )}
            onClick={toggleMute} />
          <SlideVolume
            mute={mediaPlaybackState.mute}
            volume={mediaPlaybackState.volume}
            states={[ 0, 1, 2, 3, 4, 5 ]}
            onChange={( e, value ) => mediaSetVolume( value )} />
        </Item>
        {isLive && (
          <Item>
            <LabelLive />
          </Item>
        )}
      </Left>
      <Right>
        {isHeatSupport && (
          <Item>
            <ButtonToggleHeatmap
              isHeatEnabled={isHeatSupport}
              onClick={() => { /* toggleHeatMode */ }} />
          </Item>
        )}
        {camerasOrScenes.length > 1 && (
          <Item>
            <ButtonCameras
              isActive={iveState.activePanel === PANELS.PANELCAMERAS}
              onClick={iveTogglePanelCameras}
              cameranum={camerasOrScenes.length} />
          </Item>
        )}
        <Item>
          <ButtonCog
            title={'menu'}
            isPressed={iveState.activePanel === PANELS.PANELSTATS}
            onClick={iveTogglePanelStats} />
        </Item>
        {stageState.xrIsSupported && (
          <Item>
            <ButtonToggleVR
              isVREnabled={Boolean( stageState.xrIsEnabled )}
              onClick={stageXrSessionToggle} />
          </Item>
        )}
        <Item>
          <ButtonToggleFullscreen />
        </Item>
      </Right>
    </Container>
  );
}

PlayerControlsButtonsGQL.propTypes = {
  media: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};
