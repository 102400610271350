import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    font-family: var(--ivy-font-family-primary);
`;

const Label = styled.span`
    display: block;

    font-size: var(--ivy-label-area-label-font-size);
    color: var(--ivy-label-area-label-color);

    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.22;
    white-space: nowrap;
    margin-top: 0;
`;

const Value = styled.span`
    display: block;

    font-size: var(--ivy-label-area-value-font-size);
    color: var(--ivy-label-area-value-color);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    white-space: nowrap;
`;

export default function LabelArea ({ label, children, title }) {
  return (
    <Container title={title}>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </Container>
  );
}

LabelArea.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.array
  ])
};
