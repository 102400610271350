import ismobilejs from 'ismobilejs';
import queryString from 'query-string';
import * as adapter from '../adapter';
import {
  FLAG_GQL_ENABLED,
  APPTYPE,
  gqlIsLogin
} from '../env';

import {
  SELECT_SEQUENCE,
  SELECT_HOTSPOT,
  SET_ZOOM,
  TOGGLE_PREVIEW_MODE,
  SCENE_UP,
  SCENE_DOWN,
  SCENE_SET_VIDEO,
  UPDATE_SAVE_INDICATOR,

  CANVAS_TOGGLE,
  TYPEACTIONS,
  TOGGLE_OVERLAY,
  IS_VIDEO_REQ_LOADING,
  VIDEO_IS_READY,

  DELETE_CONFIRMATION_MODAL_IS_OPEN,
  ADD_ERROR_TOAST,
  UPLOAD_IS_FINISHED,
  UPLOAD_IS_ERROR,
  AD_FINISHED
} from '../actions/ActionTypes';

import {
  isUserAuthorizedStory
} from '../utils/userUtils';

import {
  getSceneMediaSequence
} from '../utils/sceneUtils';

import {
  setSequenceListVideoSeq
} from '../utils/seqUtils';

import { modalReducer, getModalDefault } from './modal';
import { toastReducer, toastDefault } from './toast';
import { storyReducer, getStoryAdapted, getStoryGQLAdapted } from './story';
import { setSceneSequence, setSceneHotspot, removeSceneSequence, mountNextStateScene } from './scene';
import { soundsReducer, soundsDefault } from './sounds';
import { transitionReducer } from './transition';
import { vrmodeReducer, vrmodeDefault } from './vrmode';
import { apiUserReducer, apiUserDefault } from './apiuser';
import { heatmapReducer, heatmapDefault } from './heatmap';
import { amplitudeAnalyticsReducer, amplitudeAnalyticsDefault } from './amplitudeAnalytics';
import { fullscreenmodeReducer, fullscreenmodeDefault } from './fullscreenmode';
import { drawmodeReducer, drawmodeDefault } from './drawmode';
import { videoplaybackReducer, videoplaybackDefault } from './videoplayback';
import { hotspotReducer } from './hotspot';

const ismobile = ismobilejs();

const getStoryDefault = () => ({
  user: {},
  authtoken: '',
  orgs: {},
  scenes: {},
  hotspots: {},
  toasts: toastDefault,
  sounds: soundsDefault,
  istransition: false,
  isFeature_hotspotdraw: /ishotspotdraw=true/.test( window.location.search ),
  isFeature_dropprojection: /isdropprojection=true/i.test( window.location.search ),

  camerarot: [ 0, 0 ], // camera rotation, ANDY
  canvastype: APPTYPE === 'player'
    ? 'projection'
    : 'rectangle',

  // ip address defaults to player --for testing on remote device
  iseditor: APPTYPE === 'editor',
  isplayer: APPTYPE === 'player',
  apptype: APPTYPE,

  islive: /islive=true/.test( window.location.search ),
  previousScenes: [],

  fullscreenmode: fullscreenmodeDefault,
  vrmode: vrmodeDefault,
  heatmap: heatmapDefault,
  amplitudeAnalytics: amplitudeAnalyticsDefault,
  FLAG_GQL_ENABLED,
  bearerToken: localStorage.getItem( 'editor-token' ),
  apiUser: apiUserDefault,

  medialibrary: {},
  accessMedia: {
    audio: [],
    image: [],
    video: []
  },

  // to replace data.volatile and misc properties in other namespaces
  appSettings: {
    startQuality: ( APPTYPE === 'editor' || ismobile.any ) ? 'lowest' : 'auto'
  },

  select: {
    // temporarily provide a default interactive content id
    contentId: queryString.parse( window.location.search ).content || '7806f0d7-4d45-4cd3-919f-2447ea6e8f11',
    sequenceId: null,
    hotspotId: null,

    video: null,
    sceneId: null,
    storyId: null,
    postplaybacksceneId: null,
    linkedsceneId: null,
    orgId: null,
    propertyId: null,
    collectionId: null
  },

  videoplayback: videoplaybackDefault,

  modals: getModalDefault(),

  drawmode: drawmodeDefault,

  volatile: {
    isUpdating: false,
    isUpdatingSuccess: false,

    // isOpenScenePanel: false,
    isStoryRequestLoading: false,
    isStoryRequestError: false,
    isSessionRequestLoading: false,
    isSessionRequestError: false,
    isVideoRequestLoading: false,
    isVideoCreateLoading: false,
    isOverlayVisible: false,
    isPreviewMode: false,
    zoom: 1
  }
});

const upsertScene = ( state, scene ) => ({
  ...state,
  scenes: {
    ...state.scenes,
    [scene.id]: scene
  }
});

const assignScene = ( state, sceneId, delta ) =>
  upsertScene( state, {
    ...state.scenes[sceneId],
    ...delta,
    modified_ts: Date.now(),
    id: sceneId
  });

const assignSelection = ( state, delta ) => ({
  ...state,
  select: {
    ...state.select,
    ...delta
  }
});

const upScene = state => ({
  ...state
});

const downScene = state => ({
  ...state
});

const createVideo = ( state, action ) => {
  const sceneId = action.sceneId || state.select.sceneId;
  const scene = state.scenes[sceneId];

  if ( !scene ) {
    console.error( 'cannot create video, scene not found', sceneId, state, scene );
    return state;
  }

  state = assignScene( state, sceneId, {
    media_dict: { [action.video.id]: action.video },
    sequences: setSequenceListVideoSeq( scene.sequences, action.sequence )
  });

  state = assignSelection( state, {
    video: action.video
  });

  return state;
};

const setIsUpdating = ( state, isUpdating ) => ({
  ...state,
  volatile: {
    ...state.volatile,
    isUpdating
  }
});

const setIsUpdatingSuccess = ( state, isUpdatingSuccess ) => ({
  ...state,
  volatile: {
    ...state.volatile,
    // using 'date' as truthy value allows multiple succesful
    // updtates to be compared / differentiated by date
    isUpdatingSuccess: isUpdatingSuccess && Date.now()
  }
});

const mainReducer = ( state = getStoryDefault(), action ) => {
  state = {
    ...state,
    fullscreenmode: fullscreenmodeReducer( state.fullscreenmode, action ),
    vrmode: vrmodeReducer( state.vrmode, action ),
    toasts: toastReducer( state.toasts, action ),
    apiUser: apiUserReducer( state.apiUser, action ),
    modals: modalReducer( state.modals, action ),
    drawmode: drawmodeReducer( state.drawmode, action )
  };

  state = storyReducer( state, action );
  state = soundsReducer( state, action );
  state = amplitudeAnalyticsReducer( state, action );

  state.videoplayback = videoplaybackReducer( state.videoplayback, action );
  state = transitionReducer( state, action );
  state = hotspotReducer( state, action );
  state = heatmapReducer( state, action );

  switch ( action.type ) {
  case VIDEO_IS_READY:
    return {
      ...state,
      istransition: false
    };

  case TYPEACTIONS.SCENE_UPDATE.REQ_LOADING:
    if ( action.isLoading ) {
      state = assignScene( state, action.sceneId, action.delta );
    }

    return setIsUpdating( state, action.isLoading );

  case TYPEACTIONS.SEQUENCE_UPDATE.REQ_LOADING:
    if ( action.isLoading ) {
      state = setSceneSequence( state, action.sceneId, action.delta.uuid, action.delta );
    }

    return setIsUpdating( state, action.isLoading );

  case TYPEACTIONS.HOTSPOT_UPDATE.REQ_LOADING:
    if ( action.isLoading ) {
      state = setSceneHotspot( state, action.sceneId, action.hotspotId, action.delta );
    }

    return setIsUpdating( state, action.isLoading );

  case TYPEACTIONS.SCENE_UPDATE.REQ_SUCCESS:
    return setIsUpdatingSuccess( state, true );

  case TYPEACTIONS.HOTSPOT_UPDATE.REQ_SUCCESS:
    return setIsUpdatingSuccess( state, true );

  case TYPEACTIONS.SCENE_UPDATE.REQ_ERROR:
  case TYPEACTIONS.HOTSPOT_UPDATE.REQ_ERROR:
    return setIsUpdatingSuccess( state, false );

  case UPDATE_SAVE_INDICATOR:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isUpdating: action.isUpdating,
        isUpdatingSuccess: action.isUpdatingSuccess
      }
    };


  case TYPEACTIONS.HOTSPOT_CREATE.REQ_SUCCESS:
    state = setSceneHotspot( state, action.sceneId, action.res.hotspot.id, action.res.hotspot );
    state = setSceneSequence( state, action.sceneId, action.res.sequence.uuid, action.res.sequence );

    return state;

  case TYPEACTIONS.SEQUENCE_DELETE.REQ_SUCCESS:
    return removeSceneSequence( state, action.sceneId, action.sequenceId );


  case SELECT_HOTSPOT:
    return assignSelection( state, {
      hotspotId: action.id
    });

  case SELECT_SEQUENCE:
    return assignSelection( state, {
      hotspotId: action.id
    });

  case IS_VIDEO_REQ_LOADING:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isVideoRequestLoading: action.isLoadingStarted
      }
    };

  case SCENE_UP:
    return upScene( state, action.sceneId );

  case SCENE_DOWN:
    return downScene( state, action.sceneId );

  case TYPEACTIONS.STORY_GET.REQ_ERROR:
    if ( state.FLAG_GQL_ENABLED && !state.bearerToken ) {
      return { ...state };
    }

    return {
      ...state,
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'errorLoadingStory' }),
      volatile: {
        ...state.volatile,
        isStoryRequestError: action.hasErrored
      }
    };

  case TYPEACTIONS.STORY_GET.REQ_LOADING:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isStoryRequestLoading: action.isLoading
      }
    };

  case TYPEACTIONS.VIDEO_GET.REQ_SUCCESS:
    if ( !/story/g.test( action.res.media_type ) ) {
      return state;
    }
    if ( isUserAuthorizedStory( state.user, action.res.story ) || !state.iseditor ) {
      return getStoryAdapted({ ...state }, {embedType: 'story', ...action.res.story });
    }

    if ( gqlIsLogin ) {
      return {
        ...state,
        sessionerror: 'GQLsessionerror'
      };
    }
    return {
      ...state,
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'errorUnauthorizedStory' })
    };

  case TYPEACTIONS.STORY_GET.REQ_SUCCESS_GQL:
    return {
      ...state
    };

  case TYPEACTIONS.STORY_GET.REQ_SUCCESS:
    if ( isUserAuthorizedStory( state.user, action.res.story ) || !state.iseditor ) {
      return getStoryAdapted({ ...state }, {embedType: 'story', ...action.res.story });
    }

    if ( state.FLAG_GQL_ENABLED && state.apiUser.token ) {
      return getStoryGQLAdapted({ ...state }, {embedType: 'story', ...action.res.data.content }, 'story' );
    }

    if ( gqlIsLogin ) {
      return {
        ...state,
        sessionerror: 'GQLsessionerror'
      };
    }
    return {
      ...state,
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'errorUnauthorizedStory' })
    };

  case TYPEACTIONS.MEDIALIBRARY_GET.REQ_SUCCESS:
    return {
      ...state,
      medialibrary: adapter.getOrgMediaLibrary( action.res.media_library )
    };

  case TYPEACTIONS.MEDIA_GET.REQ_SUCCESS:
    return {
      ...state,
      accessMedia: adapter.getAllMediaItems( action.res )
    };


  case TYPEACTIONS.VIDEOS_GET.REQ_SUCCESS:
    return {
      ...state,
      accessMedia: {
        ...state.accessMedia,
        video: action.res
      }
    };

  case TYPEACTIONS.IMAGES_GET.REQ_SUCCESS:
    return {
      ...state,
      accessMedia: {
        ...state.accessMedia,
        image: action.res
      }
    };
  case TYPEACTIONS.AUDIO_GET.REQ_SUCCESS:
    return {
      ...state,
      accessMedia: {
        ...state.accessMedia,
        audio: action.res
      }
    };

  case TYPEACTIONS.SESSION_GET.REQ_SUCCESS:
    return {
      ...state,
      user: action.res.user,
      authtoken: JSON.stringify({
        secret: action.res.token.secret,
        id: action.res.token.id
      })
    };

  case SET_ZOOM:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        zoom: action.zoomFactor
      }
    };

  case TOGGLE_PREVIEW_MODE:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isPreviewMode: action.isPreviewMode
      }
    };

  case TYPEACTIONS.SESSION_GET.REQ_ERROR:
    if ( state.FLAG_GQL_ENABLED && !state.bearerToken ) {
      return { ...state };
    }

    if ( gqlIsLogin ) {
      return {
        ...state,
        sessionerror: 'GQLsessionerror'
      };
    }

    return {
      ...state,
      sessionerror: 'sessionerror',
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'errorLoadingSession' })
    };

  case TYPEACTIONS.SCENEMEDIA_CREATE.REQ_LOADING:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isVideoCreateLoading: action.isLoading
      }
    };

  case TYPEACTIONS.SCENEMEDIA_CREATE.REQ_SUCCESS:
    return {
      ...state,
      scenes: {
        ...state.scenes,
        [action.res.scene.id]: Object.assign( state.scenes[action.res.scene.id], action.res.scene )
      },
      videoplayback: {
        ...state.videoplayback,
        url: action.res.scene.media_dict[getSceneMediaSequence( action.res.scene ).uuid].file_url
      },
      select: {
        ...state.select,
        sceneId: action.res.scene.id,
        hotspotId: null,
        video: action.res.scene.media_dict[getSceneMediaSequence( action.res.scene ).uuid]
      }
    };

  case TYPEACTIONS.SCENEMEDIA_UPDATE.REQ_SUCCESS:
    state = assignScene( state, state.select.sceneId, {
      media_dict: { [action.res.media_item.id]: action.res.media_item }
    });

    return {
      ...state
    };

  case TYPEACTIONS.SCENE_CREATE.REQ_SUCCESS:
    return upsertScene( state, action.res.scene );

  case UPLOAD_IS_FINISHED:
    return {
      ...state,
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'infoUploadFinished' })
    };

  case UPLOAD_IS_ERROR:
    return {
      ...state,
      toasts: toastReducer( state.toasts, { type: ADD_ERROR_TOAST, errorMsg: 'errorUploadFailed' })
    };

  case DELETE_CONFIRMATION_MODAL_IS_OPEN:
    return {
      ...state,
      modals: {
        ...state.modals,
        deleteConfirmationModal: {
          modalIsOpen: action.isOpen,
          deleteWhat: action.deleteWhat,
          id: action.id
        }
      }
    };

  case SCENE_SET_VIDEO:
    return createVideo( state, action );

  case TOGGLE_OVERLAY:
    return {
      ...state,
      volatile: {
        ...state.volatile,
        isOverlayVisible: !state.volatile.isOverlayVisible
      }
    };

  case CANVAS_TOGGLE:
    return {
      ...state,
      canvastype: state.canvastype === 'rectangle'
        ? 'projection' : 'rectangle'
    };

  case TYPEACTIONS.VIDEO_DELETE.REQ_SUCCESS:
    return removeSceneSequence( state, action.sceneId, action.sequenceId );

  case AD_FINISHED:
    const nextScene = Object
      .values( state.scenes )
      .filter( scene => !/advert/gi.test( scene.nativeFormat ) )
      .find( scene => scene.id !== state.select.sceneId );

    state = mountNextStateScene( state, nextScene, 0 );

  default:
    return state;
  }
};

export default mainReducer;

export {
  getStoryDefault,
  isUserAuthorizedStory,
  upsertScene,
  createVideo
};
