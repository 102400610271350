import { useEffect, useState } from 'react';
import { getCanvasMousePosVector } from '../utils/canvasShapes';

export default function useMouse () {
  const [ elem, setMouseContainerElem ] = useState();
  const [ mouseMoveXYVector, setMoveXYVector ] = useState({ x: 0, y: 0 });
  const [ mouseDownXYVector, setDownXYVector ] = useState({ x: 0, y: 0 });
  const [ mouseUpXYVector, setUpXYVector ] = useState({ x: 0, y: 0 });

  const setMove = e => setMoveXYVector( getCanvasMousePosVector( elem, e ) );
  const setDown = e => setDownXYVector( getCanvasMousePosVector( elem, e ) );
  const setUp = e => setUpXYVector( getCanvasMousePosVector( elem, e ) );

  // eslint-disable-next-line no-shadow
  const addEventListeners = elem => {
    if ( elem ) {
      elem.addEventListener( 'mousemove', setMove );
      elem.addEventListener( 'mousedown', setDown );
      elem.addEventListener( 'mouseup', setUp );
    }
  };

  // eslint-disable-next-line no-shadow
  const removeEventListeners = elem => {
    if ( elem ) {
      elem.removeEventListener( 'mousemove', setMove );
      elem.removeEventListener( 'mousemove', setDown );
      elem.removeEventListener( 'mouseup', setUp );
    }
  };

  useEffect( () => {
    if ( elem ) {
      addEventListeners( elem );
    }

    return () => elem && removeEventListeners( elem );
  }, [ elem ]);

  return {
    setMouseContainerElem,
    mouseMoveXYVector,
    mouseDownXYVector,
    mouseUpXYVector
  };
}
