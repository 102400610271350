import iswebglcontext from 'iswebglcontext';
import * as THREE from 'three';
import * as poly2tri from 'poly2tri';
import castas from 'castas';
import scalewh from 'scalewh';
import touchboom from 'touchboom';
import cubemapModel from './360-cubemap-cube';
import { isSafari, isIosSafari } from './crossPlatform';
import { isMimeHls } from './hls';
import * as units from './units';
import {
  isLegacyProjectionCylinderRe,
  isLegacyProjectionSphereRe,
  isLegacyProjectionFlatRe,
  isLegacyProjectionCubeRe
} from './legacyProjectionUtils';
import { FLAG_GQL_ENABLED } from '../env';

const DEFAULT_HOTSPOT_VISIBILITY = false;


// new hotspots only describe scale eg { x: 1, y: 1 }
//
const getHotspotDataDimensions = hotspotData => FLAG_GQL_ENABLED
  ? [ 200 * hotspotData.scale.x, 200 * hotspotData.scale.y ]
  : hotspotData.dimensions;

// new hotspots only describe position eg { x: 1, y: 1 }
//
const getHotspotDataPosition = hotspotData => FLAG_GQL_ENABLED
  ? [ hotspotData.position.x, hotspotData.position.y ]
  : hotspotData.pos;

const getCanvasContextGL = ( canvasElem, contextId ) => {
  canvasElem = canvasElem || document.createElement( 'canvas' );
  contextId = [
    'webgl', 'experimental-webgl', 'webgl2', 'experimental-webgl2'
  ].find( ctx => canvasElem.getContext( ctx ) );

  return contextId && canvasElem.getContext( contextId );
};

const getVReffect = ( canvas, renderer, onDevice ) => {
  const parentelem = canvas.parentElement;
  const [ w, h ] = [ parentelem.offsetWidth, parentelem.offsetHeight ];

  renderer.setSize( w, h );
  renderer.renderer = renderer;

  if ( 'getVRDisplays' in navigator ) {
    navigator.getVRDisplays().then( displays => {
      if ( displays.length ) {
        renderer.vr.setDevice( displays[0]);
        renderer.vr.enabled = true;
        onDevice( displays );
        // [ this.device ] = displays;
        // this.frameData = new VRFrameData();
      }
    });
  }

  return renderer;
};

// TODO rename as create -- get would be canvasElem.getContext("wえbgl");
const getCanvasGLRenderer = canvasElem => new THREE.WebGLRenderer({
  canvas: canvasElem,
  antialias: true,
  alpha: true,
  clearColor: 0xffffff,
  devicePixelRatio: window.devicePixelRatio
});

const getCanvasGLRendererVREffect = ( canvasElem, onDevice ) => (
  getVReffect( canvasElem, getCanvasGLRenderer( canvasElem ), onDevice )
);

const getCanvasMousePosVector = ( canvasElem, e ) => {
  const mousePos = new THREE.Vector2();

  mousePos.x = ( ( e.offsetX / canvasElem.offsetWidth ) * 2 ) - 1;
  mousePos.y = ( -( e.offsetY / canvasElem.offsetHeight ) * 2 ) + 1;

  let [ x, y ] = touchboom.getevxyrelativeelem( e, canvasElem );

  x -= canvasElem.offsetWidth / 2;
  y -= canvasElem.offsetHeight / 2;

  mousePos.truex = x;
  mousePos.truey = -y;

  return mousePos;
};

// copied from legacy setMouseXY
const setCanvasMousePosVector = ( canvasElem, mousePos, coordarr ) => {
  if ( mousePos && canvasElem ) {
    const rect = canvasElem.getBoundingClientRect();
    const offsetx = rect.width / 2;
    const x = ( coordarr[0] - rect.left ) - offsetx;

    mousePos.x = x / ( rect.width / 2 );
    mousePos.y = ( -( ( coordarr[1] - rect.top ) / ( rect.bottom - rect.top ) ) * 2 ) + 1;
  }

  return mousePos;
};

// note: vrmode canvas uses position 'absolute' and other,
// different styles which do not appear to be a problem here
const setCanvasWidthHeight = ( canvasElem, { width, height }) => {
  canvasElem.style.width = '';
  canvasElem.style.height = '';

  canvasElem.height = height;
  canvasElem.width = width;

  canvasElem.style.width = `${width}px`;
  canvasElem.style.height = `${height}px`;

  return canvasElem;
};

const setCanvasStateClass = ( canvasElem, statename, statebool ) => {
  const oldclass = `${statename}-${!statebool}`;
  const newclass = `${statename}-${statebool}`;

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
  if ( canvasElem && canvasElem.classList.replace ) {
    if ( canvasElem.classList.contains( oldclass ) ) {
      canvasElem.classList.replace( oldclass, newclass );
    } else {
      canvasElem.classList.add( newclass );
    }
  }

  return canvasElem;
};

const isCanvasContextIntel = canvasElem => {
  const contextgl = iswebglcontext && getCanvasContextGL( canvasElem );
  const version = contextgl && contextgl.getParameter( contextgl.VERSION );

  return /intel/i.test( version );
};

const getVideoTexture = ( videoElem, texture ) => {
  videoElem.crossOrigin = 'anonymous';

  texture = new THREE.VideoTexture( videoElem );
  texture.format = THREE.RGBFormat;
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;

  return texture;
};

// returns a geometry from the given points
const createGeometry = vertices => {
  if ( !vertices.length ) {
    return new THREE.Geometry();
  }

  const swctx = new poly2tri.SweepContext( vertices.map( ( point, i ) => ({
    id: i,
    x: point.x,
    y: point.y
  }) ) );

  const geometry = swctx.triangulate().getTriangles().reduce( ( geom, tri ) => {
    geom.faces.push( new THREE.Face3(
      tri.getPoint( 0 ).id,
      tri.getPoint( 1 ).id,
      tri.getPoint( 2 ).id
    ) );

    return geom;
  }, new THREE.Geometry() );

  geometry.vertices = vertices;

  return geometry;
};

const createTestMaterial = ( color = 'rgb(100,0,100)', opacity = 1 ) =>
  new THREE.MeshPhongMaterial({
    // https://threejs.org/docs/#api/constants/Materials
    side: THREE.DoubleSide,
    color,
    emissive: color,
    transparent: true,
    opacity
  });

const createTestSphere = ( radius = 30 ) =>
  new THREE.Mesh(
    new THREE.SphereGeometry( radius, 30, 30 ),
    createTestMaterial()
  );

const createRingGeometry = opt =>
  new THREE.RingGeometry(
    opt.innerRadius,
    opt.outerRadius,
    opt.thetaSegments || 32, // outer perimeter segments
    opt.phiSegments || 3,
    opt.thetaStart || Math.PI / 2,
    opt.thetaLength || Math.PI * 2
  );

const createRingMesh = opt => {
  const mesh = new THREE.Mesh( createRingGeometry( opt ), new THREE.MeshBasicMaterial({
    transparent: typeof opt.opacity === 'number' && opt.opacity !== 1,
    opacity: castas.num( opt.opacity, 1 ),
    color: opt.color || 0xffffff,
    side: THREE.DoubleSide,
    fog: false
  }) );

  if ( opt.name )
    mesh.name = opt.name;

  return mesh;
};

const createMesh = ( vertices, texture ) =>
  new THREE.Mesh(
    createGeometry( vertices ),
    texture
  );

const getImgUrlMaterial = imgurl => {
  const loader = new THREE.TextureLoader();
  const tex = loader.load( imgurl );

  tex.minFilter = THREE.NearestFilter;
  tex.magFilter = THREE.LinearFilter;
  /*
    tex.repeat.x = 100 / 800;
    tex.repeat.y = 100 / 2000;
    tex.offset.x = ( 300 / 100 ) * tex.repeat.x;
    tex.offset.y = ( 400 / 100 ) * tex.repeat.y;
    */

  // scale x2 horizontal
  // texture.repeat.set(0.5, 1);
  return new THREE.MeshBasicMaterial({
    map: tex,
    transparent: true
  });
};

const createImagePlane = ( imgurl, [ w = 200, h = 200 ] = []) =>
  new THREE.Mesh(
    new THREE.PlaneGeometry( w, h, 4, 4 ),
    getImgUrlMaterial( imgurl )
  );

const getEdgeHelper = ( mesh, color = 0x5f5f5f, opacity = 1 ) =>
  new THREE.LineSegments(
    new THREE.EdgesGeometry( mesh.geometry ),
    new THREE.LineBasicMaterial({ color, opacity, linewidth: 2 })
  );

const getLineMaterial = ( color = 0x5f5f5f ) =>
  new THREE.LineBasicMaterial({ color });

const getLineGeometry = ({ width, height }, position = new THREE.Vector3() ) => {
  const geometry = new THREE.Geometry();
  const { x: posx, y: posy, z } = position;

  geometry.vertices.push( new THREE.Vector3( posx - width, posy - height, z ) );
  geometry.vertices.push( new THREE.Vector3( posx - width, posy + height, z ) );
  geometry.vertices.push( new THREE.Vector3( posx + width, posy + height, z ) );
  geometry.vertices.push( new THREE.Vector3( posx + width, posy - height, z ) );
  geometry.vertices.push( new THREE.Vector3( posx - width, posy - height, z ) );

  return geometry;
};

const getBoundingLineGeometry = mesh => {
  const bbox = new THREE.Box3().setFromObject( mesh );

  return getLineGeometry({
    width: ( bbox.max.x - bbox.min.x ) / 2,
    height: ( bbox.max.y - bbox.min.y ) / 2
  });
};

// generate outline dynamically from mesh properties
const getBoundingLine = ( mesh, color = 0xffffff ) => new THREE.Line(
  getBoundingLineGeometry( mesh ),
  getLineMaterial( color )
);

// not working... use boxhelper instead
//
//    return new THREE.BoxHelper( mesh, 0xffff00 );
//
const getArrow = mesh => {
  // return new THREE.VertexNormalsHelper( mesh, 100, 0x00ff00, 3 );

  let direction = new THREE.Vector3(); // create once and reuse it!

  direction = mesh.getWorldDirection( direction );

  return direction;
  // return new THREE.Ray( mesh.position, direction );

  // var origin = new THREE.Vector3( 0, 0, 0 );
  // var length = 100;
  // var hex = 0xffff00;

  // var arrowHelper = new THREE.ArrowHelper(
  //     direction,
  //     origin, length, hex );

  // var dir = direction;
  // dir.normalize();
  //
  // var origin = new THREE.Vector3( 0, 0, 0 );
  // var length = 100;
  // var hex = 0xffff00;
  // var arrowHelper = new THREE.ArrowHelper( mesh.position, origin, length, hex );
  //
  // return arrowHelper;
};

// for now, just return scaled mesh clone
const createMeshCollider = mesh => {
  const collider = mesh.clone();

  collider.scale.set( 1.1, 1.1, 1.1 );

  return collider;
};

// returns
//
//   [ minx, miny, maxx, maxy ]
//
// where vertices is list of xy corrds,
//
//   [ [x,y], [x,y], ... ]
//
const getVerticesMinMax = vertices => {
  const inf = Infinity;
  const { min, max } = Math;

  return vertices.reduce( ([ minx, miny, maxx, maxy ], [ vertx, verty ]) => ([
    min( minx, vertx ),
    min( miny, verty ),
    max( maxx, vertx ),
    max( maxy, verty )
  ]), [ inf, inf, -inf, -inf ]);
};

const getVerticesAvg = vertices => {
  const [ minx, miny, maxx, maxy ] = getVerticesMinMax( vertices );

  return [
    ( maxx + minx ) / 2,
    ( maxy + miny ) / 2
  ];
};

const getImgTexture = imgurl => {
  const textureLoader = new THREE.TextureLoader();

  return textureLoader.load( imgurl );
};

const getIconMaterial = ( imgurl, hotspot ) =>
  hotspot
    ? new THREE.MeshBasicMaterial({
      map: getImgTexture( imgurl ),
      transparent: true,
      color: parseInt( `0x${hotspot.color.split( '#' )[1]}`, 16 ),
      opacity: hotspot.opacity
    })
    : new THREE.MeshBasicMaterial({
      map: getImgTexture( imgurl ),
      transparent: true
    });

const getIconMesh = hotspotId => {
  const group = new THREE.Object3D();
  const outerRing = createRingMesh({
    name: 'outer-ring',
    color: '#EFEFEF',
    opacity: 0.6,
    innerRadius: 7,
    outerRadius: 7.6
  });

  const innerRing = createRingMesh({
    name: 'inner-ring',
    color: '#EFEFEF',
    opacity: 0.8,
    innerRadius: 4.8,
    outerRadius: 5.4
  });

  const bullsEyeRing = createRingMesh({
    name: 'bullseye-ring',
    color: '#EFEFEF',
    opacity: 0.9,
    innerRadius: 0.00001,
    outerRadius: 2
  });

  const hitRing = createRingMesh({
    opacity: 0,
    innerRadius: 0.00001,
    outerRadius: 7.8
  });

  hitRing.position.z = 0.2;

  if ( hotspotId )
    hitRing.hotspotId = hotspotId;

  group.add( outerRing );
  group.add( innerRing );
  group.add( bullsEyeRing );
  group.add( hitRing );

  return group;
};

// ring shaped collider should be used as corners of something like
// 'BoxHelper' object will cover areas beyond ring
const getIconMeshCollider = () => {
  const ring = createRingMesh({
    color: '#ffaffa',
    opacity: 0,
    innerRadius: 0.01,
    outerRadius: 7.7,
    thetaSegments: 18
  });

  ring.position.z = 0.5;

  return ring;
};

const getIconMeshDotted = ( hotspotId, opts = {}) => {
  const group = new THREE.Object3D();

  // x starts at 12
  for ( let x = 13; x--; ) {
    group.add( createRingMesh({
      name: 'outer-ring',
      color: '#EFEFEF',
      opacity: castas.num( opts.opacity, 0.6 ),
      innerRadius: 7,
      outerRadius: 7.6,

      thetaStart: ( Math.PI * ( ( x * 2 ) / 12 ) ),
      thetaLength: Math.PI / 12
    }) );
  }

  const hitRing = createRingMesh({
    opacity: 0,
    innerRadius: 0.00001,
    outerRadius: 7.8
  });

  hitRing.position.z = 0.2;

  if ( hotspotId )
    hitRing.hotspotId = hotspotId;

  group.add( hitRing );

  return group;
};

const getIcon3DWeak = icon3D => {
  icon3D.children.forEach( child => {
    const { name, material } = child;

    if ( name === 'bullseye-ring' )
      material.opacity = 0.9;
    else if ( name === 'inner-ring' )
      material.opacity = 0.8;
    else if ( name === 'outer-ring' )
      material.opacity = 0.6;
  });

  return icon3D;
};

const getIcon3DStrong = icon3D => {
  icon3D.children.forEach( child => {
    const { name, material } = child;

    if ( name === 'bullseye-ring' )
      material.opacity = 1;
    else if ( name === 'inner-ring' )
      material.opacity = 0.9;
    else if ( name === 'outer-ring' )
      material.opacity = 0.7;
  });

  return icon3D;
};

const getIconImgMesh = ( imgurl, [ w, h ] = [ 30, 30 ]) =>
  new THREE.Mesh(
    new THREE.PlaneGeometry( w, h ),
    getIconMaterial( imgurl )
  );

const disposeMaterial = material => {
  if ( material.map ) material.map.dispose();
  if ( material.lightMap ) material.lightMap.dispose();
  if ( material.bumpMap ) material.bumpMap.dispose();
  if ( material.normalMap ) material.normalMap.dispose();
  if ( material.specularMap ) material.specularMap.dispose();
  if ( material.envMap ) material.envMap.dispose();

  material.dispose(); // disposes any programs associated with the material
};

const disposeNode = node => {
  if ( node.geometry ) {
    node.geometry.dispose();
  }

  if ( node.material ) {
    if ( node.material instanceof THREE.MeshFaceMaterial || node.material instanceof THREE.MultiMaterial ) {
      node.material.materials.forEach( disposeMaterial );
    } else {
      disposeMaterial( node.material );
    }
  }
};

// http://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
const disposeNodeHierarchy = node => {
  if ( !node ) {
    return node;
  }

  node.traverse( disposeNode );
  node.children.map( cnode => (
    node.remove( cnode )
  ) );

  if ( node.parent ) {
    node.parent.remove( node );
  }

  return node;
};

const getFirstMeshChild = threeObj =>
  threeObj.children.find( c => c.type === 'Mesh' );

const getMeshPoints = threeObj =>
  threeObj.children.filter( c => c.type === 'Points' );

const getPointVertices = point =>
  point.geometry.vertices[0];

const getMeshPointsVertices = threeObj =>
  getMeshPoints( threeObj ).map( getPointVertices );

const isSameVertex = ( vertexa, vertexb ) =>
  vertexa.x === vertexb.x && vertexa.y === vertexb.y && vertexa.z === vertexb.z;

// use this to avoid adding the same vertex multiple times
//
// `true` if vertex found in mesh
//
// n^2 and could be optimised, but vertex lists will always be small
//
const isObjPoint = ( mesh, point ) => (
  mesh && getMeshPoints( mesh ).find( meshpoint =>
    meshpoint.geometry.vertices.find( meshpointvertex =>
      point.geometry.vertices.find( pointvertex =>
        isSameVertex( pointvertex, meshpointvertex ) ) ) ) );

// attempt to triangulate the points
// return any error
const isObjPointError = ( mesh, point ) => {
  const vertices = [
    ...getMeshPointsVertices( mesh ),
    getPointVertices( point )
  ];

  if ( vertices.length > 2 ) {
    try {
      createGeometry( vertices );
    } catch ( e ) {
      return e;
    }
  }

  return false;
};

// ex vertices, [[x,y],[x,y]]
const isTriangulateVertexError = vertices => {
  if ( vertices.length > 2 ) {
    try {
      createGeometry( vertices );
    } catch ( e ) {
      return e;
    }
  }

  return false;
};

const getLatLonToVector3 = ( lat, lng, radius ) => {
  // convert latitude / longitude to angles between 0 and 2*phi
  const phi = ( ( 90 - lat ) * Math.PI ) / 180;
  const theta = ( ( 180 - lng ) * Math.PI ) / 180;

  // Calculate the xyz coordinate from the angles
  const x = radius * Math.sin( phi ) * Math.cos( theta );
  const y = radius * Math.cos( phi );
  const z = radius * Math.sin( phi ) * Math.sin( theta );

  return new THREE.Vector3( x, y, z );
};

const getCenterOriginToTopLeft = ( x, y, w, h ) =>
  new THREE.Vector2( ( w / 2 ) - x, ( h / 2 ) - y );

// We just linearly convert the image coordinated to the angular latitude and longitude coordinates.
// Then we center them so the (0,0) coordinate is at the center of the image
const getXYToLatLon = ( x, y, width, height ) => ({
  lat: 90 - ( 180 * ( y / height ) ),
  lon: ( 360 * ( x / width ) ) + 90 // Extra 90 degree offset w/ video mesh 90 degree rotation
});

// convert 'universal' xy position to vector used for mesh position
// in spherical/projection canvas
const getUniversalXYAsProjectionVector = ([ x, y ], radius = 128 ) => {
  const normalizedPos = getCenterOriginToTopLeft( x, y, 480, 200 );
  const { lat, lon } = getXYToLatLon( normalizedPos.x, normalizedPos.y, 480, 200 );

  return getLatLonToVector3( lat, lon, radius );
};

const setUniversalXYAsProjectionVectorMeshHotspot = ( hotspot3D, hotspotData ) => {
  hotspot3D.position.copy(
    // 95 safely within radius 128
    getUniversalXYAsProjectionVector( getHotspotDataPosition( hotspotData ), 95 )
  );

  return hotspot3D;
};

//   ( x, y ) ._
//           /| ^
//          / | |
//         /  | |
//        /   |
//    h  /    | y
//      /     |
//     /      | |
//    /.      | |
//   /  θ  +--| |
//  /____\_|__|_v
//  |         |
//   <-- x -->
//
const getVertex = ( hypotenuse, radangle ) => ({
  x: hypotenuse * Math.cos( radangle ),
  y: hypotenuse * Math.sin( radangle )
});

const getVideoGeometryTypeSphere = ( isQualityHigh = true ) => {
  const widthSegments = isQualityHigh ? 100 : 20;
  const heightSegments = isQualityHigh ? 100 : 10;

  return new THREE.SphereBufferGeometry( 128, widthSegments, heightSegments );
};

const getVideoGeometryTypeCylinder = ( isQualityHigh = true ) => {
  const radialSegments = isQualityHigh ? 10 : 50;
  const heightSegments = isQualityHigh ? 2 : 50;

  return new THREE.CylinderBufferGeometry(
    256, 256, 256, radialSegments, heightSegments, true
  );
};

const getVideoGeometryTypeCube = () => {
  const cubeGeometry = new THREE.BufferGeometry();
  const { attributes, index } = cubemapModel.data;
  const getBuffer = o => new THREE.BufferAttribute(
    new window[o.type]( o.array ), o.itemSize
  );

  Object.keys( attributes ).forEach( attributeName => {
    cubeGeometry.addAttribute(
      attributeName, getBuffer( attributes[attributeName])
    );
  });

  cubeGeometry.setIndex( getBuffer( index ) );

  return cubeGeometry;
};

const getVideoGeometryTypePlane = () =>
  new THREE.PlaneGeometry( 480, 204, 4, 4 );

// upper-case types === graphql api content,
// lower-case types === legacy api
const getVideoGeometryType = ( () => ( projectionType, isQualityHigh ) => {
  let geometry;

  if ( isLegacyProjectionSphereRe.test( projectionType ) )
    geometry = getVideoGeometryTypeSphere( isQualityHigh );
  else if ( isLegacyProjectionFlatRe.test( projectionType ) )
    geometry = getVideoGeometryTypePlane( isQualityHigh );
  else if ( isLegacyProjectionCylinderRe.test( projectionType ) )
    geometry = getVideoGeometryTypeCylinder( isQualityHigh );
  else if ( isLegacyProjectionCubeRe.test( projectionType ) )
    geometry = getVideoGeometryTypeCube( isQualityHigh );
  else
    throw new Error( `Unknown video projection ${projectionType}` );

  if ( !isLegacyProjectionCubeRe.test( projectionType ) ) // Cube geo already correct UV
    geometry.applyMatrix( new THREE.Matrix4().makeScale( -1, 1, 1 ) );

  return geometry;
})();

const getImageTexture = file_url => {
  const loader = new THREE.TextureLoader();
  const imgtexture = loader
    .setCrossOrigin( '' )
    .load( file_url, () => {});

  imgtexture.minFilter = THREE.NearestFilter;
  imgtexture.magFilter = THREE.LinearFilter;

  return imgtexture;
};

const getVideoTextureShaders = ( unflipY, invertColor ) => ({
  vertex: [
    'varying vec2 vUV;',
    'void main() {',
    ` vUV = vec2( uv.x, ${unflipY ? '1.0 - uv.y' : 'uv.y'});`, // fipY: 1.0 - uv.y
    ' gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
    '}'
  ].join( '\n' ),
  fragment: [
    'uniform sampler2D texture;',
    'uniform vec2 repeat;',
    'varying vec2 vUV;',
    'void main() {',
    ` gl_FragColor = texture2D( texture, vUV * repeat )${invertColor ? '.bgra' : ''};`,
    '}'
  ].join( '\n' )
});

const getVideoShaderType = ( texture, projectionType, mime ) => {
  const isHLS = isMimeHls( mime );
  const isEquilateralRe = /^(equilateral)/i;
  let unflipY = false;
  let invertColor = false;
  let repeatVertical = false;

  if ( isSafari() && !isIosSafari() && isHLS ) {
    unflipY = true; // On Safari, the HLS y-fix is _required_ for HLS VideoTextures to work ( as of April 2017. Periodically check to see if bug has been fixed )
  } else if ( isSafari() && !isIosSafari() && !isCanvasContextIntel() ) {
    unflipY = true; // For people on Safari with non-intel GPUs, the HLS fix drastically improves performance
  } else if ( isIosSafari() && isHLS ) {
    unflipY = true; // On iOS Safari, the HLS fix is required for HLS streams, as well as an additional color swizzle. The HLS Fix crashes the browser for mp4 videos.
    invertColor = true;
  }

  if ( isEquilateralRe.test( projectionType ) )
    repeatVertical = true;

  if ( unflipY )
    console.log( 'Using HLS Fix' );

  texture.flipY = !unflipY;

  if ( invertColor ) {
    texture.format = THREE.RGBAFormat;
    console.log( 'Using iOS color fix' );
  }

  if ( repeatVertical )
    texture.repeat.y = 0.5;

  const shaders = getVideoTextureShaders( unflipY, invertColor );

  return new THREE.ShaderMaterial({
    uniforms: {
      texture: { value: texture },
      repeat: { value: texture.repeat }
    },
    vertexShader: shaders.vertex,
    fragmentShader: shaders.fragment,
    side: THREE.FrontSide
  });
};

// getMeshDist,
//
//            .__
//           /|\ ^
//          / | \|
//         /  |  \
//        /   |   \
//       / `θ-|  z \
//      /     |     \
//     /      |  |   \
//    /       |  |    \
//   /     +--|  |     \
//  /______|__|__v______\
//  |         |
//   <- opp ->
//
// fov is fitted using shape dimension (width or height) which requires
// widest angle based on size of mesh and canvas
//
// https://threejs.org/docs/#api/cameras/PerspectiveCamera
// fov is vertical, so use height opposite
//
const getCameraMeshFitDist = ( mesh, [ maxw, maxh ], camera ) => {
  const meshw = mesh.geometry.parameters.width;
  const meshh = mesh.geometry.parameters.height;
  const height = ( meshh * ( maxw / meshw ) ) > maxh ? meshh : maxh;
  const opposite = height / 2;
  const θ = units.degreetoradian( camera.fov / 2 );

  return opposite / Math.tan( θ );
};

// calculate objects intersecting the ray
const getCameraIntersecting = ( object3DArr, camera, mouse, raycaster, recursive = false ) => {
  raycaster.setFromCamera( mouse, camera );

  return raycaster.intersectObjects( object3DArr, recursive );
};

const getCameraIntersectingObject = ( object3DArr, camera, mouse, raycaster, recursive = false ) => {
  const objects = getCameraIntersecting( object3DArr, camera, mouse, raycaster, recursive );

  return objects.length ? objects[0].object : null;
};

// set the mesh to fin inside the camera view
const setMeshFitCanvasCamera = ( mesh, elem, camera ) => {
  mesh.position.z = -getCameraMeshFitDist( mesh, [
    elem.offsetWidth,
    elem.offsetHeight
  ], camera );

  return mesh;
};

const getVideoMeshTypePlane = ( material, mediaResolution, canvasDimensions ) => {
  const [ w, h ] = scalewh.max(
    [ mediaResolution.width, mediaResolution.height ],
    [ canvasDimensions.width, canvasDimensions.height ]
  );

  const plane = new THREE.PlaneGeometry( w, h, 4, 4 );

  return new THREE.Mesh( plane, material );
};

const getVideoMeshTypeSpherical = ( geometry, videoMaterial ) => {
  const scenemesh = new THREE.Mesh( geometry, videoMaterial );

  scenemesh.position.set( 0, 0, 0 );

  // 90 degree rotation 'corrects' the default position of the video
  //  material 'un-centered' w/out a 90 degree rotation.
  scenemesh.rotateY( THREE.Math.degToRad( -90 ) );
  scenemesh.name = 'scenemesh';

  return scenemesh;
};

const getVideoMeshType = ( projectionType, geometry, material, mediaResolution, canvasDimensions ) => {
  let mesh;

  if ( isLegacyProjectionFlatRe.test( projectionType ) ) {
    mesh = getVideoMeshTypePlane( material, mediaResolution, canvasDimensions );
  } else {
    mesh = getVideoMeshTypeSpherical( geometry, material );
  }

  return mesh;
};

// 'Sprite' legacy from sprite based icons
const getRayIntersectMeshList = ( raycaster, meshArr ) => raycaster
  .intersectObjects( meshArr, true )
  .filter( i => /Sprite|Mesh/.test( i.object.type ) );

const getRayIntersectMeshFirst = ( raycaster, meshArr ) => {
  const intersected = getRayIntersectMeshList( raycaster, meshArr );

  return intersected.length && intersected[0].object;
};

// LEGACY data used a fixed 480x200 planar mesh :|
//
//  (-240,100)   (0,100)     (240,100)
// +------------+-----------+
// |(-240,0)    |(0,0)      |(240,0)
// +------------+-----------+
// |(-240,-100) |(0,-100)   |(0,-100)
// +------------+-----------+
//
// recorded hotspot coordinates data correspond to these positions
// position factor used to convert coordinates to and from
// 480,200 to actual target planar mesh dimensions
//
const VERTICALEXTREMITY = 100;
const HORIZONTALEXTREMITY = 240;

const HOTSPOT_H = 200;
const HOTSPOT_W = 200;

const createPositionFactor = ( w, h ) => ({
  height: h / VERTICALEXTREMITY / 2,
  width: w / HORIZONTALEXTREMITY / 2
});

// from target position to persistable coordinates
const getUniversalPosition = ([ x, y ], posFactor ) => ([
  Math.round( x / posFactor.width ),
  Math.round( y / posFactor.height )
]);

// from persistable coordinates to target position
//
// 'z' value is slightly in 'front' of mesh target
//
const getRelativePosition = ([ x, y, z ], posFactor ) => ([
  x * posFactor.width,
  y * posFactor.height,
  z
]);

// TODO remove special 'true*' properties and use regular x/y vector properties
const setRelativePositionMeshHotspot = ( mesh, posVector ) => {
  mesh.position.x = posVector.truex;
  mesh.position.y = posVector.truey;
};

const getUniversalPositionMesh = ( mesh, posFactor ) =>
  getUniversalPosition([ mesh.position.x, mesh.position.y ], posFactor );

const setUniversalPositionMesh = ( mesh, universalXY, posFactor ) => {
  const [ x, y, z ] = getRelativePosition( universalXY, posFactor );

  mesh.position.set( x, y, z );

  return mesh;
};

const createScaleFactor = wharr => wharr[0] / scalewh.max( wharr, [
  HORIZONTALEXTREMITY, VERTICALEXTREMITY
])[0];

const setUniversalScaleMesh = ( mesh, universalWH, scaleFactor ) => {
  mesh.scale.set(
    scaleFactor * ( universalWH[0] / HOTSPOT_W ),
    scaleFactor * ( universalWH[1] / HOTSPOT_H ),
    1
  );

  return mesh;
};

const setUniversalScaleMeshHotspot = ( hotspot3D, hotspotData, scaleFactor ) => (
  setUniversalScaleMesh( hotspot3D, getHotspotDataDimensions( hotspotData ), scaleFactor ) );

// new hotspots only describe position eg { x: 1, y: 1 }
//
const setUniversalPositionMeshHotspot = ( hotspot3D, hotspotData, positionFactor, overlayzpos ) => (
  setUniversalPositionMesh( hotspot3D, [
    ...getHotspotDataPosition( hotspotData ).slice( 0, 2 ), overlayzpos
  ], positionFactor ) );

// used to debug rotations, called as follows
//
//   this.printrads( 'scene', props.scene.camerarot, this.coords );
//
const printRads = ( msg, rads, coords = []) => {
  const { pixeltodegree, degreetoradian } = units;
  const pxwindow = units.windowpixelweight();
  const round = rad => Math.round( rad * 100 ) / 100;

  if ( coords ) {
    console.log(
      msg,
      'rads', rads.map( round ),
      'coordsrads', window.touchboom.coordsgettotal({ coords }).map( px => (
        pixeltodegree( px, pxwindow )
      ) ).map( degreetoradian ).reverse().map( round )
    );
  } else {
    console.log( msg, 'coords not found' );
  }
};

const createPerspectiveCamera = ( fov = 50, ratio = 360 / 240, near = 1, far = 2000 ) => (
  new THREE.PerspectiveCamera( fov, ratio, near, far )
);

const createPerspectiveCameraForCanvas = ( canvas, fov = 50, near = 1, far = 2000 ) => (
  createPerspectiveCamera( fov, canvas.offsetWidth / canvas.offsetHeight, near, far )
);

const createHotspot3DShape = ( /* hotspotData */ ) => {
  // let hotspot3D = new THREE.Object3D();
  // let shape;
  //
  // hotspot3D.name = `hotspot-${hotspotData.id}`;
  // hotspot3D.hotspotId = hotspotData.id;
  // hotspot3D.visible = false;
  // hotspot3D.hidden = hotspotData.hidden;
  //
  // shape = this.makeShape( this.getRelativePositionArr( hotspotData.shape ) );
  // shape.hotspotId = hotspotData.id;
  // hotspot3D.add( shape );
  // this.applyShapeColor( hotspotData, hotspot3D );
  //
  // let arr = this.getRelativePositionArr( hotspotData.shape )
  //   .map( p => ({ x: p[0], y: p[1], z: this.overlayzpos }) );
  // let outline = this.makeLine( arr, 0xffffff );
  //
  // outline.hotspotId = hotspotData.id;
  // outline.visible = false;
  //
  // hotspot3D.add( outline );
  // hotspot3D.visible = DEFAULTVISIBILITY;
  //
  // let handles = this.makeHandles( arr );
  // handles.visible = false;
  // hotspot3D.add( handles );
};

// const isSphere

const createHotspot3DSphere = ( hotspotData, isSelected, sceneState ) => {
  const { scale, positionFactor, overlayzpos } = sceneState;
  let hotspot3D = hotspotData.hidden
    ? getIconMeshDotted( hotspotData.id )
    : getIconMesh( hotspotData.id );

  hotspot3D.name = `hotspot-${hotspotData.id}`;
  hotspot3D.hotspotId = hotspotData.id;
  hotspot3D.hidden = hotspotData.hidden;

  const hoverSquare = getBoundingLine( hotspot3D );
  hoverSquare.name = 'hoverSquare';
  hoverSquare.hotspotId = hotspotData.id;
  hoverSquare.visible = Boolean( isSelected );

  hotspot3D = setUniversalScaleMeshHotspot(
    hotspot3D, hotspotData, scale
  );

  if ( isLegacyProjectionSphereRe.test( sceneState.projection ) ) {
    hotspot3D = setUniversalXYAsProjectionVectorMeshHotspot(
      hotspot3D, hotspotData
    );
  } else {
    hotspot3D = setUniversalPositionMeshHotspot(
      hotspot3D, hotspotData, positionFactor, overlayzpos
    );
  }
  hotspot3D.add( hoverSquare );
  hotspot3D.visible = DEFAULT_HOTSPOT_VISIBILITY;
  hotspot3D.isMeshHotspot = true;

  if ( isSelected )
    hotspot3D = getIcon3DStrong( hotspot3D );

  return hotspot3D;
};

// shape were legacy coordinates used to 'draw' a hotspot --no longer supported
//
const createHotspot3D = ( hotspotData, isSelected, sceneState ) => (
  ( Array.isArray( hotspotData.shape ) && hotspotData.shape.length )
    ? createHotspot3DShape( hotspotData, isSelected, sceneState )
    : createHotspot3DSphere( hotspotData, isSelected, sceneState )
);

const createHotspot3DCollider = hotspot3D => {
  const collider = getIconMeshCollider();

  collider.hotspotId = hotspot3D.hotspotId;
  // position just in front of hotspot...
  collider.position.set( 0, 0, 2 );
  collider.visible = false;

  return collider;
};

// an object is 'visible' if its sequence occurs at the current time
const setHotspot3DVisible = ( hotspot3D, isVisible ) => {
  if ( hotspot3D )
    hotspot3D.visible = isVisible;

  if ( hotspot3D && hotspot3D.collider )
    hotspot3D.collider.visible = isVisible;

  return hotspot3D;
};

// should be optimised
const setHotspot3DSelected = ( hotspot3D, selected = true ) => {
  const line = hotspot3D.children.filter( c => c.type === 'Line' );
  const dots = hotspot3D.children.filter( c => c.name === 'Dots' );

  if ( line.length ) line[0].visible = selected;
  if ( dots.length ) dots[0].visible = selected;
};

// was getSceneHotspot3dArr
const scene3DGetHotspot3DArr = scene => (
  scene
    ? scene.children.filter( child => child && child.isMeshHotspot )
    : []
);

// was scene3DclearAllHotspots
const scene3DClearHotspots3D = scene3D => {
  scene3DGetHotspot3DArr( scene3D ).forEach( mesh => {
    scene3D.remove( mesh );
  });

  return scene3D;
};

export {
  createMesh,
  createRingMesh,
  createRingGeometry,
  createMeshCollider,
  createTestSphere,
  createTestMaterial,
  createImagePlane,
  createPositionFactor,
  createScaleFactor,
  createPerspectiveCamera,
  createPerspectiveCameraForCanvas,
  createHotspot3D,
  createHotspot3DCollider,
  disposeNodeHierarchy,
  isObjPoint,
  isObjPointError,
  isTriangulateVertexError,
  isCanvasContextIntel,
  getUniversalPosition,
  setUniversalPositionMesh,
  getUniversalPositionMesh,
  setUniversalPositionMeshHotspot,
  setUniversalScaleMesh,
  setUniversalScaleMeshHotspot,
  getRelativePosition,
  setRelativePositionMeshHotspot,
  getCanvasContextGL,
  getCanvasGLRenderer,
  getCanvasGLRendererVREffect,
  getCanvasMousePosVector,
  setCanvasMousePosVector,
  setCanvasWidthHeight,
  setCanvasStateClass,
  getCameraMeshFitDist,
  getCameraIntersecting,
  getCameraIntersectingObject,
  setMeshFitCanvasCamera,
  getImageTexture,
  getVideoTexture,
  getVideoTextureShaders,
  getVideoShaderType,
  getVideoGeometryTypeSphere,
  getVideoGeometryTypeCylinder,
  getVideoGeometryTypeCube,
  getVideoGeometryTypePlane,
  getVideoGeometryType,
  getVideoMeshTypePlane,
  getVideoMeshType,
  getRayIntersectMeshList,
  getRayIntersectMeshFirst,
  getXYToLatLon,
  getCenterOriginToTopLeft,
  getUniversalXYAsProjectionVector,
  getLatLonToVector3,
  getVerticesAvg,
  getFirstMeshChild,
  getIconMaterial,
  getIconImgMesh,
  getIcon3DStrong,
  getIcon3DWeak,
  getIconMesh,
  getIconMeshDotted,
  getIconMeshCollider,
  getEdgeHelper,
  getVertex,
  getArrow,
  getLineGeometry,
  getBoundingLineGeometry,
  getBoundingLine,
  setHotspot3DVisible,
  setHotspot3DSelected,
  scene3DGetHotspot3DArr,
  scene3DClearHotspots3D,
  printRads
};
