import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import * as heatmapping from 'heatmapping-lib-js';
import Heatmap from './Heatmap';
import SlateLoad from './SlateLoad';
import { π2 } from '../utils/units';
import { APPTYPE } from '../env.js';

import {
  isMediaAdImage
} from '../utils/mediaUtils';

import {
  getContentInteractive
} from '../utils/contentUtils';

import Sounds from './Sounds';
import BannerPlayer from './BannerPlayer';

import SceneVideoPlayerGQL from './SceneVideoPlayerGQL';
import CanvasPublishGQL from './CanvasPublishGQL';
import CanvasEditGQL from './CanvasEditGQL';

import useLocalMediaPlayback from '../hooks/useLocalMediaPlayback';

const Container = styled.div`
    position:relative;
    width:100%;
    height:100%;
`;

export default function CanvasPlayerGQL ( props ) {
  const isGQL = true;

  const onUnload = () => {
    if ( isGQL ) {
      props.actions.windowUnload();
    } else {
      console.log( 'on window unload' );
    }
  };

  const getVideoElem = () => {
    return document.getElementById( 'videochild' );
  };

  const getBannerElem = () => {
    return document.getElementById( 'bannerimg' );
  };

  const getTargetRequiredElem = media => isMediaAdImage( media )
    ? getBannerElem()
    : getVideoElem();

  /*
    componentWillUpdate ( nextProps ) {
        let { scene, actions } = this.props;

        if ( !isSceneSame( scene, nextProps.scene ) ) {
            actions.sceneChanged(
                scene ? scene.id : null, // prevSceneId
                nextProps.scene.id
            ); // nextSceneId
        }
    }
    */
  /*
    // if media w/ pending unrequested adverts requested
    //   begin advert request inside primitive lock
    // else
    //   return media
    getSceneMedia ( props, scene ) {
        let adverts = scene && scene.adverts;
        let media = {};

        if ( adverts && !adverts.ismerged && !this.isRequesting[adverts.advertid]) {
            this.isRequesting[scene.advertid] = true;

            props.actions.getAdvertDisp( adverts );
        } else {
            media = getSceneMedia( scene );
        }

        return media;
    }

    getCanvas ( canvastype = this.props.canvastype ) {
        return canvastype === 'rectangle'
            ? CanvasEdit
            : CanvasPublish;
    }
*/
  const getCanvasGQL = canvastype => (
    canvastype === 'rectangle' ? CanvasEditGQL : CanvasPublishGQL
  );

  const isEmitHeatmap = () => {
    return APPTYPE === 'player'
            && props.canvastype === 'projection'
            && false
            && props.data.heatmap.isHeatEvents;
  };

  // yaw - The yaw you are looking in the video, in radians. A yaw of 0 means
  // that you are looking at the center of the video. Keep this number between
  // 0 and 2π. 0 and 2π both mean looking at the center of the video. π
  // means looking backward.
  //
  // pitch - The pitch you are looking in the video, in radians. A pitch of 0
  // means that you are looking at the vertical center of the video. Keep this number
  // between -π/2 and π/2. A value of -π/2 means looking straight down. A value
  // of π/2 means looking straight up
  //
  // timeInVideo - The timestamp in the video, in milliseconds. This should be synced
  // with the seek bar of the video. If the video is paused, this number should not change.
  //
  const onRender = () => {
    return isEmitHeatmap()
      ? canvasInst => {
        let yaw = canvasInst.headgroup.rotation.y;

        while ( yaw < 0 ) yaw += π2;
        while ( yaw > π2 ) yaw -= π2;

        yaw = π2 - yaw;

        if ( false ) { // && props.data.heatmap.isHeatEvents ) {
          // heatmapping.update(
          //   yaw, // yaw
          //   canvasInst.camera.rotation.x, // pitch
          //   getTargetRequiredElem().currentTime * 1000 // timeInVideo
          // );
        }
      } : () => {};
  };

  // same video element should be always-present
  // once a click-event inititates play, the element can be
  // programatically stopped and started
  //
  // react-player does not provide access to video element,
  // which is created and loaded after the player is mounted
  // threecanvas anticipates this target element, so check
  // that it exists before including Canvas component
  //
  // note: video projection needs to support other, non-spherical
  // projection types

  const onBannerClick = () => {
    console.log( 'banner click' );
    // actions.adEngage( media );
  };

  const onBannerEnded = () => {
    console.log( 'banner ended' );
    // actions.mediaEnded( scene.id, media.id );
  };

  const onBannerPresented = () => {
    console.log( 'banner presented' );
    // actions.mediaStarted( scene.id, media.id )
  };

  const {
    // togglePlaying,
    // toggleMute,
    mediaPlaybackState
  } = useLocalMediaPlayback();

  const {
    content, media, dimensions, refresh, actions, moments, canvasType
  } = props;
  const scene = props.scene || {};
  // const media = this.getSceneMedia( this.props, scene ) || {};
  const isbanner = isMediaAdImage( media );
  const CanvasGQL = getCanvasGQL( canvasType );

  const isTransition = false; // was data.istransition;

  return (
    <Container>
      <Sounds
        actions={{}}
        scenesounds={{}} />
      {/* scene video player needs to use the media hook */}
      <SceneVideoPlayerGQL
        scene={scene}
        mediaItem={media}
        content={content}
        isTransition={isTransition}
        refresh={refresh} />
      {isbanner && (
        <BannerPlayer
          onClick={onBannerClick}
          onEnded={onBannerEnded}
          onPresented={onBannerPresented}
          media={media}
        />
      )}
      <CanvasGQL
        getMediaElem={() => getTargetRequiredElem()}
        onRenderFn={onRender()}
        content={content}
        isRenderHooksOnly={!mediaPlaybackState.isReady}
        moments={moments || []}
        playback={mediaPlaybackState}
        dimensions={dimensions}
        actions={actions}
        media={media}
        scene={scene}
        camerarot={[ 0, 0 ]}
      />
      {/*false && data.isplayer && data.heatmap.isHeatEnabled && (
                <Heatmap
                    canvastype={canvastype}
                    dimensions={dimensions}
                    data={data}
                    media={media}
                    scene={scene} />
                    )*/}
      <SlateLoad videoplayback={mediaPlaybackState} />
    </Container>
  );
}

CanvasPlayerGQL.propTypes = {
  refresh: PropTypes.number,
  canvasType: PropTypes.string.isRequired, // 'projection' | 'rectangle'
  dimensions: PropTypes.object.isRequired,

  actions: PropTypes.object,
  moments: PropTypes.array,
  content: PropTypes.object,
  scene: PropTypes.object,
  media: PropTypes.object
};
