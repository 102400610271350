import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import scalewh from 'scalewh';
import throttle from 'lodash.throttle';
import { isVideoElem } from '../utils/elemUtils';
// import { xrSessionDeviceOrientation } from '../utils/xr.js';

import CanvasThreeGQL from './CanvasThreeGQL';

import useCanvas3D from '../hooks/useCanvas3D';
import useMouseGlass from '../hooks/useMouseGlass';
import useLocalStage from '../hooks/useLocalStage';
import useLocalIVE from '../hooks/useLocalIVE';

import {
  getMediaType,
  getMediaProjection
} from '../utils/contentUtils';

import {
  isLegacyProjectionFlatRe
} from '../utils/legacyProjectionUtils';

import {
  setCanvasWidthHeight,
  getCanvasGLRenderer,
  getVideoGeometryType,
  getIcon3DWeak,
  getIcon3DStrong,
  createPerspectiveCameraForCanvas,
  getVideoTexture,
  getImageTexture,
  getVideoShaderType,
  getVideoMeshType,
  setMeshFitCanvasCamera,
  setCanvasStateClass,
  getCameraIntersectingObject,
  createPositionFactor
} from '../utils/canvasShapes';

export default function CanvasPublishGQL ( props ) {
  const canvasId = 'CanvasThree-publish';

  const {
    iveState,
    iveSceneSequenceEngage
  } = useLocalIVE();

  const {
    stageRendererSet,
    stageCameraRotationRadiansSet,
    stageState
  } = useLocalStage( props.scene.id );

  const {
    objects3DRef,
    getCanvasElem,
    lifecycleSet
  } = useCanvas3D({
    ...props,
    xrIsEnabled: stageState.xrIsEnabled,
    canvasId,
    selected_hotspotId: iveState.selectedHotspotId,
    projection: iveState.selectedProjection || getMediaProjection( props.media ),
    getMediaElem: props.getMediaElem,
    moments: props.moments || props.scene.moments,
    animationLoop: true
  });

  const {
    isMouseRef,
    setIsMouseIntersecting,
    mouseXYVector,
    setMouseOnInertia,
    setMouseContainerElem,
    resetCameraRotation
  } = useMouseGlass();

  // construct system for handling camera
  // head and body give independent vertical and horizontal control
  //
  // vrcontrols hardware position can be connected directly to camera,
  // allows positioning for both to remain independent
  const getCameraObj = camera => {
    const bodygroup = new THREE.Object3D();
    const headgroup = new THREE.Object3D();

    bodygroup.name = 'bodygroup';
    headgroup.name = 'headgroup';
    camera.name = 'camera';

    bodygroup.add( headgroup );
    headgroup.add( camera );

    return [ bodygroup, headgroup, camera ];
  };

  const applyRotation = ( sceneState, [ radx, rady ]) => {
    sceneState.camera.rotation.x = radx;
    sceneState.headgroup.rotation.y = rady;
  };

  const threeSceneCreate = ( canvasElem, sceneState, props ) => {
    const { media, projection } = props;

    canvasElem = setCanvasWidthHeight( canvasElem, props.dimensions );

    const glrenderer = getCanvasGLRenderer( canvasElem );
    const camera = createPerspectiveCameraForCanvas( canvasElem );
    const scene3D = new THREE.Scene();
    const targetElem = props.getMediaElem( props );
    const targettexture = isVideoElem( targetElem )
      ? getVideoTexture( targetElem )
      : getImageTexture( media.file_url );
    const videogeometry = getVideoGeometryType( projection );
    const videomaterial = getVideoShaderType( targettexture, projection, getMediaType( media ) );
    let videomesh = getVideoMeshType(
      projection,
      videogeometry,
      videomaterial,
      media.resolution,
      props.dimensions
    );
    const [ bodygroup, headgroup ] = getCameraObj( camera );

    if ( isLegacyProjectionFlatRe.test( projection ) ) {
      videomesh = setMeshFitCanvasCamera( videomesh, canvasElem, camera );

      const [ w, h ] = scalewh.max(
        [ media.resolution.width, media.resolution.height ],
        [ props.dimensions.width, props.dimensions.height ]
      );

      // information applied hotspots and shapes added to scene
      sceneState.overlayzpos = videomesh.position.z + 0.1;
      sceneState.positionFactor = createPositionFactor( w, h );
    }

    scene3D.add( videomesh );
    scene3D.add( bodygroup );

    camera.lookAt( scene3D.position );

    // ensure first mouse position isn't a 'hit'
    resetCameraRotation( canvasElem, props.camerarot );
    stageRendererSet( glrenderer );

    return {
      ...sceneState,
      threeInitUpdate: Date.now(),
      scale: 2,
      scene3D,
      camera,
      renderer: glrenderer,
      videomesh,
      headgroup,
      bodygroup
    };

    // different methods for entering vrmode on oculus
    //
    // http://lune.xyz/tests/vr2/
    // navigator.getVRDisplays().then( ([ display ]) => {
    //   glrenderer.renderer.vr.enabled = true;
    //   glrenderer.renderer.vr.setDevice( display );
    // });
    //
    // if ( this.props.data.vrmode.isvrsupport ) {
    //   document.body.appendChild( WEBVR.createButton( glrenderer.renderer ) );
    // }
  };

  const detectHit = ( sceneState, isMouseRef ) => {
    let ismouseintersecting = Boolean( isMouseRef.current.ismouseintersecting );
    const ismousechangeddown = Boolean( isMouseRef.current.ismousechangeddown );
    const mesh = sceneState.scene3D && getCameraIntersectingObject(
      objects3DRef.current.colliders,
      sceneState.camera,
      mouseXYVector,
      sceneState.raycaster
    );

    if ( mesh ) {
      if ( ismousechangeddown ) {
        ismouseintersecting = true;
        onClickMesh( sceneState, mesh );
      }
      onIntersectMesh( sceneState, mesh );
    } else {
      onIntersectNone( sceneState );
    }

    setIsMouseIntersecting( ismouseintersecting );
  };

  const threeSceneRefreshed = ( sceneState, canvasElem ) => {
    setCanvasStateClass( canvasElem, 'israyover', false );

    if ( props.media && props.getMediaElem() ) {
      const throttledCameraAction = throttle( ( lprops, radxy ) => {
        if ( props.actions ) {
          props.actions.cameraSetRotation( lprops.scene.id, radxy );
        } else {
          stageCameraRotationRadiansSet( radxy );
        }
      }, 400 );

      setMouseContainerElem( canvasElem );

      setMouseOnInertia( radxy => {
        applyRotation( sceneState, radxy );
        throttledCameraAction( props, radxy );
      });
    }

    return sceneState;
  };

  const onRayoverMesh = mesh => {
    const { hotspotId } = mesh;
    let hotspot3D = objects3DRef.current.hotspot3DMap[hotspotId];

    if ( mesh.actionType === 'close' ) {
      setCanvasStateClass( getCanvasElem(), 'israyover', true );
    } else if ( hotspot3D && hotspot3D.visible ) {
      setCanvasStateClass( getCanvasElem(), 'israyover', true );

      hotspot3D = getIcon3DStrong( hotspot3D );

      if ( props.actions )
        props.actions.hotspotRayover( hotspotId );
    }
  };

  const onRayoutMesh = mesh => {
    let hotspot3D = objects3DRef.current.hotspot3DMap[mesh.hotspotId];

    if ( hotspot3D || mesh.actionType === 'close' ) {
      hotspot3D = getIcon3DWeak( hotspot3D );
      setCanvasStateClass( getCanvasElem(), 'israyover', false );
    }
  };

  const onIntersectMesh = ( sceneState, mesh ) => {
    const { lastmesh } = sceneState;

    if ( mesh && mesh !== lastmesh ) {
      if ( lastmesh ) {
        onRayoutMesh( lastmesh );
      }

      onRayoverMesh( mesh );
      sceneState.lastmesh = mesh;
    }
  };

  const onIntersectNone = sceneState => {
    if ( sceneState.lastmesh ) {
      onRayoutMesh( sceneState.lastmesh );

      sceneState.lastmesh = null;
    }
  };

  const onClickMesh = ( sceneState, mesh ) => {
    const { hotspotId } = mesh;
    const hotspot = objects3DRef.current.hotspot3DMap[hotspotId];

    if ( mesh.actionType === 'close' ) {
      // mesh.parent.visible = false;
      // disposeNodeHierarchy( mesh.parent );
      // this.scene.remove( mesh.parent );
      // this.colliders = this.colliders.filter( collidermesh => (
      //     collidermesh !== mesh
      // ) );
      //
      // if ( this.aimstate ) {
      //     this.aimstate = readyaim.rmmesh( this.aimstate, mesh );
      // }
    } else if ( hotspot && hotspot.hotspotId && hotspot.visible ) {
      // let hotspotinfo = getHotspotData( props.scene.hotspots_dict, hotspotId );
      //
      // if ( isInfoBoxMeta( hotspotinfo ) ) {
      //    this.showInfoBox( hotspotId );
      // }

      if ( props.actions ) {
        props.actions.hotspotEngage( props.scene.id, hotspotId );
      } else {
        iveSceneSequenceEngage( props.content, props.scene.id, hotspotId );
      }
    }
  };

  const detectHitThrottled = throttle( detectHit, 200 );

  const threeSceneRendered = sceneState => {

    // if ( props.isVRSupport && sceneState.bodygroup ) {
    //     setDeviceOrientation( vrState.device, vrState.frameData, sceneState.camera );
    // }
    // if ( props.xrIsEnabled && sceneState.bodygroup ) {
    //     xrSessionDeviceOrientation( sceneState.renderer, sceneState.camera );
    // }

    // if ( sceneState.renderer && sceneState.renderer.isPresenting === false && isVRMode() ) {
    //     fireDisplayChanged();
    // }

    detectHitThrottled( sceneState, isMouseRef );

    if ( props.onRenderFn )
      props.onRenderFn( sceneState );
  };

  // const getCanvas = () => document.getElementById( canvasId );
  //
  // const isInfoBoxMeta = hotspotInfo => (
  //    hotspotInfo && hotspotInfo.infobox && hotspotInfo.infobox.linkedImage
  // );
  //
  // const showInfoBox = hotspotId => {
  //     let hotspotinfo = this.getHotspotData( this.props.scene.hotspots_dict, hotspotId );
  //     let infoboxmeta = this.getInfoBoxMeta( hotspotinfo );
  //     let infoboxgroup;
  //
  //     if ( infoboxmeta ) {
  //         infoboxgroup = this.getInfoBoxGroup( hotspotId, hotspotinfo, infoboxmeta );
  //
  //         this.scene.add( infoboxgroup );
  //         infoboxgroup.lookAt( this.camera.position );
  //     }
  // };

  useEffect( () => {
    // added because hooks require this component
    // to be rendered always, not inside if condition
    if ( !props.isRenderHooksOnly ) {
      lifecycleSet({
        threeSceneCreate,
        threeSceneRefreshed,
        threeSceneRendered
      });
    }
  }, [ props.isRenderHooksOnly ]);

  return <CanvasThreeGQL id={canvasId} />;
}

CanvasPublishGQL.propTypes = {
  media: PropTypes.object, // media used at targetElem (video or image)
  content: PropTypes.object,
  dimensions: PropTypes.object,

  moments: PropTypes.array,
  getMediaElem: PropTypes.func.isRequired,

  isVRMode: PropTypes.bool,
  isDrawMode: PropTypes.bool,
  isRenderHooksOnly: PropTypes.bool,

  actions: PropTypes.object,

  camerarot: PropTypes.array.isRequired,

  selected_hotspotId: PropTypes.string,
  scene: PropTypes.object.isRequired,
  playback: PropTypes.object, // video audio... target playback

  onRenderFn: PropTypes.func.isRequired
};
