import {
  isMimeHls,
  parsePlaylistQualityLevels
} from '../utils/hls';

import {
  parseMp4QualityLevels,
  getPrimaryQualityLevel,
  chooseBestQualityLevel,
  applyQualityLevel
} from './quality';

import {
  ICONIC_ENGINE_HOST,
  ICONIC_ENGINE_LABEL
} from '../env';

//
// util for probing 'media' data

const isHLS = media =>
  isMimeHls( media.type );

// only add protocol to 'url' which is a string with length
function addProtocol ( url ) {
  if ( typeof url === 'string' && url ) {
    url = /\/\/[^#?]*/g.test( url ) ? url : `//${url}`;
  }

  return url;
}

const getBrand = media => {
  const { brand } = media;

  if ( brand ) {
    if ( typeof brand.logo === 'string' ) {
      brand.brand_img_src = brand.logo;
    }

    if ( typeof brand.site === 'string' ) {
      brand.brand_link_primary = addProtocol( brand.site );
    }

    if ( typeof brand.name === 'string' ) {
      brand.brand_label_primary = brand.name;
    }

    if ( brand.brand_img_src || brand.brand_link_primary || brand.brand_label_primary ) {
      brand.isintent = false;
    }
  }

  return brand;
};

const setBrandBlob = ( media, blob ) => ( media && media.brand )
  ? ({...media, brand: { ...media.brand, brand_img_blob: blob}})
  : media;

const setMediaPlaylistCreating = ( media, isPlaylistCreating = true ) => media
  ? ({ ...media, isPlaylistCreating})
  : media;

const getPoster = media => {
  let posterdata = null;

  if ( media.poster ) {
    if ( media.poster.poster_img_src ) {
      posterdata = media.poster;
    } else if ( typeof media.poster === 'string' ) {
      posterdata = {};
      posterdata.poster_img_src = media.poster;
    }
  }

  return posterdata;
};

const setPosterBlob = ( media, blob ) => ( media && media.poster )
  ? ({...media, poster: { ...media.poster, poster_img_blob: blob}})
  : media;

const isBrandIconic = media => {
  const brand = getBrand( media );

  return brand && (
    brand.site.includes( ICONIC_ENGINE_HOST ) || brand.name.includes( ICONIC_ENGINE_LABEL ) );
};

//
// should be more strick
//
const isMedia = media =>
  media && media.id;

const isMediaAdVideo = media => Boolean(
  isMedia( media ) && media.ad_type === 'Video Ad'
);

const isMediaAdImage = media => Boolean(
  isMedia( media ) && media.ad_type === 'Flat Graphic'
);

const isMediaAd = media =>
  isMediaAdVideo( media ) || isMediaAdImage( media );

const isMediaContent = media =>
  isMedia( media ) && !isMediaAd( media );

const isMediaSame = ( mediaa, mediab ) =>
  mediaa && mediab && mediaa.id === mediab.id;

// note: HLS or MP4 is totally different from something being a
// 'vod' vs a 'live' stream
const getMediaType = media =>
  media && media.videoType;

const getProjection = media =>
  media && media.projection;

// 640x480 is a recommended default resultion
//
const getResolution = ( media, defaultResolution ) => {
  let { resolution } = media;
  let { width, height } = resolution;

  if ( !width || !height ) {
    console.error(
      `[!!!] error: invalid resolution ${JSON.stringify( resolution )}`
    );
    resolution = defaultResolution;
    width = 640;
    height = 480;
  }

  return resolution;
};

const setQualityLevels = ( media, isReducedQuality, qualityLevels = parseMp4QualityLevels( media ) ) => {
  media = {
    ...media, qualityLevels,
    qualityLevel: isReducedQuality
      ? chooseBestQualityLevel( qualityLevels )
      : getPrimaryQualityLevel( qualityLevels )
  };

  return applyQualityLevel( media, media.qualityLevel );
};

const setQualityLevelsMP4 = ( media, isReducedQuality ) =>
  setQualityLevels( media, isReducedQuality, parseMp4QualityLevels( media ) );

const setQualityLevelsPlaylist = ( media, isReducedQuality, playlist, playlistURL ) => {
  const qualityLevels = parsePlaylistQualityLevels( playlist, playlistURL, media.resolution );

  const qualityLevel = isReducedQuality
    ? qualityLevels.slice().sort( ( qlA, qlB ) => qlA.resolution.width < qlB.resolution.width ? -1 : 1 )[0]
    : qualityLevels[0];

  media = {
    ...media, qualityLevels,
    qualityLevel
  };

  return applyQualityLevel( media, media.qualityLevel );
};

const findQualityLevel = ( media, qualityValue ) => media.qualityLevels
  .find( level => String( level.value ) === String( qualityValue ) );

// normalize dates defined on media
//   from string style ("2018-05-16T19:00:00.000Z") to timestamp
//
const dateTimeTimestamp = dateTime =>
  dateTime && new Date( dateTime ).getTime();

// if end_time === start_time, stream does not end
const isMediaEndNever = media => Boolean(
  media.end_time && media.end_time !== media.start_time
);

const isMediaStartFuture = media => Boolean(
  media.start_time && Date.now() < dateTimeTimestamp( media.start_time )
);

const isMediaEndPast = media => Boolean(
  media.end_time && !isMediaEndNever( media ) && Date.now() > dateTimeTimestamp( media.end_time )
);

const getThumb = media => {
  let thumb = '';

  if ( media && media.thumbnails && media.thumbnails.length ) {
    thumb = media.thumbnails[
      Math.floor( media.thumbnails.length / 2 )].url;
  }

  return thumb;
};

const getTitle = ( media, defaultTitle ) =>
  media && media.title
    ? media.title
    : defaultTitle;

// note: hls type media do not include duration
const getDuration = ( media, def = 0 ) =>
  ( media && media.duration ) || def;

//
// note: media.qualityLevels are generated by calling
//       setMediaQualityLevels*/2
//
const isMediaQualityLevels = media => Boolean(
  Array.isArray( media.qualityLevels ) && media.qualityLevels.length
);

export {
  isHLS,
  isBrandIconic,
  isMedia,
  isMediaAd,
  isMediaAdImage,
  isMediaAdVideo,
  isMediaContent,
  isMediaSame,
  isMediaStartFuture,
  isMediaEndPast,
  isMediaEndNever,
  isMediaQualityLevels,
  setQualityLevels,
  setQualityLevelsMP4,
  setQualityLevelsPlaylist,
  findQualityLevel,
  getMediaType,
  getDuration,
  getThumb,
  getBrand,
  getTitle,
  setBrandBlob,
  setMediaPlaylistCreating,
  getPoster,
  setPosterBlob,
  getProjection,
  getResolution
};
