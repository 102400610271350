import { useState, useEffect } from 'react';
import screenfull from 'screenfull';

export default function useFullScreen () {
  const [ isFullscreen, set ] = useState( screenfull.enabled && screenfull.isFullscreen );

  const isFullscreenToggle = () => (
    ( screenfull.enabled && !screenfull.isFullscreen )
      ? screenfull.request( document.body )
      : screenfull.toggle() );

  useEffect( () => {
    if ( screenfull.enabled ) {
      screenfull.on( 'change', () => set( screenfull.isFullscreen ) );
    }

    // addEventListeners( handleResize );
    // return () => removeEventListeners( handleResize );
  }, []);

  return {
    isFullscreen,
    isFullscreenToggle
  };
}
