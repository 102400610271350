import { useEffect, useState } from 'react';
import useMedia from './useMedia';
import useContent from './useContent';
import { useLocalToastAction } from './useLocalToast';
import useWindowUnload from './useWindowUnload';
import useApplicationAuthenticate from './useApplicationAuthenticate';
import useLocalMediaPlayback from './useLocalMediaPlayback';
import useLocalPlaylist from './useLocalPlaylist';
import useLocalLogin from './useLocalLogin';
import useLocalIVE from './useLocalIVE';

import {
  analyticsInit,
  analyticsPageLoaded,
  analyticsContentStarted,
  analyticsContentExited,
  analyticsContentTimerPause
} from '../analytics';

import {
  isMediaPlayable,
  isContentVideoSupported,
  isContentInteractive,
  isContentInteractiveValid,
  isContentStream,
  isContentVOD,
  isContentVODValid,
  getContentScene,
  getContentSceneOrDefault,
  getContentCamera,
  getContentCameraDefault,
  getContentCameraOrDefault,
  getSceneSequence,
  getCameraMediaOrVideo,
  getContentMediaOrVideoFuzzy,
  getSceneSequencesTypeHotspot,
  getSceneVideoPrimary,
  createContentVODFromMedia
} from '../utils/contentUtils';

import {
  momentsAddSequenceHotspot,
  sortMoments
} from '../utils/momentsSequences';

const createSceneMoments = scene => {
  const moments = getSceneSequencesTypeHotspot( scene ).reduce( ( moments, sequence ) => momentsAddSequenceHotspot( moments, sequence ), []);

  return sortMoments( moments );
};

export default function useRootPlayer ( opts ) {
  const appAuthState = useApplicationAuthenticate();
  const { userState } = useLocalLogin( appAuthState );
  const { showToast } = useLocalToastAction();
  const { mediaPlaybackState } = useLocalMediaPlayback();
  const {
    playlistState,
    playlistSetContentVOD,
    playlistSetContentStream,
    playlistSetContentInteractive
  } = useLocalPlaylist();
  const [ contentReq, { data, loading } ] = useContent( opts );
  const [ mediaReq, mediaState ] = useMedia( opts );
  const {
    iveState,
    initializeContent
  } = useLocalIVE();
  const [ state, setState ] = useState({
    lastUpdate: Date.now(),
    loading: true,
    content: null,
    sequence: null,
    camera: null,
    level: null,
    scene: null
  });

  const content = data && data.content;

  const media = mediaState.data && mediaState.data.media;

  useEffect( () => { // occurs when login change
    if ( userState && userState.confirmed ) {
      if ( opts.contentId || opts.hash ) {
        contentReq();
      } else if ( opts.mediaId ) {
        mediaReq();
      }
    }
  }, [ userState ]);

  useEffect( () => {
    if ( content === null ) {
      showToast( 'errorMediaNotFound' );
    } else if ( content ) {
      analyticsPageLoaded( content );
    }
  }, [ content ]);

  useEffect( () => {
    if ( userState.appId ) {
      analyticsInit( userState.appId, userState.appAmplitudeKey );
    }
  }, [ userState.appId ]);

  useEffect( () => {
    if ( mediaPlaybackState.playing ) {
      analyticsContentStarted( content, state.scene || state.camera || state.level );
    }
  }, [ mediaPlaybackState.playing ]);

  useWindowUnload( () => {
    analyticsContentExited( content, state.scene || state.camera || state.level );
  });

  // PLAN... media selection will be specific to the content type...
  // VOD will extract edia from the selected level format,
  // Stream will extract medai from selected level camera,
  // Interactive will extract media from selected scene??
  useEffect( () => {
    let mediaOrVideo;

    if ( playlistState.qualityLevel ) {
      if ( media ) {
        if ( !isMediaPlayable( media ) ) {
          showToast( 'errorMediaNotPlayable' );
          return;
        }

        const contentWrapper = createContentVODFromMedia( media );

        if ( !isContentVideoSupported( contentWrapper ) ) {
          showToast( 'errorInvalidCodecH265' );
          return;
        }

        setState({
          content: contentWrapper,
          level: playlistState.qualityLevel,
          media
        });

        if ( !iveState.isInitialized )
          initializeContent( contentWrapper, media );
      }

      if ( isContentVOD( content ) ) {
        // vod hash jygvs7p1ptdgjm7szwca
        mediaOrVideo = getContentMediaOrVideoFuzzy( content, playlistState.qualityLevel.id );

        setState({
          content,
          level: playlistState.qualityLevel,
          media: mediaOrVideo
        });
      }

      if ( isContentStream( content ) ) {
        // stream hash 4ku232lewutfja64yozl
        const camera = getContentCameraOrDefault( content, iveState.selectedCameraId );
        mediaOrVideo = getCameraMediaOrVideo( camera );

        setState({
          content,
          level: playlistState.qualityLevel,
          media: mediaOrVideo,
          camera
        });
      }

      if ( isContentInteractive( content ) ) {
        // iv "S4M" hash NoVszctIlH8mvVuidW7y
        const scene = getContentSceneOrDefault( content, iveState.selectedSceneId );
        mediaOrVideo = getSceneVideoPrimary( scene );

        setState({
          lastUpdate: Date.now(),
          moments: createSceneMoments( scene ),
          content,
          media: mediaOrVideo,
          scene
        });
      }

      if ( mediaOrVideo && !iveState.isInitialized ) {
        initializeContent( content, mediaOrVideo );
      }
    }
  }, [ playlistState.qualityLevel ]);

  useEffect( () => {
    if ( content ) {
      if ( isContentVOD( content ) ) {
        if ( !isContentVODValid( content ) ) {
          showToast( 'errorInvalidVOD' );
        }
      } else if ( isContentInteractive( content ) ) {
        if ( !isContentInteractiveValid( content ) ) {
          showToast( 'errorInvalidStory' );
        }
      }

      if ( !isContentVideoSupported( content ) ) {
        showToast( 'errorInvalidCodecH265' );
      }
    }
  }, [ content && content.id ]);

  useEffect( () => {
    if ( media && !playlistState.qualityLevel ) {
      const content = createContentVODFromMedia( media );

      playlistSetContentVOD( content );
    }

    if ( content && !playlistState.qualityLevel ) {
      if ( isContentVOD( content ) ) {
        playlistSetContentVOD( content );
      }

      if ( isContentStream( content ) ) {
        const cameraDefault = getContentCameraDefault( content );

        if ( cameraDefault ) {
          playlistSetContentStream( content, cameraDefault );
        }
      }

      if ( isContentInteractive( content ) ) {
        const scene = getContentSceneOrDefault( content, iveState.selectedSceneId );

        playlistSetContentInteractive( content, scene );
      }
    }
  }, [ content && content.id, media && media.id ]);

  useEffect( () => {
    setState( state => ({
      ...state,
      lastUpdate: Date.now(),
      sequence: getSceneSequence( state.scene, iveState.selectedSequenceId )
    }) );
  }, [ iveState.selectedSequenceId ]);

  useEffect( () => {
    if ( content && iveState.selectedCameraId && iveState.isInitialized ) {
      const camera = getContentCamera( content, iveState.selectedCameraId );

      analyticsContentTimerPause( content );
      playlistSetContentStream( content, camera );
    }
  }, [ iveState.selectedCameraId ]);

  useEffect( () => {
    if ( content && iveState.selectedSceneId && iveState.isInitialized ) {
      const scene = getContentScene( content, iveState.selectedSceneId );

      analyticsContentTimerPause( content );
      playlistSetContentInteractive( content, scene );
    }
  }, [ iveState.selectedSceneId ]);

  return {
    userState,
    iveState,
    rootPlayer: {
      ...state,
      loading: Boolean( ( userState.token && !userState.confirmed ) || loading ),
      token: userState.token
    }
  };
}
